import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import bootstrapToggle from './bootstrap-toggle.js';


class ShareProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: props.project
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      project: newProps.project
    });

    let currentSharing = this.props.project ? this.props.project.isSharingEnabled : false;
    let newSharing = newProps.project ? newProps.project.isSharingEnabled : false;

    if (currentSharing !== newSharing) {
      //$('#shareProject').bootstrapToggle(newProps.project.isSharingEnabled ? 'on' : 'off');
    }
  }

  handleClose = () => {
    this.setState({
      showSpinner: false,
      showComplete: false,
    });
  }

  componentDidMount() {
    let { project } = this.state;

    let that = this;
    $('#shareProject').change(function() {
      that.handleShareChange($(this).prop('checked'));
    });

    if (project) {
      //$('#shareProject').bootstrapToggle(project.isSharingEnabled ? 'on' : 'off');
    }
  }

  handleCopyUrl = () =>  {
    const { project } = this.props;

    if (project) {
        /* Get the text field */
      var copyText = document.getElementById("share_url");

      /* Select the text field */
      copyText.select();

      /* Copy the text inside the text field */
      document.execCommand("Copy");

    }

    return false;
  }

  handleShareChange = ( shareEnabled ) => {
    utils.log('shareenabled');
    utils.log("ShareEnabled=", shareEnabled);
    let { project } = this.state;

    if (project) {
      project.isSharingEnabled = shareEnabled;

      this.setState({
        project
      });

      if (this.props.onChange) {
        this.props.onChange(shareEnabled);
      }
    }
  }

  render() {
    const { project } = this.state;

    return (
      <div className="modal fade share-modal-dialog-centered" id="ShareProjectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">Share Project</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="share_projectName">{project && project.name}</div>
                <div className="share_enabledContainer">
                  Sharing is currently:
                </div>
                <div className="share_toggleContainer">
                  <input id="shareProject" className="toggleInput share_toggle"
                    type="checkbox"
                    data-size="small"
                    data-toggle="toggle"
                    data-on="Enabled"
                    data-off="Disabled"
                    data-width="90"
                    onChange={(event) => this.handleShareChange(event.target.checked)}
                    />
                </div>
              <hr />
              <div className="share_urlHeader">Project URL</div>
              <input id="share_url" type="text" className="form-control" value={"https://voiceapps.com/preview/" + (project && project.id)} onChange={() => {return null;}}/>
              <hr />
              <div className="share_copyMessage">Anyone with the URL can preview your skill</div>
              <div className="share_copyURL">
                <button type="button" className="btn btn-warning" onClick={this.handleCopyUrl}>Copy URL</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ShareProject;
