import React, { Component } from 'react';
import './styles.css';

class Spinner extends Component {

  render() {
    let { size } = this.props;

    if (!size) size = 10;

    size += 'px';
    return (
      <div className="spinner">
        <div style={{width: size, height: size}} className="ball ball-1"></div>
        <div style={{width: size, height: size}} className="ball ball-2"></div>
        <div style={{width: size, height: size}} className="ball ball-3"></div>
      </div>
    );
  }
}

export default Spinner;
