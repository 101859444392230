import React, { Component } from 'react';
import './index.css';
import Header from '../Header';
import Footer from '../Footer';
import skills from './skills.json';

import FA from '@fortawesome/react-fontawesome';


class Home extends Component {
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount() {
    //$('.prettySocial').prettySocial();
  }

  navigateTo = (url) => {
    window.open(url, "_blank");
  }

  render() {
    return (
      <div>
        <Header menuId={1} auth={this.props.auth} history={this.props.history}/>
        <div className="built_header">
          <div className="built_headerText">Built with Voice Apps</div>
          <div className="built_tagline">Over 10% of all Alexa Skills worldwide were built with Voice Apps!<br/>Over 100,000 people use our Amazon Alexa Skills every single day!<br />Here are some examples of what you can build...</div>
        </div>
        <div className="container built_container">
          <div className="row">
          {skills.map((skill, index) => {
            return (
              <div className="col col-12 col-sm-6 col-xl-4" >
                <div className="built_card" onClick={() => this.navigateTo(skill.skillUrl)}>
                  <div className="built_cardContent">
                    <div className="built_skill_logo">
                      <img src={skill.logoUrl} alt={skill.name + " Amazon Alexa Skill"} />
                    </div>
                    <div className="built_skill_name">{skill.name}</div>
                    <div className="built_skill_text">
                      {skill.description}
                    </div>
                    <div className="built_skill_start">{"\"Alexa, open " + (skill.invocationName ? skill.invocationName : skill.name) + "\""}</div>
                  </div>
                </div>
              </div>
            )
          })};
          </div>
        </div>
        <div className="built_many_more">And many, many more!</div>
        <div className="built_footer">We can&apos;t wait to see what you build!</div>
        <div className="built_footer_link"><div className="btn btn-primary" onClick={() => this.goTo('login')}>Get Started Now!  It&apos;s Free!</div></div>
      </div>
    );
  }
}


export default Home;
