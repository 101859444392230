import React, { Component } from 'react';

import './styles.css';
import Header from '../Header';

class Privacy extends Component {

  componentDidMount() {
    window.dojoRequire(["mojo/signup-forms/Loader"], function(L) { L.start({"baseUrl":"mc.us16.list-manage.com","uuid":"80d7c20c889179bd9ebf4ed7d","lid":"f4bcbfc9d0","uniqueMethods":true}) });
  }
  render() {
    return (
      <div>
        <Header auth={this.props.auth} history={this.props.history}/>
        <div className='container escape_container'>
          <div className='row'>
            <div className="col text-center">
              <h2>"Alexa, start Escape Games"</h2>
              <div className="escape_subHeader">Absolutely the best Escape Skill on Amazon Alexa</div>
            </div>
          </div>
          <div className='row'>
            <div className="col text-center">
              <div className="escape_game_header">The Dungeon</div>
              <div className="escape_game"><img className="escape_game_img" src="https://cdn.voiceapps.com/dungeon.jpg"/></div>
            </div>
            <div className="col text-center">
              <div className="escape_game_header">Butch McClain's Hideout</div>
              <div className="escape_game"><img className="escape_game_img" src="https://cdn.voiceapps.com/butch_mcclain.jpg"/></div>
            </div>
          </div>
          <div className='row'>
            <div className="col text-center">
              <div className="escape_game_header">Calico Jack's Lair</div>
              <div className="escape_game"><img className="escape_game_img" src="https://cdn.voiceapps.com/calico_jack.jpg"/></div>
            </div>
            <div className="col text-center">
              <div className="escape_game_header">Insane Asylum</div>
              <div className="escape_game"><img className="escape_game_img" src="https://cdn.voiceapps.com/insane_asylum.jpg"/></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
