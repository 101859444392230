import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import $ from 'jquery';
import utils from '../helpers/utils.js';
import Upgrade from '../Upgrade';
import FA from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../candlewick/redux/actions/auth';

class Header extends Component {

  componentDidMount() {
    ((d, s, id, cb) => {
      const element = d.getElementsByTagName(s)[0]
      const fjs = element
      let js = element
      js = d.createElement(s)
      js.id = id
      js.src = '//apis.google.com/js/client:platform.js'
      fjs.parentNode.insertBefore(js, fjs)
      js.onload = cb
    })(document, 'script', 'google-login', () => {
      window.gapi.load('auth2', () => {
        this.setState({
          disabled: false,
        })
      })
    })

    // $('#projectButton').popover();
    // $('#helpButton').popover();
    // $('#accountButton').popover();
  }

  goTo = (route) => { // eslint-disable-line
    this.props.history.replace(`/${route}`)
    return false;
  }

  login = () => { // eslint-disable-line
    this.props.auth.login();
  }

  logout = () => { // eslint-disable-line
    utils.logout().then(result => {

      const auth2 = window.gapi.auth2.getAuthInstance()
      if (auth2 != null) {
        auth2.signOut().then(result => {
          utils.log('Google Signout success!');
          this.props.onLogoutSuccess();
          this.goTo('login');
        });
      } else {
        this.goTo('login');
      }

    }).catch(err => {
      const auth2 = window.gapi.auth2.getAuthInstance()
      if (auth2 != null) {
        auth2.signOut().then(result => {
          utils.log('Google Signout success!');
          this.props.onLogoutSuccess();
          this.goTo('login');
        });
      } else {
        this.goTo('login');
      }
    });

    try {
      this.props.logout();
    } catch(err) {
      utils.log(err);
    }

  }

  handleTestClick = () => { // eslint-disable-line
    if (this.props.onTestClick) {
      this.props.onTestClick();
    }
  }

  handleShareClick = () => { // eslint-disable-line
    if (this.props.onShareClick) {
      this.props.onShareClick();
    }
  }

  handlePublishClick = () => { // eslint-disable-line
    if (this.props.onPublishClick) {
      this.props.onPublishClick();
    }
  }

  handlePublishingInfoClick = () => {
    const { project } = this.props;
    window.open(`https://developer.amazon.com/alexa/console/ask/publish/alexapublishing/${project.skillId}/development/en_US/skill-info`, '_blank');
  }

  changeLocale = (locale) => {
    if (this.props.onChangeLocale) {
      this.props.onChangeLocale(locale);
    }
  }

  handleUpgradeClick = () => {
    if (this.props.onUpgradeClick) {
      this.props.onUpgradeClick();
    }
  }

  handleUpgradeModalOpen = () => { // eslint-disable-line
    $('#EditProjectModal').modal('hide');
    $('#PremiumModal').modal('hide');
    $('#UpgradeModal').modal('show');
  }

  handleUpgradeModalClose = () => { // eslint-disable-line
    $('#PremiumModal').modal('hide');
  }

  render() {
    const { menuId, project, currentLocale, isProfessional, showTestButton, showShareButton, title } = this.props;

    // utils.log('isProfessional = ', isProfessional);
    let availableLocales = [];
    let localeName = '';
    let projectName = title ? title : 'Voice Apps';

    if (project) {
      let locales = [
        {
          value: 'en-US',
          label: 'English (US)'
        },
        {
          label: 'English (UK)',
          value: "en-GB"
        },
        {
          label:'English (IN)',
          value: "en-IN"
        },
        {
          label:'English (CA)',
          value: "en-CA"
        },
        {
          label:'English (AU)',
          value: "en-AU"
        },
        {
          label: 'Spanish (US)',
          value: "es-US"
        },
        {
          label: 'Spanish (ES)',
          value: "es-ES"
        },
        {
          label: 'Spanish (MX)',
          value: "es-MX"
        },
        {
          label:'French (FR)',
          value: "fr-FR"
        },
        {
          label: 'French (CA)',
          value: "fr-CA"
        },
        {
          label:'German',
          value: "de-DE"
        },
        {
          label:'Italian',
          value: "it-IT"
        },
        {
          label:'Japanese',
          value: "ja-JP"
        },
        {
          label: 'Portuguese (BR)',
          value: "pt-BR"
        }
      ];

      availableLocales = locales.filter(l => {
        let locale = project.locales.find(loc => {
          //utils.log("Locale=", loc);
          return loc.locale === l.value
        });
        return locale != null;
      });

      //utils.log('availableLocales=', availableLocales);
      //utils.log('currentLocale=', currentLocale);
      //utils.log('project locales', project.locales);
      //utils.log('available locales', availableLocales);
      localeName = availableLocales.find(l => {return l.value === project.locales[currentLocale].locale}).label;
      projectName = project.name;
      utils.log('got here');
    }

    return (

      <div>
        <Upgrade />
        <nav className="navbar navbar-static-top navbar-expand-sm navbar-dark bg-dark">
          <a className="navbar-brand" href="#">
            <div className="header_logo_container" onClick={this.goTo.bind(this, 'projects')}>
              <img className="header_logo" alt="logo" src="/images/logo_small.png" />
              <div className="header_logo_text" href="">{projectName}</div>
            </div>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>


          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            {project ?
              <div className="navbar-nav hs_buttons">
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {localeName}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {availableLocales.map((locale, index) => {
                      return (
                        <a key={index} className="dropdown-item" href="#" onClick={() => this.changeLocale(locale.value)}>{locale.label}</a>
                      )
                    }, this)}
                  </div>
                </div>
              </div> : null
            }
            {project ?
              <div className="navbar-nav hs_buttonsMiddle">
                {(showTestButton == null || showTestButton === true) && <button id="test_button" type="button" className="btn btn-primary hs_button" onClick={this.handleTestClick}><FA className="hs_icon" icon={["far", "volume-up"]} /> Test</button> }
                {(showShareButton == null || showShareButton === true) && !project.isDemo && <button id="share_button" type="button" className="btn btn-info hs_button" onClick={this.handleShareClick}><FA className="hs_icon" icon={["far", "share-alt"]} /> Share</button>  }
                {!project.isDemo ? <button id="publish_button" type="button" className="btn btn-success hs_button" onClick={this.handlePublishClick}><FA className="hs_icon" icon={["far", "cloud-upload"]} /> Publish</button> : null }
                {project.IsFlashBriefing ? <button id="publishing_info_button" type="button" className="btn btn-info hs_button" onClick={this.handlePublishingInfoClick}>Skill Info</button> : null }

              </div>
              : null
            }
            <div className="navbar-nav navbar-right hs_buttonsMiddle header_navbar_links">
              <div className="btn-group header_buttonGroup">
                <button
                  type="button"
                  className="btn btn-secondary hs_button2"
                  id="projectButton" data-toggle=""
                  aria-haspopup="true" aria-expanded="false"
                  onClick={this.goTo.bind(this, 'projects')}
                  data-trigger="hover" data-placement="bottom" data-content="Projects">
                  <FA className="hs_icon" icon={["far", "file-alt"]}/>
                </button>
              </div>
              <div className="btn-group header_buttonGroup">
                <button type="button" className="btn btn-info hs_button2" id="helpButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-trigger="hover" data-placement="bottom" data-content="Help"
                onClick={() => {$('#helpButton').popover('hide')}}>
                  <FA className="hs_icon" icon={["far", "question-circle"]} />
                </button>
                <div className="dropdown-menu" aria-labelledby="helpButton">
                  <a className="dropdown-item" href="http://help.voiceapps.com" target="_new">Help Center</a>
                  <a className="dropdown-item" href="https://www.youtube.com/channel/UCMV0ey-4D0Boaj58x7FgsLw/videos" target="_new">YouTube Channel</a>
                  <a className="dropdown-item" href="https://www.facebook.com/groups/voiceapps/" target="_new">Community</a>
                </div>
              </div>
              <div className="btn-group header_buttonGroup">
                <button type="button" className="btn btn-primary hs_button2" id="hostingButton" aria-haspopup="true" aria-expanded="false" data-trigger="hover" data-placement="bottom" data-content="Account"
                onClick={this.goTo.bind(this, 'hosting')}>
                  <FA className="hs_icon" icon={["far", "h-square"]} />
                </button>
              </div>
              <div className="btn-group header_buttonGroup">
                <button type="button" className="btn btn-primary hs_button2" id="accountButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-trigger="hover" data-placement="bottom" data-content="Account"
                onClick={() => {$('#accountButton').popover('hide')}}>
                  <FA className="hs_icon" icon={["far", "user"]} />
                </button>
                <div className="dropdown-menu" aria-labelledby="accountButton">
                  <a className="dropdown-item" href="#" onClick={this.logout}>Sign Out</a>
                </div>
              </div>

              {!(isProfessional == null ? true : isProfessional) ?
                <div className="btn-group">
                <div className="navbar-nav">
                  <button id="upgrade_button" type="button" className="btn btn-warning hs_button2 hs_button_upgrade" onClick={this.handleUpgradeModalOpen}>Upgrade</button>
                </div>
                </div> : null
              }
            </div>
          </div>
        </nav>

      </div>

    );
  }
}

Header.propTypes = {
  logout: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

export default connect(null, mapDispatchToProps)(Header);
//export default Header;
