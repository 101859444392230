var Countries = [
  // {
  //   "languageCode": "cs",
  //   "countryCode": "CZ",
  //   "name": "Czech Republic",
  //   "language": "Czech"
  // },
  // {
  //   "languageCode": "da",
  //   "countryCode": "DK",
  //   "name": "Denmark",
  //   "language": "Danish"
  // },
  // {
  //   "languageCode": "de",
  //   "countryCode": "AT",
  //   "name": "Austria",
  //   "language": "German"
  // },
  // {
  //   "languageCode": "de",
  //   "countryCode": "CH",
  //   "name": "Switzerland",
  //   "language": "German"
  // },
  {
    "locale": "de-DE",
    "languageCode": "de",
    "countryCode": "DE",
    "name": "Germany",
    "language": "German"
  },
  {
    "locale": "en-AU",
    "languageCode": "en",
    "countryCode": "AU",
    "name": "Australia",
    "language": "English"
  },
  // {
  //   "languageCode": "en",
  //   "countryCode": "BE",
  //   "name": "Belgium",
  //   "language": "English"
  // },
  {
    "locale": "en-CA",
    "languageCode": "en",
    "countryCode": "CA",
    "name": "Canada",
    "language": "English"
  },
  // {
  //   "languageCode": "en",
  //   "countryCode": "CH",
  //   "name": "Switzerland",
  //   "language": "English"
  // },
  {
    "locale": "en-GB",
    "languageCode": "en",
    "countryCode": "GB",
    "name": "United Kingdom",
    "language": "English"
  },
  // {
  //   "languageCode": "en",
  //   "countryCode": "HK",
  //   "name": "Hong Kong",
  //   "language": "English"
  // },
  // {
  //   "languageCode": "en",
  //   "countryCode": "IE",
  //   "name": "Republic of Ireland",
  //   "language": "English"
  // },
  // {
  //   "languageCode": "en",
  //   "countryCode": "MY",
  //   "name": "Malaysia",
  //   "language": "English"
  // },
  // {
  //   "languageCode": "en",
  //   "countryCode": "NZ",
  //   "name": "New Zealand",
  //   "language": "English"
  // },
  // {
  //   "languageCode": "en",
  //   "countryCode": "PH",
  //   "name": "Philippines",
  //   "language": "English"
  // },
  // {
  //   "languageCode": "en",
  //   "countryCode": "SG",
  //   "name": "Singapore",
  //   "language": "English"
  // },
  {
    "locale": "en-US",
    "languageCode": "en",
    "countryCode": "US",
    "name": "United States",
    "language": "English"
  },
  // {
  //   "languageCode": "es",
  //   "countryCode": "AR",
  //   "name": "Argentina",
  //   "language": "Spanish"
  // },
  // {
  //   "languageCode": "es",
  //   "countryCode": "CL",
  //   "name": "Chile",
  //   "language": "Spanish"
  // },
  {
    "locale": "es-ES",
    "languageCode": "es",
    "countryCode": "ES",
    "name": "Spain",
    "language": "Spanish"
  },
  {
    "locale": "es-MX",
    "languageCode": "es",
    "countryCode": "MX",
    "name": "Mexico",
    "language": "Spanish"
  },
  // {
  //   "languageCode": "fi",
  //   "countryCode": "FI",
  //   "name": "Finland",
  //   "language": "Finnish"
  // },
  // {
  //   "languageCode": "fil",
  //   "countryCode": "PH",
  //   "name": "Philippines",
  //   "language": "Filipino"
  // },
  // {
  //   "languageCode": "fr",
  //   "countryCode": "BE",
  //   "name": "Belgium",
  //   "language": "French"
  // },
  {
    "locale": "fr-CA",
    "languageCode": "fr",
    "countryCode": "CA",
    "name": "Canada",
    "language": "French"
  },
  // {
  //   "languageCode": "fr",
  //   "countryCode": "CH",
  //   "name": "Switzerland",
  //   "language": "French"
  // },
  {
    "locale": "fr-FR",
    "languageCode": "fr",
    "countryCode": "FR",
    "name": "France",
    "language": "French"
  },
  // {
  //   "languageCode": "it",
  //   "countryCode": "CH",
  //   "name": "Switzerland",
  //   "language": "Italian"
  // },
  {
    "locale": "it-IT",
    "languageCode": "it",
    "countryCode": "IT",
    "name": "Italy",
    "language": "Italian"
  },
  {
    "locale": "ja-JP",
    "languageCode": "ja",
    "countryCode": "JP",
    "name": "Japan",
    "language": "Japanese"
  },
  // {
  //   "languageCode": "ms",
  //   "countryCode": "MY",
  //   "name": "Malaysia",
  //   "language": "Malay"
  // },
  // {
  //   "languageCode": "nb",
  //   "countryCode": "NO",
  //   "name": "Norway",
  //   "language": "Norwegian"
  // },
  // {
  //   "languageCode": "nl",
  //   "countryCode": "BE",
  //   "name": "Belgium",
  //   "language": "Dutch"
  // },
  // {
  //   "languageCode": "nl",
  //   "countryCode": "NL",
  //   "name": "The Netherlands",
  //   "language": "Dutch"
  // },
  // {
  //   "languageCode": "pl",
  //   "countryCode": "PL",
  //   "name": "Poland",
  //   "language": "Polish"
  // },
  // {
  //   "languageCode": "pt",
  //   "countryCode": "BR",
  //   "name": "Brazil",
  //   "language": "Portuguese"
  // },
  // {
  //   "languageCode": "pt",
  //   "countryCode": "PT",
  //   "name": "Portugal",
  //   "language": "Portuguese"
  // },
  // {
  //   "languageCode": "sv",
  //   "countryCode": "FI",
  //   "name": "Finland",
  //   "language": "Swedish"
  // },
  // {
  //   "languageCode": "sv",
  //   "countryCode": "SE",
  //   "name": "Sweden",
  //   "language": "Swedish"
  // },
  // {
  //   "languageCode": "tr",
  //   "countryCode": "TR",
  //   "name": "Turkey",
  //   "language": "Turkish"
  // },
  // {
  //   "languageCode": "zh",
  //   "countryCode": "HK",
  //   "name": "Hong Kong",
  //   "language": "Chinese"
  // },
  // {
  //   "languageCode": "zh",
  //   "countryCode": "TW",
  //   "name": "Taiwan",
  //   "language": "Chinese"
  // }
];

module.exports = Countries;
