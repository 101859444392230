import React, { Component } from 'react';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiText from '../MultiText';
import VariablePicker from '../VariablePicker';

class GetRandom extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      action: props.data,
      inputVariableName: props.data.variable
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      action: props.data,
      inputVariableName: props.data.variable
    });
  }

  componentDidMount() {
    if (this.boxRef.current) {

      this.boxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  handleVariableChange = (value) => {
    let { action } = this.state;

    action.variable = value;

    this.setState({
      inputVariableName: value,
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }

  }

  handleRandomValuesChange = (data) => {
    let { action } = this.state;

    action.data = data;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { action, inputVariableName } = this.state;

    return (
      <div className="intent_card" ref={this.boxRef}>
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_random" icon={["far", "random"]} /></span>Get Random Value</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <MultiText
          header="Choose one of the following items randomly..."
          inputPlaceHolder="Enter a new value"
          allowJSON={true}
          data={action.data}
          variables={this.props.variables}
          onChange={this.handleRandomValuesChange}
        />
        <div className="form-group">
          <span className="trigger_utteranceHeader">And assign it to a variable named:</span>
          {/*<input className="form-control random_inline" value={inputVariableName} placeholder="variable name" type="text" onChange={(event) => this.handleVariableChange(event.target.value)} />*/}
          <VariablePicker initialValue={inputVariableName} variables={this.props.variables} onChange={(data) => this.handleVariableChange(data)}/>
        </div>
      </div>
    );
  }
}

export default GetRandom;
