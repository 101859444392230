import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../redux/actions/auth';
import FA from '@fortawesome/react-fontawesome';
import utils from '../../helpers/utils.js';

import { Link } from 'react-router-dom';

import CopyToClipboard from '../components/CopyToClipboard';

import { formatDate } from '../library/time';
import { getCdnUrl } from '../library/cdn';

class Dashboard extends Component {

	static renderLoadingState() {
		return (
			<tr>
				<td colSpan="4" className="text-center">
					<button type="button" className="btn btn-link loading" />
				</td>
			</tr>
		);
	}

	static renderEmptyState() {
		return (
			<tr>
				<td colSpan="4" className="text-center">
					<i>
						{'You don\'t have any uploaded files. Click '}
						<Link to="/hosting/upload">upload</Link>
						{' to create one.'}
					</i>
				</td>
			</tr>
		);
	}

	static renderNotAuthenticatedState() {
		return (
			<tr>
				<td colSpan="4" className="text-center">
					<i>Guest users cannot view their past uploads.  Sign-in above to view your files.</i>
				</td>
			</tr>
		);
	}

	constructor(props) {
		super(props);

		const self = this;

		self.state = {
			loaded: false,
			files: [],
			isLoggedIn: false
		};

		self.renderFileRow = self.renderFileRow.bind( self );
	}

	componentWillMount() {
    var that = this;
    utils.getCurrentUser().then(user => {
			that.props.login(user.user_id);
    }).catch(err => {
      utils.log(err);
      //that.goTo('login');
    })
  }

	componentDidMount() {
		const self = this;
		const { user } = self.props;

		if (user !== '0') {
			const compare = (a, b) => {
				if (a.created > b.created) {
					return -1;
				}

				if (a.created < b.created) {
					return 1;
				}

				return 0;
			};

			fetch(`https://253mhygcg5.execute-api.us-east-1.amazonaws.com/production/files/user/${user}`)
				.then(response => response.json())
				.then(json => self.setState({ files: json.sort(compare), loaded: true }))
				.catch(error => console.error(error));
		}
	}

	handleDeleteFile(fileId, filename) {
		const self = this;
		const { files } = self.state;

		if (!confirm(`Do you really want to delete "${filename}" file?`)) { // eslint-disable-line no-restricted-globals
			return;
		}

		fetch(`https://253mhygcg5.execute-api.us-east-1.amazonaws.com/production/file/${fileId}`, { method: 'DELETE' })
			.catch(error => console.error(error));

		self.setState({ files: files.filter(file => file.id !== fileId) });
	}

	renderFileRow(file) {
		const onDeleteClick = () => this.handleDeleteFile(file.id, file.filename);
		const url = `${getCdnUrl()}/${file.id}`;

		return (
			<tr key={file.id}>
				<td>
					<a href={url} target="_blank" rel="noopener noreferrer">
						{file.filename}
					</a>
					<CopyToClipboard text={url} />
				</td>
				<td>{file.filetype}</td>
				<td>{formatDate(file.created)}</td>
				<td>
					<button type="button" className="btn-sm btn-danger" onClick={onDeleteClick}>
						Delete
					</button>
				</td>
			</tr>
		);
	}

	handleUploadClick = () => {
		this.props.history.replace(`/hosting/upload`)
    return false;
	}

	render() {
		const self = this;
		const { user } = self.props;
		const { files, loaded } = self.state;

		utils.log("dashboard user=", user);


		let rows = false;
		if (!loaded) {
			rows = Dashboard.renderLoadingState();
		} else if (files.length) {
			rows = files.map(self.renderFileRow);
		} else {
			rows = Dashboard.renderEmptyState();
		}

		if (user === false) {
			rows = Dashboard.renderNotAuthenticatedState();
		}

		return (
			<div>
				<div className="alert alert-info hosting_announce">
					<p>VoiceApps.com now features FREE audio and image file conversion and hosting!</p>
					<ul>
						<li>Automatically convert audio and image files to common formats for Alexa and Google</li>
						<li>Files are hosted using our global Content Delivery Network (CDN) so your audio and image files will be delivered much faster.</li>
						<li>Copy final URLs to the clipboard with a single click.</li>
					</ul>
					<p>Try it today!  It&apos;s completely FREE!</p>
				</div>
				<div className="dashboard_header">
					<h1>My Files</h1>
					<button onClick={this.handleUploadClick} className="btn btn-primary dashboard_upload" >Upload File(s)</button>
				</div>

				<table className="table table-striped table-hover my-2">
					<thead>
						<tr>
							<th>File</th>
							<th>Type</th>
							<th>Created</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				<div className="upload_instructions">
					Click the <span className="clipboardCopy2"title="Copy to Clipboard"><FA icon={["far", "link"]} /></span> icon to copy the URL of the file to your clipboard.
				</div>
			</div>
		);
	}

}

Dashboard.propTypes = {
	user: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	login: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth }) => ({ user: auth.user });
const mapDispatchToProps = (dispatch) => bindActionCreators({ login }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
