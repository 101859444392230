import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import MultiText from '../MultiText';
import Expression from '../Expression';
import Editor from '../Editor';

class NextAction extends Component {
  constructor(props) {
    super(props);

    //console.log("--------NEXT Action Constructor =============");
    //console.log(props.data.when);

    this.state = {
      action: props.data,
      intents: props.intents,
      editWhen: props.data.when != null
    };
  }

  componentWillReceiveProps(props) {
    //console.log("--------NEXT Action Will Receive =============");
    //console.log(props.data.when);
    this.setState({
      action: props.data,
      intents: props.intents,
      editWhen: props.data.when != null
    })
  }

  toggleWhen = () => { // eslint-disable-line
    let { action, editWhen, savedWhen } = this.state;

    if (action.when && editWhen ) {
      savedWhen = action.when;
      action.when = null;
    } else {
      action.when = savedWhen ? savedWhen : {}
    }

    this.setState({
      action,
      editWhen: !this.state.editWhen,
      savedWhen
    })

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  changeAction = (value) => { // eslint-disable-line
    let { action } = this.state;

    action.action = value;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  changeWhenOperator = (operator) => { // eslint-disable-line
    let { action } = this.state;

    if (!action.when) action.when = {};

    action.when.operator = operator;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleChangeWhenTarget = (value) => { // eslint-disable-line
    let { action } = this.state;

    if (!action.when) action.when = {};

    action.when.sourceVariable = value;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleChangeWhenValue = (value) => { // eslint-disable-line
    let { action } = this.state;

    if (!action.when) action.when = {};

    action.when.targetVariable = value;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleExpressionChange = (data) => {
    //console.log("----------Handle Expression Change----------");
    //console.log(data);

    let { action } = this.state;

    if (!action.when) action.when = {};

    action.when = data;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }



  changeTargetIntent = (id) => { // eslint-disable-line
    let { action } = this.state;

    action.intent = id;

    this.setState({
      action
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleAudioURLChange = (data) => {
    let { action } = this.state;

    action.audioUrls = data;

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleProductKeyChange = (data) => {
    let { action } = this.state;

    action.productKey = data;

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  deleteIntent = () => { // eslint-disable-line
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  handleAddIntent = () => {
    if (this.props.onAddIntent) {
      this.props.onAddIntent();
    }
  }

  handleManageProduct = () => { // eslint-disable-line
    $('#ManageProductModal').modal('show');
  }

  handleOpenVariables = () => {
    window.va_showVariables();
  }

  handleChangeAudioMetadata(key, value) {
    let { action } = this.state;

    if (!action.metadata) {
      action.metadata = {};
    }

    action.metadata[key] = value;

    this.setState({
      action
    })

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  render() {
    const { action, intents,  editWhen } = this.state;
    const { canDelete, products } = this.props;

    let rules = [
      {
        regEx: '^https:\/\/.*$',
        error: 'URL must start with https://'
      }
    ];

    let nextActionText = '';
    if (action.action === 'waitForUser') nextActionText = 'Wait for the user';
    if (action.action === 'endSession') nextActionText = 'End the session';
    if (action.action === 'pauseSession') nextActionText = 'Pause the session';
    if (action.action === 'goToIntent') nextActionText = 'Execute another intent';
    if (action.action === 'previousIntentExecute') nextActionText = 'Execute previous intent';
    if (action.action === 'previousIntentActivate' || action.action === 'previousIntent') nextActionText = 'Activate previous intent';
    if (action.action === 'playLongAudio') nextActionText = 'Play a long audio stream';
    if (action.action === 'buyProduct') nextActionText = 'Buy in-skill product';
    if (action.action === 'cancelProduct') nextActionText = 'Cancel in-skill product';

    let targetIntentText = '';
    let targetIntent = intents.find(f => { return f.id === action.intent});
    if (targetIntent) targetIntentText = targetIntent.name;

    utils.log('------------products----------');
    let productName = 'Select a Product';
    let selectedProduct = products && products.find(p => p.key === action.productKey);
    if (selectedProduct) {
      productName = selectedProduct.name;
    }
    utils.log(productName);

    let sourceVariable = "Select";

    if (action.when) {
      if (action.when.sourceVariable) {
        sourceVariable = action.when.sourceVariable;
        if (sourceVariable.length > 14 ) {
          sourceVariable = sourceVariable.substring(0,14) + '...';
        }
      }
    }

    //console.log('next action variables', this.props.variables);

    return (
      <div className="intent_card">

        <div className="form-group">
          <div className="intent_cardHeader">Next Action</div>
          {canDelete ? <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div> : null }
          <div className="na_utteranceHeader">After all of the other actions, Alexa will...</div>
          <div className="dropdown na_inline">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {nextActionText}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('waitForUser')}>Wait for the user</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('endSession')}>End the session</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('pauseSession')}>Pause the session</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('goToIntent')}>Execute another intent...</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('previousIntentExecute')}>Execute previous intent</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('previousIntentActivate')}>Activate previous intent</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('playLongAudio')}>Play a long audio stream...</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('buyProduct')}>Buy in-skill product...</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeAction('cancelProduct')}>Cancel in-skill product...</a>
            </div>
          </div>
        </div>
        {action.action === 'waitForUser' ?
          <div className="na_row">
            <button type="button" className="btn btn-primary" onClick={this.handleAddIntent}>Add Intent</button>
          </div> : null
        }
        {action.action === 'goToIntent' ?
          <div className="na_row">
            <div className="na_label">Intent to execute:</div>
            <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {targetIntentText}
            </button>
            <div className="dropdown-menu na_dropdown" aria-labelledby="dropdownMenuButton">
              {intents.map((intent, index) => {
                return (
                  <a key={index} className="dropdown-item" href="#" onClick={() => this.changeTargetIntent(intent.id)}>{intent.name}</a>
                )
              })}
            </div>
          </div> : null
        }
        {action.action === 'playLongAudio' ?
          <div className="na_row">
            <MultiText
              header="URL of the audio to play..."
              inputPlaceHolder="Enter audio URL"
              inputPlaceHolder2="Enter another and we'll pick randomly."
              inputPlaceHolder3="Enter another URL (optional)."
              data={action.audioUrls}
              variables={this.props.variables}
              allowVariables={true}
              rules={rules}
              onChange={(data) => this.handleAudioURLChange(data)}
            />

            <label htmlFor="audio_title" className="audio_label">Title:</label>
            <Editor
              variables={this.props.variables}
              placeholder="Audio title"
              value={action.metadata ? action.metadata.title : ''}
              onChange={(text) => this.handleChangeAudioMetadata("title", text)}
              allowVariables={true}
              singleLine={true}
            />

            <label htmlFor="audio_title" className="audio_label">Subtitle:</label>
            <Editor
              variables={this.props.variables}
              placeholder="Audio subtitle"
              value={action.metadata ? action.metadata.subtitle : ''}
              onChange={(text) => this.handleChangeAudioMetadata("subtitle", text)}
              allowVariables={true}
              singleLine={true}
            />

            <label htmlFor="audio_title" className="audio_label">Art:</label>
            <Editor
              variables={this.props.variables}
              placeholder="Audio artwork url"
              value={action.metadata ? action.metadata.art : ''}
              onChange={(text) => this.handleChangeAudioMetadata("art", text)}
              allowVariables={true}
              singleLine={true}
            />

            <label htmlFor="audio_title" className="audio_label">Background Image:</label>
            <Editor
              variables={this.props.variables}
              placeholder="Audio background url"
              value={action.metadata ? action.metadata.background : ''}
              onChange={(text) => this.handleChangeAudioMetadata("background", text)}
              allowVariables={true}
              singleLine={true}
            />

          </div> : null
        }
        {(action.action === 'buyProduct' || action.action === 'cancelProduct') ?
          <div className="na_row">
            <div className="na_label">Which product?</div>
            <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {productName}
            </button>
            <div className="dropdown-menu trigger_dropdown" aria-labelledby="dropdownMenuButton">
              {products && products.map((product, index) => {
                return (
                  <a key={index} className="dropdown-item" href="#" onClick={() => this.handleProductKeyChange(product.key)}>{product.name}</a>
                )
              })}
            </div>
          <button id="manage_product_button" type="button" className="btn btn-success add-product" onClick={this.handleManageProduct}><FA className="hs_icon" icon={["far", "box-open"]} /> Manage Products</button>

          </div> : null
        }
        {editWhen ?
        <div className="na_row">
          <div className="na_when_header">
            <div className="na_label">When:</div>
            <div className="na_delete" onClick={() => this.toggleWhen()}><FA icon="minus-circle" /></div>
          </div>
          {/*<input type="text" className="ss_target" placeholder="source_variable" value={action.when.sourceVariable} onChange={(event) => this.handleChangeWhenTarget(event.target.value)}/>*/}
          {/*<div className="variableDropdown_container">
            <button className="btn btn-primary dropdown-toggle text-left" type="button" id="variableDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {sourceVariable}
            </button>
            <div className="dropdown-menu" aria-labelledby="variableDropdown">
              {this.props.variables.map((variable, index) => {
                  return (
                    <div className="variableRow" onClick={() => this.handleChangeWhenTarget(variable)}>
                      <div className="variableRowLeft">
                        <div
                          className="variableIcon"
                          role="presentation"
                        />
                      </div>

                      <div className="variableRowRight">
                        {variable}
                      </div>
                    </div>
                  )
                })
              }
              <div className="variable_add" onClick={this.handleOpenVariables}>Add Variable</div>
            </div>
          </div>
          <div className="when_operator_container">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {action.when.operator}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('==')}>== (equal to)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('!=')}>!= (not equal to)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('>')}>&gt; (greater than)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('<')}>&lt; (less than)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('>=')}>&gt;= (greater than or equal to)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('<=')}>&lt;= (less than or equal to)</a>
            </div>
          </div>
          <div className="when_target_container">
          <Editor
            variables={this.props.variables}
            placeHolder={'variable or value'}
            onChange={this.onChange}
            allowVariables={true}
          />
            {/*<input type="text" className="ss_value" placeholder="value_or_variable" value={action.when.targetVariable} onChange={(event) => this.handleChangeWhenValue(event.target.value)} />}
            <div className="multiText_delete" onClick={() => this.toggleWhen()}><FA icon="minus-circle" /></div>
          </div>
        </div>*/}
          <Expression when={action.when} variables={this.props.variables} showEquation={false} onChange={this.handleExpressionChange}/>

        </div>
        :
        <div className="multiState_variable">
          <div className="na_label">When:</div>
          <div className="multiState_always" onClick={this.toggleWhen}>Always</div>
        </div>
      }
      </div>
    );
  }
}

export default NextAction;
