import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';

class VoicePicker extends Component {
  constructor(props) {
    super(props);

    //console.log("INITIAL VALUE=", props.initialValue);
    this.state = {
      selectedVoice: this.props.selectedVoice ? this.props.selectedVoice : 'Alexa',
      afterText: this.props.afterText
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      selectedVoice: this.props.selectedVoice ? this.props.selectedVoice : 'Alexa',
      afterText: this.props.afterText
    });
  }

  handleChangeVoice = (voice) => {
    this.setState({
      selectedVoice: voice
    });

    if (this.props.onChange) {
      this.props.onChange(voice);
    }
  }


  render() {
    const { selectedVoice, afterText } = this.state;

    return (
      <div className="voicp_container" >
        <button className="btn-sm btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {selectedVoice}
        </button><span>{afterText}</span>
        <div className="dropdown-menu response_dropdown" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Default')}>Project Default</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Alexa')}>Alexa</a>
          <h6 className="dropdown-header">English (US) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Matthew')}>Matthew (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Joanna')}>Joanna (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Kendra')}>Kendra (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Joey')}>Joey (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Kimberly')}>Kimberly (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Salli')}>Salli (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Ivy')}>Ivy (Girl)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Justin')}>Justin (Boy)</a>

          <h6 className="dropdown-header">English (British) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Amy')}>Amy (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Brian')}>Brian (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Emma')}>Emma (Girl)</a>

          <h6 className="dropdown-header">English (Australian) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Nicole')}>Nicole (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Russell')}>Russell (M)</a>

          <h6 className="dropdown-header">English (Indian) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Aditi')}>Aditi (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Raveena')}>Raveena (F)</a>

          <h6 className="dropdown-header">English (Welsh) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Geraint')}>Geraint (M)</a>

          <h6 className="dropdown-header">Spanish (US) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Miguel')}>Miguel (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Penelope')}>Penélope (F)</a>

          <h6 className="dropdown-header">Spanish (European) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Conchita')}>Conchita (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Lucia')}>Lucia (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Enrique')}>Enrique (M)</a>

          <h6 className="dropdown-header">Spanish (Mexican) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Mia')}>Mia (F)</a>

          <h6 className="dropdown-header">German Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Hans')}>Hans (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Marlene')}>Marlene (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Vicki')}>Vicki (F)</a>

          <h6 className="dropdown-header">Islandic Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Karl')}>Karl (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Dora')}>Dora (F)</a>

          <h6 className="dropdown-header">French Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Mathieu')}>Mathieu (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Celine')}>Céline (F)</a>

          <h6 className="dropdown-header">French (Canadian) Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Chantal')}>Chantal (F)</a>

          <h6 className="dropdown-header">Italian Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Giorgio')}>Giorgio (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Carla')}>Carla (F)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Bianca')}>Bianca (F)</a>

          <h6 className="dropdown-header">Japanese Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Takumi')}>Takumi (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Mizuki')}>Mizuki (F)</a>

          <h6 className="dropdown-header">Russian Voices</h6>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Maxim')}>Maxim (M)</a>
          <a className="dropdown-item" href="#" onClick={() => this.handleChangeVoice('Tatyana')}>Tatyana (F)</a>


        </div>
      </div>
    );
  }
}

export default VoicePicker;
