let options = {

  chart: {
    type: "line"
  },
  credits: {
      enabled: false
  },
    title: {
        text: 'Utterances',
        align: 'left',
        margin: 35,
        style: {
          "fontSize": "20px"
        }
    },
    subtitle: {
      text: 'An utterance occurs when the user gives a command, either by voice or by pressing a button on the device or remote.',
      align: "left",
      style: {
        "fontSize": "14px"
      }
    },

    yAxis: {
        title: {
            text: 'Number of Utterances'
        }
    },

    xAxis: {
      categories: []
    },

    legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            //categories: customerData.labels,//["1. Feb", "2. Feb"],
            //stacking: "normal"
        }
    },

    series: [{
        name: 'Utterances',
        data: [6, 7, 8, 9, 10],
        color: "#3CAEA3"
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

};

export default options;
