import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import DropZone from 'react-dropzone';
import Spinner from '../Spinner';

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
      showConverting: false,
      isTooLarge: false
    };
  }

  componentWillReceiveProps(props) {
  }

  handledShowUrl = (e) => {
    if (this.props.onShowUrl) {
      this.props.onShowUrl();
    }

    e.preventDefault();
    e.stopPropagation();

  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    //console.log("Dropped");
    //console.log(acceptedFiles);

    var file = acceptedFiles[0];
    //console.log(file);

    if (rejectedFiles.length > 0 && rejectedFiles[0].size > 5242880) {
      this.setState({
        isTooLarge: true
      })

      window.setTimeout(() => {
        this.setState({
          isTooLarge: false
        })
      }, 5000);
      return;
    }

    this.setState({
      showSpinner: true
    })

    utils.getFileUploadUrl(file.name, file.type)
      .then((signedResponse) => {
        utils.uploadFile(file, signedResponse.signedUrl)
          .then(response => {
            this.setState({
              showConverting: true
            });
            this.checkCount = 0;
            this.checkForConversion(signedResponse.finalUrl);
          })
          .catch(err => {
            console.log(err);
            this.setState({
              showSpinner: false,
              showConverting: false
            })
          });
      })
      .catch(err => {
        this.setState({
          showSpinner: false,
          showConverting: false
        })
      });

      return;
  }

  checkForConversion = (url) => {
    //console.log("Checking Conversion");
    this.checkCount++;
    if (this.checkCount > 100) {
      this.setState({
        showSpinner: false,
        showConverting: false
      })
      return;
    }
    utils.getUrl(url).then(response => {
      this.handleFileUploaded(url);
      this.setState({
        showSpinner: false,
        showConverting: false
      })
    }).catch(err => {
      window.setTimeout(() => {
        this.checkForConversion(url);
      }, 1000);
    })
  }

  handleFileUploaded = (url) => {
    if (this.props.onUpload) {
      this.props.onUpload(url);
    }
  }

  render() {
    const { showSpinner, showConverting, isTooLarge } = this.state;

    return (
      <div className="fu_container">
      {isTooLarge ?
        <div className="fu_tooLarge">
          <div className="fu_header">File Limit Exceeded (5MB)</div>
        </div> :
      showSpinner ?
        <div className="fu_spinning">
          {showConverting ?
            <div className="fu_header">Converting Files...</div> :
            <div className="fu_header">Uploading Files...</div>
          }
          <Spinner size={20}/>
        </div>
        :
        <DropZone
          onDrop={this.handleDrop}
          accept="audio/*"
          minSize={0}
          maxSize={5242880}
        >
          {({getRootProps, getInputProps}) => (
            <section>
              <div className="fu_content"{...getRootProps()}>
                <input {...getInputProps()} />
                <div className="fu_header">Drag and drop audio files here</div>
                <div className="fu_or">-- OR --</div>
                <div className="fu_buttons">
                  <div className="fu_button">Choose File(s)</div>
                  <div className="fu_link" onClick={this.handledShowUrl}>Enter URL</div>
                </div>

              </div>
            </section>
          )}
        </DropZone>
      }
      </div>
    );
  }
}

export default FileUpload;
