import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
//import Footer from '../Footer';
import EditProject from '../EditProject';
import Spinner from '../Spinner';
import PremiumMember from '../PremiumMember';
import Industries from '../data/industries.js';
import Countries from '../data/countries.js';

import {Typeahead} from 'react-bootstrap-typeahead';
import Stepper from 'react-stepper-horizontal';
import Fuse from 'fuse.js';

import utils from '../helpers/utils.js';


class Business extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPremium: true,
      countries: [],
      categories: [],
      filteredCategories: [],
      selectedCountry: "US",
      selectedCategory: null,
      selectedPlan: "yearly",
      validations: {
        category: true,
      }
    }

    utils.setCookie('va_business_country', "US", 1);
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentWillMount() {
    utils.getCurrentUser().then(user => {
      this.setState({
        isPremium: user.subscription ? user.subscription : false
      });

    });
  }

  componentDidMount() {
    let countries = Countries.sort((a,b) => {
      if (a.name === "United States") return -1;
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    this.updateCategories.call(this);

    this.setState({
      countries
    })
  }

  updateCategories = () => {
    let categories = [];

    Industries.forEach(industry => {
      if (industry.country_blacklist && industry.country_blacklist.includes("US")) {
        return;
      }
      if (industry.country_whitelist && !industry.country_whitelist.includes("US")) {
        return;
      }
      let parent = null;
      let newCategory = {};
      if (industry.parents.length > 0) {
        let parentCategory = Industries.find(t => t.alias === industry.parents[0]);
        if (parentCategory) {
          parent = parentCategory.title;
          if (parentCategory.parents.length > 0) {
            let grandParentCategory = Industries.find(t => t.alias === parentCategory.parents[0]);
            if (grandParentCategory) {
              parent = grandParentCategory.title + " > " + parent;
            }
          }
        }
      }
      newCategory.title = industry.title;
      newCategory.id = industry.alias;
      if (parent) {
        newCategory.title = parent + " > " + industry.title;
      }

      categories.push(newCategory);
    });

    this.setState({
      categories
    })

    this.initializeCheckout.call(this);
  }

  initializeCheckout = () => {
    var that = this;
    utils.getCurrentUser().then(user => {
      if (user == null || user.userName == null) {
        that.goTo('login/checkout');
        utils.log('User == null');
      } else {
        let cbInstance = window.Chargebee.init({
            site: "voice-apps"
        });

        //var cbInstance = window.Chargebee.getInstance();

        //var cart = cbInstance.getCart();
        utils.log("user=", user);

        cbInstance.setCheckoutCallbacks(function(cart) {
          if (user.userName) {
            var customer = {first_name: user.firstName, last_name: user.lastName, email: user.userName, billing_address:{first_name: user.firstName, last_name: user.lastName, company: "", phone: "", address_line1: "", address_line2: ""}};
            cart.setCustomer(customer);
          }


            // you can define a custom callbacks based on cart object
            return {
                loaded: function() {
                    utils.log("checkout opened");
                },
                close: function() {
                    utils.log("checkout closed");
                    //if (!that.state.showComplete) {
                      //Get the template
                      // utils.getProjectById('66f16bd5-dd0e-4921-b161-383083ca8d6b').then(proj => {
                      //   proj.UserId = user.id;
                      //   proj.SkillId = null;
                      //   proj.isBusiness = true;
                      //   proj.id = utils.guid();
                      //   if (proj.business == null) {
                      //     proj.business = {};
                      //   }
                      //   let selectedCountry = utils.getCookie('va_business_country');
                      //   let selectedCategory = utils.getCookie('va_business_category');
                      //   //console.log("cookies=", selectedCountry, selectedCategory);
                      //   proj.business.country = selectedCountry;
                      //   proj.business.category = selectedCategory;
                      //   //console.log(proj.business);
                      //   utils.createProject(proj).then(response => {
                      //     window.fbq('trackSingle', '2044350275643772', 'Purchase', {value: '19.95', currency: 'USD'});
                      //     //console.log(proj.id);
                      //     that.goTo('business/' + proj.id);
                      //   });
                      // });
                      //that.goTo('business/1234');
                    //}
                },
                success: function(hostedPageId) {
                    utils.log("checkout success!!", hostedPageId);
                    window.fbq('trackSingle', '2044350275643772', 'Purchase', {value: '14.95', currency: 'USD'});
                    window.ga('send', 'event', 'Purchase', 'Sign Up', 'Paid', '14.95');
                    window.gtag_report_conversion(19.95);

                    //Create a new skill

                    //Get the template
                    utils.getProjectById('66f16bd5-dd0e-4921-b161-383083ca8d6b').then(proj => {
                      proj.UserId = user.id;
                      proj.SkillId = null;
                      proj.isBusiness = true;
                      proj.id = utils.guid();
                      if (proj.business == null) {
                        proj.business = {};
                      }
                      let selectedCountry = utils.getCookie('va_business_country');
                      let selectedCategory = utils.getCookie('va_business_category');
                      ////console.log("cookies=", selectedCountry, selectedCategory);
                      proj.business.country = selectedCountry;
                      proj.business.category = selectedCategory;
                      ////console.log(proj.business);
                      utils.createProject(proj).then(response => {
                        window.fbq('trackSingle', '2044350275643772', 'Purchase', {value: '14.95', currency: 'USD'});
                        that.goTo('business/' + proj.id);
                      });
                    });

                },
                step: function(value) {
                    // value -> which step in checkout
                    utils.log(value);
                }
            }


        });



      }

      return null;
    }).catch(err => {
      utils.log(err);
      that.goTo('login/checkout');
    })
  }


  handleSearch = (query) => {
    const {categories} = this.state;
    let {validations} = this.state;

    var options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        "title",
        "author.firstName"
      ]
    };
    var fuse = new Fuse(categories, options); // "list" is the item array
    var result = fuse.search(query);

    validations.category = true;
    this.setState({
      filteredCategories: result,
      validations,
      selectedCategory: null
    })
  }

  handleCategoryChange = (categories) => {
    let {validations} = this.state;
    //console.log('category selected');
    if (categories.length > 0) {
      validations.category = true;
      this.setState({
        selectedCategory: categories[0].id,
        validations
      });
      utils.setCookie('va_business_category', categories[0].id, 1);
    } else {
      this.setState({
        selectedCategory: null
      })
      utils.setCookie('va_business_category', null, -1);
    }
    //console.log(categories);
  }

  handleCountryClick = (countryCode) => {
    utils.setCookie('va_business_country', countryCode, 1);
    this.setState({
      selectedCountry: countryCode
    })
  }

  handlePlanSelect = (plan) => {
    //console.log(plan);

    this.setState({
      selectedPlan: plan
    })

    let that = this;
    window.setTimeout(this.initializeCheckout, 200);
  }

  handleSignUpClick = () => {
    const {selectedPlan} = this.state;

    if (!this.validateCategory()) return;

    //console.log(selectedPlan);

    var checkout = document.getElementById('business_signup_' + selectedPlan);
    checkout.click();
  }

  validateCategory = () => {
    //console.log('validating category');
    let {selectedCategory, validations} = this.state;

    if (!selectedCategory) {
      validations.category = false;
      this.setState({
        validations
      })

      return false;
    }

    return true;
  }

  render() {
    const {isPremium, countries, filteredCategories, selectedCountry, selectedCategory, selectedPlan, validations} = this.state;

    const filterByCallback = (option, props) => {
      return true;
    };

    //console.log(selectedCountry, countries);
    let selectedCountryItem = countries.find(c => c.countryCode === selectedCountry);
    let selectedCountryName = "Choose your Country";
    if (selectedCountryItem) {
      selectedCountryName = selectedCountryItem.name + " (" + selectedCountryItem.language + ")";
    }

    let planId = selectedPlan === 'yearly' ? 'business-basic-annual' : 'business-basic-monthly';

    return (
      <div className="business_full">

        <HeaderSecure menuId={2} auth={this.props.auth} history={this.props.history} isProfessional={isPremium} />
        <div className="container">
          <div className="row align-items-top">
            <div className="col">
              <Stepper steps={ [{title: 'Payment Plan'}, {title: 'Business Info'}, {title: 'Skill Responses'}, {title: 'Publish'}] } activeStep={ 0 } />
            </div>
          </div>

          <div className="row align-items-center business_container">
            <div className="col">
              <div className="business_header">You're only a few steps away from creating an Alexa Skill for your Business!</div>
              <div className="business_subHeader">Select your country and type of business and then select a payment plan.</div>
              <div className="form-group">

                <div className="dropdown">
                  <div className="label-inline">Country/Language:</div>
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="countryDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {selectedCountryName}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="countryDropdown">
                    {countries.map((country, index) => {
                      return (
                        <a key={index} onClick={() => this.handleCountryClick(country.countryCode)} className="dropdown-item" href="#">{country.name + " (" + country.language + ")"}</a>
                      );
                    })}

                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="label-inline">Business Type:</div>
                <span id="categoryHelpBlock" class="form-text text-muted">
                  Start typing and then choose one of the business types from the drop-down menu that appears.  Choose the best category for your business.
                </span>
                <Typeahead
                  filterBy={filterByCallback}
                  labelKey="title"
                  options={filteredCategories}
                  placeholder="examples:  &quot;plumbing&quot;, &quot;italian restaurant&quot;, &quot;hair salon&quot;"
                  onInputChange={this.handleSearch}
                  onChange={this.handleCategoryChange}
                  minLength={1}
                  isInvalid={!validations.category}
                  onBlur={this.validateCategory}
                />

                {validations.category !== true && <div className="va-invalid-feedback">
                  You have not selected a business type.  Click the type from the drop down.
                </div> }
              </div>
              <div className="business_paymentPlans">
                <div className={"business_paymentPlan" + (selectedPlan === 'monthly' ? ' selected' : '')} onClick={() => this.handlePlanSelect('monthly')}>
                  <div className="business_paymentPlan_header">Monthly</div>
                  <div className="business_paymentPlan_price">$14.95</div>
                  <div className="business_paymentPlan_per">per Month</div>
                  <div className="business_paymentPlan_summary">Paid Monthly</div>
                </div>
                <div className={"business_paymentPlan" + (selectedPlan === 'yearly' ? ' selected' : '')} onClick={() => this.handlePlanSelect('yearly')}>
                  <div className="business_paymentPlan_header">Annually</div>
                  <div className="business_paymentPlan_price">$12.71</div>
                  <div className="business_paymentPlan_per">per Month</div>
                  <div className="business_paymentPlan_summary">$152.52 Billed annually</div>
                  <div className="business_paymentPlan_savings">
                    <div className="title">Save</div>
                    <div className="amount">15%</div>
                  </div>
                </div>

              </div>
              <div className="business_signup">
                <button className="btn btn-primary" type="button" id="signUp" onClick={this.handleSignUpClick}>Build My Skill</button>
              </div>
              <a id="business_signup_monthly" className="checkout_selectPlan" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="business-basic-monthly" >Select this plan</a>
              <a id="business_signup_yearly" className="checkout_selectPlan" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="business-basic-annual" >Select this plan</a>
            </div>
          </div>
        </div>

      </div>
    );
  }
}




export default Business;
