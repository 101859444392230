import React, { Component } from 'react';
import utils from '../../helpers/utils.js';

import $ from 'jquery';
import Toggle from 'react-bootstrap-toggle';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../redux/actions/upload';

// formats must follow ImageMagick Image Geometry: https://imagemagick.org/script/command-line-processing.php#geometry
const supportedImageFormats = [
	{
		key: '108x108',
		label: 'Small Icon (108px x 108px)',
	},
	{
		key: '512x512',
		label: 'Large Icon (512px x 512px)',
	},
	{
		key: '340x340',
		label: 'Echo Show (BodyTemplate 2,3,6) (340px x 340px)',
	},
	{
		key: '880x346',
		label: 'Echo Show (BodyTemplate 7, main image) (880px x 346px)',
	},
	{
		key: '1024x600',
		label: 'Echo Show (BodyTemplate 7, background image) (1024px x 600px)',
	}
];

const supportedAudioFormats = [
	{
		key: '16000:48k',
		label: 'Short Audio (16000Hz/48kbps MP3)',
	}
];

class ConversionOptions extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// window.initializeBootstrapToggle($);
		// utils.log("mounting component");
		// $('.audioToggle').bootstrapToggle().change(this.handleAudioCheckboxToggle);
		// $('.imageToggle').bootstrapToggle().change(this.handleImageCheckboxToggle);
	}
	componentDidUpdate() {
		utils.log("updating component");
		//$('.toggle').bootstrapToggle();
	}

	handleAudioCheckboxToggle = (key, state) => {
		utils.log('audio change');
		//const { target } = e;
		const { selectAudioOption, deselectAudioOption } = this.props;

		//utils.log("checkbox", target.checked)

		if (state) {
			selectAudioOption(key);
		} else {
			deselectAudioOption(key);
		}
	}

	handleImageCheckboxToggle = (key, state) => {
		//const { target } = e;
		const { selectImageOption, deselectImageOption } = this.props;

		//console.log('state', state, key);
		if (state) {
			selectImageOption(key);
		} else {
			deselectImageOption(key);
		}
	}

	render() {
		const self = this;
		const { imageOptions, audioOptions } = self.props;

		const imageSettings = supportedImageFormats.map((format) => (
			<div key={format.key}>
				<label className="form-switch">
					{/*<input type="checkbox" className="imageToggle" data-toggle="toggle" data-size="small" value={format.key} checked={imageOptions.indexOf(format.key) >= 0} onChange={this.onImageCheckboxToggle} />*/
				}
					<Toggle
	          onClick={(state) => this.handleImageCheckboxToggle(format.key, state)}
	          on={<span>ON</span>}
	          off={<span>OFF</span>}
	          size="xs"
	          offstyle="danger"
	          active={imageOptions.indexOf(format.key) >= 0}
	        />
					<i className="form-icon" />
					{` to ${format.label}`}
				</label>
			</div>
		));

		const audiosettings = supportedAudioFormats.map((format) => (
			<div key={format.key}>
				<label className="form-switch">
					{/*<input type="checkbox" className="audioToggle" data-toggle="toggle" data-size="small" value={format.key} checked={imageOptions.indexOf(format.key) >= 0} onChange={this.handleAudioCheckboxToggle} />*/
				}
					<Toggle
	          onClick={(state) => this.handleAudioCheckboxToggle(format.key, state)}
	          on={<span>ON</span>}
	          off={<span>OFF</span>}
	          size="xs"
	          offstyle="danger"
	          active={audioOptions.indexOf(format.key) >= 0}
	        />
					<i className="form-icon" />
					{` to ${format.label}`}
				</label>
			</div>
		));

		return (
			<div>
				<div className="conversion_header">Conversion Settings</div>

				<form className="container">
					<div className="row conv_row">
						<div className="col-3">
							<label className="form-label">Convert Audio Files</label>
						</div>
						<div className="col-9">
							{audiosettings}
						</div>
					</div>
					<div className="row conv_row">
						<div className="col-3">
							<label className="form-label">Resize Image Files</label>
						</div>
						<div className="col-9">
							{imageSettings}
						</div>
					</div>
				</form>
			</div>
		);
	}

}

ConversionOptions.propTypes = {
	selectImageOption: PropTypes.func.isRequired,
	deselectImageOption: PropTypes.func.isRequired,
	selectAudioOption: PropTypes.func.isRequired,
	deselectAudioOption: PropTypes.func.isRequired,
	imageOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
	audioOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ upload }) => ({
	imageOptions: upload.imageOptions,
	audioOptions: upload.audioOptions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	selectImageOption: actions.selectImageOption,
	deselectImageOption: actions.deselectImageOption,
	selectAudioOption: actions.selectAudioOption,
	deselectAudioOption: actions.deselectAudioOption,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConversionOptions);
