import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import MultiText from '../MultiText';
import VoicePicker from '../VoicePicker';

class InvalidResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responses: props.responses,
      invalidResponseVoice: props.invalidResponseVoice,
      reprompts: props.reprompts,
      noResponseVoice: props.noResponseVoice
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      responses: props.responses,
      invalidResponseVoice: props.invalidResponseVoice,
      reprompts: props.reprompts,
      noResponseVoice: props.noResponseVoice
    });
  }

  handleInvalidResponsesChange = (data) => { // eslint-disable-line
    const { reprompts, invalidResponseVoice, noResponseVoice } = this.state;

    this.setState({
      responses: data
    });

    if (this.props.onChange) {
      this.props.onChange({
        responses: data,
        invalidResponseVoice,
        reprompts,
        noResponseVoice
      });
    }
  }

  handleRepromptsChange = (data) => { // eslint-disable-line
    const { responses, invalidResponseVoice, noResponseVoice } = this.state;

    this.setState({
      reprompts: data
    });

    if (this.props.onChange) {
      this.props.onChange({
        responses,
        invalidResponseVoice,
        reprompts: data,
        noResponseVoice
      });
    }
  }

  handleChangeInvalidVoice = (voice) => {
    const { isPremium, onShowPremiumMember, responses, reprompts, noResponseVoice } = this.props;

    if (isPremium) {
      if (this.props.onChange) {
        this.props.onChange({
          responses,
          invalidResponseVoice: voice,
          reprompts,
          noResponseVoice
        });
      }
    } else {
      onShowPremiumMember();
    }
  }

  handleChangeNoResponseVoice = (voice) => {
    const { isPremium, onShowPremiumMember, responses, reprompts, invalidResponseVoice } = this.props;

    utils.log('invalid response voice=', voice);
    if (isPremium) {
      if (this.props.onChange) {
        this.props.onChange({
          responses,
          invalidResponseVoice,
          reprompts,
          noResponseVoice: voice
        });
      }
    } else {
      onShowPremiumMember();
    }
  }

  render() {
    const { responses, reprompts } = this.state;
    const { isPremium, onShowPremiumMember } = this.props;

    let { invalidResponseVoice, noResponseVoice } = this.state;

    if (invalidResponseVoice == null) {
      invalidResponseVoice = 'Default';
    }

    if (noResponseVoice == null) {
      noResponseVoice = 'Default';
    }

    return (
      <div className="intent_card">
        <div className="intent_cardHeader">Invalid Responses</div>
        <div className="form-group">
          <div>If user gives an <b>invalid</b> response,</div>
          <VoicePicker selectedVoice={invalidResponseVoice} afterText=" will say..." onChange={(voice) => this.handleChangeInvalidVoice(voice)} />
          <MultiText
            inputPlaceHolder="What should Alexa say?"
            inputPlaceHolder2="Enter another and Alexa will choose randomly (optional)."
            inputPlaceHolder3="Enter another random response."
            data={responses}
            variables={this.props.variables}
            isPremium={isPremium}
            onShowPremiumMember={onShowPremiumMember}
            onChange={this.handleInvalidResponsesChange}
          />
        </div>

        <div className="form-group">
          <div>If user doesn&apos;t respond,</div>
          <VoicePicker selectedVoice={noResponseVoice} afterText=" will say..." onChange={(voice) => this.handleChangeNoResponseVoice(voice)} />
          <MultiText
            inputPlaceHolder="What should Alexa say?"
            inputPlaceHolder2="Enter another and Alexa will choose randomly (optional)."
            inputPlaceHolder3="Enter another random response."
            data={reprompts}
            variables={this.props.variables}
            isPremium={isPremium}
            onShowPremiumMember={onShowPremiumMember}
            onChange={this.handleRepromptsChange}
          />
        </div>

      </div>
    );
  }
}

export default InvalidResponse;
