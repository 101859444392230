import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectFiles, deselectFile } from '../redux/actions/upload';
import { getFileKey } from '../library/file';

class SelectFiles extends Component {

	constructor(props) {
		super(props);

		const self = this;
		self.onFileChange = self.handleFileChange.bind(self);
		self.onDrop = self.handleDrop.bind( self );
		self.onDragOver = self.handleDragOver.bind( self );
	}

	handleFileChange(e) {
		const { target } = e;
		const { files } = target;

		const newfiles = [];
		for (let i = 0, len = files.length; i < len; i++) {
			newfiles.push(files[i]);
		}

		this.props.select(newfiles);
	}

	handleDrop(e) {
		const self = this;
		const { dataTransfer } = e;
		e.preventDefault();

		const files = [];
		if (dataTransfer.items) {
			const { items } = dataTransfer;
			for (let i = 0, len = items.length; i < len; i++) {
				const item = items[i];
				if (item.kind === 'file') {
					files.push(item.getAsFile());
				}
			}

			items.clear();
		} else {
			const { files } = dataTransfer;
			for (let i = 0, len = files.length; i < len; i++) {
				files.push(files[i]);
			}

			dataTransfer.clearData();
		}

		if (files.length) {
			self.props.select(files);
		}
	}

	handleDragOver(e) {
		e.preventDefault();
	}

	render() {
		const self = this;
		const { files, deselect } = self.props;

		const selected = files.map(file => (
			<div className="chip" key={getFileKey(file)}>
				{file.name}
				<button type="button" className="btn btn-clear" onClick={() => deselect(file)} />
			</div>
		));

		return (
			<div>
				<div id="drop-zone" className="empty" onDrop={self.onDrop} onDragOver={self.onDragOver}>
					<div className="empty-icon">
						<i className="icon icon-3x icon-download"></i>
					</div>

					<p className="empty-title h5">Drop files here</p>
					<p className="empty-subtitle">or select files on your computer</p>

					<div className="empty-action">
						<label htmlFor="file-input" className="btn btn-primary">Select files</label>
					</div>

					<input type="file" className="d-none" id="file-input" multiple onChange={self.onFileChange} />
				</div>

				<div className="mt-2">{selected}</div>
			</div>
		);
	}

}

SelectFiles.propTypes = {
	select: PropTypes.func.isRequired,
	deselect: PropTypes.func.isRequired,
	files: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
	select: selectFiles,
	deselect: deselectFile,
}, dispatch);

export default connect(null, mapDispatchToProps)(SelectFiles);
