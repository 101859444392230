let questions = [
  {
    "title": "Welcome Message (First Visit)",
    "for": "This is the message that will be heard when the user first uses your skill.",
    "sample": "Welcome to ACME Plumbing.  We look forward to serving you.  To learn more about our company, say \"About Us\".  You can also ask about our \"Location\", \"Get Directions\", \"Hours of Operation\", or \"Phone Number\". ",
    "targetIntent": "Welcome",
    "responses": [],
  },
  {
    "title": "About Us",
    "for": "What the skill will say when the user says things like \"Tell me about your company.\", or \"Who are you?\".",
    "sample": "ACME Plumbing is a fully insured and licensed plumbing company that has been serving the needs of its customers in San Francisco since 2010. We have a team of well qualified, skilled and trained professionals who arrive at your home or business to deliver quality plumbing services right from start to finish. We understand that most people are not ready for plumbing emergencies and may not be aware of the best course of action to take. Call us at (415) 555-1212 at the first sign of any plumbing emergency!",
    "targetIntent": "About Us",
    "responses": [],
  },
  {
    "title": "Location",
    "for": "What you would tell a customer if they asked \"Where are you located\".  This can be your street address and/or a description of where your business is located in reference to common landmarks.",
    "sample": "We are located at 123 A San Bruno Avenue in the Nob Hill area and are proud to serve all of San Francisco.",
    "targetIntent": "Location",
    "responses": [],
  },
  {
    "title": "Directions",
    "for": "What you would tell a customer if they asked \"How do I get there?\".  You'll need to give generic directions from common landmarks, highways, or intersections.",
    "sample": "We are located at 2360 A San Bruno Avenue right across from the Silver Terrace Playground.",
    "targetIntent": "Directions",
    "responses": [],
  },
  {
    "title": "Phone Number",
    "for": "What you would tell a customer if they asked for your phone number?",
    "sample": "You can reach us at 904-555-1212.",
    "targetIntent": "Phone Number",
    "responses": [],
  },
  {
    "title": "Hours of Operation",
    "for": "What the skill will say when the user asks anything about your hours.",
    "sample": "We are open Monday through Friday from 7:30am to 5pm.   If you have an emergency, call us at (415) 555-1212 and our emergency response team will be happy to help you.",
    "targetIntent": "Hours of Operation",
    "responses": [],
  },
  {
    "title": "Goodbye",
    "for": "What Alexa will say when the user ends your skill.",
    "sample": "We hope you have a wonderful day!",
    "targetIntent": "Goodbye",
    "responses": [],
  }
];

export default questions;
