import { ACTION_LOGIN, ACTION_LOGOUT } from '../actions/auth';
import utils from '../../../helpers/utils.js';

export const defaultState = {
	user: false,
};

const reducer = (state = {}, action = {}) => {
	switch (action.type) {
		case ACTION_LOGIN:
			utils.log("reducer.login-", action.user);
			let result = Object.assign({}, state, {
				user: action.user,
			});
			utils.log('result=', result);
			return result;
			break;
		case ACTION_LOGOUT:
		utils.log('logging out.')
			return Object.assign({}, defaultState);
		default:
			// do nothing
			break;
	}
	return state;
};

export default reducer;
