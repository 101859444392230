import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import FunctionContainer from '../FunctionContainer';

import SetState from '../SetState';
import JSCode from '../JSCode';
import Integration from '../Integration';
import GetRandom from '../GetRandom';
import Lookup from '../Lookup';
import CallAPI from '../CallAPI';
import GetRSS from '../GetRSS';
import ISP from '../ISP';

class DataContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: props.data,
      products: props.products
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      groups: props.data,
      products: props.products
    })
  }

  deleteIntent = () => { // eslint-disable-line
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  getItems = () => { // eslint-disable-line
    const { groups, products } = this.state;
    const { isPremium, onShowPremiumMember} = this.props;

    let data = [];
    groups.forEach((func, index) => {
      if (func.type === 'setState') {
        data.push({
          id: index,
          content: (
            <JSCode isPremium={isPremium} onShowPremiumMember={onShowPremiumMember} data={func} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleJSCodeChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'setState2') {
        data.push({
          id: index,
          content: (
            <SetState isPremium={isPremium} onShowPremiumMember={onShowPremiumMember} variables={this.props.variables} data={func} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleSetStateChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'integration') {
        data.push({
          id: index,
          content: (
            <Integration data={func} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleIntegrationChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'getRandom') {
        data.push({
          id: index,
          content: (
            <GetRandom key={index} data={func} variables={this.props.variables} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleGetRandomChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'lookup') {
        data.push({
          id: index,
          content: (
            <Lookup key={index} data={func} variables={this.props.variables} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleLookupChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'getRSS') {
        data.push({
          id: index,
          content: (
            <GetRSS key={index} data={func} variables={this.props.variables} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleGetRSSChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'isp') {
        data.push({
          id: index,
          content: (
            <ISP key={index} data={func} variables={this.props.variables} products={products} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleISPChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'callAPI') {
        data.push({
          id: index,
          content: (
            <CallAPI key={index} data={func} variables={this.props.variables} active={func.isActive} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleCallAPIChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      }
    })

    return data;
  }

  deleteFunction = (index) => { // eslint-disable-line
    let {groups} = this.state;

    groups.splice(index, 1);

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleFunctionsChange = (data) => { // eslint-disable-line
    utils.log("Functions Change");

    utils.log('data=', data);
    let { groups } = this.state;

    let newFunctions = [];
    data.forEach((func, index) => {
      newFunctions.push(groups[func.id]);
    });

    groups = newFunctions;

    utils.log('Group After=', groups);
    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleIntegrationChange = (index, data) => { // eslint-disable-line
    let { groups } = this.state;

    if (data === 'callAPI') {
      groups[index] = {
        type: 'callAPI',
        url: '',
        method: 'GET',
        headers: [],
        variables: [],
        isActive: true
      };
    } else if (data === 'getRSS') {
      groups[index] = {
        type: 'getRSS',
        url: '',
        variables: []
      };
    }

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleSetStateChange = (index, data) => { // eslint-disable-line
    let { groups } = this.state;

    //console.log("______________Handele Set State Chang4__________");
    //console.log(data);
    //console.log("got here");
    groups[index].values = null;
    groups[index].targetVariable = data.targetVariable;
    groups[index].calculatedExpression = data.calculatedExpression;
    groups[index].expression = data.expression;

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleJSCodeChange = (index, data) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index].values = null;
    groups[index].code = data.code;

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleGetRandomChange = (index, action) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index].variable = action.variable;
    groups[index].data = action.data;

    this.setState({
      groups
    })

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleLookupChange = (index, action) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index].sourceVariable = action.sourceVariable;
    groups[index].targetVariable = action.targetVariable;
    groups[index].keyValues = action.keyValues;

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleCallAPIChange = (index, action) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index].url = action.url;
    groups[index].method = action.method;
    groups[index].headers = action.headers;
    groups[index].variables = action.variables;

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleGetRSSChange = (index, action) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index].url = action.url;
    groups[index].variables = action.variables;

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleISPChange = (index, action) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index].productId = action.productId;
    groups[index].targetVariable = action.targetVariable;

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  render() {
    const { groups, editWhen } = this.state;

    let functions = this.getItems();

    return (
      <div className="dc_container">
          {functions.length > 0 ?
            <FunctionContainer data={functions} onChange={(data) => this.handleFunctionsChange(data)} padding={0}/>
            :
            <div className="rg_noFunctions">Use the icons below to add data functions.</div>
          }
      </div>
    );
  }
}

export default DataContainer;
