import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiKeyValue from '../MultiKeyValue';
import ReactJson from 'react-json-view';
import Spinner from '../Spinner';
import Editor from '../Editor';

class CallAPI extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      action: props.data ? props.data : {url: '', method: 'GET', headers:[], variables:[]},
      inputValue: null,
      testStep: 0,
      variables: [],
      apiResults: {
        test: "123",
        test2: "123",
        nested: [
          {
            test: "345"
          },
          {
            test2: "456"
          }
        ]
      }
    };
  }

  componentWillReceiveProps(props) {
    //console.log("00000 API CALL 00000");
    utils.log(props.data);
    utils.log('action=', props.data);
    this.setState({
      inputValue: null,
      action: props.data ? props.data : {url: '', method: 'GET', headers:[], variables:[]}
    });
  }

  componentDidMount() {
    //console.log('------------------------');
    //console.log('scrolling CallAPI into view');
    //console.log('------------------------');
    //console.log(this.boxRef);
    //if (this.props.active) { // whatever your test might be
      if (this.boxRef.current) {

        this.boxRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    //}
  }

  handleUrlChange = (url) => {
    let { action } = this.state;

    action.url = url;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handlePostDataChange = (data) => {
    let { action } = this.state;

    action.body = data;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleMethodChange = (method) => {
    let { action } = this.state;

    action.method = method;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleHeadersChange = (data) => {
    let { action } = this.state;

    action.headers = data;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleVariablesChange = (data) => {
    let { action } = this.state;

    action.variables = data;

    this.setState({
      action,
      inputValue: null
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleCopyPath = (data) => {
    //console.log(data);

    let pathString = "";
    data.namespace.forEach((name, index) => {
      if (this.isNumeric(name)) {
        pathString += '[' + name + ']';
      } else {
        if (index > 0) {
          pathString += ".";
        }
        pathString += name;
      }
    })

    this.copyStringToClipboard(pathString);

    this.setState({
      inputValue: pathString
    })
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  copyStringToClipboard = (str) => {
     // Create new element
     var el = document.createElement('textarea');
     // Set value (string to be copied)
     el.value = str;
     // Set non-editable to avoid focus and move outside of view
     el.setAttribute('readonly', '');
     el.style = {position: 'absolute', left: '-9999px'};
     document.body.appendChild(el);
     // Select text inside element
     el.select();
     // Copy text to clipboard
     document.execCommand('copy');
     // Remove temporary element
     document.body.removeChild(el);
  }

  handleTestClick = () => {
    let {action, testStep, variables} = this.state;


    // Check for variables in settings
    let varRegEx = new RegExp("{{?(.*?)}", "gi");

    let vars = [];
    //console.log(utils.getRegexMatches(action.url, varRegEx));
    vars = vars.concat(utils.getRegexMatches(action.url, varRegEx));
    if (action.body ) {
      vars = vars.concat(utils.getRegexMatches(action.body, varRegEx));
    }
    action.headers.forEach(keyvalue => {
      vars = vars.concat(utils.getRegexMatches(keyvalue.value, varRegEx));
    })

    //console.log(vars);

    let uniq = [...new Set(vars)];
    //console.log(uniq);

    let newVariables = [];
    let hasValues = true;
    uniq.forEach(key => {
      let keyvalue = {
        key: key,
        value: null
      };
      let existingVar = variables.find(v => {return v.key === key});
      if (existingVar) {
        keyvalue.value = existingVar.value;
      } else {
        hasValues = false;
      }
      newVariables.push(keyvalue);
    })

    variables = newVariables;

    // If we have variables, prompt user for values
    if (variables.length > 0) {
      if (testStep === 1 && hasValues) {
        testStep = 2;
      } else {
        testStep = 1;
      }
    } else {
      testStep = 2;
    }

    this.setState({
      testStep,
      variables
    });


    if (testStep === 2) {
      // Replace headers with Variables
      action.headers.forEach((keyvalue) => {
        keyvalue.value = utils.replaceVariables(keyvalue.value, variables);
      });
        utils.callAPI(
          action.url,//utils.replaceVariables(action.url, variables, true),
          action.method,
          action.body,//utils.replaceVariables(action.body, variables),
          action.headers
        ).then((response) => {
          utils.log("GOT API RESPONSE", response);

          if (utils.isJSON(response)) {
            this.setState({
              apiResults: response,
              testStep: 3
            })
          } else {
            this.setState({
              apiResults: {message: response},
              testStep: 3
            })
          }
        }).catch((err) => {
          this.setState({
            apiError: err.message,
            testStep: 4
          })
          utils.log(err);
        });
      }
  }

  handleChangeValue = (index, value) => {
    let {variables} = this.state;

    variables[index].value = value;

    this.setState({
      variables
    })
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { action, testStep, apiResults, inputValue, variables, apiError } = this.state;

    action.variables = action.variables ? action.variables : [];
    utils.log('CallAPI Action=', action);

    return (
      <div className="intent_card" ref={this.boxRef}>
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_api" icon={["far", "plug"]} /></span>Call Custom API</div>
        <div className="api_textHeader">Call the following API endpoint...</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div className="api_settings">
          <label htmlFor="inputEmail3" className="api_label">API URL:</label>

          <div className="api_url">
            {/*<input className="form-control" placeholder="https://api.someurl.com" type="text" value={action.url} onChange={(event) => this.handleUrlChange(event.target.value)}/>*/}
            <Editor
              variables={this.props.variables}
              placeholder="https://api.someurl.com"
              value={action.url}
              onChange={(data) => this.handleUrlChange(data)}
              allowVariables={true}
              singleLine={true}
            />
          </div>
          <div className="api_method">
            <label htmlFor="inputEmail3" className="api_label inline">Method:</label>
            <div className="dropdown inline">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {action.method}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="#" onClick={() => this.handleMethodChange('GET')}>GET</a>
                <a className="dropdown-item" href="#" onClick={() => this.handleMethodChange('POST')}>POST</a>
              </div>
            </div>
          </div>
        </div>
        {action.method === "POST" &&
          <div className="form-group">
            <label htmlFor="inputPostData" className="text-nowrap api_label">Post Data (JSON):</label>
            <textarea className="form-control" placeholder="Enter data in JSON format." type="text" value={action.body} onChange={(event) => this.handlePostDataChange(event.target.value)}></textarea>
          </div>
        }
        <div className="api_textHeader">With these headers...</div>
        <MultiKeyValue
          keyPlaceHolder="key"
          valuePlaceHolder="value"
          addButtonText="Add Header"
          allowJSON={false}
          data={action.headers}
          onChange={this.handleHeadersChange}
        />

        <div className="api_textHeader">And assign these variables...</div>
        <MultiKeyValue
          keyPlaceHolder="variableName"
          valuePlaceHolder={"response.value"}
          value={inputValue}
          keyIsVariable={true}
          variables={this.props.variables}
          addButtonText="Add Variable"
          allowJSON={true}
          data={action.variables}
          onChange={this.handleVariablesChange}
        />

        <button type="button" className="btn btn-light api_testButton" onClick={this.handleTestClick}><span className="api_testIcon"><FA icon={["far", "tachometer-alt-fastest"]} /></span>Test API</button>

        {testStep === 1 &&
          <div>
            <div className="api_variables_header">We found variables in your API settings.  Please provide test values for each variable below.</div>
            <div className="api_variables_container">
              {variables.map((keyvalue, index) => {
                return (
                  <div key={index} className="api_variable">
                      <div className="api_variable_key">{keyvalue.key}</div>
                      <div className="api_variable_value">
                        <input className={"api_variable_value" } placeholder="enter variable value" value={keyvalue.value} onChange={(event) => this.handleChangeValue(index, event.target.value)} />
                      </div>
                  </div>
                )
              })}
            </div>
          </div>
        }
        {testStep === 2 &&
          <div className="api_loading">
            <Spinner size={20}/>
          </div>
        }
        {testStep === 3 &&
          <div className="api_results">
            <ReactJson
              name="response"
              src={apiResults}
              enableClipboard={(copy) => this.handleCopyPath(copy)}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </div>
        }
        {testStep === 4 &&
          <div className="api_error_container">
            <div className="api_error_header">An error occured while calling the API.  Please check your settings and try again.</div>
            <div className="alert alert-danger api_error" role="alert">
              {apiError}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CallAPI;
