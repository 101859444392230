let questions = [
  {
    "title": "Welcome Message (First Visit)",
    "for": "This is the message that will be heard when the user first uses your skill.",
    "sample": "Welcome to ACME Plumbing.  We look forward to serving you.  To learn more about us, say \"About Us\", or \"Services\".  You can also ask about our \"Location\", \"Hours of Operation\", or \"Specials\". ",
    "targetIntent": "Welcome",
    "responses": [],
  },
  // {
  //   "title": "Welcome Message (Subsequent Visits)",
  //   "for": "This message will be played whenever the user opens the skill after their first visit.",
  //   "sample": "Welcome back to ACME Plumbing.",
  //   "targetIntent": "Welcome",
  //   "responses": [],
  // },
  {
    "title": "About Us",
    "for": "What the skill will say when the user says things like \"Tell me about your company.\", or \"Who are you?\".",
    "sample": "ACME Plumbing is a fully insured and licensed plumbing company that has been serving the needs of its customers in San Francisco since 2010. We have a team of well qualified, skilled and trained professionals who arrive at your home or business to deliver quality plumbing services right from start to finish. We understand that most people are not ready for plumbing emergencies and may not be aware of the best course of action to take. Call us at (415) 555-1212 at the first sign of any plumbing emergency!",
    "targetIntent": "About Us",
    "responses": [],
  },
  {
    "title": "Location",
    "for": "What you would tell a customer if they asked \"Where are you located\".  This can be your street address and/or a description of where your business is located in reference to common landmarks.",
    "sample": "We are located at 123 A San Bruno Avenue in the Nob Hill area and are proud to serve all of San Francisco.",
    "targetIntent": "Location",
    "responses": [],
  },
  {
    "title": "Directions",
    "for": "What you would tell a customer if they asked \"How do I get there?\".  You'll need to give generic directions from common landmarks, highways, or intersections.",
    "sample": "We are located at 2360 A San Bruno Avenue right across from the Silver Terrace Playground.",
    "targetIntent": "Directions",
    "responses": [],
  },
  {
    "title": "Phone Number",
    "for": "What you would tell a customer if they asked for your phone number?",
    "sample": "You can reach us at 904-555-1212.",
    "targetIntent": "Phone Number",
    "responses": [],
  },
  {
    "title": "Social Media",
    "for": "What you would tell the customer if they asked how to find you on social media.",
    "sample": "You can find us on Facebook at ACME Plumbing and on Twitter as,  @ ACME Plumbing.",
    "targetIntent": "Social Media",
    "responses": [],
  },
  {
    "title": "Hours of Operation",
    "for": "What the skill will say when the user asks anything about your hours.",
    "sample": "We are open Monday through Friday from 7:30am to 5pm.   If you have an emergency, call us at (415) 555-1212 and our emergency response team will be happy to help you.",
    "targetIntent": "Hours of Operation",
    "responses": [],
  },
  {
    "title": "Products or Services",
    "for": "What you would tell a customer if they asked \"What do you sell?\" or \"What services do you offer?\".",
    "sample": "We specialize in copper re-piping, water heater installation and repair, hydro-jetting, leak detection, toilet and faucet installation and repair,  sewer repair,  remodeling, gas piping,  drain cleaning, and restaurant maintenance.   Call us today and let's talk about your project!",
    "targetIntent": "Products And Services",
    "responses": [],
  },
  {
    "title": "Top Clients",
    "for": "What you would tell a customer if they asked about your best clients.",
    "sample": "We service hundreds of very satisfied clients.  Call us and we'll be happy to provide you with references that you can contact to learn about the quality of our work.",
    "targetIntent": "Top Clients",
    "responses": [],
  },
  {
    "title": "Owner Bio",
    "for": "Tell the user about the company's owner and background.",
    "sample": "Hi.  I'm Jim Smith, the owner of ACME Plumbing.  I’m a licensed and insured plumbing contractor with extensive experience and knowledge gained over many years and countless jobs. I never compromise quality for time or cost, ever. You will always be met with a licensed, bonded plumber and all of our work is fully warranted.  I look forward to working with you!",
    "targetIntent": "Owner Bio",
    "responses": [],
  },
  {
    "title": "Company History",
    "for": "\"How did you get started?\" or \"What is your history?\"",
    "sample": "Jim Smith, founder of ACME Plumbing, began his career in 1955 working for a plumbing service and repair company after his discharge from serving in the US Army. Jim’s uncompromising standards quickly earned his growing business an outstanding reputation for high quality work and service. Jim enjoyed his work in the plumbing and heating industry and in 1968, earned his Master Plumbers license.   Following his tradition of service, Jim went on to work for the City of San Francisco. Eventually, Jim's entrepreneurial spirit brought him to the decision to found his own company and ACME Plumbing was born.",
    "targetIntent": "Company History",
    "responses": [],
  },
  {
    "title": "Company Mission",
    "for": "\"What is your mission?\" or \"What are your values?\"",
    "sample": "ACME Plumbing is trusted and recommended for exceptional residential and commercial plumbing services throughout San Francisco and its neighboring areas. We employ a team of plumbing experts who are ready to get their hands dirty to serve. We take pride in being capable of providing the appropriate blend of experience and quality, which is simply the beginning of what you will enjoy with ACME Plumbing. We provide free estimates and promising plumbing service.",
    "targetIntent": "Mission",
    "responses": [],
  },
  {
    "title": "Employment Opportunities",
    "for": "\"Are you hiring?\" or \"Do you have any job openings?\"",
    "sample": "Are you a Plumber looking for a great place to work? ACME Plumbing may be the right place for you..   ACME Plumbing has been proudly serving satisfied residential customers and employees with respect, honesty, and support for over 40 years. We are looking for a competent, professional Plumber who shares our values and has at least five years of experience. Please call ACME Plumbing at 415-555-1212 and tell our friendly Customer Service Representative that you are inquiring about the Plumber position.",
    "targetIntent": "Employment Opportunities",
    "responses": [],
  },
  {
    "title": "Specials, Coupons, or Deals",
    "for": "\"Do you have any specials going on right now?\" or \"What deals do you have?\"",
    "sample": "Call us directly, mention that you found us on Alexa, and get $25 off!   You can also ask us how to get 10% off your drain cleaning service.",
    "targetIntent": "Specials",
    "responses": [],
  },
  {
    "title": "Testimonials",
    "for": "\"What do others say about your business?\" or \"Do you have any references?\"",
    "sample": "Brian N said...Had a great experience with ACME on two occasions. Bill and Jim were both on time and very knowledgeable answering any questions I had on my building's plumbing. Awesome customer service, communication, efficiency and reasonable pricing. Highly recommended.  Craig A said... I have been using ACME Plumbing for several years on rental properties I manage in San Francisco. They are professional, show up on time, and offer good solutions. Honest and good guys!",
    "targetIntent": "Testimonials",
    "responses": [],
  },
  {
    "title": "Support Information",
    "for": "\"How do I get in touch with Customer Service or Support?\"",
    "sample": "Are you looking for quality plumbing services in San Francisco and its neighboring areas? ACME Plumbing is always ready to help you will all your plumbing needs or answer any queries you have.  Call us today at (415) 555-1212.",
    "targetIntent": "Support",
    "responses": [],
  },
  {
    "title": "Frequently Asked Questions",
    "for": "\"What are your most commonly asked questions?\" or \"FAQ\"",
    "sample": "Here are answers to the questions we hear most often:  Why should you hire a Master Plumber?   It really comes down to the highest level of experience and expertise. A licensed master plumber has a lot of training, extensive experience and advanced skills. So hiring a master plumber, as opposed to an unlicensed plumber, has its advantages.  How do I know when to replace my bath faucet?   You and your family probably rely on the bath faucet on a daily basis to get clean, particularly if your bathtub doubles as a shower. These plumbing fixtures usually work flawlessly and reliably and aren't given much thought, until they aren't working properly. Here are a few signs that it's time to schedule a new bath faucet installation...constant dripping, leaking handles, rust and grime, or if you just want a new look.",
    "targetIntent": "FAQ",
    "responses": [],
  },
  {
    "title": "Upcoming Events",
    "for": "\"Do you have any upcoming events?\" or \"Events\"",
    "sample": "Come out this Saturday  from 2pm to 5pm for our annual Customer Appreciation Day!  There will be free food provided by Knockout Chicken, fun and games for the kids, and door prizes for everyone.   We hope to see you there!",
    "targetIntent": "Events",
    "responses": [],
  },
  {
    "title": "Return/Refund Policy or Service Guarantee",
    "for": "\"What is your return policy?\" or \"Do you have a money back guarantee?\"",
    "sample": "If you are not completely satisfied with our servies, just let us know and we will do everything in our power to make it right.  If we can't make it right, you don't pay.",
    "targetIntent": "Guarantee",
    "responses": [],
  },
  {
    "title": "Interesting Facts",
    "for": "\"Tell me an interesting fact.\"",
    "type": "multiple",
    "placeHolder": "Enter a fact...",
    "samples": [
      "Albert Einstein was made an honorary member of the Plumbers and Steamfitters Union after he had announced that he would be a plumber if he had to live his life all over again.",
      "In the technology capitol of the world, Japan, some urinals have voice activated flushing mechanisms.  The urinals respond to several commands, including \"fire.\"",
      "Over $100,000 were spent on a study to determine whether most people put their toilet paper on the holder with the flap in front or behind."
    ],
    "targetIntent": "Fun Facts",
    "responses": []
  },
  {
    "title": "Jokes",
    "for": "\"Tell me a joke.",
    "type": "multiple",
    "placeHolder": "Enter a joke...",
    "samples": [
      "Did you hear about the blind plumber?.. He picked up a hammer and saw.",
      "Did you hear that someone broke into our local police station and stole the toilet?..  Right now the cops have nothing to go on.",
      "I would put a good chunk of money down to bet that you will never find a plumber who bites their nails.",
      "Why did the plumber fall asleep at work?..  His job was draining!",
      "Why are plumbers good at poker?..  They know how to get a royal flush!"
    ],
    "targetIntent": "Jokes",
    "responses": [],
  },
  {
    "title": "Goodbye",
    "for": "What Alexa will say when the user ends your skill.",
    "sample": "We hope you have a wonderful day!",
    "targetIntent": "Goodbye",
    "responses": [],
  }
];

export default questions;
