import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import VoicePicker from '../VoicePicker';

import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/theme/tomorrow';

class Variables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabId: 1,
      projectName: props.project.name,
      defaultVoice: props.project.defaultVoice ? props.project.defaultVoice : 'Alexa',
      permissions: props.project.permissions ? props.project.permissions : {
        firstName: false,
        fullName: false,
        email: false,
        phoneNumber: false
      },
      dynamicEntities: props.project.dynamicEntities
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      projectName: props.project.name,
      defaultVoice: props.project.defaultVoice ? props.project.defaultVoice : 'Alexa',
      permissions: props.project.permissions ? props.project.permissions : {
        firstName: false,
        fullName: false,
        email: false,
        phoneNumber: false
      },
      dynamicEntities: props.project.dynamicEntities
    })
  }

  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleTabChange = (id) => {
    this.setState({
      tabId: id
    })
  }

  handleChangeProjectName = (name) => {
    const { defaultVoice, permissions, dynamicEntities } = this.state;

    this.setState({
      projectName: name
    })

    if (this.props.onChange) {
      this.props.onChange({
        projectName: name,
        defaultVoice: defaultVoice,
        permissions,
        dynamicEntities
      })
    }
  }

  handleChangeVoice = (voice) => {
    const { projectName, permissions, dynamicEntities } = this.state;

    this.setState({
      defaultVoice: voice
    })

    if (this.props.onChange) {
      this.props.onChange({
        projectName,
        defaultVoice: voice,
        permissions,
        dynamicEntities
      })
    }

  }

  handleChangeDynamicEntities = (newEntities) => {
    const { projectName, defaultVoice, permissions } = this.state;

    this.setState({
      dynamicEntities: newEntities
    })

    if (this.props.onChange) {
      this.props.onChange({
        projectName,
        defaultVoice,
        permissions,
        dynamicEntities: newEntities
      })
    }

  }

  togglePermission = (permission) => {
    let { projectName, defaultVoice, permissions, dynamicEntities } = this.state;

    permissions[permission] = !permissions[permission];

    if (permission === 'firstName' && permissions[permission] === true) {
      permissions['fullName'] = !permissions[permission];
    }

    if (permission === 'fullName' && permissions[permission] === true) {
      permissions['firstName'] = !permissions[permission];
    }


    this.setState({
      permissions
    })

    if (this.props.onChange) {
      this.props.onChange({
        projectName,
        defaultVoice,
        permissions,
        dynamicEntities
      })
    }
  }


  render() {
    const { tabId, projectName, defaultVoice, permissions, dynamicEntities } = this.state;

    return (
      <div className="vb_container">
        <ReactTooltip effect="solid" multiline={true}/>
        <div className="tb_header">Project Settings</div>
        <div className="tb_close" onClick={this.close}><FA icon={["far", "times"]} /></div>
        <hr className="tb_hr"/>
        <ul className="nav nav-tabs">
          <li className="nav-item" onClick={() => this.handleTabChange(1)}>
            <a className={"nav-link" + (tabId === 1 ? " active" : "")} href="#">Basic</a>
          </li>
          <li className="nav-item" onClick={() => this.handleTabChange(2)}>
            <a className={"nav-link" + (tabId === 2 ? " active" : "")} href="#">Permissions</a>
          </li>
          <li className="nav-item" onClick={() => this.handleTabChange(3)}>
            <a className={"nav-link" + (tabId === 3 ? " active" : "")} href="#">Advanced</a>
          </li>
        </ul>

        {tabId === 1 &&
          <div>
            <div className="tb_section">
              <div className="tb_title">Project Name</div>
              <div className="tb_textInput">
                <input type="text" placeholder="Enter a new variable..." value={projectName} onKeyPress={this.handleInputKeyPress} onChange={(event) => this.handleChangeProjectName(event.target.value)}/>
              </div>
            </div>
            <div className="tb_section">
              <div className="tb_title">Default Voice</div>
              <div className="tb_subTitle">Choose the default voice you want to use for all voice responses in your project. You can override this setting with each voice response.</div>

              <VoicePicker selectedVoice={defaultVoice} onChange={(voice) => this.handleChangeVoice(voice)} />
            </div>
          </div>
        }

        {tabId === 2 &&
          <div>
            <div className="tb_section"></div>
            <div className="tb_subTitle">Ask the user for acess to the following data/features:</div>
            <div className="tb_section">
              <div className="tb_title">Customer Information</div>
              <div className="tb_subSection">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="customSwitches1" checked={permissions.firstName} onChange={() => this.togglePermission("firstName")}/>
                  <label className="custom-control-label" htmlFor="customSwitches1">First Name</label>
                </div>

                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="customSwitches2" checked={permissions.fullName} onChange={() => this.togglePermission("fullName")}/>
                  <label className="custom-control-label" htmlFor="customSwitches2">Full Name</label>
                </div>

                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="customSwitches3" checked={permissions.email} onChange={() => this.togglePermission("email")}/>
                  <label className="custom-control-label" htmlFor="customSwitches3">E-mail Address</label>
                </div>

                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="customSwitches4" checked={permissions.phoneNumber} onChange={() => this.togglePermission("phoneNumber")}/>
                  <label className="custom-control-label" htmlFor="customSwitches4">Phone Number</label>
                </div>
              </div>
            </div>

          </div>
        }

        {tabId === 3 &&
          <div>
            <div className="tb_section">
              <div className="tb_title">Dynamic Entities</div>
              <div className="tb_textArea">
                <AceEditor
                  mode="javascript"
                  theme="tomorrow"
                  width="100%"
                  height="180px"
                  value={dynamicEntities}
                  onChange={this.handleChangeDynamicEntities}
                  name="tb_de_editor"
                  editorProps={{$blockScrolling: true}}
                />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Variables;
