import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FA from '@fortawesome/react-fontawesome';

class CopyToClipboard extends PureComponent {

	constructor(props) {
		super(props);

		const self = this;
		self.state = { copied: false };
		self.onCopyClick = self.handleCopyClick.bind( self );
	}

	handleCopyClick = (e) => {
		const self = this;
		const { text } = self.props;
		const element = document.createElement('textarea');

		e.preventDefault();
		e.stopPropagation();

		document.body.appendChild(element);

		element.value = text;
		element.select();
		document.execCommand('copy');

		document.body.removeChild(element);

		self.setState( { copied: true });
	}

	render() {
		const self = this;
		const { copied } = self.state;

		return (
			<span className="clipboardCopy" onClick={this.handleCopyClick} title="Copy to Clipboard">
				{copied ? <FA icon={["far", "check"]} /> : <FA icon={["far", "link"]} />}
			</span>
		);
	}

}

CopyToClipboard.propTypes = {
	text: PropTypes.string.isRequired,
};

export default CopyToClipboard;
