import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiText from '../MultiText';
import MultiKeyValue from '../MultiKeyValue';
import ReactTooltip from 'react-tooltip';

class Integration extends Component {
  constructor(props) {
    super(props);

    this.boxRef = React.createRef();

    this.state = {
      action: props.data ? props.data : {url: '', method: 'GET', headers:[], variables:[]}
    };
  }

  componentWillReceiveProps(props) {

    utils.log('action=', props.data);
    this.setState({
      action: props.data ? props.data : {url: '', method: 'GET', headers:[], variables:[]}
    });
  }

  componentDidMount() {
    if (this.boxRef.current) {

      this.boxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  chooseIntegration = (integrationType) => {
    if (this.props.onChange) {
      this.props.onChange(integrationType);
    }
  }



  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { action } = this.state;

    action.variables = action.variables ? action.variables : [];
    utils.log('CallAPI Action=', action);

    return (
      <div className="intent_card" ref={this.boxRef} >
        <ReactTooltip effect="solid" multiline={true}/>
        <div className="integrations_help" data-tip="Integrations allow you to connect<br/>to external data sources and applications."><FA icon={["far", "question-circle"]} /></div>
        <div className="integrations_title">Integrations</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div className="integrations_choose">Choose an integration</div>
        <div className="integration_container">
          <div className="row integration_row">
            <div className="col-sm integration_col">
              <div className="integration" onClick={() => this.chooseIntegration('callAPI')}>
              <div className="integration_help" data-tip="Call an external API to retrieve, create,<br /> update, or delete data."><FA icon={["fal", "question-circle"]} /></div>
              <div className="integration_icon json">
                <img alt="logo" src="/images/api.png" />
              </div>
                <div className="integration_title">Call Custom API</div>
              </div>
            </div>
            <div className="col-sm integration_col">
              <div className="integration" onClick={() => this.chooseIntegration('getRSS')}>
              <div className="integration_help" data-tip="Retrieve data from an RSS Feed."><FA icon={["fal", "question-circle"]} /></div>
              <div className="integration_icon rss">
                <img alt="logo" src="/images/rss.png" />
              </div>
                <div className="integration_title">Get RSS Feed</div>
              </div>
            </div>
          </div>
          <div className="row integration_row">
            <div className="col-sm integration_col">
              <div className="integration_disabled">
                <div className="integration_soon" data-tip="Stay tuned...we're working on this!">Coming Soon!</div>
                <div className="integration_help" data-tip="Read and write information<br />to/from a Google Sheet."><FA icon={["fal", "question-circle"]} /></div>
                <div className="integration_icon googlesheets">
                  <img alt="logo" src="/images/googlesheets.png" />
                </div>
                <div className="integration_title">Google Sheets</div>
              </div>
            </div>
            <div className="col-sm integration_col">
              <div className="integration_disabled">
                <div className="integration_soon" data-tip="Stay tuned...we're working on this!">Coming Soon!</div>
                <div className="integration_help" data-tip="Read and write information<br />to/from an AirTable Base."><FA icon={["fal", "question-circle"]} /></div>
                <div className="integration_icon airtable">
                  <img alt="logo" src="/images/airtable.png" />
                </div>
                <div className="integration_title">AirTable</div>
              </div>
            </div>
          </div>
          <div className="row integration_row">
            <div className="col-sm integration_col">
              <div className="integration_disabled">
                <div className="integration_soon" data-tip="Stay tuned...we're working on this!">Coming Soon!</div>
                <div className="integration_help" data-tip="Send emails using SendGrid"><FA icon={["fal", "question-circle"]} /></div>
                <div className="integration_icon sendgrid">
                  <img alt="logo" src="/images/sendgrid.png" />
                </div>
                <div className="integration_title">SendGrid</div>
              </div>
            </div>
            <div className="col-sm integration_col">
              <div className="integration_disabled">
                <div className="integration_soon" data-tip="Stay tuned...we're working on this!">Coming Soon!</div>
                <div className="integration_help" data-tip="Send emails and add users<br/>to mailing lists in MailChimp."><FA icon={["fal", "question-circle"]} /></div>
                <div className="integration_icon mailchimp">
                  <img alt="logo" src="/images/mailchimp.png" />
                </div>
                <div className="integration_title">MailChimp</div>
              </div>
            </div>
          </div>
          <div className="row integration_row">
            <div className="col-sm integration_col">
              <div className="integration_disabled">
                <div className="integration_soon" data-tip="Stay tuned...we're working on this!">Coming Soon!</div>
                <div className="integration_help" data-tip="Send real-time usage data<br/>to DashBot for advanced analytics."><FA icon={["fal", "question-circle"]} /></div>
                <div className="integration_icon dashbot">
                  <img alt="logo" src="/images/dashbot.png" />
                </div>
                <div className="integration_title">DashBot</div>
              </div>
            </div>
            <div className="col-sm integration_col">
              <div className="integration_disabled">
                <div className="integration_soon" data-tip="Stay tuned...we're working on this!">Coming Soon!</div>
                <div className="integration_help" data-tip="Trigger events and send<br/> data almost anywhere with Zapier."><FA icon={["fal", "question-circle"]} /></div>
                <div className="integration_icon zapier">
                  <img alt="logo" src="/images/zapier.png" />
                </div>
                <div className="integration_title">Zapier</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Integration;
