import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';

class Permissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: props.permissions ? props.permissions : {
        firstName: false,
        fullName: false,
        email: false,
        phoneNumber: false
      }
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      permissions: props.permissions ? props.permissions : {
        firstName: false,
        fullName: false,
        email: false,
        phoneNumber: false
      }
    });
  }

  togglePermission = (permission) => {
    let { permissions } = this.state;

    permissions[permission] = !permissions[permission];

    if (permission === 'firstName' && permissions[permission] === true) {
      permissions['fullName'] = !permissions[permission];
    }

    if (permission === 'fullName' && permissions[permission] === true) {
      permissions['firstName'] = !permissions[permission];
    }


    this.setState({
      permissions
    })

    if (this.props.onChange) {
      this.props.onChange(permissions);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { cardData, permissions } = this.state;

    return (
      <div className="intent_card">
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_permissions" icon={["far", "key"]} /></span>Permissions</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div className="tb_subTitle">Send a request to the user's Alexa App for the following permissions:</div>

        <div className="tb_title">Customer Information</div>
        <div className="tb_subSection">
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="customSwitches1" checked={permissions.firstName} onChange={() => this.togglePermission("firstName")}/>
            <label className="custom-control-label" htmlFor="customSwitches1">First Name</label>
          </div>

          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="customSwitches2" checked={permissions.fullName} onChange={() => this.togglePermission("fullName")}/>
            <label className="custom-control-label" htmlFor="customSwitches2">Full Name</label>
          </div>

          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="customSwitches3" checked={permissions.email} onChange={() => this.togglePermission("email")}/>
            <label className="custom-control-label" htmlFor="customSwitches3">E-mail Address</label>
          </div>

          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="customSwitches4" checked={permissions.phoneNumber} onChange={() => this.togglePermission("phoneNumber")}/>
            <label className="custom-control-label" htmlFor="customSwitches4">Phone Number</label>
          </div>
        </div>

      </div>
    );
  }
}

export default Permissions;
