import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
//import Footer from '../Footer';
import Spinner from '../Spinner';
import PremiumMember from '../PremiumMember';
import Stats from '../Stats';

import utils from '../helpers/utils.js';

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: null
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentWillMount() {
    utils.getCurrentUser().then(user => {
      this.setState({
        isPremium: user.subscription ? user.subscription : false
      });
    });
  }

  componentDidMount() {
    let projectId = this.props.match.params.id;

    if (projectId) {
      utils.getProjectById(projectId).then(proj => {
        utils.log(proj);

        this.setState({
          project: proj,
          //questions
        });
      })
    } else {
      // Project does not exist
      this.goTo('projects');
    }


  }

  render() {
    const {isPremium, project} = this.state;

    let projectName = null;
    if (project) {
      projectName = project.name;
    }
    return (
      <div className="analytics_full">

        <HeaderSecure menuId={2} auth={this.props.auth} history={this.props.history} isProfessional={true} title={projectName}/>
        <div className="row row-full">
          <div className="col">
            <Stats skillId={project && project.skillId}/>
            {//"amzn1.ask.skill.c84ce801-3645-4f00-b753-b11c3ebd19e3"/>
            }
          </div>
        </div>
      </div>
    );
  }
}




export default Analytics;
