import React, { Component } from 'react';
import './styles.css';
import utils from '../helpers/utils.js';
import $ from 'jquery';

class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentWillMount() {


  }
  componentDidMount() {
    $('#deleteModal').modal('show');

    window.setTimeout(() => {
      $('#deleteModal').modal('show');
    }, 500);
  }

  handleConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }


  render() {
    const { title, body, confirmText } = this.props;

    return (
      <div className="modal fade slide" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {body}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" onClick={this.handleConfirm}>{confirmText}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
