import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import "draft-js/dist/Draft.css";
//import 'draft-js-mention-plugin/lib/plugin.css';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import Editor2 from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter }from 'draft-js-mention-plugin';
import './editorStyles.css';
import './mentionStyles.css';
import mentions from '../data/builtInVariables.js';
import createMentionEntities from './mentions';
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
const {hasCommandModifier} = KeyBindingUtil;



const Entry = (props) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className="mentionSuggestionsEntryContainer">
        <div className="mentionSuggestionsEntryContainerLeft">
          <div
            className="mentionSuggestionsEntryAvatar"
            role="presentation"
          />
        </div>

        <div className="mentionSuggestionsEntryContainerRight">
          <div className="mentionSuggestionsEntryText">
            {mention.name}
          </div>
        </div>
      </div>
    </div>
  );
};

const EntryContainer = (props) => {
  const {
    positionSuggestions
  } = props;

  return (
    <div className="entryContainer" style={positionSuggestions}>
      <div className="entryContent">
        {props.children}
      </div>
      <div className="entryContainerLink">Create Variable</div>
    </div>
  );
};

const SuggestionsFilter = (searchValue, suggestions) => {
  const value = searchValue.toLowerCase();
  const filteredSuggestions = suggestions.filter((suggestion) => (
    !value || suggestion.name.toLowerCase().indexOf(value) > -1
  ));
  return filteredSuggestions;
  // const length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
  // return filteredSuggestions.slice(0, length);
};

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this._mentionPlugin = createMentionPlugin({
      mentions: [],
      mention: 'mention',
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '{',
      supportWhitespace: false
    });

    ////console.log("MultiTextprops=", props);
    let variables = props.variables ? props.variables : [];
    let tags = [];
    variables.forEach(v => {
      tags.push({
        name: v
      })
    })
    //tags = tags.concat(mentions);

    ////console.log(tags);

    let value = props.value == null ? "" : props.value ;

    //console.log("props.value=", props.value);
    //console.log("value=", value);
    //console.log.log("giveInitialFocus=", props.giveInitialFocus);
    this.state = {
      isEditing: props.giveInitialFocus === true ? true : false,
      editorState: EditorState.createWithContent(createMentionEntities(value.toString(), tags)),
      suggestions: tags,
      tags: tags
    };

    // window.setTimeout(() => {
    //   if (this.editor) {
    //     this.editor.focus();
    //   }
    // }, 100);
  }

  componentWillReceiveProps(props) {
    const { editorState, isEditing } = this.state;

    //console.log.log("isEditing=", isEditing);

    ////console.log("multiTextpropsWR=", props);
    ////console.log('isEditing=', isEditing);

    let variables = props.variables ? props.variables : [];
    let tags = [];
    variables.forEach(v => {
      tags.push({
        name: v
      })
    })
    //tags = tags.concat(mentions);

    ////console.log(tags);

    let text = editorState.getCurrentContent().getPlainText();

    let value = props.value == null ? "" : props.value;

    if (text === value.toString()) {
      //console.log("returning, no change");
      return;
    };

    if (!isEditing) {
      this.setState({
        textArray: props.data ? props.data : [],
        selectedTextIndex: null,
        editorState: EditorState.createWithContent(createMentionEntities(value.toString(), tags)),
        error: '',
        isEditing: false,
        suggestions: tags,
        tags: tags
      });
    }
  }

  componentDidMount() {
    const { giveInitialFocus } = this.props;
    const { editorState, tags } = this.state;

    //console.log(tags);
    if (false && giveInitialFocus) {
      let text = editorState.getCurrentContent().getPlainText();
      //this.focus();
      this.setState({
        editorState: EditorState.createWithContent(createMentionEntities(text.toString(), tags)),
        isEditing: true
      });
    }
  }

  onChange = (editorState) => {
    //console.log("onChange");
    // if (!editorState.getSelection().getHasFocus()) {
    //   ////console.log("setting focus");
    //   if (this.editor) {
    //     this.editor.focus();
    //     //editorState = EditorState.moveFocusToEnd(editorState)
    //   }
    // }

    const { isEditing } = this.state;

    let text = editorState.getCurrentContent().getPlainText();

    if (isEditing && text.length === 1) {
      editorState = EditorState.moveFocusToEnd(editorState)
    }

    ////console.log('changed');
    this.setState({
      editorState,
    });

    //////console.log(convertToRaw(editorState.getCurrentContent()))
  };

  onSearchChange = ({ value }) => {
    let { tags } = this.state;

    let suggestions = SuggestionsFilter(value, tags);

    ////console.log("new suggestions", suggestions);

    this.setState({
      suggestions,
    });
  };

  onAddMention = () => {
    // get the mention object selected
  }

  focus = () => {
    //console.log("Focusing");
    // if (this.editor) {
    //   this.editor.focus();
    // }
    let { editorState, tags } = this.state;
    let text = editorState.getCurrentContent().getPlainText();

    let selectionState = editorState.getSelection();
    let anchorKey = selectionState.getAnchorKey();
    let currentContent = editorState.getCurrentContent();
    let currentContentBlock = currentContent.getBlockForKey(anchorKey);
    let start = selectionState.getStartOffset();
    let end = selectionState.getEndOffset();
    let selectedText = currentContentBlock.getText().slice(start, end);

    if (!editorState.getSelection().getHasFocus()) {
      if (selectedText.length === 0) {
        this.setState({
          editorState: EditorState.createWithContent(createMentionEntities(text.toString(), tags)),
          isEditing: true
        });
      }
    }

    this.onFocus();
  };

  onFocus = () => {
    //console.log("onFocus");
    let { editorState, suggestions } = this.state;

    if (!editorState.getSelection().getHasFocus()) {
      //console.log("Control Doesn't Have Focus");
      let text = editorState.getCurrentContent().getPlainText();

      let selectionState = editorState.getSelection();
      let anchorKey = selectionState.getAnchorKey();
      let currentContent = editorState.getCurrentContent();
      let currentContentBlock = currentContent.getBlockForKey(anchorKey);
      let start = selectionState.getStartOffset();
      let end = selectionState.getEndOffset();
      let selectedText = currentContentBlock.getText().slice(start, end);

      if (selectedText.length === 0) {
        this.setState({
          editorState: EditorState.moveFocusToEnd(EditorState.createWithContent(createMentionEntities(text.toString(), suggestions)))
        })
      }
      window.setTimeout(() => {
        this.editor.focus();
      },500);
    } else {
      // this.setState({
      //   editorState: EditorState.moveFocusToEnd(editorState),
      //   isEditing: true
      // });
    }


  }

  onKeyPress = (e: SyntheticKeyboardEvent): string => {
    const { isEditing } = this.state;

    if (!isEditing) {
      this.setState({
        isEditing: true
      })
    }

    if (e.keyCode === 13) {
      let { editorState } = this.state;
      let text = editorState.getCurrentContent().getPlainText().trim();

      if (this.props.onChange) {
        this.props.onChange(text);
      }

      this.setState({
        editorState: EditorState.moveFocusToEnd(EditorState.createEmpty()),
        isEditing: false
      });

      e.preventDefault();
      e.stopPropagation();

      return false;
    }

    //return getDefaultKeyBinding(e);
  }

  onBlur = () => {
    //console.log("onBlur");


    const { editorState, suggestions, isEditing } = this.state;

    if (!isEditing) return;

    let text = editorState.getCurrentContent().getPlainText().trim();

    this.state.isEditing = false;
    this.setState({
      isEditing: false
    })

    if (this.props.onChange) {
      this.props.onChange(text);
    }

    ////console.log('GOT HERE');

    if (this.props.clearOnBlur) {
      window.setTimeout(() => {
        this.setState({
          editorState: EditorState.createWithContent(createMentionEntities("", suggestions)),
          isEditing: false
        });
      }, 100);
    }

  }

  render() {
    const { editorState, suggestions} = this.state;

    ////console.log("Suggestions", suggestions);

    const { placeHolder, allowVariables = true } = this.props;

    const { MentionSuggestions } = this._mentionPlugin;

    let plugins = [this._mentionPlugin];

    return (
      <div key={this.props.key} className={this.props.singleLine ? "editor singleLine" : "editor"} onClick={this.focus}>
        <Editor
          placeholder={placeHolder}
          editorState={editorState}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onChange}
          keyBindingFn={this.onKeyPress}
          plugins={plugins}
          ref={(element) => { this.editor = element; }}
        />
        {allowVariables &&
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={suggestions}
            onAddMention={this.onAddMention}
            entryComponent={Entry}
          />
        }
      </div>
    );
  }
}

export default RichTextEditor;
