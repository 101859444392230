import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiKeyValue from '../MultiKeyValue';

class PublishingInfo extends Component {
  constructor(props) {
    super(props);

    let publishingInfo = props.data;

    //utils.log(publishingInfo);
    //utils.log("Publishing INFO (constructor)=", JSON.parse(JSON.stringify(publishingInfo)));
    if (publishingInfo == null) {
      publishingInfo = {
        publicName: '',
        invocationName: '',
        samplePhrase1: '',
        samplePhrase2: '',
        samplePhrase3: '',
        shortDescription: '',
        description: '',
        keywords: ''
      }
    }

    this.state = {
      publishingInfo,
      formFields: {
        publicName: {
          isValid: true
        },
        invocationName: {
          isValid: true
        },
        samplePhrase1: {
          isValid: true
        },
        samplePhrase2: {
          isValid: true
        },
        samplePhrase3: {
          isValid: true
        },
        keywords: {
          isValid: true
        }
      }
    };
  }

  componentWillReceiveProps(props) {

    let publishingInfo = props.data;

    if (publishingInfo == null) {
      publishingInfo = {
        publicName: '',
        invocationName: '',
        samplePhrase1: '',
        samplePhrase2: '',
        samplePhrase3: '',
        shortDescription: '',
        description: '',
        keywords: ''
      }
    }

    utils.log("Publishing INFO=", JSON.parse(JSON.stringify(publishingInfo)));
    
    if (publishingInfo.publicName == null) {
      publishingInfo.publicName = '';
    }

    this.setState({
      publishingInfo
    });
  }

  validateFormFields = () => {
    utils.log('================== VALIDATING FORM FIELDS ===================');
    let { publishingInfo, formFields } = this.state;

    // Invocation Name
    let inRegEx = /^[a-z][a-z \.\u00C0-\u017Fa']*$/g;
    if (!inRegEx.test(publishingInfo.invocationName)) {
      formFields.invocationName.isValid = false;
      formFields.invocationName.error = 'Invocation names must only contain lowercase numbers, spaces, periods (when used in apostrophes), and possessive apostrophes';
    } else {
      formFields.invocationName.isValid = true;
      formFields.invocationName.error = '';
    }

    // Sample Phrases

    // Keywords
    utils.log(publishingInfo.keywords.replace(/,/g, ' ').replace(/ {2}/g, ' ').trim().split(' ').length);
    if (publishingInfo.keywords.replace(/,/g, ' ').replace(/ {2}/g, ' ').trim().split(' ').length > 30) {
      formFields.keywords.isValid = false;
      formFields.keywords.error = "You cannot have more than 30 keywords.";
    } else {
      formFields.keywords.isValid = true;
      formFields.keywords.error = '';
    }

    this.setState({
      formFields
    })
  }

  handlePublicNameChange = (value) => {
    let { publishingInfo } = this.state;

    publishingInfo.publicName = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleInvocationNameChange = (value) => {
    let { publishingInfo } = this.state;

    publishingInfo.invocationName = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleSamplePhrase1Change = (value) => {
    let {publishingInfo} = this.state;

    publishingInfo.samplePhrase1 = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleSamplePhrase2Change = (value) => {
    let {publishingInfo} = this.state;

    publishingInfo.samplePhrase2 = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleSamplePhrase3Change = (value) => {
    let {publishingInfo} = this.state;

    publishingInfo.samplePhrase3 = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleShortDescriptionChange = (value) => {
    let {publishingInfo} = this.state;

    publishingInfo.shortDescription = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleDescriptionChange = (value) => {
    let {publishingInfo} = this.state;

    publishingInfo.description = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }

  handleKeywordsChange = (value) => {
    let {publishingInfo} = this.state;

    publishingInfo.keywords = value;

    this.setState({
      publishingInfo,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(publishingInfo);
    }
  }


  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { publishingInfo, formFields } = this.state;

    let pi = publishingInfo;

    return (
      <div className="intent_card">
        <div className="intent_cardHeader">Publishing Info</div>
        <div className="form-group">
          <div className="trigger_utteranceHeader">Public Name</div>
          <span className="pi_counter">{pi.publicName == null ? 0 : pi.publicName.length }/50</span>
          <input className={"form-control" + (formFields.publicName.isValid ? '' : ' is-invalid')} maxLength="50" placeholder="My Cool Skill" type="text" value={pi.publicName} onChange={(event) => this.handlePublicNameChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.publicName.error}
          </div>
          <div className="trigger_utteranceHeader">Invocation Name</div>
          <span className="pi_counter">{pi.invocationName == null ? 0 : pi.invocationName.length}/50</span>
          <input className={"form-control" + (formFields.invocationName.isValid ? '' : ' is-invalid')} maxLength="50" placeholder="my cool skill" type="text" value={pi.invocationName} onChange={(event) => this.handleInvocationNameChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.invocationName.error}
          </div>
        </div>
        <div className="form-group">
          <div className="trigger_utteranceHeader">Sample Phrases</div>
          <input className={"form-control pi_input" + (formFields.samplePhrase1.isValid ? '' : ' is-invalid')} placeholder="Alexa, open My Cool Skill" type="text" value={pi.samplePhrase1} onChange={(event) => this.handleSamplePhrase1Change(event.target.value)} />
          <div className="invalid-feedback">
            {formFields.samplePhrase1.error}
          </div>
          <input className={"form-control pi_input" + (formFields.samplePhrase2.isValid ? '' : ' is-invalid')} placeholder="Alexa, ask My Cool Skill for a joke" type="text" value={pi.samplePhrase2} onChange={(event) => this.handleSamplePhrase2Change(event.target.value)} />
          <div className="invalid-feedback">
            {formFields.samplePhrase2.error}
          </div>
          <input className={"form-control" + (formFields.samplePhrase3.isValid ? '' : ' is-invalid')} placeholder="Tell me another one" type="text" value={pi.samplePhrase3} onChange={(event) => this.handleSamplePhrase3Change(event.target.value)} />
          <div className="invalid-feedback">
            {formFields.samplePhrase3.error}
          </div>
        </div>
        <div className="form-group">
          <div className="trigger_utteranceHeader">One Sentence Description</div>
          <span className="pi_counter">{pi.shortDescription == null ? 0 : pi.shortDescription.length}/160</span>
          <textarea className="form-control pi_input" id="featureText" rows="2" maxLength="160" value={pi.shortDescription} placeholder="Short Description" onChange={evt => this.handleShortDescriptionChange(evt.target.value)}></textarea>
          <div className="trigger_utteranceHeader">Detailed Description</div>
          <span className="pi_counter">{pi.description == null ? 0 : pi.description.length}/4000</span>
          <textarea className="form-control" id="featureText" rows="5" maxLength="4000" value={pi.description} placeholder="Long Description" onChange={evt => this.handleDescriptionChange(evt.target.value)}></textarea>
        </div>
        <div className="form-group">
          <div className="trigger_utteranceHeader">Keywords (comma separated)</div>
          <span className="pi_counter">{pi.keywords === '' ? 0 : pi.keywords.replace(/,/g, ' ').replace(/ {2}/g, ' ').trim().split(' ').length}/30</span>
          <textarea className={"form-control" + (formFields.keywords.isValid ? '' : ' is-invalid')} placeholder="keyword1,keyword2,..." type="text" value={pi.keywords} onChange={(event) => this.handleKeywordsChange(event.target.value)} />
          <div className="invalid-feedback">
            {formFields.keywords.error}
          </div>
        </div>
      </div>
    );
  }
}

export default PublishingInfo;
