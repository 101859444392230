import * as actions from '../actions/upload';
import { getFileKey } from '../../library/file';

export const defaultState = {
	files: [],
	uploaded: {},
	failed: {},
	imageOptions: [],
	audioOptions: [],
};

const reducer = (state = {}, action = {}) => {
	switch (action.type) {
		case actions.ACTION_SELECT_FILES: {
			const files = action.files.filter((newFile) => {
				return !state.files.find((existingFile) => {
					return existingFile.name === newFile.name && existingFile.lastModified === newFile.lastModified;
				});
			});

			return Object.assign({}, state, {
				files: [...state.files, ...files],
			});
		}

		case actions.ACTION_DESELECT_FILE:
			return Object.assign({}, state, {
				files: state.files.filter(file => action.file.name !== file.name && action.file.lastModified !== file.lastModified),
			});

		case actions.ACTION_UPLOADED_FILE:
			return Object.assign({}, state, {
				uploaded: {
					...state.uploaded,
					[getFileKey(action.originalFile)]: action.uploadedFiles,
				},
			});

		case actions.ACTION_FAILED_FILE:
			return Object.assign({}, state, {
				failed: {
					...state.failed,
					[getFileKey(action.originalFile)]: true,
				},
			});

		case actions.ACTION_RESET_FILES:
			return Object.assign({}, defaultState);

		case actions.ACTION_SELECT_IMAGE_OPTION:
			return Object.assign({}, state, {
				imageOptions: [...state.imageOptions, action.option],
			});

		case actions.ACTION_DESELECT_IMAGE_OPTION:
			return Object.assign({}, state, {
				imageOptions: state.imageOptions.filter(option => option !== action.option),
			});

		case actions.ACTION_SELECT_AUDIO_OPTION:
			return Object.assign({}, state, {
				audioOptions: [...state.audioOptions, action.option],
			});

		case actions.ACTION_DESELECT_AUDIO_OPTION:
			return Object.assign({}, state, {
				audioOptions: state.audioOptions.filter(option => option !== action.option),
			});

		default:
			// do nothing
			break;
	}

	return state;
};

export default reducer;
