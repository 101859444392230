import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import App from './App';
import Home from './Home/Home';
import BuiltWith from './BuiltWith';
import Checkout from './Checkout';
import Plans from './Plans';
import Preview from './Preview/index.js';
import Login from './Login/index.js';
import ForgotPassword from './ForgotPassword/index.js';
import ProjectDetails from './ProjectDetails/index.js';
import Projects from './Projects/index.js';
import Business from './Business/index.js';
import BusinessDetails from './BusinessDetails/index.js';
import Analytics from './Analytics/index.js';
import Import from './Import/index.js';
import Posts from './Posts/index.js';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import history from './history';
import Hosting from './candlewick/Application.js';
import Upload from './candlewick/pages/Upload.js';
import HostingTerms from './candlewick/pages/Terms.js';
import EscapePrivacy from './EscapePrivacy/index.js';
import Escape from './Escape/index.js';
import Privacy from './Privacy/index.js';
import Terms from './Terms/index.js';

import utils from './helpers/utils.js';
import { Provider } from 'react-redux';
import createStore from './candlewick/redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import RouterCreate from './RouterCreate.js';
import GAListener from './gaListener';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-102960892-2');

const auth = new Auth();



const handleAuthentication = (nextState, replace) => {
  utils.log("testing authentication:", nextState.location);
  if (/access_token|id_token|error/.test(nextState.location.search)) {
    auth.handleAuthentication(nextState.location);
  }
}

function loggedIn() {
  //return true;
  return utils.isAuthenticated();
}

// history.listen((location, action) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

let store = createStore();
let persistor = persistStore(store);

export const makeMainRoutes = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history} component={App}>
          <GAListener>
            <Switch>
              <Route path="/login/:nextpage" render={(props) => <Login auth={auth} {...props} />} />
              <Route path="/login" render={(props) => <Login auth={auth} {...props} />} />
              <Route path="/resetpassword/:code" render={(props) => <ForgotPassword auth={auth} {...props} />} />
              <Route path="/home" render={(props) => <Home auth={auth} {...props} />} />
              <Route path="/checkout" render={(props) => <Checkout auth={auth} {...props} />} />
              <Route path="/skills" render={(props) => <BuiltWith auth={auth} {...props} />} />
              <Route path="/plans" render={(props) => <Plans auth={auth} {...props} />} />
              <Route path="/preview/:id" render={(props) => <Preview auth={auth} {...props} />} />
              <Route path="/hosting/terms" render={(props) => <HostingTerms auth={auth} {...props} />} />
              <Route path="/hosting" render={(props) => <Hosting auth={auth} {...props} />} />
              <Route path="/hosting/upload" render={(props) => <Upload auth={auth} {...props} />} />
              <Route path="/escape" render={(props) => <Escape auth={auth} {...props} />} />
              <Route path="/escape_privacy" render={(props) => <EscapePrivacy auth={auth} {...props} />} />
              <Route path="/privacy" render={(props) => <Privacy auth={auth} {...props} />} />
              <Route path="/terms" render={(props) => <Terms auth={auth} {...props} />} />
              <RouterCreate path="/business/:id" route="business" render={(props) => <BusinessDetails  {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/business" route="business" render={(props) => <BusinessDetails  {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/projects" render={(props) => <Projects  {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/import" render={(props) => <Import  {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/project/:id/:demo" render={(props) => <ProjectDetails auth={auth} {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/project/:id" render={(props) => <ProjectDetails auth={auth} {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/posts/:id" render={(props) => <Posts auth={auth} {...props} />} onEnter={loggedIn}/>
              <RouterCreate path="/analytics/:id" render={(props) => <Analytics  {...props} />} onEnter={loggedIn}/>
              <Route path="/" render={(props) => <Home  {...props} />} onEnter={loggedIn}/>
              <Route path="/callback" render={(props) => {
                handleAuthentication(props);
                return <Callback {...props} />
              }}/>
            </Switch>
          </GAListener>
        </Router>
      </PersistGate>
    </Provider>
  );
}
