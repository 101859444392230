var templateIntents = {
  "1": [ // General Business
    {
      "key": "welcome",
      "messages": [
        {
          "visitCount": "*",
          "message": "Welcome. To learn more about us and our business you can ask about things like our company history or our services.  Say Help to get a full list of things you can ask about.  What can I help you with?",
          "reprompt": "What can I help you with?",
          "audio": "logo1.mp3"
        }
      ],
      "type": "messageByVisit"
    },
    {
      "key": "help",
      "text": "You can ask about our services, company history, our mission, location, hours of operation, clients, testimonials, and open jobs. You can also get our website, phone number, and social media handles.  For a little fun, ask me for a joke.  What would you like to know?",
      "type": "text"
    },
    {
      "key": "goodbye",
      "text": "Thanks for visiting. Have a great day!",
      "type": "text"
    }
  ],
  "2": [ // Real Estate
    {
      "key": "welcome",
      "messages": [
        {
          "visitCount": "*",
          "message": "Welcome. To learn more about us and our business you can ask about things like our company history or our services.  Say Help to get a full list of things you can ask about.  What can I help you with?",
          "reprompt": "What can I help you with?",
          "audio": "logo1.mp3"
        }
      ],
      "type": "messageByVisit"
    },
    {
      "key": "help",
      "text": "You can ask about our services, company history, our mission, location, hours of operation, clients, testimonials, and open jobs. You can also get our website, phone number, and social media handles.  For a little fun, ask me for a joke.  What would you like to know?",
      "type": "text"
    },
    {
      "key": "goodbye",
      "text": "Thanks for visiting. Have a great day!",
      "type": "text"
    }
  ],
  "3": [ // Trivia
    {
      "id": "welcome",
      "name": "Welcome",
      "description": "Activated when users open the skill.",
      x: -629.3954420666817,
      y: -386.22721224006153,
      "publishingInfo": {
        "invocationName": "",
        "samplePhrase1": "",
        "samplePhrase2": "",
        "samplePhrase3": "",
        "shortDescription": "",
        "description": "",
        "keywords": ""
      }
    },
    {
      "id": "help",
      "name": "Help",
      "description": "Activated when users ask for help.",
      x: -629.3954420666814,
      y: -24.18864492312818
    },
    {
      "id": "goodbye",
      "name": "Goodbye",
      "description": "Activated when users close the skill.",
      x: 52.17531288936902,
      y: -389.0911328565678
    }
  ],
  "4": [ // Facts
    {

      "key": "welcome",
      "messages": [
        {
          "visitCount": "*",
          "message": "Welcome. To get a fact, say \"Get Fact\".  Say Help to get a full list of things you can ask about.  What can I help you with?",
          "reprompt": "What can I help you with?",
          "audio": "logo1.mp3"
        }
      ],
      "type": "messageByVisit"
    },
    {
      "key": "facts",
      "facts": [
        "Sample Fact 1",
        "Sample Fact 2"
      ],
      "prompts": [
         "Want to hear another one?",
         "Want to hear another fact?",
         "Another one?",
         "Another fact?",
         "I've got more. Want another one?"
       ],
       "prefixes": [
         "Here's your fact.",
         "It's a fact.",
         "Did you know?",
         "Can you believe it?",
         "Believe it or not."
       ],
      "type": "facts"

    },
    {
      "key": "help",
      "text": "To get a fact, just say \"Get Fact\"",
      "type": "text"
    },
    {
      "key": "goodbye",
      "text": "Thanks for visiting. Have a great day!",
      "type": "text"
    }
  ],

};

module.exports = templateIntents;
