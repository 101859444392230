import React, { Component } from 'react';
import './index.css';
import utils from '../helpers/utils.js';
//import FA from '@fortawesome/react-fontawesome';
//import Footer from '../Footer';
import SkillTester from "../SkillTester";

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: null,
      isSharingEnabled: false,
      isLoading: true
    }
  }

  componentDidMount() {
    let projectId = this.props.match.params.id;

    utils.getCurrentUser().then(response => {
      utils.getProjectById(projectId).then(project => {
        utils.log("got project!!");

        this.setState({
          project,
          isSharingEnabled: project.isSharingEnabled,
          isLoading: false
        });
      });
      return null;
    }).catch(err => {
      utils.getProjectById(projectId).then(project => {
        utils.log("got project!!");

        this.setState({
          project,
          isSharingEnabled: project.isSharingEnabled,
          isLoading: false
        });
      });

      return null;
    })

  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }


  handleProjectClick = (id) => { // eslint-disable-line
    this.goTo(`project/${id}`);
  }


  render() {
    const { project, isSharingEnabled, isLoading } = this.state;

    return (
      <div className="prev_testContainer">
        {isLoading ?
          null
          :
          <div>
            <div className="prev_projectTitle">{project && project.name}</div>
            {isSharingEnabled ?
              <SkillTester visible={true} project={project} locale="en-US" className="prev_Container"/>
              :
              <div className="prev_notSharedContainer">
                Sharing is not enabled for this project.  Please contact the skill owner.
              </div>

            }
            <div className="prev_projectFooter">Built with <a href="https://voiceapps.com">VoiceApps.com</a></div>
          </div>
        }
      </div>
    );
  }
}


export default Plans;
