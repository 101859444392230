import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import FA from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { contentUpdateFrequency, contentType, contentGenre } from './sampleData';

class PostView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPost: '',
        }
    }
    componentDidUpdate() {
        utils.log(this.state.selectedPost);
    }

    /** Render genre */
    renderGenre(givenValue) {
        let searchedItem = contentGenre.find((item) => item.value == givenValue);
        return searchedItem.label;
    }

    /** Render renderContentType */
    renderContentType(givenValue) {
        let searchedItem = contentType.find((item) => item.value == givenValue);
        return searchedItem.label;
    }



    handlePostClick = (id) => {
        utils.log(id)
        this.setState({
            selectedPost: id
        });

    }

    render() {
        let { post, editPost, dateFilter } = this.props;
        utils.log("rendering post=", post);
        let date = moment(post.postDate, 'YYYY-MM-DD').format("DD-MMM-YYYY");
        return (
            <div key={post.id} className="col col-12 post-page">
                <div className={'card'} style={{ border: this.props.selectedPost == post.id ? "2px solid #28a745" : "none" }} id={post.id} onClick={() => editPost(post.id)}>
                    <div className="post_delete" data-toggle="modal" onClick={(e) => this.props.deleteSelectPost(e, post.id)} data-target="#deleteModal"><span><FA icon={["far", "trash"]} /></span></div>
                    <div className="card-body">
                        <div className="card-title-box">
                            <div className="bdr-btm">
                                <h4 className="pv_cardTitle">{post.title}</h4>
                            </div>
                        </div>
                        <p className="card-text">
                          { post.contentType === 'TEXT' ? post.text : post.audioUrl}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}


export default PostView;
