import React, { Component } from 'react';
import './index.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
import Spinner from '../Spinner';
import PremiumMember from '../PremiumMember';
import PostView from './postView';
import PostAdd from './postAdd';
import PublishPost from '../PublishPost';
import Calendar from 'react-calendar';
//import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import utils from '../helpers/utils.js';

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      isPremium: true,
      postDate: new Date(),
      selectedPost: {},
      projectId: "",
      posts: [],
      allPost: [],
      postAction: "Create",
      isAccountLinked: false,
      currentSelectedPost:''
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }


  componentWillMount() {
    var that = this;
    this.setState({ showLoading: true });
    const projectId = this.props.match.params.id;
    utils.getCurrentUser().then(user => {
      utils.log('===========user========', user);

      this.setState({
        isPremium: user.subscription ? user.subscription : false,
        projectId: projectId,
        isAccountLinked: user.refreshToken ? true : false,
        user: user
      });
      /** Get feed posts  */
      this.handlePostsUpdated();

      window.Intercom("boot", {
        app_id: "rxv0p3l3",
        name: user.firstName + ' ' + user.lastName, // Full name
        email: user.userName, // Email address
        created_at: user.signUpDate // Signup date
      });
      //utils.getProject(projectId).then(proj => {
      utils.getProjectById(projectId).then(proj => {

        utils.log("Project (component did mount)=", JSON.parse(JSON.stringify(proj)));


        that.setState({
          project: proj,
        });

        return null;
      }).catch(err => {
        utils.log(err);
      });
      return null;
    }).catch(err => {
      utils.log(err);
      //that.goTo('login');
    })

  }

  /** Get list of all post regarding the project  */
  handlePostsUpdated = () => {

    this.setState({ showLoading: true, currentSelectedPost: '' });
    let currentDate = moment(this.state.postDate).format("YYYY-MM-DD");
    utils.log("handlePostsUpdated  ", currentDate);
    utils.getPosts(this.state.projectId + "-" + currentDate).then(response => {
      utils.log("posts listing", response);
      this.setState({
        posts: response,
        showLoading: false
      });
    }).catch(err => {
      utils.log(err);
    });

    utils.getPosts2(this.state.projectId).then(response => {
      utils.log("All posts = ", response);
      this.setState({
        allPosts: response
      });
    }).catch(err => {
      utils.log(err);
    });
  }


  toggleDemoPanel = () => { // eslint-disable-line
    this.setState({
      showDemos: !this.state.showDemos,
    });
  }

  setPremium = () => {
    this.handlePremiumModalClose();
    $('#EditProjectModal').modal('show');
    this.setState({
      isPremium: true
    })
  }

  handlePremiumModalOpen = () => { // eslint-disable-line
    $('#EditProjectModal').modal('hide');
    $('#PremiumModal').modal('show');
  }

  handlePremiumModalClose = () => { // eslint-disable-line
    $('#PremiumModal').modal('hide');
  }

  /* Open modal for opening  adding/updating the post */
  handleAddEditPostModal = () => {
    let formFields = {
      id: "",
      name: '',
      vuiPreamble: '',
      updateFrequency: 'HOURLY',
      contentType: 'TEXT',
      genre: 'HEADLINE_NEWS',
      url: '',
      imageUri: '',
    };
    this.setState({ selectedPost: formFields, postAction: "Create" });
    setTimeout(() => {
      $('#AddPostModal').modal('show');
    }, 300)

  }

  /* Edit post */
  editPostHandler = (id) => {
    utils.log("edit post " + id);
    let selectedPost = this.state.posts.find(val => val.id == id);
    utils.log("selectedPost", selectedPost);
    this.setState({ selectedPost: selectedPost, postAction: "Update" });

    setTimeout(() => {
      utils.log("this.state.selectedPost ", this.state.selectedPost);
      $('#AddPostModal').modal('show');
    }, 300)
  }

  /* Render the post listing */
  renderPosts() {
    const { postDate, isPremium } = this.state;

    if (this.state.showLoading) {
      return (
        <div className="proj_loadingContainer active">
          <div className="proj_spinnerBox"><Spinner size={30} /></div>
          <div className="proj_spinnerText">Loading Posts...</div>
        </div>
      );
    }

    var date1 = new Date();
    date1.setHours(0,0,0,0);
    var date2 = postDate;
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (!isPremium && date2 > date1 && diffDays > 1 ) {
      return (
        <div className="alert alert-warning feed-alert">
          To schedule posts in the future, you need to upgrade your account.  Click the upgrade button to the right of the header bar above.
       </div>
      )
    }
    if (this.state.posts.length == 0) {
      return (
        <div className="alert alert-warning feed-alert">
          There are no posts for this day.  Click "Add Post" to create one.
       </div>
      )
    }

    return this.state.posts.map((post) => {
      return <PostView deleteSelectPost={this.deleteSelectPost} key={post.id} post={post} dateFilter={this.state.postDate} editPost={this.editPostHandler} selectedPost={this.state.currentSelectedPost} updateSelectedPost={this.updateSelectedPost} />
    });

  }

  updateSelectedPost = (id) => {
    this.setState({ currentSelectedPost: id });
  }

  handlePublishClick = () => { // eslint-disable-line
    utils.log('publish clicked');
    $('#PublishProjectModal').modal('show');
  }

  // eslint-disable-line
  handleMenuClick = (e) => {
    utils.log('menu clicked');
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  /** Update the filter date  */
  updateFilterDate = (date) => {
    this.setState({ postDate: date, currentSelectedPost: '' });
    setTimeout(() => {
      this.handlePostsUpdated();
    }, 100);
  }

  /**select delete project */
  deleteSelectPost = (e, id) => {
    utils.log("deleteSelectPost", id);
    let selectedPost = this.state.posts.find(item => item.id === id);
    this.setState({ selectedPost: selectedPost });

    e.preventDefault();
    e.stopPropagation();
  }


  handleDeleteConfirm = () => {
    this.setState({ showLoading: true });
    let posts = this.state.posts;
    let selectedIndex = posts.findIndex((item) => item.id == this.state.selectedPost.id);
    posts.splice(selectedIndex, 1);
    let postDate = moment(this.state.postDate).format("YYYY-MM-DD");
    utils.createPost(this.state.projectId + "-" + postDate, posts).then(respose => {
      toast.success("The post has been successfully deleted.");
      this.setState({ showLoading: false });
      $('#deleteModal').modal('toggle');
      this.handlePostsUpdated();
    });
  }

  renderDeleteActionButton() {
    if (this.state.showLoading == true) {
      return <button type="button" className="btn btn-danger" onClick={this.handleDeleteConfirm}>Deleteing...</button>
    } else {
      return <button type="button" className="btn btn-danger" onClick={this.handleDeleteConfirm}>Delete</button>
    }
  }

  handleRefreshPost = () => {

  }

  getTileClassName = ({date, view}) => {
    const { allPosts, project} = this.state;

    if (allPosts) {
      const curDate = date.getFullYear() + '-' + ("0000" + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate();
      let activePosts = allPosts.filter(p => {return p.postDate === curDate});
      if (activePosts && activePosts.length>0) {
        return "tile_bold";
      } else {
        return "tile";
      }
    }
  }

  getTileContent = ({date, view}) => {
    const { allPosts, project} = this.state;

    if (allPosts) {
      const curDate = date.getFullYear() + '-' + ("0000" + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate();
      let activePosts = allPosts.filter(p => {return p.postDate === curDate});
      if (activePosts && activePosts.length>0) {
        return (
          <div className="dots">
          {activePosts.map((ap, key) => {
            return (
              <span key={key} className="dot"></span>
            )
          })}
          </div>
        )
      }
    }
  }

  render() {
    utils.log("in render", this.state.selectedPost);
    const { project, posts, postDate, showLoading, isPremium, isAccountLinked, user } = this.state;

    var date1 = new Date();
    date1.setHours(0,0,0,0);
    var date2 = postDate;
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    var canPost = true;
    if (!isPremium && date2 > date1 && diffDays > 1) {
      canPost = false;
    }

    utils.log('render: isAccountLinked', isAccountLinked);
    return (
      <div className="projects_full">
        <ToastContainer />

        <PostAdd postDate={this.state.postDate} selectedPost={this.state.selectedPost} projectId={this.state.projectId} posts={this.state.posts} postAction={this.state.postAction} handlePostsUpdated={() => this.handlePostsUpdated()} />
        <div id="intercom_placeholder"></div>
        <HeaderSecure
          menuId={2}
          project={project}
          currentLocale={0}
          showShareButton={false}
          showTestButton={false}
          auth={this.props.auth}
          history={this.props.history}
          isProfessional={isPremium}
          onPublishClick={this.handlePublishClick}/>

        <PublishPost isAccountLinked={isAccountLinked} project={project && project} onUpdateProject={this.handleRefreshProject} user={user}/>
        <div className="app-content">
          <div className="app-sidebar">
            <div className="date-filter-section">
              <h2 className="proj_yourProjectsHeader">Choose a Date</h2>
              <div className="date-filter">
                <Calendar
                    calendarType="US"
                    value={this.state.postDate}
                    onChange={this.updateFilterDate}
                    tileClassName={this.getTileClassName}
                    tileContent={this.getTileContent} />
              </div>
            </div>


          </div>
          <div className="date-filter-section">
            <h2 className="proj_yourProjectsHeader">Manage Posts ({moment(this.state.postDate).format("DD-MMM-YYYY")})
            {posts.length < 5 ?
              (canPost ? <button className="nav-link btn btn-primary pull-right" onClick={this.handleAddEditPostModal} >Add Post</button>
              :
              <button className="nav-link btn btn-primary pull-right" disabled>Upgrade Required</button>)
              :
              <button className="nav-link btn btn-primary pull-right" disabled>5 Post Limit Reached</button>
            }
            </h2>
            <div className="clearfix"></div>
            <div className="feed-content">
              {this.renderPosts()}
            </div>
          </div>
        </div>
        <div className="modal fade slide" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header modal-header-primary">
                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete Project</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete post <strong>{this.state.selectedPost.name}</strong>?</p>
                <p>This action cannot be undone.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                {this.renderDeleteActionButton()}
              </div>
            </div>
          </div>
        </div>
        <PremiumMember onSuccess={this.setPremium} history={this.props.history} />


      </div>
    );
  }
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {  // eslint-disable-line
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    }
  });
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", { // eslint-disable-line
    enumerable: false,
    value: function (obj) {
      var newArr = this.filter(function (el) {
        return el == obj;
      });
      return newArr.length > 0;
    }
  });
}


export default Posts;
