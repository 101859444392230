var invocationWords = [
  "ask",
  "begin",
  "launch",
  "load",
  "open",
  "play",
  "play the game",
  "resume",
  "run",
  "start",
  "start playing the game",
  "tell",
  "use",

  "ouvre",
  "démarre",
  "commence",
  "lance",
  "allume",
  "joue",

  "apri",
  "avvia",
  "comincia",
  "inizia",
  "lancia",
  "accedi a",
  "gioca con",
  "usa",
  "riproduci",

  "pregúntale a",
  "empieza",
  "lanza",
  "abrir",
  "jugar",
  "reanudar",
  "empeza",
  "decirle a",

  "frage",
  "lade",
  "öffne",
  "Spiele",


];

module.exports = invocationWords;
