import React, { Component } from 'react';
import './styles.css';
import $ from 'jquery';

class Footer extends Component {

  componentDidMount() {
    $('.prettySocial').prettySocial();
  }
  render() {
    return (
      <footer>
        <hr />
        <div className="social-container">
            <div className="links">
                    <a href="#" data-type="twitter" data-url="https://sleepsounds.io"
                       data-description="Check out this awesome Alexa Skill!  Just say: &#x27;Alexa, enable Sleep Sounds skill" data-via="@voiceappsllc"
                       className="prettySocial fa fa-twitter"></a>
                    <a href="#" data-type="facebook" data-url="https://sleepsounds.io" data-title="Check out this awesome Alexa Skill!  Just say: &#x27;Alexa, enable Sleep Sounds skill" data-media="https://scontent.xx.fbcdn.net/v/t1.0-9/17884231_1900630343518508_5702453559193723850_n.jpg?oh=d8d86bdb00f9a44a10ceeeb8016c14c0&oe=5A0960A6"
                       className="prettySocial fa fa-facebook"></a>
                    <a href="#" data-type="googleplus" data-url="https://sleepsounds.io"
                       data-description="Check out this awesome Alexa Skill!  Just say: &#x27;Alexa, enable Sleep Sounds skill"
                       className="prettySocial fa fa-google-plus"></a>
                    <a href="#" data-type="pinterest" data-url="https://sleepsounds.io"
                       data-description="Check out this awesome Alexa Skill!  Just say: &#x27;Alexa, enable Sleep Sounds skill" data-media="https://scontent.xx.fbcdn.net/v/t1.0-9/17884231_1900630343518508_5702453559193723850_n.jpg?oh=d8d86bdb00f9a44a10ceeeb8016c14c0&oe=5A0960A6"
                       className="prettySocial fa fa-pinterest"></a>
                    <a href="#" data-type="linkedin" data-url="https://sleepsounds.io" data-title="Sleep Sounds Alexa Skill"
                       data-description="Check out this awesome Alexa Skill!  Just say: &#x27;Alexa, enable Sleep Sounds skill" data-via="@voiceappsllc"
                       className="prettySocial fa fa-linkedin"></a>
            </div>
        </div>

        <p>&copy; 2017 - Voice Apps, LLC.</p>
        <a href="/Privacy">Privacy Policy</a><br />
      </footer>
    );
  }
}

export default Footer;
