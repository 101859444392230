export const formatDate = (timestamp, style = 'long') => {
	const now = new Date();
	const datetime = new Date(timestamp);
	const postDay = datetime.getUTCDate();
	const postYear = datetime.getUTCFullYear();

	// if within 24 hours
	const elapsed = now - datetime;
	if (Math.abs(elapsed) <= 60 * 60 * 24 * 1000) {
		let number;
		const msPerMinute = 60 * 1000;
		const msPerHour = msPerMinute * 60;
		const msPerDay = msPerHour * 24;

		if (elapsed < msPerHour) {
			number = Math.round(elapsed / msPerMinute);
			if (number === 0) {
				return 'just now';
			}

			return number === 1 ? `a minute ago` : `${number} minutes ago`;
		} else if (elapsed < msPerDay) {
			number = Math.round(elapsed / msPerHour);
			return number === 1 ? 'an hour ago' : `${number} hours ago`;
		}
	}

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return style === 'short' && datetime.getFullYear() === postYear
		? `${months[datetime.getMonth()]} ${postDay}`
		: `${months[datetime.getMonth()]} ${postDay}, ${postYear}`;
};

export default {
	formatDate,
};
