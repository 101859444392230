let builtInVariables = [
  {
    name: "{visitCount}",
    description: "Number of times the user<br/>has opened the skill or action."
  },
  {
    name: "{linkedAccountToken}",
    description: "The token stored by Amazon for this user<br/> as part of Account Linking."
  },
  {
    name: "{va_userId}",
    description: "An identifier that represents the current user.<br /> This can change if the user disables and then<br/> re-enables the skill or action."
  },
  {
    name: "{va_timeZone}",
    description: "The time zone of the current user."
  },
  {
    name: "{va_supportsMonetization}",
    description: "Set to 1 (true) if monetization is available<br/> in the user's locale. Otherwise 0 (false)."
  },
  {
    name: "{va_supportsAPL}",
    description: "Set to 1 (true) if the user is using a device<br/> capable of displaying APL templates."
  },
  {
    name: "{dayOfYear}",
    description: "The current day of the year (1 - 365)."
  },
  {
    name: "{weekOfYear}",
    description: "The current week of the year (1 - 52)."
  }
];

export default builtInVariables;
