export const getCdnUrl = () => {
	let host = 'https://cdn.voiceapps.com' || process.env.CDN_URL || `https://${process.env.BUCKET_HOST}`;
	while (host.length && host[host.length - 1] === '/') {
		host = host.substring(0, host.length - 1);
	}

	return host;
};

export default {
	getCdnUrl,
};
