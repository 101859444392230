import React, { Component } from 'react';
import FA from '@fortawesome/react-fontawesome';
import utils from '../helpers/utils.js';
import './styles.css';
import Spinner from '../Spinner';
import invocationWords from '../data/invocationWords.js';

var converter = require('number-to-words');
var self = null;

class SkillTester extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [
        // {text: 'open General Business Template', source: 'user'},
        // {text: '< Short Audio >', source: 'alexa'},
        // {text: 'Welcome to Raymond Johnston Marketing. To learn more about us and our business you can ask about things like our company history or our services. Say Help to get a full list of things you can ask about. What can I help you with?', source: 'alexa'},
      ],
      newEvents: [],
      isMicActive: false,
      isSkillStarted: false,
    }

    window.setTimeout(this.processNewEvents.bind(this), 500);
  }

  handleInputFocus = (e) => { // eslint-disable-line
    if (this.input.value === 'Type or click and hold the mic') {
      this.input.value = '';
    }
  }

  handleKeyPress = (e) => { // eslint-disable-line
    //utils.log('keypress', e.charCode);
    const { isSkillStarted } = this.state;
    const { locale } = this.props;

    if (e.charCode === 13) {
      //utils.log('ENTER', this.input.value);

      var inputText = this.input.value;
      this.input.value = '';

      this.processVoiceInput(inputText, locale, isSkillStarted);
    }
  }

  componentWillReceiveProps(props) {

  }

  processVoiceInput(inputText, locale, isSkillStarted) {

    let { previousResponse } = this.state;
    let hist = this.state.history;

    let originalInput = inputText;
    inputText = inputText.replace(/([\d][\d|,|\.]*)/g, function (match, capture) {
        return converter.toWords(Number(capture));
    });

    let isStart = false;

    invocationWords.forEach(word => {
      if (inputText.startsWith(word)) {
        isStart = true;
      }
    });

    // If they didn't give an opening phrase and the skill hasn't started,
    if (!isStart && !isSkillStarted) {
      hist.push({text: 'Please start the skill by clicking the "Start Skill" button or by saying or typing "open" followed by your skill invocation name.', source: 'alexa'});
      this.setState({
        history: hist
      });
      return;
    } else {
      this.setState({
        isSkillStarted: true
      })
    }

    if (inputText === '') {
      inputText = 'open ' + this.props.project.invocationName;
      previousResponse = null;
    }
    // Add the unplayed events to the history
    hist = hist.concat(this.state.newEvents);

    // Add the user request and show a spinner for Alexa's response
    hist.push({text: inputText, source: 'user'});
    hist.push({text: '', source: 'alexa'});

    // Stop the player if it's playing
    const player = document.getElementById('tst_player');
    player.pause();

    this.setState({
      history: hist,
      newEvents: [],
      previousResponse
    });

    window.setTimeout(function() {
      var objDiv = document.getElementById("scrollable");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 100);


    let that = this;
    utils.log('Project=', this.props);
    utils.processVoiceInput(this.props.project, inputText, locale, previousResponse).then((response) => {
      let hist = that.state.history;

      let newEvents = [];
      utils.log('Got Skill Response=', response);

      // Begin new code
      if (response.body.sessionAttributes && response.body.sessionAttributes.events) {
        utils.log('New Code!!!');
        newEvents = JSON.parse(JSON.stringify(response.body.sessionAttributes.events));

        console.log('New Events:', newEvents);

        let eventList = [];
        newEvents.forEach(tag => {
          eventList = eventList.concat(this.processAudioTags(tag));
        })


        this.setState({
          history: hist,
          newEvents: eventList,
          previousResponse: response
        });

        return;
      }
      // End new code
      // utils.log('Old Code :( :(');
      // let data = [];
      // if (response.body.sessionAttributes && response.body.sessionAttributes.variables) {
      //   data = response.body.sessionAttributes.variables;
      // }
      //
      // let variables = '<div class="dia_info_header">Current State:</div>';
      // data.forEach(keyvalue => {
      //   let text = keyvalue.name + ': ' + JSON.stringify(keyvalue.value);
      //   variables += `<div class="dia_info_row" title='${text}'>${text}</div>`;
      // })
      //
      // let activeIntent = response.body.sessionAttributes['active-block'];
      // utils.log(activeIntent);
      // if (that.props.onChangeActiveIntent) {
      //   utils.log('got here');
      //   that.props.onChangeActiveIntent(activeIntent);
      // }
      //
      // // -------------------------
      // //  Process Speach Output
      // // -------------------------
      // let ssml = '';
      // if (response.body.response.outputSpeech) {
      //   ssml = response.body.response.outputSpeech.ssml.replace('<speak>','').replace('</speak>','');
      // }



      //
      // // -------------------------
      // //  Process Audio Directive
      // // -------------------------
      // if (response.body.response.directives) {
      //   response.body.response.directives.forEach(directive => {
      //     if (directive.type === 'AudioPlayer.Play') {
      //       let src = directive.audioItem.stream.url;
      //       newEvents.push({text: '< Long Audio >', source: 'alexa', src:src, data: variables});
      //       hasResponse = true;
      //     }
      //   })
      // }
      //
      // if (!hasResponse) {
      //   utils.log("No Response from Alexa");
      //   newEvents.push({text: '<No Response>', source: 'alexa', ssml: '', data: variables});
      // }
      //
      // if (response.body.response.shouldEndSession) {
      //   utils.log('We should end the session.');
      //   newEvents.push({text: '<end>', source: 'alexa', data: variables});
      //   this.setState({
      //     isSkillStarted: false
      //   })
      // }
      //
      // this.setState({
      //   history: hist,
      //   newEvents: newEvents,
      //   previousResponse: response
      // });
      //
      // window.setTimeout(function() {
      //   var objDiv = document.getElementById("scrollable");
      //   objDiv.scrollTop = objDiv.scrollHeight;
      // }, 100);
    }).catch((err) => {
      utils.log(err);
    });
  }

  processAudioTags = (tag) => {
    let newTags = [];

    if (tag.ssml) {
      let ssml = tag.ssml;
      // Find any audio tags in the text
      let speechBlocks = ssml.split('<audio');
      utils.log("Speech Blocks", speechBlocks);

      let hasResponse = false;
      for(var i=0;i<speechBlocks.length;i++) {
        // Remove any whitespace
        let block = speechBlocks[i].trim();
        if (block.length > 0) {
          //if (true) { //}(i+1) % 2 === 0) {
          var index = block.indexOf('>');
          utils.log('index=', index);
          if (index > -1) {
            var splits = [block.slice(0, index), block.slice(index + 1)];
            utils.log("Splits-", splits);
            var srcRegEx = /src="([^"]*)"/g;
            var srcArray = srcRegEx.exec(splits[0]);
            if (srcArray) {
              utils.log('srcArray=', srcArray);
              var src = srcArray.length>1 ? srcArray[1] : null;
              utils.log('SRC-', src);

              console.log('Has audio...');
              newTags.push({intentId: tag.intentId, text: '< Short Audio >', source: 'alexa', src:src, dataType: "text", data: src});

              if (splits[1].length > 0) {
                let text = this.removeSSMLTags(splits[1]);
                newTags.push({intentId: tag.intentId, text, source: 'alexa', ssml: splits[1], data: tag.data});
                //that.say(splits[1]);
              }
            } else {
              console.log('Doesn\'t have audio...');
              let text = this.removeSSMLTags(block);
              newTags.push({intentId: tag.intentId, text, source: 'alexa', ssml: block, data: tag.data});
            }
            // TODO: Play Audio


          } else {
            let text = this.removeSSMLTags(block);
            console.log('text=', text);
            newTags.push({intentId: tag.intentId, text: text, source: 'alexa', ssml: block, data: tag.data});
            //that.say(block);
          }

          hasResponse = true;
        } else if (i === 0 && speechBlocks.length === 1){
          hasResponse = false;
        }
      }
    } else {
      let text = tag.text.replace('https://cdn.voiceapps.com/sa/', '');
      text = text.replace(/"\d{1,12}-/g, '"');
      tag.text = text;
      newTags.push(tag);
    }

    return newTags;
  }

  processNewEvents = () => { // eslint-disable-line
    var newEvents = this.state.newEvents;
    var hist = this.state.history;
    const { locale } = this.props;

    if (newEvents.length>0) {

      const player = document.getElementById('tst_player');
      if (player && player.paused) {

        if (hist.length>0 && hist[hist.length - 1].text === '') {
          utils.log('removing: ', hist[hist.length - 1]);
          // Remove the "loading" image
          hist.splice(-1);
        }
        let nextEvent = newEvents[0];
        if (this.props.onChangeActiveIntent) {
          console.log("Changing Active Intent", nextEvent);
          this.props.onChangeActiveIntent(nextEvent.intentId);
          if (this.activeIntentTimeout) {
            window.clearTimeout(this.activeIntentTimeout);
          }
          this.activeIntentTimeout = window.setTimeout(() => {
            this.props.onChangeActiveIntent(null);
          }, 20000);
        }
        if (nextEvent.src) {
          utils.log('playing: ', nextEvent.src);
          if (nextEvent.src.startsWith('soundbank:')) {
            let newSrc = nextEvent.src.replace('soundbank://soundlibrary/', '');
            let parts = newSrc.split('/');
            if (parts.length === 2) {
              nextEvent.src = `https://d3qhmae9zx9eb.cloudfront.net/${parts[0]}/${parts[1]}.mp3`
            } else {
              nextEvent.src = `https://d3qhmae9zx9eb.cloudfront.net/${parts[0]}/${parts[1]}/${parts[2]}.mp3`
            }
          }
          const player = document.getElementById('tst_player');
          player.src = nextEvent.src;
          utils.log('playing:', nextEvent.src);
          player.load();
          player.play();
          newEvents.splice(0,1);
          hist.push(nextEvent);

          this.setState({
            history: hist,
            newEvents: newEvents
          });

          window.setTimeout(function() {
            var objDiv = document.getElementById("scrollable");
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 100);

          window.setTimeout(this.processNewEvents, 500);
        } else {
          if (nextEvent.ssml) {
            let ssml = nextEvent.ssml;
            let voice = null;
            let isPartial = false;
            try {
              let voiceRE = /<voice name="([^"]*)">/g
              let voiceResult = voiceRE.exec(nextEvent.ssml);
              //console.log(voiceResult);
              if (voiceResult) {
                if (voiceResult.index > 0) {
                  ssml = nextEvent.ssml.substring(0, voiceResult.index);
                  isPartial = true;
                  nextEvent.ssml = nextEvent.ssml.slice(voiceResult.index);
                  nextEvent.text = this.removeSSMLTags(ssml);
                } else {
                  voice = voiceResult[1];
                  nextEvent.ssml = nextEvent.ssml.replace(/<voice name="([^"]*)">/g, '');
                  let voiceEnd = nextEvent.ssml.indexOf('</voice>');
                  ssml = nextEvent.ssml.substring(0, voiceEnd);
                  nextEvent.ssml = nextEvent.ssml.slice(voiceEnd + 8);
                  nextEvent.text = this.removeSSMLTags(ssml);
                  isPartial = true;
                }
              } else {
                nextEvent.text = this.removeSSMLTags(nextEvent.ssml);
              }
            } catch(err) {
              utils.log(err);
            }
            //console.log(ssml);
            utils.getSpeechAudio('<speak>' + ssml + '</speak>', locale, voice).then((url) => {
              utils.log('saying: ', url);
              const player = document.getElementById('tst_player');
              if (player.paused) {
                player.src = url;
                player.load();
                player.play();
                if (!isPartial) {
                  newEvents.splice(0,1);
                }
                hist.push(JSON.parse(JSON.stringify(nextEvent)));
              }

              this.setState({
                history: hist,
                newEvents: newEvents
              });

              window.setTimeout(function() {
                var objDiv = document.getElementById("scrollable");
                objDiv.scrollTop = objDiv.scrollHeight;
              }, 100);
              window.setTimeout(this.processNewEvents, 500);
            }).catch((err) => {
              utils.log(err);
              window.setTimeout(this.processNewEvents, 500);
            })
          } else {
            if (nextEvent.dataType === 'error' ) {
              utils.log('Error Found');
              newEvents.splice(0,1);
              hist.push(JSON.parse(JSON.stringify(nextEvent)));
            } else if (nextEvent.text === '<No Response>' || nextEvent.dataType === 'text') {
              newEvents.splice(0,1);
              hist.push(JSON.parse(JSON.stringify(nextEvent)));
            } else {
              utils.log('Ending Response');
              newEvents.splice(0,1);
            }
            this.setState({
              history: hist,
              newEvents: newEvents
            });

            window.setTimeout(this.processNewEvents, 500);
          }

        }
      } else {
        window.setTimeout(this.processNewEvents, 500);
      }
    } else {
      window.setTimeout(this.processNewEvents, 500);
    }


  }

  removeSSMLTags(ssml) {
    var regex = /(<([^>]+)>)/ig;
    let text = ssml.replace(regex, '');
    var spaceRegex = /&nbsp;/ig;
    text = text.replace(spaceRegex, '');
    var ampRegex = /&/ig;
    text = text.replace(ampRegex, 'and');
    return text;
  }

  handleMicClick = (e) => { // eslint-disable-line
    this.setState({
      isMicActive: true
    });

    self = this;
    var recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onstart = function() { utils.log('starting') }
    recognition.onresult = this.onVoiceResult;
    recognition.onerror = function(event) { utils.log('error', event); }
    recognition.onend = this.onVoiceEnd;

    this.recognition = recognition;

    this.recognition.start();
  }

  handleMicStop = (e) => { // eslint-disable-line
    this.setState({
      isMicActive: false
    });

    this.recognition.stop();

  }

  onVoiceResult(event) {
    utils.log(event);
    self.voiceText = event.results[0][0].transcript;
    utils.log('interumResult=', self.voiceText);
  }

  onVoiceEnd() {
    const { locale } = self.props;

    utils.log('ended');
    utils.log('voiceText=', self.voiceText)
    let processedText = self.voiceText.replace(/([\d][\d|,|\.]*)/g, function (match, capture) {
        return converter.toWords(Number(capture));
    });
    self.processVoiceInput(processedText, locale, true);
  }

  say = (text) => { // eslint-disable-line
    const player = document.getElementById('tst_player');

    utils.sayText('<speak>' + text + '</speak>', player);
  }

  clearDialogue = () => { // eslint-disable-line
    this.setState({
      history: []
    })
  }

  startSkill = () => {
    const { locale } = this.props;

    this.setState({
      history: [],
      newEvents: [],
      isSkillStarted: true
    });

    this.input.value = '';

    this.processVoiceInput('', locale, true);

  }

  toggleInfo = (id) => {
    if (id === this.state.activeInfo) {
      id = null;
    }
    this.setState({
      activeInfo: id
    })
  }

  convertVariables = (dataType, data) => {
    let result = '';

    if (dataType === 'text') {
      let newData = JSON.stringify(data);

      if (typeof data !== "string") {
        result = '<pre><code>';
        result += JSON.stringify(data, null, 2);
        result += '</pre></code>';
      } else {
        if (data.trim().startsWith('{')) {
          result = '<pre><code>';
          result += data;//JSON.stringify(JSON.parse(data), null, 2);
          result += '</pre></code>';
        } else {
          result = '<p>';
         result += data;
         result += '</p>';
       }
      }
    } else {
      result = '<div class="dia_info_header">Current State:</div>';
      if (data) {
        data.forEach(keyvalue => {
          let text = keyvalue.name + ': ' + JSON.stringify(keyvalue.value);
          result += `<div class="dia_info_row" title='${text}'>${text}</div>`;
        });
      }
    }

    return result;
  }

  render() {
    const { history, isMicActive, activeInfo, isSkillStarted } = this.state;

    const { className } = this.props;

    let containerClass = "tst_Container" + (this.props.visible ? ' visible' : '');

    if (className) containerClass = className;

    return (
      <div className={containerClass}>
        <div className="tst_InputArea">
          <input
            className="tst_Input"
            placeholder="Type or click and hold the mic"
            type="text"
            onKeyPress={this.handleKeyPress}
            onFocus={this.handleInputFocus}
            ref={(input) => this.input = input} />
          <div
            className={"tst_Microphone" + (isMicActive ? ' active' : '')}
            onMouseDown={this.handleMicClick}
            onMouseUp={this.handleMicStop}><FA icon={["fal", "microphone"]} /></div>
        </div>
        <div className="tst_Conversation" id="scrollable">
          <div className="tst_Scrollable" >
            {history.length === 0 ?
              <div className="tst_Instructions">
                The skill tester simulates your skill in the browser.
                <br/>
                <br/>
                Here are a few tips to get you started:
                <br/>
                <br/>
                <ul>
                  <li>Start the skill with the "Start Skill" button or by saying "open [skill invocation name]"</li>
                  <li>You can use your keyboard to interact with the tester or click the microphone icon and use your voice.</li>
                  <li>Press enter in the empty text box to quickly open the skill.</li>
                  <li>You don&apos;t have to wait for the speech to complete before typing or saying your next response.</li>
                  <li>Remember, this is a simulator and is NOT a substitute for testing on a real device.</li>
                </ul>
              </div>
              :
              history.map((entry, index) => {
                let leftRight = entry.source === 'alexa' ? 'left' : 'right';
                //console.log("entry=", entry);
                if (entry.text === '') {
                  return (
                    <div key={index} className="dia_Row">
                      <div className={`dia_CalloutIcon ${leftRight}`}></div>
                      <div className={`dia_Callout ${leftRight}`}>
                        <div className="tst_spinner"><Spinner /></div>
                      </div>
                    </div>
                  )
                } else if (entry.dataType === 'error'){
                  return (
                    <div key={index} className="dia_Row">
                      {leftRight == 'left' &&
                        <div className="dia_info">
                          <div className="dia_button" onClick={() => this.toggleInfo(index)}><FA icon={["fal", "info-circle"]} /></div>
                          {index === activeInfo && <div className="dia_details" dangerouslySetInnerHTML={{__html: this.convertVariables(entry.dataType, entry.data)}}></div> }
                        </div>
                      }
                      <div className={`dia_CalloutIcon ${leftRight}`}></div>
                      <div className={`dia_Callout ${leftRight} error`} dangerouslySetInnerHTML={{__html: entry.text.replace(/\n/g, '<br/>')}}></div>
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className="dia_Row">
                      {leftRight == 'left' &&
                        <div className="dia_info">
                          <div className="dia_button" onClick={() => this.toggleInfo(index)}><FA icon={["fal", "info-circle"]} /></div>
                          {index === activeInfo && <div className="dia_details" dangerouslySetInnerHTML={{__html: this.convertVariables(entry.dataType, entry.data)}}></div> }
                        </div>
                      }
                      <div className={`dia_CalloutIcon ${leftRight}`}></div>
                      <div className={`dia_Callout ${leftRight}`}>{entry.text}</div>
                    </div>
                  )
                }
              })
            }
          </div>

        </div>
        <div className="tst_toolbar">
          <button className="btn btn-primary btn-sm" onClick={this.startSkill}>Start Skill</button>
          <button className="btn btn-secondary btn-sm" onClick={this.clearDialogue}>Clear</button>
        </div>

        <audio id="tst_player" type="audio/mpeg"></audio>
      </div>

    );
  }
}

export default SkillTester;
