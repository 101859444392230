import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../redux/actions/auth';

class Authenticate extends PureComponent {

	constructor(props) {
		super(props);

		const self = this;

		self.state = {
			userId: '',
		};

		self.onUserIdChange = self.handleUserIdChange.bind( self );
		self.onLoginSubmit = self.handleLoginSubmit.bind( self );
		self.onSkipClick = self.handleSkipClick.bind( self );
	}

	handleUserIdChange(e) {
		const { target } = e;
		this.setState({ userId: target.value.trim() });
	}

	handleLoginSubmit(e) {
		const self = this;
		const { userId } = self.state;

		e.preventDefault();
		e.stopPropagation();

		self.props.login(userId);
	}

	handleSkipClick() {
		this.props.login('0');
	}

	render() {
		const self = this;
		const { userId } = self.state;

		return (
			<div id="authpage" className="fixed text-center">
				<h3>Candle Wick</h3>

				<form onSubmit={self.onLoginSubmit}>
					<div className="form-group">
						<label htmlFor="userid">Enter your User ID:</label>
						<div className="input-group">
							<input type="text" id="userid" className="form-input" autoFocus value={userId} onChange={self.onUserIdChange} />
							<button type="submit" className="btn btn-primary input-group-btn" disabled={!userId.length}>Login</button>
						</div>
					</div>
				</form>

				<button type="button" className="btn btn-link" onClick={self.onSkipClick}>continue as guest</button>
			</div>
		);
	}

}

Authenticate.propTypes = {
	login: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ login }, dispatch);

export default connect(null, mapDispatchToProps)(Authenticate);
