import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import Editor from '../Editor';
import Editor2 from '../Editor';


class MultiText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textArray: props.data != null ? props.data : [],
      selectedTextIndex: null,
      isJSON: this.tryParseJSON(props.data && props.data.length === 1 && props.data[0]),
      bulkText: '',
      error: '',
      isEditing: false,
    };

    const { id } = props;

    //id === 'trig_utterance' && console.log('Multitext Will Receive Props');
  }

  componentWillReceiveProps(props) {
    const { isEditing } = this.state;
    const { id } = this.props;

    //id === 'trig_utterance' && console.log('Multitext Will Receive Props');

    if (true) {//}!isEditing) {
      this.setState({
        textArray: props.data != null ? props.data : [],
        isJSON: this.tryParseJSON(props.data && props.data.length === 1 && props.data[0]),
        bulkText: '',
        error: '',
        isEditing: false,
      });
    }
  }

  isInputValid = (input) => {
    const { rules } = this.props;

    let isValid = true;
    if (rules) {
      rules.forEach(rule => {
        let inRegEx = new RegExp(rule.regEx);

        if (!inRegEx.test(input)) {
          utils.log(input);
          utils.log('input is invalid');
          this.setState({
            error: rule.error
          });
          isValid = false;
        } else {
          utils.log('input is valid!!');
          this.setState({
            error: ''
          });
        }
      });
    }

    return isValid;
  }

  handleTextKeyPress = (e) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13 && !e.shiftKey) {
      if (this.isInputValid(e.target.value)) {
        utils.log('got here');
        this.addText(e.target.value);
        e.target.value = '';
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  handleTextEditKeyPress = (e, index) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      let { textArray } = this.state;

      textArray[index] = e.target.value;
      e.target.value = '';

      this.setState({
        textArray,
        selectedTextIndex: null,
        showBulk: false
      });

      if (this.props.onChange) {
        this.props.onChange(textArray);
      }
    }
  }

  handleInputChange = (text, index) => {
    //console.log("Input Change", text);
    let {textArray} = this.state;

    //console.log("text = ", textArray);
    if (text === '') {
      textArray.splice(index, 1);
    } else {
      textArray[index] = text;
    }

    this.setState({
      textArray,
      selectedTextIndex: null
    })

    if (this.props.onChange) {
      this.props.onChange(textArray);
    }
  }

  onChange = (text) => {
    this.addText(text);
  }

  addText = (text) => { // eslint-disable-line
    if (text === '') return;

    utils.log(text);
    let { textArray } = this.state;

    textArray.push(text);

    this.setState({
      textArray: textArray,
      isEditing: false
    });

    if (this.props.onChange) {
      this.props.onChange(textArray);
    }
  }

  selectText = (index) => { // eslint-disable-line
    //console.log("selectText");

    let {textArray, inputState, suggestions} = this.state;

    this.setState({
      selectedTextIndex: index,
      isEditing: true,
    });
  }

  handleTextDelete = (e, index) => { // eslint-disable-line
    utils.log(index);
    let { textArray, isJSON } = this.state;

    textArray.splice(index, 1);

    if (isJSON) {
      this.setState({
        isJSON: false,
        bulkText: '',
        showBulk: false
      })
    }
    this.setState({
      textArray,
    });

    if (this.props.onChange) {
      this.props.onChange(textArray);
    }

    e.preventDefault();
    e.stopPropagation();
  }

  toggleBulkEdit = () => { // eslint-disable-line
    const { isPremium, onShowPremiumMember } = this.props;

    if (isPremium == null || isPremium) {
      utils.log("toggle bulk");
      this.setState({
        showBulk: !this.state.showBulk
      });
    } else {
      if (onShowPremiumMember) {
        onShowPremiumMember();
      }
    }
  }

  handleBulkTextChange = (e) => { // eslint-disable-line
    this.setState({
      bulkText: e.target.value
    });
  }

  bulkAddText = () => { // eslint-disable-line
    let { textArray, bulkText, isJSON } = this.state;
    const { allowJSON } = this.props;

    let json = this.tryParseJSON(bulkText);
    utils.log("isJSON?", json);
    if (json && json.length > 0 && allowJSON === true) {
      textArray = [];
      textArray.push(JSON.stringify(json, null, 4));

      this.setState({
        isJSON: true,
        textArray,
        bulkText: ''
      });

      if (this.props.onChange) {
        this.props.onChange(textArray);
      }
    } else {

      if (bulkText != null) {
        if (isJSON) {
          textArray = [];
        }

        let bulkTextValues = bulkText.replace(/\n\n/g, '\n').split('\n');
        bulkTextValues = bulkTextValues.filter(f => {return f.trim() !== '';});
        textArray = textArray.concat(bulkTextValues);
      }

      this.setState({
        textArray,
        showBulk: false,
        isJSON: false,
        bulkText: ''
      });

      if (this.props.onChange) {
        this.props.onChange(textArray);
      }
    }

    $('#bulkText_textArea').val('')
  }

  tryParseJSON (jsonString) {
      try {
          var o = JSON.parse(jsonString);

          // Handle non-exception-throwing cases:
          // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
          // but... JSON.parse(null) returns null, and typeof null === "object",
          // so we must check for that, too. Thankfully, null is falsey, so this suffices:
          if (o && typeof o === "object") {
              return o;
          }
      }
      catch (e) { }

      return false;
  }

  render() {
    const { textArray, selectedTextIndex, showBulk, isJSON, bulkText, error} = this.state;

    const { header, inputPlaceHolder, inputPlaceHolder2, inputPlaceHolder3, allowJSON, allowVariables } = this.props;
    let { allowBulk } = this.props;

    if (allowBulk == null) allowBulk = true;

    let placeHolder = inputPlaceHolder;
    if (inputPlaceHolder2 && textArray.length > 0) placeHolder = inputPlaceHolder2;
    if (inputPlaceHolder3 && textArray.length > 1) placeHolder = inputPlaceHolder3;

    let bulkPlaceholder = "Enter or paste a list of values.  Place each value on its own line.";
    if (allowJSON) {
      bulkPlaceholder += "\n\nYou can also paste a valid JSON array.";
    }

    return (
      <div className="form-group">
        <div className="multiText_TextHeader" dangerouslySetInnerHTML={{__html: header}}></div>
        {isJSON ?
          <div className="multiText_JSONGroup">
            <textarea className="form-control multiText_textArea" rows="10" value={textArray[0]} readOnly></textarea>
            <div className="multiText_deleteJSON" onClick={(e) => this.handleTextDelete(0)}><FA icon="minus-circle" /></div>
          </div>
          :
          <div className="multiText_TextGroup">
            {textArray.map((Text, index) => {
              return (
                (selectedTextIndex === index ?
                  /*<textarea key={index} id={"tu_" + index} className="form-control multiText_TextEdit" defaultValue={Text} type="text" onBlur={(event) => this.handleTextEditBlur(event, index)} onKeyPress={(event) => this.handleTextEditKeyPress(event, index)}/>*/

                  <Editor
                    key={this.props.id + "_mt_" + index}
                    // id={this.props.id + "_mt_" + index}
                    variables={this.props.variables}
                    value={Text}
                    giveInitialFocus={true}
                    onChange={(data) => this.handleInputChange(data, index)}
                    allowVariables={allowVariables}
                    clearOnBlur={false}
                  />
                  :
                  <div key={"mt_" + index} className="multiText_textBlock">
                    <div title={JSON.stringify(Text, null, 4)} onClick={() => this.selectText(index)} className="multiText_Text">{JSON.stringify(Text)}</div>

                    <div className="multiText_delete" onClick={(e) => this.handleTextDelete(e, index)}>
                      <FA className="mutiText_deleteIcon" icon="minus-circle" />
                    </div>

                  </div>
                )

              )
            })}

          </div>
        }
        <div className="multiText_InputGroup">
          {showBulk ?
            <div>
              <textarea id="bulkText_textArea" className="form-control multiText_textArea" placeholder={bulkPlaceholder} rows="4" value={bulkText} onChange={(event) => this.handleBulkTextChange(event)}></textarea>
              <div className="multiText_bulkAdd" onClick={this.bulkAddText}>Bulk Add</div>
            </div>
            :
            <div>
              <Editor2
                //id={this.props.id + "_mt_input"}
                variables={this.props.variables}
                placeHolder={placeHolder}
                onChange={this.onChange}
                allowVariables={allowVariables}
                clearOnBlur={true}
                giveInitialFocus={true}
              />

              {allowVariables && <div className="multiText_help" dangerouslySetInnerHTML={{__html: "Press \"<b>{</b>\" to add a variable."}}></div>}
              <div className="invalid-feedback">
                {error}
              </div>
              {allowBulk ? <div className={"multiText_bulk"} onClick={this.toggleBulkEdit} title="Enter values in bulk"><FA icon={["fal", "truck"]} /></div> : null }

            </div>
          }

        </div>
      </div>
    );
  }
}

export default MultiText;
