import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';

import utils from '../helpers/utils.js';

class Import extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }


  componentWillMount() {
    var that = this;
    utils.getCurrentUser().then(user => {
      ////console.log("Got here");
      this.setState({
        isPremium: user.subscription ? user.subscription : false
      });

      try {
        ////console.log(localStorage[])
        var skillInfo = localStorage["skillInfo"] ? JSON.parse(localStorage["skillInfo"]) : null;
        var blocks = localStorage["blocks"] ? JSON.parse(localStorage["blocks"]) : null;
        var flashBriefing = localStorage["flashBriefing"] ? JSON.parse(localStorage["flashBriefing"]) : null;

        ////console.log("skillInfo=", JSON.stringify(skillInfo));
        ////console.log("blocks=", JSON.stringify(blocks));

        if (skillInfo && blocks) {
          let project = that.importSkill(skillInfo, blocks);

          project.id = utils.guid();

          utils.updateProject(project).then(response => {
            //console.log("Success");
            that.goTo('projects');
          }).catch(err => {
            //console.log(err);
            alert("There was an error importing skill.");
          })

        } else if (flashBriefing) {
          let briefingInfo = that.importFlashBriefing(flashBriefing);
          let project = briefingInfo.skill;

          utils.updateProject(project).then(response => {
            briefingInfo.posts.forEach(post => {
              utils.createPost(project.id + '-' + post[0].postDate, post);
            });

            that.goTo('projects');
          });



        }
      } catch(err) {
        //console.log(err);
        alert("There was an error importing skill.")
      }

      localStorage.removeItem("skillInfo");
      localStorage.removeItem("blocks");
      localStorage.removeItem("flashBriefing");

      return null;
    }).catch(err => {
      utils.log(err);
      //that.goTo('login');]
    })
  }

  // import Flash Briefing
  importFlashBriefing(flashBriefing) {
    let flashBriefingTemplate = require('./flashBriefingTemplate.json');
    let postTemplate = require('./postTemplate.json');

    let newSkill = JSON.parse(JSON.stringify(flashBriefingTemplate));

    let locale = "en-US";
    if (flashBriefing.language === 'english_ca') {
      locale = "en-CA";
    }
    if (flashBriefing.language === 'english_gb') {
      locale = "en-GB";
    }
    if (flashBriefing.language === 'english_in') {
      locale = "en-IN";
    }
    if (flashBriefing.language === 'english_au') {
      locale = "en-AU";
    }
    if (flashBriefing.language === 'italian') {
      locale = "it-IT";
    }
    if (flashBriefing.language === 'french') {
      locale = "fr-FR";
    }
    if (flashBriefing.language === 'german') {
      locale = "de-DE";
    }
    if (flashBriefing.language === 'spanish') {
      locale = "es-ES";
    }
    if (flashBriefing.language === 'japanese') {
      locale = "jp-JP";
    }

    newSkill.id = utils.guid();
    newSkill.locales[0].locale = locale;
    newSkill.skillId = flashBriefing.amazonSkillId;
    newSkill.name = flashBriefing.name;
    newSkill.description = flashBriefing.publishingInfo.description;
    newSkill.icon = flashBriefing.publishingInfo.largeIconUri;
    newSkill.iconSmall = flashBriefing.publishingInfo.smallIconUri;

    let posts = [];
    let postGroup = [];
    let curPostDate = null;

    flashBriefing.records.sort((a, b) => {
      if (a.publish_at > b.publish_at) {
        return 1;
      } else if (a.publish_at < b.publish_at) {
        return -1;
      } else {
        return 0;
      }
    });

    flashBriefing.records.forEach(record => {
      if (curPostDate && curPostDate != record.publish_at) {
        posts.push(postGroup);
        postGroup = [];
      }

      curPostDate = record.publish_at;

      let newPost = JSON.parse(JSON.stringify(postTemplate));

      newPost.id = utils.guid();
      newPost.contentType = record.record_type.toUpperCase();
      newPost.title = record.title_text;
      newPost.text = record.main_text;
      newPost.postDate = record.publish_at;
      newPost.displayUrl = record.redirection_url;
      newPost.audioUrl = record.stream_url;

      postGroup.push(newPost);
    })

    if (postGroup.length>0) {
      posts.push(postGroup);
    }

    return { skill: newSkill, posts: posts };
  }
  importSkill(skillInfo, blocks) {

    let nodes = {};

    let intentSettings = [];
    let intentTemplate = require('./intent.json');
    let skillTemplate = require('./template.json');

    Object.keys(blocks).forEach(blockId => {
      let block = blocks[blockId];

      if (block && block.children) {

        block.children.sort((a,b) => {
          if (a.position < b.position) {
            return -1;
          } else if (a.position > b.position) {
            return 1;
          } else {
            return 0;
          }
        });

        let previousNode = null;
        block.children.forEach(node => {

          if (previousNode) {
            nodes[previousNode.id].nextLink = node.id;
          }
          nodes[node.id] = node;
          previousNode = node;
        })
      }
    });


    let processNodeRecursive = (node, intent) => {
      if (!node) {
        return;
      }
      if (node.node_type === "audio") {
        ////console.log("Configure Short Audio: ", node.audio_url, node.random_options);

        let urls = [];
        urls.push(node.audio_url);
        urls.concat(node.random_options);

        intent.responseGroups[0].functions.push({
           "type":"playAudio",
           "urls": urls
        });

      } else if (node.node_type === "api_request") {
        ////console.log("Configure Call API: ", node.api_request_url, node.api_request_http_type, node.api_request_body, node.api_request_headers );

        let headers = [];
        let headerList = node.api_request_headers.split(";");
        headerList.forEach(header => {
          if (header !== "") {
            let keyvalue = header.split(":");
            headers.push({
              key: keyvalue[0],
              value: keyvalue[1]
            })
          }
        });

        let variables = [];
        let variableList = node.api_request_response_parser.split(";");
        variableList.forEach(variable => {
          if (variable !== "") {
            let keyvalue = variable.split("=");
            variables.push({
              key: keyvalue[0],
              value: keyvalue[1].replace("api_response", "response")
            })
          }
        });

        intent.actions.push({
           "type":"callAPI",
           "url": node.api_request_url,
           "method": node.api_request_http_type.toUpperCase(),
           "headers": headers,
           "js": node.api_request_response_parser,
           "variables": variables
        });
      } else if (node.node_type === "text") {
        ////console.log("Configure Response: ", node.text, node.random_options);

        let texts = [];
        texts.push(node.text);
        texts.concat(node.random_options);

        intent.responseGroups[0].functions.push({
           "type":"response",
           "responses": texts
        })
      }

      if (node.linked_to_block_id) {
        ////console.log("Configure Execute Intent: ", node.linked_to_block_id);

        intent.nextActions[0] = {
           "action":"goToIntent",
           "intent": node.linked_to_block_id.toString()
        }
      } else if (node.linked_to_node_id) {
        processNodeRecursive(nodes[node.linked_to_node_id], intent);
      } else if (node.nextLink) {
        processNodeRecursive(nodes[node.nextLink], intent);
      }
    };

    // --------------------------------------------------
    // Create Intents
    // --------------------------------------------------
    Object.keys(blocks).forEach(blockId => {
      let block = blocks[blockId];

      if (block.children) {
        block.children.sort((a,b) => {
          if (a.position < b.position) {
            return -1;
          } else if (a.position > b.position) {
            return 1;
          } else {
            return 0;
          }
        });
      }

      if (block.block_type == "default") {
        ////console.log("\n\nNew Intent: " + block.name);

        let newIntent = JSON.parse(JSON.stringify(intentTemplate));

        newIntent.id = block.id.toString();
        newIntent.name = block.name;
        newIntent.x = block.x_coord/3;
        newIntent.y = block.y_coord/3;

        if (block.starting_point === true) {
          newIntent.triggeredBy = "launch";
          // newIntent.id = "welcome";
          // newIntent.name = "Welcome";
          newIntent.isLaunch = true;
          let pi = skillInfo.publishingInfo;
          newIntent.publishingInfo = {};
          newIntent.publishingInfo.invocationName = pi.invocationName;
          newIntent.publishingInfo.samplePhrase1 = (pi.examplePhrases && pi.examplePhrases.length > 0) ? pi.examplePhrases[0].name : "";
          newIntent.publishingInfo.samplePhrase2 = (pi.examplePhrases && pi.examplePhrases.length > 1) ? pi.examplePhrases[1].name : "";
          newIntent.publishingInfo.samplePhrase3 = (pi.examplePhrases && pi.examplePhrases.length > 2) ? pi.examplePhrases[2].name : "";
          newIntent.publishingInfo.shortDescription = pi.oneSentenceDescription;
          newIntent.publishingInfo.description = pi.detailedDescription;
          newIntent.publishingInfo.keywords = (pi.keywords && pi.keywords.length > 0) ? pi.keywords.join(", ") : "";
          newIntent.publishingInfo.publicName = skillInfo.name;
        }

        if (newIntent.name == "Help Block") {
          newIntent.triggeredBy = "help";
          newIntent.nextActions[0] = {
            "action": "previousIntentActivate"
          }
        }

        intentSettings.push(newIntent);

        if (block.children && block.children.length > 0) {
          processNodeRecursive(block.children[0], newIntent);
        }
      } else if (block.block_type == "audio_player") {
        let newIntent = JSON.parse(JSON.stringify(intentTemplate));

        newIntent.id = block.id.toString();
        newIntent.name = block.name;
        newIntent.x = block.x_coord/3;
        newIntent.y = block.y_coord/3;

        newIntent.nextActions[0] = {
           "action":"playLongAudio",
           "audioUrls":[
              block.audio_player_info.stream_url
           ]
        }

        if (block.starting_point === true) {
          newIntent.triggeredBy = "launch";
          // newIntent.id = "welcome";
          // newIntent.name = "Welcome";
          newIntent.isLaunch = true;
          let pi = skillInfo.publishingInfo;
          newIntent.publishingInfo = {};
          newIntent.publishingInfo.invocationName = pi.invocationName;
          newIntent.publishingInfo.samplePhrase1 = (pi.examplePhrases && pi.examplePhrases.length > 0) ? pi.examplePhrases[0].name : "";
          newIntent.publishingInfo.samplePhrase2 = (pi.examplePhrases && pi.examplePhrases.length > 1) ? pi.examplePhrases[1].name : "";
          newIntent.publishingInfo.samplePhrase3 = (pi.examplePhrases && pi.examplePhrases.length > 2) ? pi.examplePhrases[2].name : "";
          newIntent.publishingInfo.shortDescription = pi.oneSentenceDescription;
          newIntent.publishingInfo.description = pi.detailedDescription;
          newIntent.publishingInfo.keywords = (pi.keywords && pi.keywords.length > 0) ? pi.keywords.join(", ") : "";
          newIntent.publishingInfo.publicName = skillInfo.name;
        }

        intentSettings.push(newIntent);

        if (block.children && block.children.length > 0) {
          processNodeRecursive(block.children[0], newIntent);
        }
      }


    })


    // --------------------------------------------------
    // Process Buttons
    // --------------------------------------------------
    Object.keys(blocks).forEach(blockId => {
      let block = blocks[blockId];

      block.buttons && block.buttons.forEach(button => {
        if (button.connected_node_group_id) {
          ////console.log("Configure Intent: ", button.connected_node_group_id, button.name, button.synonyms);

          let targetIntent = intentSettings.find(t => t.id === button.connected_node_group_id.toString());
          if (targetIntent) {
            if (targetIntent.name == "Help Block") {
              targetIntent.triggeredBy = "help";
            } else {
              let texts = [];
              texts.push(button.name);
              texts.concat(button.synonyms);
              targetIntent.sampleUtterances = texts;
              targetIntent.triggeredBy = "user";
            }

            targetIntent.activatedBy.push(block.id.toString());
          }
        } else if (button.connected_node_id) {
          ////console.log("\n\nNew Intent: ", button.name);

          let newIntent = JSON.parse(JSON.stringify(intentTemplate));

          newIntent.id = button.id.toString();
          newIntent.name = button.name;
          newIntent.x = 0;
          newIntent.y = 0;

          intentSettings.push(newIntent);

          processNodeRecursive(nodes[button.connected_node_id], newIntent);
        }
      })
    });

    var getPointOnLine = function(center, target, multiplier) {
      var dx = target.x - center.x;
      var dy = target.y - center.y;

      var distance = Math.sqrt( dx*dx + dy*dy );

      var k = Math.sqrt(Math.pow(distance * multiplier, 2) / (dx*dx + dy*dy) );

      var x3 = center.x + dx * k;
      var y3 = center.y + dy * k;

      return {x: x3, y: y3};
    }


    // --------------------------------------------------
    // Create Intents Collection
    // --------------------------------------------------
    var getCenter = function (arr)
    {
        var x = arr.map (x => x[0]);
        var y = arr.map (x => x[1]);
        var cx = (Math.min (...x) + Math.max (...x)) / 2;
        var cy = (Math.min (...y) + Math.max (...y)) / 2;
        return {x: cx, y: cy};
    }


    let coordinates = [];
    let welcomeId = null;
    intentSettings.forEach(intent => {
      coordinates.push([intent.x, intent.y]);
    });

    let center = getCenter(coordinates);

    let intents = [];
    intentSettings.forEach(intent => {

      let newLoc = getPointOnLine(center, {x:intent.x, y:intent.y}, 4.8);

      // Add Slots
      let slots = [];
      intent.sampleUtterances.forEach(utterance => {
        let newSlots = utterance.match(/{[^}]*}/g);
        ////console.log('newSlots=', newSlots);
        if (newSlots) {
          newSlots.forEach(slot => {
            //foundSlots.push(slot);

            // If we don't already have this slot...
            if (!slots.find(s => { return s.name === slot})) {

              // Find variable
              let variable = skillInfo.variables.find(v => ("{" + v.name + "}") == slot);

              if (variable) {
                slots.push({
                  "name": slot,
                  "type": variable.amazonType ? variable.amazonType : "Custom",
                  "values": []
                })
              } else {
                // add it
                slots.push({
                  "name": slot,
                  "type": "Custom",
                  "values": []
                })
              }
            }
          })
        }

      })

      intent.slots = slots;

      intents.push({
        id: intent.id.toString(),
        name: intent.name,
        description: "",
        x: newLoc.x,
        y: newLoc.y
      })


    });




    // --------------------------------------------------
    // Create Intents Collection
    // --------------------------------------------------
    let newSkill = JSON.parse(JSON.stringify(skillTemplate));
    let locale = "en-US";
    if (skillInfo.language === 'english_ca') {
      locale = "en-CA";
    }
    if (skillInfo.language === 'english_gb') {
      locale = "en-GB";
    }
    if (skillInfo.language === 'english_in') {
      locale = "en-IN";
    }
    if (skillInfo.language === 'english_au') {
      locale = "en-AU";
    }
    if (skillInfo.language === 'italian') {
      locale = "it-IT";
    }
    if (skillInfo.language === 'french') {
      locale = "fr-FR";
    }
    if (skillInfo.language === 'german') {
      locale = "de-DE";
    }
    if (skillInfo.language === 'spanish') {
      locale = "es-ES";
    }
    if (skillInfo.language === 'japanese') {
      locale = "jp-JP";
    }

    newSkill.id = utils.guid();
    newSkill.intents = intents;
    newSkill.locales[0].locale = locale;
    newSkill.locales[0].intentSettings = intentSettings;
    newSkill.skillId = skillInfo.amazon_skill_id;
    newSkill.name = skillInfo.name;
    newSkill.description = skillInfo.publishingInfo.oneSentenceDescription;
    newSkill.invocationName = skillInfo.publishingInfo.invocationName;
    newSkill.icon = skillInfo.publishingInfo.largeIconUri;
    newSkill.iconSmall = skillInfo.publishingInfo.smallIconUri;
    newSkill.category = skillInfo.publishingInfo.category;



    //newSkill = JSON.stringify(newSkill, null, 2).replace(/\\/g, '\\\\');
    //newSkill = newSkill.replace(/"/g, "\\\"");

    ////console.log("\n\n\n");

    ////console.log(newSkill);
    return newSkill;
  }


  render() {
    const { isPremium } = this.state;

    return (
      <div className="projects_full">

        <HeaderSecure menuId={2} auth={this.props.auth} history={this.props.history} isProfessional={isPremium} />
        <div id="your_projects" className="projectsContainer">


        </div>


      </div>
    );
  }
}

export default Import;
