import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import './index.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import Multiselect from 'react-bootstrap-multiselect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

import moment from 'moment';
import { contentUpdateFrequency, contentType, contentGenre } from './sampleData';

import Spinner from '../Spinner';
import utils from '../helpers/utils.js';


const CLOUDINARY_UPLOAD_PRESET = 'bophohq0';
const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/voiceapps/upload';
// const CLOUDINARY_FETCH_URL = 'https://res.cloudinary.com/voiceapps/image/fetch/';


class PostAdd extends Component {


  constructor(props) {
    super(props);

    this.state = {

      formFields: {
        id: '',
        updateFrequency: 'DAILY',
        contentType: 'TEXT',
        genre: 'HEADLINE_NEWS',
        displayUrl: ''
      },
      showIcons: false,
      icons: [],
      iconSearchTerm: 'skills',
      isLoading: false,
      isEditing: false,
      isSubmitPost: false,
      showLoader: false

    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentWillUpdate(nextProps, nextState) {
    utils.log(nextProps.selectedPost, "selected post details", this.props.selectedPost);
    if (typeof nextProps.selectedPost.id != 'undefined' && this.props.selectedPost !== nextProps.selectedPost) {
      let selectedPost = nextProps.selectedPost;
      let formFields = this.state.formFields;
      formFields.id = selectedPost.id;
      formFields.title = selectedPost.title;
      formFields.updateFrequency = selectedPost.updateFrequency;
      formFields.contentType = selectedPost.contentType;
      formFields.text = selectedPost.text;
      formFields.audioUrl = selectedPost.audioUrl;
      formFields.displayUrl = selectedPost.displayUrl;
      this.setState({ formFields });
    }
  }



  handleSaveChanges = (e) => {
    this.setState({ isSubmitPost: true });
    utils.log('------------Handle Save Changes-------');

    let state = this.state;
    utils.log("formFields", state.formFields);
    utils.log("props", this.props);



    /** Check required validation  */
    let count = 0;
    if (state.formFields.contentType === 'TEXT') {
      if (state.formFields.text === '' || state.formFields.text == null) {
        return false;
      }
    }

    if (state.formFields.contentType === 'AUDIO') {
      if (state.formFields.audioUrl === '' || state.formFields.audioUrl == null) {
        return false;
      }
    }

    /* Check valid URL  */
    if (this.validURL(state.formFields.url) == false) {
      utils.log("Invalid URL");
      return false;
    }

    /**Check for valid Preamble name */
    if(this.valildPreambleName(this.state.formFields.vuiPreamble) ==false){
      return false;
    }

     /**Check for Preamble max length */
    if(this.maxPreambleNameLength(this.state.formFields.vuiPreamble)== false){
      return false;
    }

    let postData = Object.assign({}, state.formFields);
    if (this.props.postAction === "Create") {
      /** Adding a post */
      postData.postDate = moment(this.props.postDate).format("YYYY-MM-DD");
      /** add a unique id  */
      postData.id = btoa(new Date());
      utils.log(this.props.projectId, "success", postData);
      this.setState({ showLoader: true });

      this.props.posts.push(postData);
      /** Save details in db */
      this.updatePostAPI(this.props.projectId + "-" + postData.postDate, this.props.posts);

    } else {
      /** Updating a post  */
      utils.log("Updateing", );
      let selectedIndex = this.props.posts.findIndex((item) => item.id == state.formFields.id);


      postData.postDate = moment(this.props.postDate).format("YYYY-MM-DD");
      utils.log("selectedIndex", selectedIndex, postData);
      this.props.posts[selectedIndex] = postData;
      /** Save details in db */
      this.updatePostAPI(this.props.projectId + "-" + postData.postDate, this.props.posts);
    }
  }

  /** Call update API  here  */
  updatePostAPI = (projectId, posts) => {
    utils.log("UPdatePostAPI called ");
    /*** Update json */
    this.setState({ showLoader: true });
    utils.createPost(projectId, posts).then(respose => {
      if (this.props.postAction === "Create") {
        toast.success("The post has been successfully added.");
      } else {
        toast.success("The post has been successfully updated.");
      }
      utils.log("response", respose);
      this.setState({
        showLoader: false,
        isSubmitPost: false,
        formFields: {
          name: '',
          vuiPreamble: '',
          updateFrequency: 'HOURLY',
          contentType: 'TEXT',
          genre: 'HEADLINE_NEWS',
          url: '',
          imageUri: '',
        }
      });
      $('#AddPostModal').modal('toggle');
      this.props.handlePostsUpdated();
    });
  }

  /** update form values in state  */
  updateField = (field, value) => { // eslint-disable-line
    utils.log(field, value, this.state.formFields);
    let formFields = this.state.formFields;
    formFields[field] = value;
    this.setState({
      formFields: formFields,
      isEditing: true,
    });
  }

  /** handle icon  */
  handleChangeIcon = (e) => { // eslint-disable-line
    const { formFields } = this.state;
    this.setState({
      showIcons: true,
      isEditing: true,
      iconSearchTerm: this.state.formFields.name,
    });

    this.searchTimer = setTimeout(this.performSearch, 200);
  }

  setIconUrl = (url) => { // eslint-disable-line
    utils.log(url);
    this.handleImageUploadFromUrl(url);
    return;

  }


  handleSearchChange = (text) => { // eslint-disable-line
    if (text !== '') {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(this.performSearch, 500);
    }

    this.setState({
      iconSearchTerm: text,
    })
  }

  performSearch = () => { // eslint-disable-line
    const { isPremium } = this.props;
    let text = this.state.iconSearchTerm;

    if (text !== '') {
      this.setState({
        isLoading: true
      });

      utils.searchIcons(text, isPremium).then(icons => {
        // utils.log(icons);
        this.setState({
          icons: icons,
          isLoading: false
        })
      });
    }
  }

  onImageDrop = (files) => { // eslint-disable-line
    this.setState({
      uploadedFile: files[0]
    });

    this.handleImageUpload(files[0]);
  }


  handleImageUpload(file) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', file);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formFields = this.state.formFields;
        formFields.imageUri = response.body.secure_url;
        this.setState({
          formFields: formFields,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleImageUploadFromUrl(url) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', url);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formFields = this.state.formFields;
        formFields.imageUri = response.body.secure_url;
        this.setState({
          formFields: formFields,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleCloseIcons = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
    });
  }






  handleCloseIcons = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
    });
  }

  /** Check valid URL  */
  /*validURL(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    //let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    let regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let res = regexp.test(str);
    return res;
  }*/

   validURL(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    let id = str.indexOf("http");
    if(id == 0){
      let reg = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/
      return reg.test(str)
    }else{
      return false;
    }

  }

/**Check Preamble is started with In or From   */
  valildPreambleName(str){
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    let regexp = /^(In|From).*$/;

    let res = regexp.test(str);
    return res;
  }

  /** check length of character for preamble  */
  maxPreambleNameLength(str){
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    if(str.length <= 70 ){
      return true;
    }else{
      return false;
    }

  }

  handleClose = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
      isEditing: false,
    });
  }

  /* show required validatin  */
  renderRequiredValidation(input) {
    let state = this.state;
    if (state.isSubmitPost == true && (typeof state.formFields[input] == 'undefined' || state.formFields[input] == '')) {
      return <p className="text-danger">This is required</p>
    }
  }


  renderButton() {
    if (this.state.showLoader == true) {
      return <a href="javascript:void(0)" className="btn btn-primary">{this.props.postAction}ing....</a>
    } else {
      return <button type="button" className="btn btn-primary" onClick={this.handleSaveChanges}>{this.props.postAction}</button>
    }
  }

  render() {

    const { showIcons, isPremium, icons, isLoading, iconSearchTerm } = this.state;

    /* Set default Icon if value is empty */
    if (this.state.formFields.imageUri == null || this.state.formFields.imageUri == '') {
      this.state.formFields.imageUri = 'https://res.cloudinary.com/voiceapps/image/upload/v1531481073/ni90vyxlbqgmf7njf69v.png';
    }

    return (
      <div className="modal fade slide" id="AddPostModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">{this.props.postAction} Post</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col col-6">
                    <div className="form-group multi-select">
                      <label htmlFor="contentType">Content Type <span className="text-danger">*</span></label>
                      <Multiselect onChange={evt => this.updateField('contentType', evt[0].value)} data={contentType} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <input type="text" className="form-control" id="title" placeholder="Enter the title of the post to display in the Alexa app." value={this.state.formFields.title} onChange={evt => this.updateField('title', evt.target.value)} />
                      {this.renderRequiredValidation('title')}
                    </div>
                  </div>
                </div>

                { this.state.formFields.contentType === 'TEXT' &&
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="text">Text to Read</label>
                        <textarea type="text" className="form-control" id="text" placeholder="Enter the text Alexa should read." value={this.state.formFields.text} onChange={evt => this.updateField('text', evt.target.value)}></textarea>
                        {this.renderRequiredValidation('text')}
                      </div>
                    </div>
                  </div>
                }

                { this.state.formFields.contentType === 'AUDIO' &&
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="text">Audio URL</label>
                        <input type="text" className="form-control" id="audioUrl" placeholder="Enter the HTTPS URL of your audio file." value={this.state.formFields.audioUrl} onChange={evt => this.updateField('audioUrl', evt.target.value)} />
                        {this.renderRequiredValidation('audioUrl')}
                      </div>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="displayUrl">Display URL (Optional)</label>
                      <input type="text" className="form-control" id="displayUrl" placeholder="Enter a URL that will show in the Alexa app and on devices with a screen." value={this.state.formFields.displayUrl} onChange={evt => this.updateField('displayUrl', evt.target.value)} />
                      {this.renderRequiredValidation('displayUrl')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn m-b0 btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                {this.renderButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostAdd;
