var Templates = [
   {
      "id": 1,
      "name": "General Business",
      "description": "This template is a great start for most businesses.  Your users will be able to ask about things like your company history, your location, your services, hours of operation, and much more.  Over 30 different features are available.",
      "iconSearchTerm": "business"
   },
   {
      "id": 2,
      "name": "Real Estate Agent",
      "description": "This template is designed specifically for Real Estate Agents.  Let Buyers and Sellers know why they should choose you as their Realtor.  Users can ask about things like your background and experience as well as many questions about the local market or the real estate buying and selling process.",
      "iconSearchTerm": "real estate agent"
   },
   {
      "id": 3,
      "name": "Trivia Game",
      "description": "With this template, you can create a really fun trivia game.  All you need to do is provide the trivia questions and the skill is ready to go!",
      "iconSearchTerm": "quiz"
   },
   {
      "id": 4,
      "name": "Facts",
      "description": "This template allows you to create a fact skill so users can get facts about your favorite subject.  All you need to provide are the facts and the skill is ready to go live!",
      "iconSearchTerm": "knowledge"
   }


];

module.exports = Templates;
