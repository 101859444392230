 const contentUpdateFrequency = [
  {
    label: 'Hourly',
    value: 'HOURLY'
  },
  {
    label: 'Daily',
    value: "DAILY"
  },
  {
    label: 'Weekly',
    value: "WEEKLY"
  }
];

const contentType = [
  {
    label: 'Text',
    value: 'TEXT'

  },
  {
    label: 'Audio',
    value: 'AUDIO'
  }
]

const contentGenre = [
  {
    label: 'Headline News',
    value: 'HEADLINE_NEWS'
  },
  {
    label: 'Business',
    value: 'BUSINESS'
  },
  {
    label: 'Politics',
    value: 'POLITICS'
  },
  {
    label: 'Entertainment',
    value: 'ENTERTAINMENT'
  },
  {
    label: 'Technology',
    value: 'TECHNOLOGY'
  },
  {
    label: 'Humor',
    value: 'HUMOR'
  },
  {
    label: 'Lifestyle',
    value: 'LIFESTYLE'
  },
  {
    label: 'Sports',
    value: 'SPORTS'
  },
  {
    label: 'Science',
    value: 'SCIENCE'
  },
  {
     label: 'Health and Fitness',
    value: 'HEALTH_AND_FITNESS'
  },
  {
     label: 'Arts and Culture',
    value: 'ARTS_AND_CULTURE'
  },
  {
    label: 'Productvity and Utilitiy',
    value: 'PRODUCTIVITY_AND_UTILITIES'
  },
  {
    label: 'Other',
    value: 'OTHER'
  }
]

const posts = [
  {
    "id": "101",
    "userId": "100",
    "updateDate": "2018-07-10T12:15:00.0Z",
    "name": "Angels of Death Anime's English-Subtitled Trailer Streamed",
    "preamble": "In",
    "contentUpdateFrequency": "Daily",
    "contentType": "text",
    "contentGenre": "Business",
    "feedURL": "https://res.cloudinary.com/voiceapps/image/upload/v1531315355/w3nxmuvvtptpx1xhdu0k.png",
    "feedIcon": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
  },
  {
    "id": "102",
    "userId": "100",
    "updateDate": "2018-07-10T12:15:00.0Z",
    "name": "Angels of Death Anime's English-Subtitled",
    "preamble": "From",
    "contentUpdateFrequency": "Monthly",
    "contentType": "text",
    "contentGenre": "Business",
    "feedURL": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
    "feedIcon": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
  },
  {
    "id": "103",
    "userId": "100",
    "updateDate": "2018-07-09T12:15:00.0Z",
    "name": "Testing Feed",
    "preamble": "From",
    "contentUpdateFrequency": "Monthly",
    "contentType": "text",
    "contentGenre": "Business",
    "feedURL": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
    "feedIcon": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
  },
  {
    "id": "104",
    "userId": "100",
    "updateDate": "2018-07-11T12:15:00.0Z",
    "name": "Demo Feed",
    "preamble": "From",
    "contentUpdateFrequency": "Monthly",
    "contentType": "text",
    "contentGenre": "Business",
    "feedURL": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
    "feedIcon": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
  },
  {
    "id": "105",
    "userId": "100",
    "updateDate": "2018-07-11T12:15:00.0Z",
    "name": "Demo Feed",
    "preamble": "From",
    "contentUpdateFrequency": "Monthly",
    "contentType": "text",
    "contentGenre": "Business",
    "feedURL": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
    "feedIcon": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
  },
  {
    "id": "106",
    "userId": "100",
    "updateDate": "2018-07-12T12:15:00.0Z",
    "name": "Future Feed",
    "preamble": "From",
    "contentUpdateFrequency": "Monthly",
    "contentType": "text",
    "contentGenre": "Business",
    "feedURL": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
    "feedIcon": "https://res.cloudinary.com/voiceapps/image/upload/v1531314259/ikobpixntn3qmgepiucs.png",
  },
];


export { posts, contentUpdateFrequency, contentType, contentGenre};
