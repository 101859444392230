import React, { Component } from 'react';
import './styles.css';
import queryString from 'query-string';
import utils from '../helpers/utils.js';
import withdrawalReasons from '../data/withdrawalReasons.js';

import Spinner from '../Spinner';

class Upgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      withdrawalReason: '',
    };
  }

  componentDidMount() {
    var that = this;
    utils.getCurrentUser().then(user => {
      if (user == null) {
        //that.goTo('login/checkout');
        utils.log('User == null');
      } else {
        let cbInstance = window.Chargebee.init({
            site: "voice-apps"
        });

        //var cbInstance = window.Chargebee.getInstance();

        //var cart = cbInstance.getCart();
        utils.log("user=", user);

        cbInstance.setCheckoutCallbacks(function(cart) {
          if (user.userName) {
            var customer = {first_name: user.firstName, last_name: user.lastName, email: user.userName, billing_address:{first_name: user.firstName, last_name: user.lastName, company: "", phone: "", address_line1: "", address_line2: ""}};
            cart.setCustomer(customer);
          }


            // you can define a custom callbacks based on cart object
            return {
                loaded: function() {
                    utils.log("checkout opened");
                },
                close: function() {
                    utils.log("checkout closed");
                },
                success: function(hostedPageId) {
                    utils.log("checkout success!!", hostedPageId);
                    utils.updateSubscription(hostedPageId).then(response => {
                      that.props.onSuccess();
                    });

                },
                step: function(value) {
                    // value -> which step in checkout
                    utils.log(value);
                }
            }


        });

      }

      return null;
    }).catch(err => {
      utils.log(err);
      //that.goTo('login/checkout');
    })
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  handleUpgrade = () => {
    var checkout = document.getElementById('checkout');
    checkout.click();
  }

  render() {
    let { step, withdrawalReason, errorText} = this.state;
    const { project } = this.props;

    let withdrawalText = '[Please Select]';
    let reason = withdrawalReasons.find(r => { return r.value === withdrawalReason});
    if (reason) {
      withdrawalText = reason.name;
    }

    return (
      <div className="modal fade" id="UpgradeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog upgrade-modal-dialog-centered" role="document">
          <div className="modal-content upgrade-modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">Upgrade Your Account</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {step === 1 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="upgrade_projectName">Build even more engaging skills!<br /> And build them faster!</div>
                  <br />
                  <div className="upgrade_messageText">To get access to our <b>Professional</b> features, <b>UPGRADE</b> your account.</div>
                  <br />
                  <div className="premium_price">Only $19.95 per month</div>
                  <br />
                  <div className="premium_features">Customize Alexa&apos;s Voice, Schedule Flash Briefings, Display Templates (for Echo Show, Spot, and Fire TV Cube), Premium Icons, Lookup Function, Get Random Function, Contextual Help, Advanced Audio Player, and more!</div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                  <button type="button" className="btn btn-success" onClick={this.handleUpgrade}>Upgrade</button>
                </div>
                <a id="checkout" className="premium_selectPlan" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="professional" >Select this plan</a>
              </div> : null
            }

            </div>
          </div>
        </div>
    );
  }
}

export default Upgrade;
