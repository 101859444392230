import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import multistate from '../MultiState';
import './styles.css';

import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/theme/tomorrow';

class JS extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      code: props.data.code,
      id: utils.guid(),
      isEditing: false
    };
  }

  componentWillReceiveProps(props) {
    let code = props.data.code;

    utils.log("received props=", props);

    if (props.data && props.data.values) {
      code = "";
      props.data.values.forEach(state => {
        let block = "";
        if (state.method === 'diff') {
          block += (state.name + ' = (' + state.value + " - " + state.value2 + ');\n' );
        } else if (state.method === 'sum') {
          block += (state.name + ' = (' + state.value + " + " + state.value2 + ');\n' );
        } else {
          block += state.name + ' ' + state.method + ' ' + state.value + ";\n";
        }
        if (state.when) {
          let whenStart = "if (" + state.when.sourceVariable + ' ' + state.when.operator + ' ' + state.when.targetVariable + ") {\n";
          let whenEnd = "}\n";
          block = whenStart + "\t" + block + whenEnd;
        }
        code += block;
      });
    }

    if (code == null) {
      code = "";
    }

    this.setState({
      code: code,
      isEditing: false
    });
  }

  componentDidMount() {
    const { id } = this.state;

    if (this.boxRef.current) {

      this.boxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    let editor = document.getElementById(id);
    editor.setAttribute('contenteditable', true);
  }

  handleStateChange = (stateData) => {
    utils.log("stateDate=", stateData);
    if (this.props.onChange) {
      this.props.onChange({
        values: null,
        code: stateData
      });
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { code, id } = this.state;

    return (
      <div
        className="intent_card"
        ref={this.boxRef}>
        <div className="intent_cardHeader">JS Code</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>


        <AceEditor
          mode="javascript"
          theme="tomorrow"
          width="100%"
          height="180px"
          value={code}
          onChange={this.handleStateChange}
          name={id}
          editorProps={{$blockScrolling: true}}
        />

        <a className="ss_learn" target="_blank" href="https://help.voiceapps.com/skill-building/set-state">Learn how to use this feature</a>
      </div>
    );
  }
}

export default JS;
