import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import FunctionContainer from '../FunctionContainer';

import ResponseGroup from '../ResponseGroup';

class ResponseContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: props.data
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      groups: props.data
    })
  }

  deleteIntent = () => { // eslint-disable-line
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  addResponse = () => { // eslint-disable-line
    let { groups } = this.state;

    groups.push({
      type: "response",
      responses: []
    });

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }


  handleResponsesChange = (index, data) => { // eslint-disable-line
    let { groups } = this.state;

    groups.functions[index].responses = data.responses;
    groups.functions[index].voice = data.voice;

    utils.log("Responses Changed, ", groups.functions[index]);

    this.setState({
      groups
    })

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  getItems = () => { // eslint-disable-line
    const { groups } = this.state;
    const { isPremium, onShowPremiumMember} = this.props;

    let data = [];
    groups.forEach((func, index) => {
      data.push({
        id: index,
        content: (
          <ResponseGroup key={index} draggableId={index} isPremium={isPremium} onShowPremiumMember={onShowPremiumMember} data={func} variables={this.props.variables} onDelete={() => this.handleResponseGroupDelete(index)} onChange={(data) => this.handleResponseGroupChange(index, data)} />
        ),
        isDragDisabled: false,
      })
    })

    return data;
  }

  deleteFunction = (index) => { // eslint-disable-line
    let {groups} = this.state;

    groups.splice(index, 1);

    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleFunctionsChange = (data) => { // eslint-disable-line
    utils.log("Functions Change");

    utils.log('data=', data);
    let { groups } = this.state;

    let newFunctions = [];
    data.forEach((func, index) => {
      newFunctions.push(groups[func.id]);
    });

    groups = newFunctions;

    utils.log('Group After=', groups);
    this.setState({
      groups
    });

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleResponseGroupDelete = (index) => { // eslint-disable-line
    utils.log("responseGroupIndex=", index);

    let { groups } = this.state;

    groups.splice(index, 1);

    this.setState({
      groups
    })

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  handleResponseGroupChange = (index, data) => { // eslint-disable-line
    let { groups } = this.state;

    groups[index] = data;

    utils.log("ResponseGroup Changed", groups);
    this.setState({
      groups
    })

    if (this.props.onChange) {
      this.props.onChange(groups);
    }
  }

  render() {
    let functions = this.getItems();

    return (
      <div className="rc_container">
          {functions.length > 0 ?
            <FunctionContainer data={functions} variables={this.props.variables} onChange={(data) => this.handleFunctionsChange(data)} padding={0}/>
            :
            <div className="rg_noFunctions">Use the icons below to add responses.</div>
          }
      </div>
    );
  }
}

export default ResponseContainer;
