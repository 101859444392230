import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';

import utils from '../helpers/utils.js';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

import cust_new_returning from './cust_new_returning.js';
import total_sessions from './total_sessions.js';
import sessions_per_customer from './sessions_per_customer.js';
import intents from './intents.js';
import utterances from './utterances.js';
import session_duration from './session_duration.js';


class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDatePicker: false,
      startDate: new Date().addDays(-7),
      endDate: new Date(),
      customerData: {
        labels: [],
        newUsers: [],
        returningUsers: []
      },
      sessionData: {
        labels: [],
        sessions: [],
        duration: []
      },
      utteranceData: {
        labels: [],
        series: []
      },
      intentData: {
        labels: [],
        series: []
      },
      focusedRange: [0, 0],
      tab: 1
    }
  }

  componentDidMount() {
    window.setTimeout(this.updateChart, 1000);
    //this.updateChart();
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  handleSelect = (ranges) => {
    let { startDate, endDate, focusedRange } = this.state;

    //return;
    let newStartDate = ranges.selection.startDate;
    let newEndDate = ranges.selection.endDate;

		//console.log(ranges);
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      showDatePicker: focusedRange[1] === 1
    })

    window.setTimeout(this.updateChart, 100);
	}

  handleRangeFocusChange = (data) => {

    //console.log('rangeFocusChanged', data);

    this.setState({
      focusedRange: data,
      showDatePicker: data[1] === 1
    });


  }

  updateChart = () => {
    const {startDate, endDate, focusedRange} = this.state;

    if (focusedRange[1] === 0) {
      utils.getCustomerStats(this.props.skillId, startDate.toLocaleDateString(), endDate.addDays(1).toDateString()).then(stats => {
        //console.log(stats);
        let customerData = {
          labels: [],
          newUsers: [],
          returningUsers: []
        };
        stats.forEach(stat => {
          let d = new Date(stat.dateTime);
          let label = d.getUTCDate() + ". " + d.toLocaleString('en-us', { month: 'short' });
          //console.log('label: ', d.toString(), d.getDate());
          customerData.labels.push(label);
          customerData.newUsers.push(stat.newUsers);
          customerData.returningUsers.push(stat.returningUsers);
        });

        this.setState({
          customerData
        })
      });

      utils.getSessionStats(this.props.skillId, startDate.toDateString(), endDate.addDays(1).toDateString()).then(stats => {
        //console.log("sessions=", stats);
        let sessionData = {
          labels: [],
          sessions: [],
          duration: []
        };
        if (stats) {
          stats.forEach(stat => {
            let d = new Date(stat.dateTime);
            let label = d.getUTCDate() + ". " + d.toLocaleString('en-us', { month: 'short' });
            //console.log('label: ', d.toString(), d.getDate());
            sessionData.labels.push(label);
            sessionData.sessions.push(stat.sessions);
            sessionData.duration.push(stat.duration);
          });
        }

        this.setState({
          sessionData
        })
      });

      utils.getIntentStats(this.props.skillId, startDate.toDateString(), endDate.addDays(1).toDateString()).then(stats => {
        //console.log("intents=", stats);
        let intentData = {
          labels: [],
          series: []
        };

        let utteranceData = {
          labels: [],
          series: []
        }
        let firstKey = null;
        Object.keys(stats).forEach(key => {
          if (firstKey == null) {
            firstKey = key;
          }
          let series = [];
          stats[key].forEach(stat => {
            series.push(stat.count);
          })
          intentData.series.push({
            name: key,
            data: series
          })
        })
        ////console.log(firstKey);
        stats[firstKey].forEach(stat => {
          let d = new Date(stat.dateTime);
          let label = d.getUTCDate() + ". " + d.toLocaleString('en-us', { month: 'short' });
          ////console.log('label: ', d.toString(), d.getDate());
          intentData.labels.push(label);
          utteranceData.labels.push(label);
        });


        //console.log("Intent Data=", intentData);

        // Consolidate Invocation Data
        for(var i=0;i < intentData.series[0].data.length; i++) {
          let total = 0;
          for (var j=0;j < intentData.series.length; j++) {
            total += intentData.series[j].data[i];
          }
          utteranceData.series.push(total);
        }


        //console.log("utteranceData=", utteranceData);
        this.setState({
          intentData,
          utteranceData
        })
      })
    }
  }

  changeDate = () => {
    this.setState({
      showDatePicker: !this.state.showDatePicker
    })
  }

  handleMenuClick = (id) => {
    this.setState({
      tab: id
    })
  }

  render() {
    const {startDate, endDate, showDatePicker, customerData, sessionData, utteranceData, intentData, focusedRange, tab} = this.state;

    //console.log(sessionData);
    sessionData.sessionsPerUser = [];
    for(var i=0; i < sessionData.sessions.length; i++) {
      let sessions = sessionData.sessions[i];
      let customers = (customerData.newUsers[i] + customerData.returningUsers[i]);
      //console.log(sessions, customers);
      if (customers > 0) {
        sessionData.sessionsPerUser.push( +(sessions / customers).toFixed(1) );
      } else {
        sessionData.sessionsPerUser.push( 0);
      }
    }

    let header = "";
    let subHeader = "";
    if (tab === 1) {
      header = "Customers";
    } else if (tab === 2) {
      header = "Sessions";
      subHeader = "A session begins when a user invokes your skill and ends when the user explicitly exits the skill, stops interacting with the skill, or the skill chooses to end the interaction.";
    } else if (tab === 3) {
      header = "Utterances";
    } else if (tab === 4) {
      header = "Intents";
    }

    let cust_new_returning_options = JSON.parse(JSON.stringify(cust_new_returning));
    cust_new_returning_options.series[0].data = customerData.newUsers;
    cust_new_returning_options.series[1].data = customerData.returningUsers;
    cust_new_returning_options.xAxis.categories = customerData.labels;

    let total_sessions_options = JSON.parse(JSON.stringify(total_sessions));
    total_sessions_options.series[0].data = sessionData.sessions;
    total_sessions_options.xAxis.categories = sessionData.labels;

    let session_duration_options = JSON.parse(JSON.stringify(session_duration));
    session_duration_options.series[0].data = sessionData.duration;
    session_duration_options.xAxis.categories = sessionData.labels;

    let sessions_per_customer_options = JSON.parse(JSON.stringify(sessions_per_customer));
    sessions_per_customer_options.series[0].data = sessionData.sessionsPerUser;
    sessions_per_customer_options.xAxis.categories = sessionData.labels;

    let utterance_options = JSON.parse(JSON.stringify(utterances));
    utterance_options.series[0].data = utteranceData.series;
    utterance_options.xAxis.categories = utteranceData.labels;

    let intent_options = JSON.parse(JSON.stringify(intents));
    intent_options.series = intentData.series;
    intent_options.xAxis.categories = intentData.labels;
    ////console.log(JSON.stringify(total_sessions_options, null, 2));

    const selectionRange = {
			startDate: startDate ? startDate : new Date(),
			endDate: endDate ? endDate : new Date(),
			key: 'selection'
		}


    return (
      <div className="stats_rowFull">
        <div className="row stats_rowFull">
          <div className="col-1 stats_menuCol">
            <div className="stats_menu">
              <div className="status_menuHeader">Statistic</div>
              <div className={"stats_menuItem" + (tab === 1 ? ' selected' : '')} onClick={() => this.handleMenuClick(1)}>Customers</div>
              <div className={"stats_menuItem" + (tab === 2 ? ' selected' : '')} onClick={() => this.handleMenuClick(2)}>Sessions</div>
              <div className={"stats_menuItem" + (tab === 3 ? ' selected' : '')} onClick={() => this.handleMenuClick(3)}>Utterances</div>
              <div className={"stats_menuItem" + (tab === 4 ? ' selected' : '')} onClick={() => this.handleMenuClick(4)}>Intents</div>
            </div>
          </div>
          <div className="col stats_colFull">
            <div className="row">
              <div className="col stats_options">
                <div className="stats_header">{header}</div>
              </div>
              <div className="col text-right stats_options">
                <div className="stats_title">Date Range:</div>
                <div className="stats_value">{startDate && startDate.toLocaleDateString()} to {endDate && endDate.toLocaleDateString()}</div>
                <button type="button" class="btn btn-primary stats_button_inline" onClick={this.changeDate}>Change</button>
                <div className="stats_refresh" onClick={this.updateChart}><FA className="stats_refresh_icon" icon={["far", "sync-alt"]} /></div>
                {showDatePicker &&
                  <div className="stats_datepicker">
                    <DateRangePicker
              				ranges={[selectionRange]}
                      focusedRange={focusedRange}
                      maxDate={new Date()}
              				onChange={this.handleSelect}
                      onRangeFocusChange={this.handleRangeFocusChange}
              			/>
                  </div>
                }
              </div>
            </div>
            <div className="row">
              {tab === 1 &&
                <div className="col">
                  <div className="stats_container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={cust_new_returning_options}
                    />
                  </div>
                </div>
              }

              {tab === 2 &&
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="stats_container">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={total_sessions_options}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="stats_container">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={session_duration_options}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="stats_container">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={sessions_per_customer_options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }

              {tab === 3 &&
                <div className="col scrollable">
                    <div className="row">
                      <div className="col">
                        <div className="stats_container">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={utterance_options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              }

              {tab === 4 &&
                <div className="col scrollable">
                    <div className="row">
                      <div className="col">
                        <div className="stats_container">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={intent_options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}




export default Stats;
