import React, { Component } from 'react';
import './styles.css';
import $ from 'jquery';
import queryString from 'query-string';
import utils from '../helpers/utils.js';
import withdrawalReasons from '../data/withdrawalReasons.js';
import Spinner from '../Spinner';
class PublishPost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 11,
            withdrawalReason: '',
        };
    }

    componentWillReceiveProps(props) {
      if (props.isAccountLinked) {
          this.setState({
              step: 13
          })
      }
    }

    componentWillMount() {
        const parsed = queryString.parse(location.search); // eslint-disable-line no-restricted-globals
        utils.log(parsed);

        if (parsed.code) {
            utils.log('parsed.code=', parsed.code);
            this.setState({
                step: 12,
            });


            utils.processAccessCode(parsed.code).then(response => {
                this.setState({
                    step: 12
                });
            });
        }
    }
    componentDidMount() {
        if (this.props.isAccountLinked) {
            this.setState({
                step: 13
            })
        }
        (function (d) {
            var a = d.createElement('script'); a.type = 'text/javascript';
            a.async = true; a.id = 'amazon-login-sdk';
            a.src = 'https://api-cdn.amazon.com/sdk/login1.js';
            d.getElementById('amazon-root').appendChild(a);
        })(document);

        if (this.state.step === 12) {
            $('#PublishProjectModal').modal('show');
        }


        window.onAmazonLoginReady = function () {
            window.amazon.Login.setClientId('amzn1.application-oa2-client.b36926cdc808430f9b87878789315e6f'); //Jeff
            //window.amazon.Login.setClientId('amzn1.application-oa2-client.f740aa01506e4e8ba556d38d229db299');//pankaj
            //window.amazon.Login.setClientId('amzn1.application-oa2-client.8d0ce18af8544d5486c3115ddb3845ff');
            //window.amazon.Login.setClientId('amzn1.application-oa2-client.cd39818a82394e6ab0d630b75ce7b35b'); //Fact-Skill
        };
    }
    handleLinkAccount = () => {
        let options = { scope: 'alexa::ask:skills:readwrite alexa::ask:models:readwrite alexa::ask:skills:test', response_type: 'code' };
        window.amazon.Login.authorize(options, this.successCallBack);
    }

    successCallBack = (response) => {
        if (response.error) {
            utils.log('oauth error====', response.error);
        }

        if (response.code) {
            utils.log('response.code=', response.code);
            utils.log('success:==== ', response);
            // this.setState({
            //     step: 12,
            // });
            utils.processAccessCode(response.code).then(response => {
                this.setState({
                    step: 12
                });
            });
        }
    }
    handleClose = () => {
        if (this.props.isAccountLinked) {
            this.setState({
                step: 13
            })
        } else {
            this.setState({
                step: 11
            });
        }
    }
    handleDeploySkill = () => {
        const { project, user }  = this.props;

        this.setState({
          step: 7
        })
        //let post = posts.filter(function (s) { return s.id === currentSelectedPost });
        //utils.log("Selected Post =", post);
        return utils.publishSkill(project, true).then(response => {
          if (response.result === 'success') {

            window.fbq('trackSingle', '211310806174815', 'PublishedSkill');
            window.ga('send', 'event', 'Skill Making', 'Publish', 'Success');
            utils.addIntercomTag('PublishedSkill');
            utils.getGeolocationInfo().then(ipInfo => {
              utils.sendProofEvent('publishedskill', {
                "type": "custom",
                "email": user.userName,
                "first_name": user.firstName,
                "city": ipInfo.city,
                "state": ipInfo.region,
                "country": ipInfo.country,

                "ip": ipInfo.query
              });
            });
            this.setState({
              project,
              step: 14
            });
            if (this.props.onUpdateProject) {
              this.props.onUpdateProject();
            }
          } else {
            if (response.code === 3) {
              window.ga('send', 'event', 'Skill Making', 'Publish', 'In Certification');
              this.setState({
                project,
                step: 9
              })
            } else {
              window.ga('send', 'event', 'Skill Making', 'Publish', 'Failure');
            }
            utils.log(response);
          }

        }).catch(err => {
          utils.log("Publish Error: ", err);
          this.setState({
            errorText: err.message,
            step: 15
          });
        });
    }

    render() {
        const { project } = this.props;
        let { step, errorText } = this.state;

        //let post = posts.filter(function (s) { return s.id === currentSelectedPost });

        let postName = project && project.name;

        return (
            <div className="modal fade publish-modal-dialog-centered" id="PublishProjectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog publish-card" role="document">
                    <div className="modal-content publish-modal-content">
                        <div className="modal-header modal-header-primary">
                            <h5 className="modal-title" id="exampleModalLabel">Publish Post</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>


                        {step === 3 ?
                            <div className="modal-body">

                                <div className="publish_spinnerContainer">
                                    <div className="publish_spinnerTitle">Publishing...</div>
                                    <Spinner size={30} />
                                </div>
                            </div> : null
                        }

                        {step === 7 ?
                          <div className="modal-body">

                              <div className="publish_spinnerContainer">
                                <div className="publish_spinnerTitle">Publishing...</div>
                                <Spinner size={30} />
                              </div>
                          </div> : null
                        }

                        {step === 11 ?
                            <div className="modal-body">
                                <div id="amazon-root"></div>
                                <div className="container-fluid">
                                    <div className="publish_projectName">{postName}</div>
                                    <div className="publish_messageText">Voice Apps can automatically deploy your skill to your Amazon developer account so you can test on your own Alexa device.</div>
                                    <br />

                                    <div className="publish_messageText">If you don&apos;t already have a developer account, creating one only takes a few seconds.</div>
                                    <br />
                                    <div className="publish_messageText">Click the Link Account button below to link your developer account to Voice Apps.  You&apos;ll only need to do this once.</div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={this.handleLinkAccount}>Link Account</button>
                                    </div>
                                </div>
                            </div> : null
                        }

                        {step === 12 ?
                            <div className="modal-body">
                                <div id="amazon-root"></div>
                                <div className="container-fluid">
                                    <div className="publish_projectName">{postName}</div>
                                    <div className="publish_messageText">Your Amazon developer account is now linked.</div>
                                    <br />
                                    <div className="publish_messageText">Click the Deploy Skill button below to deploy your skill to your Alexa device.</div>
                                    <br />
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={this.handleDeploySkill}>Deploy Skill</button>
                                    </div>
                                </div>
                            </div> : null
                        }

                        {step === 13 ?
                            <div className="modal-body">
                                <div id="amazon-root"></div>
                                <div className="container-fluid">
                                    <div className="publish_projectName">{postName}</div>
                                    <div className="publish_messageText">Click the Deploy Skill button below to deploy your skill to your Alexa device.</div>
                                    <br />
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={this.handleDeploySkill}>Deploy Skill</button>
                                    </div>
                                </div>
                            </div> : null
                        }

                        {step === 14 ?
                          <div className="modal-body">
                            <div className="container-fluid">
                              <div className="publish_projectName">{project && project.name}</div>
                              <div className="publish_messageText">Your skill has been deployed to your Amazon account.</div>
                              <br />
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                            </div>
                          </div>
                          :
                          null
                        }

                        {step === 15 ?
                          <div className="modal-body">
                            <div className="container-fluid">
                              <div className="publish_projectName">{project && project.name}</div>
                              <div className="publish_messageText">There was a problem deploying your skill.  Please try again in a few minutes.</div>
                              <br />
                              <div className="alert alert-danger" role="alert">{errorText}</div>
                              <br />
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                            </div>
                          </div>
                          :
                          null
                        }
                    </div>
                </div>
            </div>
        );
    }


}

export default PublishPost;
