import React, { Component } from 'react';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiAudio from '../MultiAudio';

class ShortAudio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urls: props.data.urls,
      formFields: {

      }
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      urls: props.data.urls
    })
  }

  handleURLChange = (data) => {
    this.setState({
      urls: data
    });

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { urls } = this.state;

    let rules = [
      {
        regEx: '^https:\/\/.*$|^soundbank:\/\/.*$|^{{.*$',
        error: 'URL must start with https:// or soundbank:// or contain a single variable.'
      }
    ]
    return (
      <div className="intent_card">
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_music" icon="music" /></span>Short Audio Response</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <MultiAudio
          header="URL of the audio to play..."
          inputPlaceHolder="Enter audio URL"
          inputPlaceHolder2="Enter another and we'll pick randomly."
          inputPlaceHolder3="Enter another URL (optional)."
          data={urls}
          variables={this.props.variables}
          allowVariables={true}
          onChange={(data) => this.handleURLChange(data)}
          rules={rules}
        />
      </div>
    );
  }
}

export default ShortAudio;
