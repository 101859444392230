import 'bootstrap/dist/css/bootstrap.min.css';
import Popper from 'popper.js';
import ReactDOM from 'react-dom';
import './index.css';
import { makeMainRoutes } from './routes';

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);

// utils.log('INDEX.JS REndoer');
window.Intercom("update");
