import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import Header from '../Header';
//import Footer from '../Footer';

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  // componentWillMount() {
  //   utils.getUserAttribute('custom:isNewUser').then(value => {
  //     utils.log('custom:isNewUser=', value);
  //     if (value === '1') {
  //       utils.createProject(sampleProject).then(result => {
  //         utils.log('DB Response=', result);
  //         utils.setUserAttribute('custom:isNewUser', '0').then(result => {
  //           utils.log('Set isNewUser = 0');
  //           return null;
  //         });
  //         return null;
  //       });
  //     }
  //     return null;
  //   }).catch(err => {
  //     utils.log(err);
  //     utils.logout();
  //     this.goTo('login');
  //   });
  // }


  componentDidMount() {

  }


  handleProjectClick = (id) => { // eslint-disable-line
    this.goTo(`project/${id}`);
  }


  render() {

    return (
      <div>
        <Header menuId={3} auth={this.props.auth} history={this.props.history}/>
        <div className="planContainer">
          <div className="plans_Header">Alexa Skills Made Simple</div>
          <div className="plans_subtitle">Create A High Quality Alexa Skill In Minutes</div>
          <div className="container-fluid plans_planWrapper">

                  <div className="row">
                  <div className="col col-12 col-sm-4 col-xl-4">
                    <div className="plans_plan">
                      <div className="plans_planTitle">FREE Plan</div>
                      <div className="plans_planSubtitle">Get started quickly building Alexa Skills</div>
                      <div className="plans_priceContainer">
                        <div className="plans_price">FREE Forever</div>
                        {/*<div className="plans_priceHeader">As low as</div>
                        <div className="plans_priceAnnual">$8.29 <span className="plans_priceAnnualPerMonth">/ month</span></div>
                        <div className="plans_priceFooter">(When paid annually)</div>*/}
                      </div>
                      <div className="plans_selectPlan" onClick={() => this.goTo('login')}>Select this plan</div>

                    </div>
                  </div>
                    <div className="col col-12 col-sm-4 col-xl-4">
                      <div className="plans_plan">
                        <div className="plans_planTitle">Professional Plan</div>
                        <div className="plans_planSubtitle">Everything you need to build professional skills.</div>
                        <div className="plans_priceContainer">
                          <div className="plans_price">$19.95 <span className="plans_pricePerMonth">/ month</span></div>
                          {/*<div className="plans_priceHeader">As low as</div>
                          <div className="plans_priceAnnual">$8.29 <span className="plans_priceAnnualPerMonth">/ month</span></div>
                          <div className="plans_priceFooter">(When paid annually)</div>*/}
                        </div>
                        <div className="plans_selectPlan" onClick={() => this.goTo('checkout')}>Select this plan</div>

                      </div>
                    </div>
                    <div className="col col-12 col-sm-4 col-xl-4">
                      <div className="plans_plan">
                        <div className="plans_planTitle">Custom</div>
                        <div className="plans_planSubtitle">Let us build a fully customized skill for you or your business.  Our expert team will work with you every step of the way!</div>
                        <div className="plans_customSpacer"></div>
                        <div className="plans_selectPlan"><a href="mailto:support@voiceapps.com">Contact Us Today!</a></div>

                      </div>

                    </div>
                  </div>
          </div>

          <div className="plans_comparisonHeader">Plan Comparison</div>
          <div className="plans_comparisonContainer">
            <div className="row">
              <div className="col plans_colHeader1">
                Feature
              </div>
              <div className="col plans_colHeader">
                FREE Plan
              </div>
              <div className="col plans_colHeader3">
                Professional Plan
              </div>
              <div className="col plans_colHeader4">
                Leading Competitor
              </div>
            </div>
            <div className="row plans_row1">
               <div className="col plans_col1">Project Creation</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"></div>
               <div className="col plans_col3"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Templates to use as a starting point for your skill</div>
               <div className="col plans_col">Standard</div>
               <div className="col plans_col3">Professional</div>
               <div className="col plans_col4">Limited</div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Create "Custom" skills</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Create "Flash Briefing" skills</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>

            <div className="row plans_row">
               <div className="col plans_col1">Icons to use in your skill</div>
               <div className="col plans_col">Standard</div>
               <div className="col plans_col3">Premium</div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Build skills for multiple locales/languages with single project</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row1">
               <div className="col plans_col1">Skill Building</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"></div>
               <div className="col plans_col3"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Visual drag and drop interface</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Home Cards</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Display Templates (Echo Show, Spot, Fire TV Cube)</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Monetize Your Skills</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4">Basic</div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Linked Accounts</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Limit the scope of Intents</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Globally scoped Intents</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Easily manage content for different languages</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Capture data from the user (Slots)</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Easily duplicate intents</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Standard Audio Player<br/>(Play, Pause, Stop)</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Advanced Audio Player<br />(Next, Previous, Nearly Finished)</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Bulk upload of "what the user can say phrases"</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Store data in variables and re-use them later in your skill</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Set variables based on the value of other variables </div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Store state information between user sessions</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Conditional branching logic (If/then)</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Mathematical Functions<br/>(Increment, Decrement, Add, Subtract, Multiply, Divide)</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Use basic javascript in variables</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Get data from 3rd party APIs </div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Easily get data from RSS feeds</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Get a random value from a list of values</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Lookup a value from a list of values</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">	Bulk upload data values</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Respond to the user with spoken text</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Choose randomly from a list of responses</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Use SSML in skill responses</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Respond to the user in multiple voices</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/> (57 Voices)</div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Have different responses based on the user&apos;s visit count</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Have different responses based on the value of variables</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Include short audio in the skill response</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">	Choose randomly from a list of audio tracks</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Chain intents together to form a single response</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Return control flow back to a previous intent</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Take different actions based on the value of variables</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Context sensitive help</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Handle invalid responses from user</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Custom reprompt messages if user doesn&apos;t respond in time</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row1">
               <div className="col plans_col1">Built-in Test Tool</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"></div>
               <div className="col plans_col3"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Simulates Alexa&apos;s voice in each locale/language</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Automatically keeps track of state information during each session</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Can be activated by voice or text</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4">Voice Only</div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Visual representation of the conversation</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Supports playback of long/streaming audio</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row1">
               <div className="col plans_col1">Deployment</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"></div>
               <div className="col plans_col3"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Deploy your skill to your Amazon Developer account</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Deploy to multiple locales/languages from single project</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
            <div className="row plans_row1">
               <div className="col plans_col1">Support</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"></div>
               <div className="col plans_col3"></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Self-guided Help Center</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">YouTube Videos</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Voice Apps Community (Facebook Group)</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4">Has own community</div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">e-mail support</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Live Chat support</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Standard 24 hour response time</div>
               <div className="col plans_col"><FA icon="check"/></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"><FA icon="check"/></div>
            </div>
            <div className="row plans_row">
               <div className="col plans_col1">Priority 1 hour response time</div>
               <div className="col plans_col"></div>
               <div className="col plans_col3"><FA icon="check"/></div>
               <div className="col plans_col4"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Plans;
