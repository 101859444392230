let options = {

  chart: {
    type: "line"
  },
  credits: {
      enabled: false
  },
    title: {
        text: 'Average Session Duration',
        align: 'left',
        margin: 35,
        style: {
          "fontSize": "20px"
        }
    },

    yAxis: {
        title: {
            text: 'Duration (in seconds)'
        }
    },

    xAxis: {
      categories: []
    },

    legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            //categories: customerData.labels,//["1. Feb", "2. Feb"],
            stacking: "normal"
        }
    },

    series: [{
        name: 'Duration',
        data: [6, 7, 8, 9, 10],
        color: "#3CAEA3"
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

};

export default options;
