import history from '../history';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';
import utils from '../helpers/utils.js';

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    var options = { scope : 'profile' };
    window.amazon.Login.authorize(options, 'https://sleep-sounds.surge.sh/callback');
    return false;
  }

  handleAuthentication(location) {
    utils.log("Authenticating: ", location);
    var result = location.search.split('&');
    var accessToken = result[0].split('=')[1];
    var expiresIn = result[2].split('=')[1];

    utils.log('accessToken=', accessToken);
    utils.log('expiresIn=', expiresIn);

    var authResult = {
      accessToken: accessToken,
      expiresIn: expiresIn
    };

    if (authResult && authResult.accessToken) {
      this.setSession(authResult);
      //history.replace('/home');
    } else {
      history.replace('/home');
      utils.log(location);
      alert(`Error: Check the console for further details.`);
    }

  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    history.replace('/home');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
    history.replace('/home');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}   
