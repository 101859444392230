import React, { Component } from 'react';
import './App.css';
import fontawesome from '@fortawesome/fontawesome';
// import brands from '@fortawesome/fontawesome-free-brands'
import faMicrophone from '@fortawesome/fontawesome-pro-light/faMicrophone';
import faTruck from '@fortawesome/fontawesome-pro-light/faTruck';
import faUser from '@fortawesome/fontawesome-pro-light/faUser';
import faHelp from '@fortawesome/fontawesome-pro-light/faQuestion';
import faFile from '@fortawesome/fontawesome-pro-light/faFileAlt';
import faVolumeUp from '@fortawesome/fontawesome-pro-regular/faVolumeUp';
import faShareAlt from '@fortawesome/fontawesome-pro-regular/faShareAlt';
import faCloudUpload from '@fortawesome/fontawesome-pro-regular/faCloudUpload';
import faStar from '@fortawesome/fontawesome-free-solid/faStar';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faStarReg from '@fortawesome/fontawesome-free-regular/faStar';
import faArrowLeft from '@fortawesome/fontawesome-pro-regular/faArrowLeft';
import faBoxUsd from '@fortawesome/fontawesome-pro-regular/faBoxUsd';
import faLink from '@fortawesome/fontawesome-pro-regular/faLink';
import faHSquare from '@fortawesome/fontawesome-pro-regular/faHSquare';
import faSyncAlt from '@fortawesome/fontawesome-pro-regular/faSyncAlt';
import { faBracketsCurly } from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAltFastest } from '@fortawesome/pro-regular-svg-icons';
import { faSlidersVSquare } from '@fortawesome/pro-regular-svg-icons';
import { faWaveSine, faWaveSquare } from '@fortawesome/pro-regular-svg-icons';

fontawesome.library.add(faMicrophone, faTruck, faVolumeUp, faShareAlt,
  faCloudUpload, faStar, faStarReg, faArrowLeft, faCheck, faHelp, faUser,
  faFile, faBoxUsd, faLink, faHSquare, faSyncAlt, faBracketsCurly, faTachometerAltFastest, faSlidersVSquare,
  faWaveSine, faWaveSquare);

class App extends Component {

  componentDidMount() {
    //utils.log("APP Mounted");



  }

    render() {
      //utils.log("App.js Render ----------------------");
      if (window.location.href.indexOf('business') > -1) {
        window.fbq('trackSingle', '2044350275643772', 'ViewContent');
        window.twq('trackSingle', '2044350275643772', 'PageView');
      } else {
        window.fbq('trackSingle', '211310806174815', 'ViewContent');
        window.twq('trackSingle', '211310806174815', 'PageView');
      }
      return (
        <div></div>
      );
    }
}

export default App;
