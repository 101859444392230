import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import VariablePicker from '../VariablePicker';

class Expression extends Component {
  constructor(props) {
    super(props);

    let expression = {
      type: "expression",
      value: {
        left: {
          type: "variable",
          id: utils.guid(),
          value: ""
        },
        operator: {
          id: utils.guid(),
          value: "=="
        },
        right: {
          type: "value",
          id: utils.guid(),
          value: ""
        }
      }
    }

    let showTree = false;

    if (props.when) {
      if (props.when.expression) {
        expression = props.when.expression;
      } else if (props.when.sourceVariable) {
        expression.value.left.value = props.when.sourceVariable ? ("{" + props.when.sourceVariable + "}").replace("{{", "").replace("}}", "") : "";
        expression.value.operator.value = props.when.operator ? props.when.operator : "==";
        expression.value.right.value = props.when.targetVariable ? props.when.targetVariable : "";
        if (props.when.targetVariable && props.when.targetVariable.indexOf("{") > -1) {
          expression.value.right.type = "variable";
        }
      } else {
        showTree = true;
      }
    } else {
      showTree = true;
    }

    let showExpressionView = true;

    if (expression.value.operator == null) {
      showExpressionView = false;
      showTree = true;
    }

    this.state = {
      expression: expression,
      showChanger: false,
      selectedExpression: null,
      showTree: showTree,
      showExpressionView
    }
  }

  componentWillReceiveProps(props) {
    //utils.log("===============Expression Will Receive Props===============");
    //utils.log(props);

    let expression = {
      type: "expression",
      value: {
        left: {
          type: "variable",
          id: utils.guid(),
          value: ""
        },
        operator: {
          id: utils.guid(),
          value: "=="
        },
        right: {
          type: "value",
          id: utils.guid(),
          value: ""
        }
      }
    }

    let showTree = false;

    if (props.when) {
      if (props.when.expression) {
        expression = props.when.expression;
      } else if (props.when.sourceVariable) {
        expression.value.left.value = props.when.sourceVariable ? ("{" + props.when.sourceVariable + "}").replace("{{", "").replace("}}", "") : "";
        expression.value.operator.value = props.when.operator ? props.when.operator : "==";
        expression.value.right.value = props.when.targetVariable ? props.when.targetVariable : "";
        if (props.when.targetVariable && props.when.targetVariable.indexOf("{") > -1) {
          expression.value.right.type = "variable";
        }
      } else {
        showTree = true;
      }
    } else {
      showTree = true;
    }

    this.setState({
      expression: expression,
      showExpressionView: true
    });

    if (expression.value.operator == null) {
      this.setState({
        showTree: true,
        showExpressionView: false
      })
    }



  }

  componentDidMount() {
    window.addEventListener('mouseup', this.mouseupHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseupHandler);
  }

  mouseupHandler = (event) => {
    let box = document.getElementById('expressionBuider');
    //utils.log(box);
    //utils.log("EVENT TARGET", event.target, event.target.parentNode);
    if (event.target !== box && event.target.parentNode !== box) {
      this.setState({
        showChanger: false
      })
    }
  }

  // Calculates the mathematical expression for evaluation
  calculateExpression(expression, isRoot) {
    expression = expression ? expression : {};

    if (this.operatorType !== this.getOperatorType(expression.operator ? expression.operator.value : null)) {
      this.expressionLevel++;
    }
    this.operatorType = this.getOperatorType(expression.operator ? expression.operator.value : null);

    if (this.expressionLevel > 0) {
      return "( " +
          (expression.left ? this.calculateLeft(expression.left) : '')
          +

          (expression.operator ? this.calculateOperator(expression.operator) : '')

          +

          (expression.right ? this.calculateLeft(expression.right) : '')

          +
          ") "
    } else {
      return '' +
          (expression.left ? this.calculateLeft(expression.left) : '')
          +

          (expression.operator ? this.calculateOperator(expression.operator) : '')

          +

          (expression.right ? this.calculateLeft(expression.right) : '')
    }

  }

  calculateLeft(left) {
    if (left.type === 'variable') {
      return left.value === '' ? '? ' : left.value.replace(/{/g, '').replace(/}/g, '') + ' '

    } else if (left.type === 'value') {
      return left.value === '' ? '? ' : this.convertValue(left.value) + ' '

    } else if (left.type === 'expression') {
      return this.calculateExpression(left.value);
    }

  }

  convertValue = (value) => {
    if (value !== 'true' && value !== 'false' && !utils.isNumeric(value)) {
      return `'${value}'`;
    } else {
      return value;
    }
  }

  calculateOperator(operator) {
    return operator.value + ' ';
  }

  // Generates the shorthand view of the expression
  generateExpression(expression, isRoot) {
    const { showEquation } = this.props;

    expression = expression ? expression : {};

    if (this.operatorType !== this.getOperatorType(expression.operator ? expression.operator.value : null)) {
      this.expressionLevel++;
    }
    this.operatorType = this.getOperatorType(expression.operator ? expression.operator.value : null);

    if (this.expressionLevel > 0) {
      return (
        <span className="exp_block">
          <span className="exp_paren">(</span>
          {expression.left ?
            this.generateLeft(expression.left) : null
          }

          {expression.operator ?
            this.generateOperator(expression.operator) : null
          }

          {expression.right ?
            this.generateLeft(expression.right) : null
          }
          <span className="exp_paren">)</span>
          {showEquation && isRoot &&
            <span className="exp_block">
              <span className="exp_operator_large">=</span>
              <span className="exp_value">true</span>
            </span>
          }
        </span>
      )
    } else {
      return (
        <span className="exp_block">
          {expression.left ?
            this.generateLeft(expression.left) : null
          }

          {expression.operator ?
            this.generateOperator(expression.operator) : null
          }

          {expression.right ?
            this.generateLeft(expression.right) : null
          }
          {showEquation && isRoot &&
            <span className="exp_block">
              <span className="exp_operator_large">=</span>
              <span className="exp_value">true</span>
            </span>
          }
        </span>
      )
    }
  }

  generateLeft(left) {
    if (left.type === 'variable') {
      return (
        <span className="exp_variable_container">
        {left.value === '' &&
          <span className="exp_missing_value">
            {'?'}
          </span>
        }

        {left.value !== '' &&
          <span className="exp_variable">
            {left.value}
          </span>
        }
        </span>
      )
    } else if (left.type === 'value') {
      return (
        <span className="exp_variable_container">
        {left.value === '' &&
          <span className="exp_missing_value">
            {'?'}
          </span>
        }
        {left.value !== '' &&
          <span className="exp_value">
            {left.value}
          </span>
        }
        </span>
      )
    } else if (left.type === 'expression') {
      return this.generateExpression(left.value);
    }

  }

  generateOperator(operator) {
    let operatorDisplay = this.getDisplayValue(operator.value);

    if (operator.value === '&&' || operator.value === '||') {
      return (
        <span className="exp_operator" dangerouslySetInnerHTML={{__html: operatorDisplay}}></span>
      )
    } else {
      return (
        <span className="exp_operator_large" dangerouslySetInnerHTML={{__html: operatorDisplay}}></span>
      )
    }
  }

  getOperatorType = (operator) => {
    let operatorType = operator;

    // Addition
    if (operator === '+' || operator === '-') {
      operatorType = 'addition';
    } else if (operator === '*' || operator === '/') {
      operatorType = 'multiplication';
    }

    return operatorType;
  }
  // Renders the expression Editor
  renderExpression(expression) {
    expression = expression ? expression : {};

    if (this.operatorType !== this.getOperatorType(expression.operator ? expression.operator.value : null)) {
      this.expressionLevel++;
    }
    this.operatorType = this.getOperatorType(expression.operator ? expression.operator.value : null);

    if (this.expressionLevel > 0) {
      return (
        <div className="expression_block">
          <div className="expression_block_left"/>
          <div className="expression_block_center"/>
          {expression.left ?
            this.renderLeft(expression.left, this.expressionCount) : null
          }

          {expression.operator ?
            this.renderOperator(expression.operator, this.expressionCount) : null
          }

          {expression.right ?
            this.renderLeft(expression.right, this.expressionCount) : null
          }
        </div>
      )
    } else {
      return (
        <div className="expression_block2">
          {expression.left ?
            this.renderLeft(expression.left, this.expressionCount) : null
          }

          {expression.operator ?
            this.renderOperator(expression.operator, this.expressionCount) : null
          }

          {expression.right ?
            this.renderLeft(expression.right, this.expressionCount) : null
          }
        </div>
      )
    }
  }

  renderLeft(left, expressionId) {
    if (left.type === 'variable') {
      //utils.log("---------Left.value--------", left.value);
      return (
        <div className="expression_variable">
          <div className="expression_variablePicker">
            <VariablePicker initialValue={left.value} variables={this.props.variables} onChange={(data) => this.variableChange(data, left.id)}/>
          </div>
          <div className="expression_changer" onClick={() => this.showExpressionChanger(left.id)}><FA icon={["far", "cog"]} /></div>
        </div>
      )
    } else if (left.type === 'value') {
      return (
        <div className="expression_value">
          <div className="expression_valuePicker">
            <input type="text" placeholder="value" value={left.value} onChange={(event) => this.valueChange(event, left.id)}/>
          </div>
          <div className="expression_changer" onClick={() => this.showExpressionChanger(left.id)}><FA icon={["far", "cog"]} /></div>
        </div>
      )
    } else if (left.type === 'expression') {
      return this.renderExpression(left.value);
    }

  }

  getDisplayValue(value) {
    let operatorDisplay = value;
    if (operatorDisplay === '/') {
      operatorDisplay = '÷';
    }
    if (operatorDisplay === '*') {
      operatorDisplay = '&times;';
    }
    if (operatorDisplay === '-') {
      operatorDisplay = '&minus;';
    }
    if (operatorDisplay === '+') {
      operatorDisplay = '&plus;';
    }
    if (operatorDisplay === '==') {
      operatorDisplay = '=';
    }
    if (operatorDisplay === '!=') {
      operatorDisplay = '≠';
    }
    if (operatorDisplay === '&&') {
      operatorDisplay = 'AND';
    }
    if (operatorDisplay === '||') {
      operatorDisplay = 'OR';
    }
    return operatorDisplay;
  }

  renderOperator(operator, expressionId) {
    let operatorDisplay = this.getDisplayValue(operator.value);

    if (operator.value === '&&' || operator.value === '||' || operator.value === '!') {
      return (
        <div className="expression_operator_container">
          <div className="expression_operator">{operatorDisplay}</div>
          <div className="expression_changer" onClick={() => this.showExpressionChanger(operator.id)}><FA icon={["far", "cog"]} /></div>
        </div>
      )
    } else {
      return (
        <div className="expression_operator_container">
          <div className="expression_operator large" dangerouslySetInnerHTML={{__html: operatorDisplay}}></div>
          <div className="expression_changer" onClick={() => this.showExpressionChanger(operator.id)}><FA icon={["far", "cog"]} /></div>
        </div>
      )
    }
  }

  valueChange = (e, expressionId) => {
    let { expression } = this.state;

    this.updateExpression(expression, expressionId, 'value', e.target.value);

    this.setState({
      expression
    })

    if (this.props.onChange) {
      this.props.onChange({
        expression: expression,
        calculatedExpression: this.calculateExpression(expression.value, true)
      })
    }
  }

  variableChange = (data, expressionId) => {
    let { expression } = this.state;

    this.updateExpression(expression, expressionId, 'variable', data);

    this.setState({
      expression
    })

    if (this.props.onChange) {
      this.props.onChange({
        expression: expression,
        calculatedExpression: this.calculateExpression(expression.value, true)
      })
    }
  }

  // target = "variable", "value", or "expression"
  updateExpression(expression, expressionId, target, value) {
    expression = expression ? expression : {};

    if (expression.value) {
      if (expression.value.left) {
        this.updateValueLeft(expression.value.left, expressionId, target, value);
      }

      if (expression.value.right) {
        this.updateValueLeft(expression.value.right, expressionId, target, value);
      }
    } else {
      if (expression.left) {
        this.updateValueLeft(expression.left, expressionId, target, value);
      }

      if (expression.right) {
        this.updateValueLeft(expression.right, expressionId, target, value);
      }
    }
  }

  updateValueLeft(left, expressionId, target, value) {
    if (left.id === expressionId) {
      if (left.type === 'variable' && target === 'variable') {
        left.value = value;
      } else if (left.type === 'value' && target === 'value') {
        //utils.log('---------UPDATING VALUE___________');
        //utils.log(value);
        left.value = value;
      }
    } else if (left.type === 'expression') {
      return this.updateExpression(left.value, expressionId, target, value);
    }

  }

  showExpressionChanger = (id) => {
    this.setState({
      showChanger: true,
      selectedExpression: id
    })
  }

  changeOption = (option) => {
    let { expression, selectedExpression, showTree } = this.state;

    this.changeExpression(expression, selectedExpression, option, true);

    let showExpressionView = true;

    if (expression.value.operator == null) {
      showExpressionView = false;
      showTree = true;
    }

    utils.log("Final Expression=", expression);

    this.setState({
      showChanger: false,
      expression,
      showTree,
      showExpressionView
    })

    if (this.props.onChange) {
      this.props.onChange({
        expression: expression,
        calculatedExpression: this.calculateExpression(expression.value.operator ? expression.value : expression, true)
      })
    }
  }

  // target = "variable", "value", or "expression"
  changeExpression(expression, expressionId, option, isRoot) {
    expression = expression ? expression : {};

    utils.log("------------Change Expression --------");
    utils.log(JSON.parse(JSON.stringify(expression)));
    utils.log(expressionId);

    if (expression.value) {
      if (expression.value.operator) {
        if (expression.value.left) {
          if (expression.value.operator) {
            this.changeValueLeft(expression.value.left, expressionId, option, false, isRoot);
          } else {
            if (expression.value.id === expressionId) {
              this.changeValueLeft(expression, expressionId, option, true, isRoot);
            }
          }
        }

        if (expression.value.operator.id === expressionId) {
          if (option === 'value' || option === 'variable') {
            this.changeOperatorExpression(expression, option, isRoot);
          } else {
            this.changeOperatorValue(expression.value.operator, option);
          }
        }

        if (expression.value.right) {
          this.changeValueLeft(expression.value.right, expressionId, option);
        }
      } else {
        // This is a single value or variable
        if (expression.value.left) {
          if (expression.value.left.id === expressionId) {
            this.changeValueLeft(expression, expressionId, option, true, isRoot);
          }
        }
      }
    } else {
      if (expression.left) {
        this.changeValueLeft(expression.left, expressionId, option);
      }

      if (expression.operator) {
        if (expression.operator.id === expressionId) {
          this.changeOperatorExpression(expression, option);
        }
      }

      if (expression.right) {
        this.changeValueLeft(expression.right, expressionId, option);
      }
    }
  }

  changeOperatorExpression(operator, option, isRoot) {
    //utils.log("-------Change Operator Expression------");
    //utils.log(JSON.parse(JSON.stringify(operator)));

    if (isRoot && operator.type === 'expression' && operator.value && operator.value.operator) {
      operator.value = {
        left: {
          type: option,
          id: utils.guid(),
          value: ""
        }
      }
    } else {
      operator.type = option;
      operator.id = utils.guid();
      operator.value = "";

      delete operator.left;
      delete operator.operator;
      delete operator.right;
    }
  }

  changeOperatorValue(operator, option) {
    operator.value = option;
  }

  changeValueLeft(left, expressionId, option, force, isRoot) {
    if (left === null) return;
    //utils.log("-----Change value Left --------");
    //utils.log(JSON.parse(JSON.stringify(left)));
    //utils.log("force, isRoot", force, isRoot);
    if (force || left.id === expressionId) {
      if (option === 'value' || option === 'variable') {
        if (isRoot && left.type === 'expression') {
          left.value = {
            left: {
              type: option,
              id: utils.guid(),
              value: ""
            }
          }
        } else {
          left.type = option;
          left.id = utils.guid();
          left.value = "";
        }
      } else {
        let prevLeft = JSON.parse(JSON.stringify(left));
        if (force) {
          prevLeft = JSON.parse(JSON.stringify(left.value.left));
        }
        left.type = "expression";
        left.id = utils.guid();
        left.value = {
          left: prevLeft,
          operator: {
            id: utils.guid(),
            value: option
          },
          right: {
            type: "value",
            id: utils.guid(),
            value: 1
          }
        }
      }
    } else if (left.type === 'expression') {
      this.changeExpression(left, expressionId, option);
    }

  }

  toggleTree = () => {
    this.setState({
      showTree: !this.state.showTree
    })
  }


  render() {
    let { expression, showChanger, showTree, showExpressionView } = this.state;

    this.expressionLevel = 0;
    this.operatorType = this.getOperatorType(expression.value.operator ? expression.value.operator.value : null);
    let calculatedExpression = this.calculateExpression(expression.value, true);
    utils.log("__________Test Expression__________", calculatedExpression);
    this.expressionLevel = 0;
    this.operatorType = this.getOperatorType(expression.value.operator ? expression.value.operator.value : null);
    let expressionText = this.generateExpression(expression.value, true);
    //utils.log("EXPRESSION:", expression);
    this.expressionLevel = 0;
    this.operatorType = this.getOperatorType(expression.value.operator ? expression.value.operator.value : null);
    let expressionJSX = this.renderExpression(expression.value);

    return (
      <div className="expression_container">
        {showChanger &&
          <div className="expression_menu" id="expressionBuider">
            <div onClick={() => this.changeOption('value')}>Value</div>
            <div onClick={() => this.changeOption('variable')}>Variable</div>
            <div className="expression_menu_divider" />
            <div className="large" onClick={() => this.changeOption('+')}>+</div>
            <div className="large" onClick={() => this.changeOption('-')}>&minus;</div>
            <div className="large" onClick={() => this.changeOption('*')}>&times;</div>
            <div className="large" onClick={() => this.changeOption('/')}>÷</div>
            <div className="expression_menu_divider" />
            <div className="large" onClick={() => this.changeOption('==')}>=</div>
            <div className="large" onClick={() => this.changeOption('!=')}>≠</div>
            <div className="large" onClick={() => this.changeOption('>')}>&gt;</div>
            <div className="large" onClick={() => this.changeOption('<')}>&lt;</div>
            <div className="large" onClick={() => this.changeOption('>=')}>&gt;=</div>
            <div className="large" onClick={() => this.changeOption('<=')}>&lt;=</div>
            <div className="expression_menu_divider" />
            <div onClick={() => this.changeOption('&&')}>AND</div>
            <div onClick={() => this.changeOption('||')}>OR</div>
          </div>
        }
        {showExpressionView &&
          <div className="expression_view" onClick={this.toggleTree}>
            {expressionText}
          </div>
        }
        {showTree &&
          <div className="expression_tree">
            {expressionJSX}
          </div>
        }
      </div>
    );
  }
}

export default Expression;
