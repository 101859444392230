export const ACTION_LOGIN = 'ACTION_LOGIN';
export const ACTION_LOGOUT = 'ACTION_LOGOUT';

export const login = (user) => ({ type: ACTION_LOGIN, user });
export const logout = () => ({ type: ACTION_LOGOUT });

export default {
	login,
	logout,
};
