import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';

class Intent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOverlay: false
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      variables: props.data
    })
  }

  getFunctions(intent) {
    let functions = [];

    //utils.log(JSON.stringify(intent, null, 2));

    if (intent.triggeredBy === 'launch') {
      let invocationName = intent.publishingInfo && intent.publishingInfo.invocationName;

      if (!invocationName) {
        invocationName = "[skill name]";
      }

      let sample = "Alexa, open " + invocationName;
      functions.push(
        <div key={intent.id + "user"} className="intent_function">
          <div className="intent_icon" title="Triggered when user says..."><FA className="intent_user" icon="user"/></div>
          <div className="intent_userText" title={sample}>{sample}</div>
        </div>
      );
    }

    if (intent.triggeredBy === 'user') {
      if (intent.sampleUtterances && intent.sampleUtterances.length > 0) {
        let sample = intent.sampleUtterances[0];
        functions.push(
          <div key={intent.id + "user"} className="intent_function">
            <div className="intent_icon" title="Triggered when user says..."><FA className="intent_user" icon="user"/></div>
            <div className="intent_userText" title={sample}>{sample}</div>
          </div>
        )
      }
    }

    try {
      if (intent.actions) {
        intent.actions.forEach((action, index) => {
          if (action.type === "callAPI") {
            let sample = action.url;
            functions.push(
              <div key={'api' + index} className="intent_function">
                <div className="intent_icon" title="Call a JSON API"><FA className="intent_api" icon={["far", "plug"]} /></div>
                <div className="intent_userText" title={sample}>{sample}</div>
              </div>
            )
          }

          if (action.type === "getRSS") {
            let sample = action.url;
            functions.push(
              <div key={'rss' + index} className="intent_function">
                <div className="intent_icon" title="Get an RSS Feed"><FA className="intent_rss" icon={["far", "rss-square"]} /></div>
                <div className="intent_userText" title={sample}>{sample}</div>
              </div>
            )
          }

          if (action.type === "getRandom") {
            let sample = action.variable;
            functions.push(
              <div key={'rand' + index} className="intent_function">
                <div className="intent_icon" title="Get a Random Value"><FA className="intent_random" icon={["far", "random"]} /></div>
                <div className="intent_userText" title={sample}><FA className="intent_arrow" icon={["far", "long-arrow-right"]} /> {sample}</div>
              </div>
            )
          }

          if (action.type === "lookup") {
            let source = action.sourceVariable;
            let target = action.targetVariable;
            functions.push(
              <div key={'lu' + index} className="intent_function">
                <div className="intent_icon" title="Lookup a Value"><FA className="intent_lookup" icon={["far", "map-signs"]} /></div>
                <div className="intent_userText" title={source + " -> " + target}>{source} <FA className="intent_arrow" icon={["far", "long-arrow-right"]} /> {target}</div>
              </div>
            )
          }

          if (action.type === "isp") {
            let sample = action.productKey;
            functions.push(
              <div key={'isp' + index} className="intent_function">
                <div className="intent_icon" title="In-skill Purchase"><FA className="intent_isp" icon={["far", "box-usd"]} /></div>
                <div className="intent_userText" title={sample}>{sample}</div>
              </div>
            )
          }
        })
      }
    } catch(err) {
      utils.log(err);
    }

    try {
      if (intent.responseGroups && intent.responseGroups.length > 0) {
        intent.responseGroups.forEach((rg, rgKey) => {
          if (rg.when == null) {
            rg.functions.forEach((func, key) => {
              if (func.type === "response" && func.responses.length > 0) {
                let sample = func.responses[0];
                functions.push(
                  <div key={"rs_" + rgKey + "_" + key} className="intent_function">
                    <div className="intent_icon" title="Alexa will say..."><span className="intent_icon_alexa"/></div>
                    <div className="intent_userText" title={sample}>{sample}</div>
                  </div>
                )
              }

              if (func.type === "playAudio" && func.urls.length > 0) {
                let sample = func.urls[0];
                functions.push(
                  <div key={"pa_" + rgKey + "_" + key} className="intent_function">
                    <div className="intent_icon" title="Short Audio"><FA className="intent_music" icon="music"/></div>
                    <div className="intent_userText music" title={sample}>{sample}</div>
                  </div>
                )
              }

              if (func.type === "homeCard") {
                let sample = func.title;
                functions.push(
                  <div key={"pa_" + rgKey + "_" + key} className="intent_function">
                    <div className="intent_icon" title="Card Response"><FA className="intent_homecard" icon={["far", "id-card"]}/></div>
                    <div className="intent_userText music" title={sample}>{sample}</div>
                  </div>
                )
              }

              if (func.type === "permissions") {
                let text = '';
                text += func.permissions.firstName ? 'First Name, ' : '';
                text += func.permissions.fullName ? 'Full Name, ' : '';
                text += func.permissions.email ? 'E-mail, ' : '';
                text += func.permissions.phoneNumber ? 'Phone Number, ' : '';
                text = text.slice(0, -2);
                functions.push(
                  <div key={"pa_" + rgKey + "_" + key} className="intent_function">
                    <div className="intent_icon" title="Permissions"><FA className="intent_permissions" icon={["far", "key"]}/></div>
                    <div className="intent_userText music" title={text}>{text}</div>
                  </div>
                )
              }
            })
          }
        })
      }
    } catch(err) {
      utils.log(err);
    }

    try {
      if (intent.nextActions && intent.nextActions.length > 0) {
        intent.nextActions.forEach((na, index) => {
          if (na.action === "playLongAudio" && na.audioUrls.length > 0) {
            let sample = na.audioUrls[0];
            functions.push(
              <div key={"la_" + index} className="intent_function">
                <div className="intent_icon" title="Play Streaming Audio"><FA className="intent_play" icon="play"/></div>
                <div className="intent_userText" title={sample}>{sample}</div>
              </div>
            )
          }
        })
      }
    } catch(err) {
      utils.log(err);
    }



    return functions;
  }


  handleIntentClick = (id) => {
    if (this.props.onClick) {
      this.props.onClick(id);
    }
  }

  handleDeleteClick = (e, id) => {
    if (this.props.onDelete) {
      this.props.onDelete(e, id);
    }
  }

  handleMouseEnter = () => {
    this.setState({
      showOverlay: true
    });
  }

  handleMouseLeave = () => {
    this.setState({
      showOverlay: false
    })
  }

  handleCreateIntent = (e, id) => {
    if (this.props.onNewIntent) {
      this.props.onNewIntent(id);
    }

    this.setState({
      showOverlay: false
    })

    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { intent, isSelected, scopeColor, isActive } = this.props;
    const { showOverlay } = this.state;

    let isValid = true;
    if (intent.id === 'welcome' || intent.isLaunch === true) {
      if (intent.messages && intent.messages[0].message !== '') {
        isValid = true;
      }
    } else if (intent.text !== '') {
      isValid = true;
    }
    //utils.log('selectedIntent', selectedIntent);
    const intentClass = 'intent-card draggable ' + (isSelected ? ' selected' : '');
    const showClose = !(intent.id === 'welcome' || intent.isLaunch === true);

    let headerColor = "";
    if (intent.id === 'welcome' || intent.isLaunch === true) {
      headerColor = " intent-start";
    }

    if (intent.nextActions && intent.nextActions.length > 0) {
      intent.nextActions.forEach(na => {
        if (na.action === "endSession") {
          headerColor = " intent-end";
        }
      })
    }

    if (intent.activatedBy === "help" || (intent.sampleUtterances && intent.sampleUtterances.length>0 && intent.sampleUtterances[0] === "help")) {
      headerColor = " intent-help";
    }

    let intentActive = '';
    if (isActive) {
      intentActive = 'pulse';
    }

    let style = {};
    style.WebkitTransform =
    style.transform =
      'translate(' + intent.x + 'px, ' + intent.y + 'px)';
    style.visibility = 'visible';

    // update the posiion attributes
    //target.setAttribute('data-x', intent.x);
    //target.setAttribute('data-y', intent.y);

    utils.log(intent.name);
    return [
        <div key={intent.id}
          id={'intent' + intent.id}
          className={ `${intentClass} ${intentActive}`}
          onClick={() => this.handleIntentClick(intent.id)}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          data-x={intent.x}
          data-y={intent.y}
          style={style}>
          <div className={`intent_color intent_color${scopeColor}`}>
            {showClose ? <div className="card-close" onClick={(e) => this.handleDeleteClick(e, intent.id)}><FA icon="times"/></div> : null }
            <div className="card-body intent-card-body">
              <div className={"intent-card-title" + headerColor}>
                {intent.name}
                <p className="intent-card-description">{intent.description}</p>
              </div>
              {this.getFunctions(intent, )}
            </div>

            {!isValid ? <div className="card-not-complete" data-toggle="tooltip" data-placement="right" title="This intent is not complete."><FA icon="exclamation-circle"/></div> : null }

            <div className="intent_overlay">
              {showOverlay && <div id={'connector' + intent.id}className="intent_connector arrow-right connectable" onClick={(e) => this.handleCreateIntent(e, intent.id)}/>}
            </div>
          </div>
        </div>
    ];
  }
}

export default Intent;
