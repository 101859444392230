import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import $ from 'jquery';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import utils from '../helpers/utils.js';
import moment from 'moment';
import FA from '@fortawesome/react-fontawesome';
import Spinner from '../Spinner';

const CLOUDINARY_UPLOAD_PRESET = 'bophohq0';
const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/voiceapps/upload';


/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
function shallowEqual(objA: mixed, objB: mixed): boolean {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null ||
      typeof objB !== 'object' || objB === null) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

function shallowCompare(instance, nextProps, nextState) {
  return (
    !shallowEqual(instance.props, nextProps) ||
    !shallowEqual(instance.state, nextState)
  );
}

class AddProduct extends Component {


  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        id: {
          value: '',
          isValid: true
        },
        key: {
          value: '',
          isValid: true
        },
        name: {
          value: '',
          isValid: true
        },
        frequency: {
          value: 'ONETIME',
          isValid: true
        },
        days: {
          value: 0,
          isValid: true
        },
        units: {
          value: 1,
          isValid: true
        },
        summary: {
          value: '',
          isValid: true
        },
        description: {
          value: '',
          isValid: true
        },
        keywords: {
          value: '',
          isValid: true
        },
        phrasesFirst: {
          value: '',
          isValid: true
        },
        phrasesSecond: {
          value: '',
          isValid: true
        },
        phrasesThird: {
          value: '',
          isValid: true
        },
        purchasePrompt: {
          value: '',
          isValid: true
        },
        boughtCard: {
          value: '',
          isValid: true
        },
        price: {
          value: '',
          isValid: true
        },
        url: {
          value: '',
          isValid: true
        },
        productUrl: {
          value: '',
          isValid: true
        },
        privacyPolicyUrl: {
          value: '',
          isValid: true
        }
      },
      showIcons: false,
      icons: [],
      iconSearchTerm: 'pdf',
      isLoading: false,
      isEditing: false,
      isSubmitPost: false,
      showLoader: false,
      tab: 1,
      tabOneSubmitted: false

    }
  }



  componentWillReceiveProps(nextProps, nextState) {
    utils.log("componentWillUpdate", nextProps);
    if (nextProps.productKey === this.props.productKey) return;

      if (typeof (nextProps.productKey) !== "undefined" && nextProps.productKey !== "") {
        this.callMe(nextProps.productKey);
      } else if (nextProps.productKey === "") {
        /** Add popup */
        this.setState({
          formFields: {
            id: {
              value: '',
              isValid: true
            },
            key: {
              value: '',
              isValid: true
            },
            name: {
              value: '',
              isValid: true
            },
            frequency: {
              value: 'ONETIME',
              isValid: true
            },
            days: {
              value: 0,
              isValid: true
            },
            units: {
              value: 1,
              isValid: true
            },
            summary: {
              value: '',
              isValid: true
            },
            description: {
              value: '',
              isValid: true
            },
            keywords: {
              value: '',
              isValid: true
            },
            phrasesFirst: {
              value: '',
              isValid: true
            },
            phrasesSecond: {
              value: '',
              isValid: true
            },
            phrasesThird: {
              value: '',
              isValid: true
            },
            purchasePrompt: {
              value: '',
              isValid: true
            },
            boughtCard: {
              value: '',
              isValid: true
            },
            price: {
              value: '',
              isValid: true
            },
            url: {
              value: '',
              isValid: true
            },
            productUrl: {
              value: '',
              isValid: true
            },
            privacyPolicyUrl: {
              value: '',
              isValid: true
            }
          },
          tabOneSubmitted: false,
          tab: 1,
          isSubmitPost: false
        });
      }
    //} else {
      this.setState({
        tab: 1,
        isSubmitPost: false
      })
    //}
  }


  callMe = (id) => {
    let { formFields } = this.state;

    //console.log("call me called", id)
    //console.log("this.props.productArr", this.props.productArr);
    if (this.props.productArr) {
      const product = this.props.productArr.find(function (s) {
        return s.key === id
      });
      //console.log("this.props.productArr product", product);

      if (!product) return;

      Object.keys(product).forEach(key => {
        formFields[key].value = JSON.parse(JSON.stringify(product[key]));
      })

      this.setState({
        formFields,
        tab: 1,
        isSubmitPost: false
      })
    }
  }

  isFormValid = () => {
    let { formFields, tab } = this.state;

    let hasError = false;

    if (formFields.name.value === '') {
      formFields.name.isValid = false;
      hasError = true;
    }

    if (formFields.days.value === '') {
      formFields.days.isValid = false;
      hasError = true;
    }

    if (formFields.price.value === '') {
      formFields.price.isValid = false;
      hasError = true;
    }

    if (formFields.purchasePrompt.value === '') {
      formFields.purchasePrompt.isValid = false;
      hasError = true;
    }

    /**Check for purchasePrompt max length */
    if (this.maxLength(formFields.purchasePrompt.value, 160) === false) {
      formFields.purchasePrompt.isValid = false;
      hasError = true;
    }



    if (formFields.boughtCard.value === '') {
      formFields.boughtCard.isValid = false;
      hasError = true;
    }

    /**Check for boughtCard max length */
    if (this.maxLength(formFields.boughtCard.value, 160) === false) {
      formFields.boughtCard.isValue = false;
      hasError = true;
    }

    if (tab > 1) {
      // Check Summary
      if (formFields.summary.value === '') {
        formFields.summary.isValid = false;
        hasError = true;
      }

      if (this.maxLength(formFields.summary.value, 160) === false) {
        formFields.summary.isValue = false;
        hasError = true;
      }

      // Check Description
      if (formFields.description.value === '') {
        formFields.description.isValid = false;
        hasError = true;
      }

      if (this.maxLength(formFields.description.value, 160) === false) {
        formFields.description.isValue = false;
        hasError = true;
      }

      // Check Keywords
      if (formFields.keywords.value === '') {
        formFields.keywords.isValid = false;
        hasError = true;
      }

      // Check phrasesFirst
      if (formFields.phrasesFirst.value === '') {
        formFields.phrasesFirst.isValid = false;
        hasError = true;
      }
    }

    this.setState({
      formFields
    })

    return !hasError;
  }

  handleNext = () => {

    this.setState({
      isSubmitPost: true
    });

    if (!this.isFormValid()) return;

    this.setState({
      tab: 2,
      isSubmitPost: false
    });
  }

  handleSaveChanges = (e) => {
    this.setState({ isSubmitPost: true });
    utils.log('------------Handle Save Changes-------');

    if (!this.isFormValid()) return;

    let postData = Object.assign({}, this.state.formFields);
    if (this.props.postAction === "Create") {
      /** Adding a post */
      //postData.postDate.value = moment(this.props.postDate).format("YYYY-MM-DD");
      //utils.log('Post Id:--', postData.postDate);
      /** add a unique id  */
      postData.key.value = utils.guid();
      utils.log(this.props.projectId, "success", postData);
      this.setState({ showLoader: true });
      /** Save details in db */
      this.props.addUpdateProduct({
        id: postData.id.value,
        key: postData.key.value,
        name: postData.name.value,
        frequency: postData.frequency.value,
        days: postData.days.value,
        units: postData.units.value,
        summary: postData.summary.value,
        description: postData.description.value,
        keywords: postData.keywords.value,
        phrasesFirst: postData.phrasesFirst.value,
        phrasesSecond: postData.phrasesSecond.value,
        phrasesThird: postData.phrasesThird.value,
        purchasePrompt: postData.purchasePrompt.value,
        boughtCard: postData.boughtCard.value,
        price: postData.price.value,
        url: postData.url.value,
        productUrl: postData.productUrl.value,
        privacyPolicyUrl: postData.privacyPolicyUrl.value
      }, 0);
    } else {
      /** Updating a post  */
      utils.log("Updateing", );
      let selectedIndex = this.props.productArr.findIndex((item) => item.key === this.props.productKey);
      //postData.updatedDate.value = moment(this.props.postDate).format("YYYY-MM-DD");
      //utils.log("selectedIndex", selectedIndex, postData);
      this.props.productArr[selectedIndex] = {
        id: postData.id.value,
        key: postData.key.value,
        name: postData.name.value,
        frequency: postData.frequency.value,
        days: postData.days.value,
        units: postData.units.value,
        summary: postData.summary.value,
        description: postData.description.value,
        keywords: postData.keywords.value,
        phrasesFirst: postData.phrasesFirst.value,
        phrasesSecond: postData.phrasesSecond.value,
        phrasesThird: postData.phrasesThird.value,
        purchasePrompt: postData.purchasePrompt.value,
        boughtCard: postData.boughtCard.value,
        price: postData.price.value,
        url: postData.url.value,
        productUrl: postData.productUrl.value,
        privacyPolicyUrl: postData.privacyPolicyUrl.value
      };
      /** Save details in db */
      this.props.addUpdateProduct(this.props.productArr[selectedIndex], this.state.formFields.key.value);
    }
    let formFields = this.state.formFields;
    formFields= {
      id: {
        value: '',
        isValid: true
      },
      key: {
        value: '',
        isValid: true
      },
      name: {
        value: '',
        isValid: true
      },
      frequency: {
        value: 'ONETIME',
        isValid: true
      },
      days: {
        value: 0,
        isValid: true
      },
      units: {
        value: 1,
        isValid: true
      },
      summary: {
        value: '',
        isValid: true
      },
      description: {
        value: '',
        isValid: true
      },
      keywords: {
        value: '',
        isValid: true
      },
      phrasesFirst: {
        value: '',
        isValid: true
      },
      phrasesSecond: {
        value: '',
        isValid: true
      },
      phrasesThird: {
        value: '',
        isValid: true
      },
      purchasePrompt: {
        value: '',
        isValid: true
      },
      boughtCard: {
        value: '',
        isValid: true
      },
      price: {
        value: '',
        isValid: true
      },
      url: {
        value: '',
        isValid: true
      },
      productUrl: {
        value: '',
        isValid: true
      },
      privacyPolicyUrl: {
        value: '',
        isValid: true
      }
    }
        this.setState({
          formFields: formFields,
          isSubmitPost: false
        });
  }



  /** update form values in state  */
  updateField = (field, value) => { // eslint-disable-line
    let {formFields} = this.state;

    utils.log(field, value, formFields);
    if (field === 'summary' || field === 'description' || field === 'purchasePrompt' || field === 'boughtCard') {
      formFields[field].value = value.substring(0, 160);
    } else if(field === 'name'){
      formFields[field].value = value.substring(0, 50);
    }else {
      formFields[field].value = value;
    }
    this.setState({
      formFields,
      isEditing: true,
    });
  }

  /** handle icon  */
  handleChangeIcon = (e) => { // eslint-disable-line
    const { formFields } = this.state;
    utils.log('Default Image Tag', formFields.name.value , this.state.iconSearchTerm);
    this.setState({
      showIcons: true,
      isEditing: true,
      iconSearchTerm: formFields.name.value !== "" ? formFields.name.value :  this.state.iconSearchTerm,
    });

    this.searchTimer = setTimeout(this.performSearch, 200);
  }

  setIconUrl = (url) => { // eslint-disable-line
    utils.log(url);
    this.handleImageUploadFromUrl(url);
    return;

  }


  handleSearchChange = (text) => { // eslint-disable-line
    if (text !== '') {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(this.performSearch, 500);
    }

    this.setState({
      iconSearchTerm: text,
    })
  }

  performSearch = () => { // eslint-disable-line
    const { isPremium } = this.props;
    let text = this.state.iconSearchTerm;
    utils.log("Search Icon text:--", text);
    if (text !== '') {
      this.setState({
        isLoading: true
      });

      utils.searchIcons(text, isPremium).then(icons => {
        utils.log("Search Icon:--", icons);
        this.setState({
          icons: icons,
          isLoading: false
        })
      });
    }
  }

  onImageDrop = (files) => { // eslint-disable-line
    this.setState({
      uploadedFile: files[0]
    });

    this.handleImageUpload(files[0]);
  }


  handleImageUpload(file) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', file);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formFields = this.state.formFields;
        formFields.productUrl.value = response.body.secure_url;
        this.setState({
          formFields: formFields,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleImageUploadFromUrl(url) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', url);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formFields = this.state.formFields;
        formFields.productUrl.value = response.body.secure_url;
        this.setState({
          formFields: formFields,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleCloseIcons = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
    });
  }

  /** Check valid URL  */
  /*validURL(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    //let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    let regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let res = regexp.test(str);
    return res;
  }*/

  validURL(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    let id = str.indexOf("http");
    if (id == 0) {
      let reg = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/
      return reg.test(str)
    } else {
      return false;
    }

  }

  /**Check Preamble is started with In or From   */
  valildPreambleName(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    let regexp = /^(In|From).*$/;

    let res = regexp.test(str);
    return res;
  }


  /** check length of character for description  */
  maxLength(str, length) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    if (str.length <= length) {
      return true;
    } else {
      return false;
    }
  }

  isValidPrice(price){
    if (typeof price == 'undefined' || price == '') {
      return true;
    }
    let regexp = /^\d+(,\d{3})*(\.\d{1,2})?$/;

    let res = regexp.test(price);
    return res;
  }


  changeProductFrequency = (type) => { // eslint-disable-line
    let { formFields } = this.state;
    //console.log('changing frequency=', type);

    formFields.frequency.value = type;

    this.setState({
      formFields: formFields,
    });
  }


  handleClose = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
      isEditing: false,
    });
  }

  /* show required validatin  */
  renderRequiredValidation(input) {
    let state = this.state;
    //utils.log(state.formFields[input].value);
    if (state.isSubmitPost === true && (typeof state.formFields[input] === 'undefined' || state.formFields[input].value === '')) {
      return <p className="text-danger">This is required</p>
    }
  }


  renderButton() {
    if (this.state.showLoader == true) {
      return <a href="javascript:void(0)" className="btn btn-primary">{this.props.postAction}ing....</a>
    } else {
      return <button type="button" className="btn btn-primary" onClick={this.handleSaveChanges}>{this.props.postAction}</button>
    }
  }


  handleAddProductModal = () => { // Show product list
    $('#AddProductModal').modal('show');
  }

  render() {
    const { showIcons, icons, isLoading, iconSearchTerm, tab, formFields } = this.state;
    const { isPremium } = this.props;
    utils.log("Product URL:---", formFields);
    /* Set default Icon if value is empty */
    if (formFields.productUrl.value == null || formFields.productUrl.value === '') {
      formFields.productUrl.value = 'https://res.cloudinary.com/voiceapps/image/upload/v1531481073/ni90vyxlbqgmf7njf69v.png';
    }

    let frequencyName = 'One Time';
    if (formFields.frequency.value === 'ONETIME') {
      frequencyName = 'One Time';
    } else if (formFields.frequency.value === 'CONSUMABLE') {
      frequencyName = 'Consumable';
    } else if (formFields.frequency.value === 'MONTHLY') {
      frequencyName = 'Monthly';
    } else if (formFields.frequency.value === 'YEARLY') {
      frequencyName = 'Yearly';
    }

    return (
      <div className="modal fade slide" id="AddProductModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg-add" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">{this.props.postAction} Product</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <div className="container-fluid">

                {tab === 1 ?
                <div className="col col-12 post-page">
                  <div className="row">
                    <div className="col col-12">
                      <div className="form-group">
                        <label htmlFor="name">Name <span className="text-danger">*</span></label>
                        <span className="pi_counter">{formFields.name.value == null ? 0 : formFields.name.value.length}/50</span>
                        <input type="text" className="form-control" id="name" placeholder="Enter product name" value={formFields.name.value} onChange={evt => this.updateField('name', evt.target.value)} />
                        {this.renderRequiredValidation('name')}
                        {this.maxLength(formFields.name.value, 50) === false &&
                          <p className="text-danger">Name allowed only 50 characters.</p>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-6">
                      <div>
                        <div className="form-group">
                          <label htmlFor="price">Price (USD) <span className="text-danger">*</span></label>
                          <input type="text" className="form-control" id="price" placeholder="Enter price (USD)" value={formFields.price.value} onChange={evt => this.updateField('price', evt.target.value)} />
                          {this.renderRequiredValidation('price')}
                          {this.isValidPrice(formFields.price.value) === false &&
                          <p className="text-danger">Enter correct price.</p>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-6">
                      <label htmlFor="frequency">Payment Frequency <span className="text-danger">*</span></label>
                      <div className="dropdown na_inline row-spacer">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="productTypeDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {frequencyName}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="productTypeDropDown">
                          <a className="dropdown-item" href="#" onClick={() => this.changeProductFrequency('ONETIME')}>One Time</a>
                          <a className="dropdown-item" href="#" onClick={() => this.changeProductFrequency('CONSUMABLE')}>Consumable</a>
                          <a className="dropdown-item" href="#" onClick={() => this.changeProductFrequency('MONTHLY')}>Monthly</a>
                          <a className="dropdown-item" href="#" onClick={() => this.changeProductFrequency('YEARLY')}>Yearly</a>
                        </div>
                      </div>
                    </div>

                    {(formFields.frequency.value === 'MONTHLY' || formFields.frequency.value === 'YEARLY') &&
                      <div className="col col-6">
                        <div className="form-group multi-select">
                          <label htmlFor="days">Trial Period (Days)  <span className="text-danger">*</span></label>
                          <input type="number" className="form-control" id="days" placeholder="Enter number of days" value={formFields.days.value} onChange={evt => this.updateField('days', evt.target.value)} />
                          {this.renderRequiredValidation('days')}
                        </div>
                      </div>
                    }

                    {formFields.frequency.value === 'CONSUMABLE' &&
                      <div className="col col-6">
                        <div className="form-group multi-select">
                          <label htmlFor="days">Number of Units  <span className="text-danger">*</span></label>
                          <input type="number" className="form-control" id="days" placeholder="Enter number of consumable units" value={formFields.units.value} onChange={evt => this.updateField('units', evt.target.value)} />
                          {this.renderRequiredValidation('units')}
                        </div>
                      </div>
                    }
                  </div>

                  <div className="row">
                    <div className="col col-12">
                      <div className="form-group">
                        <label htmlFor="name">Purchase Prompt <span className="text-danger">*</span></label>
                        <span className="pi_counter">{formFields.purchasePrompt.value == null ? 0 : formFields.purchasePrompt.value.length}/160</span>
                        <textarea name="purchasePrompt" className="form-control" id="purchasePrompt" placeholder="Alexa will say this when prompting the user to buy this product." value={formFields.purchasePrompt.value}
                          onChange={evt => this.updateField('purchasePrompt', evt.target.value)} />
                        {this.renderRequiredValidation('purchasePrompt')}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-12">
                      <div className="form-group">
                        <label htmlFor="name">Bought Card Description <span className="text-danger">*</span></label>
                        <span className="pi_counter">{formFields.boughtCard.value == null ? 0 : formFields.boughtCard.value.length}/160</span>
                        <textarea name="boughtCard" className="form-control" id="boughtCard" placeholder="This will show on the Home Card that is sent to the user's Alexa app." value={formFields.boughtCard.value}
                          onChange={evt => this.updateField('boughtCard', evt.target.value)} />
                        {this.renderRequiredValidation('boughtCard')}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-12">
                      <div className="form-group">
                        <label htmlFor="name">Privacy Policy URL <span className="text-danger">*</span></label>
                        <span className="pi_counter">{formFields.privacyPolicyUrl.value == null ? 0 : formFields.privacyPolicyUrl.value.length}/50</span>
                        <input type="text" className="form-control" id="privacyPolicyUrl" placeholder="Enter product name" value={formFields.privacyPolicyUrl.value} onChange={evt => this.updateField('privacyPolicyUrl', evt.target.value)} />
                        {this.renderRequiredValidation('privacyPolicyUrl')}
                        {this.maxLength(formFields.privacyPolicyUrl.value, 50) === false &&
                          <p className="text-danger">privacyPolicyUrl allowed only 50 characters.</p>}
                      </div>
                    </div>
                  </div>

                </div> :
                <div className="col col-12 post-page">

                  <div className="form-group">
                    <label htmlFor="summary">Summary <span className="text-danger">*</span></label>
                    <span className="pi_counter">{formFields.description.value == null ? 0 : formFields.summary.value.length}/160</span>
                    <textarea name="summary" className="form-control" id="summary" placeholder="Enter summary" value={formFields.summary.value}
                      onChange={evt => this.updateField('summary', evt.target.value)} />
                    {this.renderRequiredValidation('summary')}
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Description <span className="text-danger">*</span></label>
                    <span className="pi_counter">{formFields.description.value == null ? 0 : formFields.description.value.length}/160</span>
                    <textarea name="description" className="form-control" id="description" placeholder="Enter description" value={formFields.description.value}
                      onChange={evt => this.updateField('description', evt.target.value)} />
                    {this.renderRequiredValidation('description')}
                    {this.maxLength(formFields.description.value, 160) === false &&
                      <p className="text-danger">Description allowed only 160 characters.</p>}
                  </div>

                  <div className="row">
                    <div className="col col-6">
                      <div>
                        <div className="form-group">
                          <label htmlFor="keywords">Keywords</label>
                          <input type="text" className="form-control" id="keywords" placeholder="Enter keywords" value={formFields.keywords.value} onChange={evt => this.updateField('keywords', evt.target.value)} />
                          {this.renderRequiredValidation('keywords')}
                        </div>
                      </div>
                    </div>
                    <div className="col col-6">
                      <div>
                        <div className="form-group">
                          <label htmlFor="icon">Icon</label>
                          <div className="edit_iconThunbnail" onClick={this.handleChangeIcon}>
                            <img className="iconThumbnail" src={formFields.productUrl.value} alt="icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col col-4">
                      <div className="form-group multi-select">
                        <label htmlFor="phrases">Example Phrases </label>
                        <input type="text" className="form-control" id="phrasesFirst" placeholder="Enter phrase first" value={formFields.phrasesFirst.value} onChange={evt => this.updateField('phrasesFirst', evt.target.value)} />
                        {this.renderRequiredValidation('phrasesFirst')}
                      </div>
                    </div>
                    <div className="col col-4 ">
                      <div className="form-group multi-select">
                        <label htmlFor="phrasesSecond"></label>
                        <input type="text" className="form-control" id="phrasesSecond" placeholder="Enter phrase second" value={formFields.phrasesSecond.value} onChange={evt => this.updateField('phrasesSecond', evt.target.value)} />
                      </div>
                    </div>
                    <div className="col col-4 ">
                      <div className="form-group multi-select">
                        <label htmlFor="phrasesThird"></label>
                        <input type="text" className="form-control" id="phrasesThird" placeholder="Enter phrase third" value={formFields.phrasesThird.value} onChange={evt => this.updateField('phrasesThird', evt.target.value)} />
                      </div>
                    </div>
                  </div>



                </div>
              }
              </div>

              <div className="modal-footer">
                <button type="button" className="btn m-b0 btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                {tab === 1 ?
                  <button type="button" className="btn btn-primary" onClick={this.handleNext}>Next</button>
                  :
                  <button type="button" className="btn btn-primary" onClick={this.handleSaveChanges}>{this.props.postAction}</button>
                }
              </div>
            </div>
          </div>
        </div>
        {showIcons ?
          <div className="edit_icons">
            <div className="edit_iconHeader">
              <div className="edit_iconHeaderTitle">Choose an Icon</div>
              <div className="edit_close" onClick={this.handleCloseIcons}><FA icon="times" /></div>
            </div>
            {isPremium && true ? null : <div className="edit_premium" onClick={this.handleUpgrade}>Upgrade your account to get premium icons!</div>}
            <div className="edit_searchIconContainer">
              <div className="edit_search">
                <input className="edit_searchBox" type="text" placeholder="Enter search terms" value={iconSearchTerm} onChange={evt => this.handleSearchChange(evt.target.value)} />
                <div className="edit_searchIcon"><FA icon="search" /></div>
              </div>
              <div className="edit_iconsScrollable">
                {icons.length > 0 ?
                  icons.map(function (icon, index) {
                    if (icon.raster_sizes.length < 9) {
                      return null;
                    }
                    let iconUrl = icon.raster_sizes[8].formats[0].preview_url;
                    return (
                      <div key={index} className="edit_iconContainer" onClick={() => this.setIconUrl(iconUrl)} title="Click to select this icon.">
                        <img className="edit_icon" src={iconUrl} alt="icon" />
                      </div>
                    )
                  }, this)
                  : (isLoading ?
                    <div className="edit_spinner">
                      <div className="edit_spinnerBox"><Spinner size={30} /></div>
                      <div className="edit_spinnerText">Loading Icons...</div>
                    </div> :
                    <div className="edit_iconMessage">Enter a search term above to search for icons.</div>
                  )
                }
              </div>

              <Dropzone
                onDrop={this.onImageDrop}
                accept="image/*"
                multiple={false}
                minSize={0}
                maxSize={5242880}
              >
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div className="edit_dropzone"{...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drop an image or click to select a file to upload.</p>

                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div> : null
        }
      </div>
    );
  }
}

export default AddProduct;
