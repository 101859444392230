import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
//import Footer from '../Footer';
import EditProject from '../EditProject';
import Spinner from '../Spinner';

import utils from '../helpers/utils.js';


class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showComplete: false
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentDidMount() {
    var that = this;
    utils.getCurrentUser().then(user => {
      if (user == null || user.userName == null) {
        that.goTo('login/checkout');
        utils.log('User == null');
      } else {
        let cbInstance = window.Chargebee.init({
            site: "voice-apps"
        });

        //var cbInstance = window.Chargebee.getInstance();

        //var cart = cbInstance.getCart();
        utils.log("user=", user);

        cbInstance.setCheckoutCallbacks(function(cart) {
          if (user.userName) {
            var customer = {first_name: user.firstName, last_name: user.lastName, email: user.userName, billing_address:{first_name: user.firstName, last_name: user.lastName, company: "", phone: "", address_line1: "", address_line2: ""}};
            cart.setCustomer(customer);
          }


            // you can define a custom callbacks based on cart object
            return {
                loaded: function() {
                    utils.log("checkout opened");
                },
                close: function() {
                    utils.log("checkout closed");
                    if (!that.state.showComplete) {
                      that.goTo('projects');
                    }
                },
                success: function(hostedPageId) {
                    utils.log("checkout success!!", hostedPageId);
                    window.fbq('trackSingle', '211310806174815', 'Purchase', {value: '19.95', currency: 'USD'});
                    window.ga('send', 'event', 'Upgraded', 'Sign Up', 'Paid', '19.95');
                    window.gtag_report_conversion(19.95);
                    utils.updateSubscription(hostedPageId).then(response => {
                      //utils.sendAffiliateData(hostedPageId, 'sale', 19.95).then(response=>{
                        that.setState({
                          orderId: hostedPageId,
                          showComplete: true
                        })
                      // }).catch(err => {
                      //   utils.log(err);
                      //   that.setState({
                      //     showComplete: true
                      //   })
                      // });
                    });

                },
                step: function(value) {
                    // value -> which step in checkout
                    utils.log(value);
                }
            }


        });


                  var checkout = document.getElementById('checkout');
                  checkout.click();
      }

      return null;
    }).catch(err => {
      utils.log(err);
      that.goTo('login/checkout');
    })
  }


  render() {
    const { showComplete, orderId } = this.state;

    let trackingNumber = orderId ? orderId : '';

    let affiliateUrl = `https://shareasale.com/sale.cfm?amount=19.95&tracking=${trackingNumber}&transtype=sale&merchantID=80286`;

    return (
      <div className="checkout_container">
        <HeaderSecure />
        {showComplete ?
          <div className="checkout_complete">
            <img src={affiliateUrl} width="1" height="1" />
            <div className="checkout_completeHeader">
              <span className="checkout_completeHeaderHighlight">Thank You</span>
              <span className="checkout_completeHeaderText"> For Upgrading!</span>
            </div>
            <div className="checkout_completeTagline">You are now ready to use Voice Apps Professional</div>
            <div className="checkout_return" onClick={() => this.goTo('projects')}>Back to Voice Apps</div>
          </div>
          :
          <a id="checkout" className="checkout_selectPlan" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="professional" >Select this plan</a>
        }
      </div>
    );
  }
}


export default Projects;
