var data = {
  "1" :[ // General Business Template
    {
      "key": "welcome",
      "name": "Welcome Message",
      "description": "Activated when users open your skill.",
      "type": "messageByVisit"
    },
    {
      "key": "phoneNumber",
      "name": "Phone Number",
      "description": "Activated when users ask about your phone number.",
      "type": "text"
    },
    {
      "key": "website",
      "name": "Website",
      "description": "Activated when users ask about your website.",
      "type": "text"
    },
    {
      "key": "socialMedia",
      "name": "Social Media",
      "description": "Activated when users ask about any of your social media handles.",
      "type": "text"
    },
    {
      "key": "services",
      "name": "Services",
      "description": "Activated when users ask about your services.",
      "type": "text"
    },
    {
      "key": "products",
      "name": "Products",
      "description": "Activated when users ask about your products.",
      "type": "text"
    },
    {
      "key": "deals",
      "name": "Coupons/Deals/Specials",
      "description": "Activated when users ask about your coupons, deals, or specials.",
      "type": "text"
    },
    {
      "key": "history",
      "name": "History",
      "description": "Activated when users ask about your history.",
      "type": "text"
    },
    {
      "key": "mission",
      "name": "Mission",
      "description": "Activated when users ask about your mission.",
      "type": "text"
    },
    {
      "key": "clients",
      "name": "Clients",
      "description": "Activated when users ask about your clients.",
      "type": "text"
    },
    {
      "key": "testimonials",
      "name": "Testimonials",
      "description": "Activated when users ask about your client testimonials.",
      "type": "text"
    },
    {
      "key": "location",
      "name": "Location",
      "description": "Activated when users ask about your location.",
      "type": "text"
    },
    {
      "key": "directions",
      "name": "Directions",
      "description": "Activated when users ask about directions to your business/event.",
      "type": "text"
    },
    {
      "key": "hours",
      "name": "Hours",
      "description": "Activated when users ask about your hours of operation.",
      "type": "text"
    },
    {
      "key": "employment",
      "name": "Employment",
      "description": "Activated when users ask about employment opportunities.",
      "type": "text"
    },
    {
      "key": "jokes",
      "name": "Jokes",
      "description": "Activated when users ask to hear a joke.",
      "type": "jokes"
    },
    {
      "key": "help",
      "name": "Help",
      "description": "Activated when users ask for help.",
      "type": "text"
    },
    {
      "key": "goodbye",
      "name": "Goodbye",
      "description": "Activated when users end the session.",
      "type": "text"
    }
  ],
  "2": [ // Real Estate
    {
      "key": "welcome",
      "name": "Welcome Message",
      "description": "Activated when users open your skill.",
      "type": "messageByVisit"
    },
    {
      "key": "phoneNumber",
      "name": "Phone Number",
      "description": "Activated when users ask about your phone number.",
      "type": "text"
    },
    {
      "key": "website",
      "name": "Website",
      "description": "Activated when users ask about your website.",
      "type": "text"
    },
    {
      "key": "socialMedia",
      "name": "Social Media",
      "description": "Activated when users ask about any of your social media handles.",
      "type": "text"
    },
    {
      "key": "howLong",
      "name": "Time in Business",
      "description": "Activated when users ask about how long you've been in busines.",
      "type": "text"
    },
    {
      "key": "listSalesRatio",
      "name": "List/Sale Price Ratio",
      "description": "Activated when users ask about your List price to Sales price ratio.",
      "type": "text"
    },
    {
      "key": "soloTeam",
      "name": "Solo or Team",
      "description": "Activated when users ask whether you work alone or as part of a team.",
      "type": "text"
    },
    {
      "key": "currentClients",
      "name": "Client Count",
      "description": "Activated when users ask about how many clients you are currently representing.",
      "type": "text"
    },
    {
      "key": "performance",
      "name": "Track Record",
      "description": "Activated when users ask about your performance history or track record.",
      "type": "text"
    },
    {
      "key": "buyerSellerClients",
      "name": "Buyer/Seller Ratio",
      "description": "Activated when users ask about the number of buyers vs the number of sellers you have.",
      "type": "text"
    },
    {
      "key": "neighborhoods",
      "name": "Coverage Area",
      "description": "Activated when users ask about what neighborhoods or areas you primarily cover.",
      "type": "text"
    },
    {
      "key": "fullPartTime",
      "name": "Full or Part Time",
      "description": "Activated when users ask about whether you work full or part-time as a real estate agent.",
      "type": "text"
    },
    {
      "key": "fees",
      "name": "My Fees",
      "description": "Activated when users ask about your fees and how they are calculated.",
      "type": "text"
    },
    {
      "key": "experience",
      "name": "My Experience",
      "description": "Activated when users ask about your relevant experience.",
      "type": "text"
    },
    {
      "key": "credentials",
      "name": "My Credentials",
      "description": "Activated when users ask about your certifications or credentials.",
      "type": "text"
    },
    {
      "key": "communication",
      "name": "Communication",
      "description": "Activated when users ask about how you will communicate with them.",
      "type": "text"
    },
    {
      "key": "areaOutlook",
      "name": "Area Outlook",
      "description": "Activated when users ask about how the local market is trending.",
      "type": "text"
    },
    {
      "key": "references",
      "name": "References",
      "description": "Activated when users ask about your references.",
      "type": "text"
    },
    {
      "key": "whyChooseMe",
      "name": "Why Choose Me",
      "description": "Activated when users ask why they should choose you as their Realtor.",
      "type": "text"
    },
    {
      "key": "jokes",
      "name": "Jokes",
      "description": "Activated when users ask to hear a joke.",
      "type": "jokes"
    },
    {
      "key": "help",
      "name": "Help",
      "description": "Activated when users ask for help.",
      "type": "text"
    },
    {
      "key": "goodbye",
      "name": "Goodbye",
      "description": "Activated when users end the session.",
      "type": "text"
    }
  ],
  "3": [
    {
      "key": "welcome",
      "name": "Welcome Message",
      "description": "Activated when users open your skill.",
      "type": "messageByVisit"
    },
    {
      "key": "facts",
      "name": "Facts",
      "description": "Activated when users ask your skill for a fact.",
      "type": "facts"
    },
    {
      "key": "help",
      "name": "Help",
      "description": "Activated when users ask for help.",
      "type": "text"
    },
    {
      "key": "goodbye",
      "name": "Goodbye",
      "description": "Activated when users end the session.",
      "type": "text"
    }
  ],
  "4": [
    {
      "key": "welcome",
      "name": "Welcome Message",
      "description": "Activated when users open your skill.",
      "type": "messageByVisit"
    },
    {
      "key": "facts",
      "name": "Facts",
      "description": "Activated when users ask your skill for a fact.",
      "type": "facts"
    },
    {
      "key": "help",
      "name": "Help",
      "description": "Activated when users ask for help.",
      "type": "text"
    },
    {
      "key": "goodbye",
      "name": "Goodbye",
      "description": "Activated when users end the session.",
      "type": "text"
    }
  ]
}

module.exports = data;
