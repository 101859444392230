import utils from '../../helpers/utils.js';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer, { defaultState as authState } from './reducers/auth';
import uploadReducer, { defaultState as uploadState } from './reducers/upload';


import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { persistReducer } from 'redux-persist'

export default () => {
	utils.log('Creating Store');
	const middleware = [thunk];

	let composeEnhancers = compose;
	if (process.env.NODE_ENV !== 'production') {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
	}

	const rootReducer = combineReducers({
		auth: authReducer,
		upload: uploadReducer,
	});

	const persistConfig = {
	  key: 'root',
	  storage,
	}

	const persistedReducer = persistReducer(persistConfig, rootReducer)

	const state = {
		auth: authState,
		upload: uploadState,
	};

	return createStore(persistedReducer, state, composeEnhancers(applyMiddleware(...middleware)));
};
