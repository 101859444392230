var data = [{
  "id": "raymond-johnston-marketing",
  "name": "Raymond Johnston Marketing",
  "invocationName": "raymond johnston marketing",
  "icon": "https://cdn.kwimer.com/voice-apps/icons/business/7.png",
  "template": 1,
  "description": "Attract more attention online. Get more customers. Boost your profits. We grow companies through social media and online advertising.",
  "defaultPrompt": "What would you like to do?",
  "defaultReprompt": "Is there anything else I can do for you?  Say Help to hear all the things you can ask me about.",
  "features": [
    {
      "key": "welcome",
      "messages": [
        {
          "visitCount": "*",
          "message": "Welcome to Raymond Johnston Marketing. To learn more about us and our business you can ask about things like our company history or our services.  Say Help to get a full list of things you can ask about.  What can I help you with?",
          "reprompt": "What can I help you with?",
          "audio": "logo1.mp3"
        }
      ]
    },
    {
      "key": "phoneNumber",
      "text": "Please call us at 1-850-555-1212.  We're happy to answer your questions and excited to begin working with your business.  Again, that's 1.850.555.1212.",
    },
    {
      "key": "website",
      "text": "You can visit us on the web at www.raymondjohnstonmarketing.com . Make sure you fill out the contact form and schedule your free consultation.",
    },
    {
      "key": "socialMedia",
      "text": "Come join the conversation with us on social media.  You can find our facebook page at raymond g.a. johnston",
    },
    {
      "key": "services",
      "text": "We dive into your industry, customers, and their buying behaviors to create a strategy that creates value and draws attention. Call us today to schedule a FREE consultation.  1.850.555.1212  You can ask me about things like our top clients or our mission.",
    },
    {
      "key": "history",
      "text": "I taught myself digital marketing and social media—and not just from books, webinars, and courses over 200+ hours. I took a job in marketing with a university/textbook/online learning company. Three years later, I'd worked my way up to social media manager and digital marketing specialist. By then, I knew how to compete in today's market. And the numbers showed it. Raymond Johnston Marketing was born!  To schedule a FREE consultation with Raymond and his team, call 1.850.555.1212 or visit us on the web at www dot raymond johnston marketing dot com.  You can ask me about things like client testimonials or our location.",
    },
    {
      "key": "mission",
      "text": "Our mission is to grow companies through social media and online advertising.  We're excited to work with companies just like yours.  Visit us on the web at www dot raymond johnston marketing dot com or call us at 1.850.555.1212 today.",
    },
    {
      "key": "clients",
      "text": "We've worked to grow businesses with as few as 100 to well over a million products. Some of our clients include Rage On, Karako Suits, Magnetic Me, Threading Water, and Voice Apps.  We'd love to add your business to our growing list of clients.  Call us today for a free consultation! You can ask me about things like our client testimonials or hours of operation.",
    },
    {
      "key": "testimonials",
      "text": "We've had some great feedback from our customers.  J. Rowland, Marketing Manager for Abeka said,<break time=\"500ms\"/>  Honesty. Integrity. Professionalism. That's what I've come to expect on the projects I've worked with Ray on. He continually meets and exceeds expectations with clear vision and drive that get's the job on schedule.  <break time=\"1s\"/> We know you'll have a great experience with us too!  Call us today to get started!",
    },
    {
      "key": "location",
      "text": "Our offices are located at 40 West Nine Mile Rd Suite #2, Pensacola, FL 32534.  You can ask me about things like our hours of operation or our website.",
    },
    {
      "key": "hours",
      "text": "Our hours are Monday through Friday, 8am to 6:30pm. Saturday, 9am to 3pm. And closed on Sunday. You can ask me about things like our phone number or location.",
    },
    {
      "key": "employment",
      "text": "We are always looking for top talent to join our team.  We are actively looking for a graphics designer and a copywriter.  If you are interested in one of these positions or feel you would be a great addtion to our team, please send your resume to jobs at raymond johnston marketing dot com.  You can ask about things like our history or ask me to tell you a joke.",
    },
    {
      "key": "jokes",
      "jokes": [
        {
          "question": "How many marketers does it take to screw in a light bulb?",
          "answer": "None.  They've automated it!"
        },
        {
          "question": "What does the new Chips Ahoy marketing director do her first day on the job?",
          "answer": "Enable Cookies"
        },
        {
          "question": "Why did the naughty lead's phone ring at 1 a.m.?",
          "answer": "For a booty call-to-action."
        },
        {
          "question": "What's a personality trait of a bad marketer?",
          "answer": "Anti-social"
        },
        {
          "question": "Why did the marketer get off the trampoline?",
          "answer": "He was worried about his bounce rate."
        },
        {
          "question": "Why can't a lead date a religious marketer?",
          "answer": "Because she'll always be trying to convert him."
        },
        {
          "question": "A stock photo walks into a bar, and the patrons start pointing and giggling. She looks at the bartender and says, \"Why's everyone staring?\" The bartender says ...",
          "answer": "Cuz your ALT tag is showing."
        },
        {
          "question": "Why did the marketing couple decide not to get married?",
          "answer": "Because they weren't on the same landing page."
        }
      ]
    },
    {
      "key": "help",
      "text": "You can ask about our services, company history, our mission, location, hours of operation, clients, testimonials, and open jobs. You can also get our website, phone number, and social media handles.  For a little fun, ask me for a joke.  What would you like to know?",
    },
    {
      "key": "goodbye",
      "text": "Thanks for visiting.  For more information on our services, visit us on the web at www dot raymond johnston marketing dot com.  Have a great day!",
    }
  ]
}];

module.exports = data;
