import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import Multiselect from 'react-bootstrap-multiselect';
import MultiText from '../MultiText';
import Select from 'react-select';

class Trigger extends Component {
  constructor(props) {
    super(props);

    //console.log('-----Trigger VAriables----');
    //console.log(props);
    this.state = {
      isDuplicateIntent: props.data.isDuplicateIntent,
      duplicateIntentName: props.data.duplicateIntentName,
      triggeredBy: props.data.triggeredBy,
      utterances: props.data.sampleUtterances,
      intents: props.intents,
      activatedBy: props.data.activatedBy,
      scope: props.data.scope ? props.data.scope : '',
      selectedUtteranceIndex: null,
      utterancesChanged: false
    };
  }

  componentWillReceiveProps(props) {
    //console.log('-----Trigger VAriables WR----');
    //console.log(props);
    this.setState({
      isDuplicateIntent: props.data.isDuplicateIntent,
      duplicateIntentName: props.data.duplicateIntentName,
      triggeredBy: props.data.triggeredBy,
      utterances: props.data.sampleUtterances,
      intents: props.intents,
      activatedBy: props.data.activatedBy,
      scope: props.data.scope ? props.data.scope : '',
      selectedUtteranceIndex: null,
      utterancesChanged: false
    });

    if (this.activatedBy && this.activatedBy.syncData) {
      this.activatedBy.syncData();
    }
  }

  handleUtteranceKeyPress = (e) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      utils.log('got here');
        this.addUtterance(e.target.value);
        e.target.value = '';
    }
  }

  handleUtteranceEditKeyPress = (e, index) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      let { utterances } = this.state;

      utterances[index] = e.target.value;

      this.setState({
        utterances,
        selectedUtteranceIndex: null
      });
    }
  }

  addUtterance = (text) => { // eslint-disable-line
    utils.log(text);
    let { isDuplicateIntent, duplicateIntentName, utterances, activatedBy, triggeredBy, scope } = this.state;

    utterances.push(text);

    this.setState({
      utterances: utterances,
      utterancesChanged: true
    });

    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent,
        duplicateIntentName,
        sampleUtterances: utterances,
        activatedBy,
        triggeredBy,
        scope,
        utterancesChanged: true
      });
    }
  }

  selectUtterance = (index) => { // eslint-disable-line
    this.setState({
      selectedUtteranceIndex: index
    });

    setTimeout(() => {
      let element = document.getElementById('tu_' + index);
      element.select();
      var val = element.value; //store the value of the element
      element.value = ''; //clear the value of the element
      element.value = val; //set that value back.
    }, 200);
  }

  handleUtteranceDelete = (e, index) => { // eslint-disable-line
    let { isDuplicateIntent, duplicateIntentName, utterances, activatedBy, triggeredBy, scope } = this.state;

    utterances.splice(index, 1);

    this.setState({
      utterances,
      utterancesChanged: true
    });

    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent,
        duplicateIntentName,
        sampleUtterances: utterances,
        activatedBy,
        triggeredBy,
        scope,
        utterancesChanged: true
      });
    }

    e.preventDefault();
    e.stopPropagation();
  }

  handleSampleUtteranceChange = (utterances) => { // eslint-disable-line
    let {isDuplicateIntent, duplicateIntentName, activatedBy, triggeredBy, scope} = this.state;

    this.setState({
      utterances,
      utterancesChanged: true
    });


    utils.log("utterances changed=", utterances);
    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent,
        duplicateIntentName,
        sampleUtterances: utterances,
        activatedBy,
        triggeredBy,
        scope,
        utterancesChanged: true
      });
    }
  }

  changeTriggeredBy = (id) => { // eslint-disable-line
    const { isDuplicateIntent, duplicateIntentName, utterances, activatedBy, scope} = this.state;
    this.setState({
      triggeredBy: id,
    })

    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent,
        duplicateIntentName,
        triggeredBy: id,
        sampleUtterances: utterances,
        activatedBy: activatedBy,
        scope
      })
    }
  }

  handleActivatedByChange = (element, checked) => { // eslint-disable-line
    utils.log("element:", element[0]);
    utils.log('value=', checked);

    let { isDuplicateIntent, duplicateIntentName, activatedBy, utterances, triggeredBy, scope } = this.state;

    if (activatedBy == null) activatedBy = [];

    // If it was checked, add it to the list
    if (checked.action === 'select-option') {
      if (!activatedBy.includes(checked.option.value)) {
        activatedBy.push(checked.option.value);
      }
    } else if (checked.action === 'remove-value') {
      let newActivatedBy = activatedBy.filter(l => l !== checked.removedValue.value);
      utils.log("newActivatedBy=", newActivatedBy);

      activatedBy = newActivatedBy;
    } else if (checked.action === 'clear') {
      activatedBy = [];
    }

    this.setState({
      activatedBy
    });

    if (this.props.onChange) {
      this.props.onChange({
        duplicateIntentName,
        isDuplicateIntent: isDuplicateIntent,
        triggeredBy: triggeredBy,
        sampleUtterances: utterances,
        activatedBy: activatedBy,
        scope
      })
    }

  }

  handleIsDuplicateIntentChange = (value) => { // eslint-disable-line

    let { isDuplicateIntent, duplicateIntentName, activatedBy, utterances, triggeredBy, scope } = this.state;

    isDuplicateIntent = value;

    this.setState({
      isDuplicateIntent
    });

    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent: isDuplicateIntent,
        duplicateIntentName,
        triggeredBy: triggeredBy,
        sampleUtterances: utterances,
        activatedBy: activatedBy,
        scope,
      })
    }

  }

  changeDuplicateIntent = (value) => {
    let { isDuplicateIntent, duplicateIntentName, activatedBy, utterances, triggeredBy, scope } = this.state;

    utils.log('Duplicate Intent =', value);
    duplicateIntentName = value;

    this.setState({
      duplicateIntentName
    });

    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent: isDuplicateIntent,
        duplicateIntentName,
        triggeredBy: triggeredBy,
        sampleUtterances: utterances,
        activatedBy: activatedBy,
        scope,
      })
    }
  }

  handleScopeChange = (value) => {
    let { isDuplicateIntent, duplicateIntentName, activatedBy, utterances, triggeredBy, scope } = this.state;

    scope = value;

    this.setState({
      scope,
    });

    //this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange({
        isDuplicateIntent: isDuplicateIntent,
        duplicateIntentName,
        triggeredBy: triggeredBy,
        sampleUtterances: utterances,
        activatedBy: activatedBy,
        scope,
      })
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { utterances, isDuplicateIntent, triggeredBy, activatedBy, intents, scope} = this.state;
    let { duplicateIntentName } = this.state;
    const { isSecondary } = this.props;

    utils.log("Rendering Utterances:", utterances);
    utils.log("activatedBy", activatedBy);

    let availableIntents = intents.map((intent) => {
      return {
        value: intent.id,
        label: intent.name,
        selected: activatedBy ? activatedBy.includes(intent.id) : false
      };
    });

    let none = [{
      value: 0,
      label: 'None',
      selected: true
    }];
    none=[];

    if (duplicateIntentName == null) {
      duplicateIntentName = 'None';
    }
    let selectableIntents = none.concat(availableIntents);

    let options = [];

    availableIntents.forEach(intent => {
      if (activatedBy && activatedBy.includes(intent.value)) {
        options.push(intent);
      }
    })

    //console.log(options);

    utils.log('AvailableIntents', availableIntents);


    let triggeredByText = '';
    if (triggeredBy === 'user') triggeredByText = 'What the user says';
    if (triggeredBy === 'help') triggeredByText = 'When the user says "Help"';
    if (triggeredBy === 'yes') triggeredByText = 'When the user says "Yes"';
    if (triggeredBy === 'no') triggeredByText = 'When the user says "No"';
    if (triggeredBy === 'anything') triggeredByText = 'When the user says anything';

    if (triggeredBy === 'intent') triggeredByText = 'Another intent';
    if (triggeredBy === 'launch') triggeredByText = 'When the skill is launched';
    if (triggeredBy === 'next') triggeredByText = 'When the user says "Next"';
    if (triggeredBy === 'previous') triggeredByText = 'When the user says "Previous"';
    if (triggeredBy === 'repeat') triggeredByText = 'When the user says "Repeat"';
    if (triggeredBy === 'stop') triggeredByText = 'When the user says "Stop"';
    if (triggeredBy === 'resume') triggeredByText = 'When the user says "Resume"';
    if (triggeredBy === 'startover') triggeredByText = 'When the user says "Start Over"';
    if (triggeredBy === 'almostfinished') triggeredByText = 'By Audio Player "Almost Finished"';

    if (triggeredBy === 'buyAccepted') triggeredByText = 'In-skill Product Buy - Accept';
    if (triggeredBy === 'buyDeclined') triggeredByText = 'In-skill Product Buy - Decline';
    if (triggeredBy === 'buyAlreadyPurchased') triggeredByText = 'In-skill Product Buy - Already Purchased';

    if (triggeredBy === 'cancelAccepted') triggeredByText = 'In-skill Product Cancel - Accept';
    if (triggeredBy === 'cancelDeclined') triggeredByText = 'In-skill Product Cancel - Decline';
    if (triggeredBy === 'cancelNotEntitled') triggeredByText = 'In-skill Product Cancel - Not Entitled';

    return (
      <div className="intent_card">
        <div className="intent_cardHeader">Intent Activation</div>
        {isSecondary ? <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div> : null }
        <div className="form-group">
          <div className="trigger_triggeredLabel">This intent is triggered by</div>
          {isSecondary ?
            <span> by what the user says</span>
            :
            <div className="dropdown trigger_inline">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {triggeredByText}
              </button>

              {triggeredBy === 'launch' ?
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('launch')}>When the skill is launched</a>
                </div>
                :
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('user')}>What the User says</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('help')}>When the user says "Help"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('yes')}>When the user says "Yes"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('no')}>When the user says "No"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('next')}>When the user says "Next"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('previous')}>When the user says "Previous"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('repeat')}>When the user says "Repeat"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('stop')}>When the user says "Stop"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('resume')}>When the user says "Resume"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('startover')}>When the user says "Start Over"</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('anything')}>When the user says anything</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('intent')}>Another Intent</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('almostfinished')}>Audio Player "Almost Finished"</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('buyAccepted')}>In-skill Product Buy - Accept</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('buyDeclined')}>In-skill Product Buy - Decline</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('buyAlreadyPurchased')}>In-skill Product Buy - Already Purchased</a>

                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('cancelAccepted')}>In-skill Product Cancel - Accept</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('cancelDeclined')}>In-skill Product Cancel - Decline</a>
                  <a className="dropdown-item" href="#" onClick={() => this.changeTriggeredBy('cancelNotEntitled')}>In-skill Product Cancel - Not Entitled</a>

                </div>
              }
            </div>
          }
          <br />
          {(triggeredBy === 'yes' || triggeredBy === 'no' || triggeredBy === 'anything') ?
            <div className="form-group">
              <MultiText
                header="(Optional) Additional things the user might say..."
                inputPlaceHolder="Enter an example of what the user might say."
                data={utterances}
                variables={this.props.variables}
                onChange={this.handleSampleUtteranceChange}
              />
            </div>: <br />
          }
          {triggeredBy === 'user' ?
            <div className="form-group">
              <br />
              <input type="checkbox" value={isDuplicateIntent} checked={isDuplicateIntent} name="isDuplicateIntent" onChange={evt => this.handleIsDuplicateIntentChange(evt.target.checked)}/> <span className="edit_synchEnglishLocales">This is a duplicate Intent</span>
              {!isDuplicateIntent ?
              <MultiText
                id="trig_utterance"
                header="The user can say things like..."
                inputPlaceHolder="Enter an example of what the user might say."
                inputPlaceHolder2="Enter another way the user might say the same thing."
                inputPlaceHolder3="Enter more examples for better recognition."
                data={utterances}
                variables={this.props.variables}
                allowVariables={true}
                onChange={this.handleSampleUtteranceChange}
              /> :
              <div>
                <br/>
                <div className="trigger_triggeredLabel">Duplicate intent:</div>
                <div className="dropdown trigger_inline">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="duplicateIntent" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {duplicateIntentName}
                  </button>
                  <div className="dropdown-menu trigger_dropdown" aria-labelledby="dropdownMenuButton">
                    {selectableIntents.map((intent, index) => {
                      return (
                        <a key={index} className="dropdown-item" href="#" onClick={() => this.changeDuplicateIntent(intent.label)}>{intent.label}</a>
                      )
                    })}
                  </div>
                </div>
              </div>
              }
            </div>: <br />
          }
        </div>
        {triggeredBy !== 'next' && triggeredBy !== 'previous' ?
          <div className="form-group">
            <div className="trigger_triggeredLabel">And is only activated by</div>
            <div className="trigger_multiselect">
            <Select
              placeholder="Any Intent"
              isMulti
              name="colors"
              value={options}
              options={availableIntents}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={this.handleActivatedByChange}
            />
              {/*<Multiselect
                onChange={this.handleActivatedByChange}
                ref={(input) => { this.activatedBy = input; }}
                data={availableIntents}
                multiple
                nonSelectedText="Any Intent"
              />*/}
            </div>
          </div> : null
        }

        {triggeredBy !== 'next' && triggeredBy !== 'previous' ?
          <div className="form-group">
            <div className="trigger_triggeredLabel">Or is within this scope:</div>
            <input className={"form-control trigger_input"} placeholder="" type="text" value={scope} onChange={(event) => this.handleScopeChange(event.target.value)} />
          </div> : null
        }
        </div>
    );
  }
}

export default Trigger;
