import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import FA from '@fortawesome/react-fontawesome';
import './styles.css';

import VariablePicker from '../VariablePicker';
import Expression from '../Expression';


class SetState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data
    };
  }

  componentWillReceiveProps(props) {
    utils.log("received props=", props);

    this.setState({
      data: props.data
    });
  }

  componentDidMount() {
  }

  handleTargetVariableChange = (target) => {
    //console.log("___________HANDLE TARGET VARIABLE CHANGE___________");
    //console.log(target);
    let { data } = this.state;

    data.targetVariable = target;

    this.setState({
      data
    })

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  handleExpressionChange = (result) => {
    //console.log("----------Handle Expression Change----------");
    //console.log(result);

    let { data } = this.state;

    data.expression = result.expression;
    data.calculatedExpression = result.calculatedExpression;

    this.setState({
      data
    })

    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    let { data } = this.state;
    let { variables } = this.props;

    let expression = {
      type: "expression",
      value: {
        left: {
          type: "value",
          id: utils.guid(),
          value: ""
        }
      }
    }

    data.expression = data.expression ? data.expression : expression;

    return (
      <div className="intent_card" >
        <div className="intent_cardHeader">Set State</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>

        <div className="setstate_set_block">
          <div className="setstate_label">Set </div>
          <div className="setstate_picker"><VariablePicker initialValue={data.targetVariable} variables={variables} onChange={this.handleTargetVariableChange}/></div>
          <div className="setstate_label"> to</div>
        </div>
        <Expression when={data} variables={variables} showEquation={false} onChange={this.handleExpressionChange}/>

        {/*<a className="ss_learn" target="_blank" href="https://help.voiceapps.com/skill-building/set-state">Learn how to use this feature</a>*/}
      </div>
    );
  }
}

export default SetState;
