import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SelectFiles from '../components/SelectFiles';
import ConversionOptions from '../components/ConversionOptions';
import Uploading from '../components/Uploading';

import { resetFiles } from '../redux/actions/upload';

const STEP_SELECT = 'select';
const STEP_CONVERT = 'convert';
const STEP_UPLOAD = 'upload';

class Upload extends PureComponent {

	constructor(props) {
		super(props);

		const self = this;

		self.state = {
			step: STEP_SELECT,
		};

		self.onPrevClick = self.handlePrevClick.bind( self );
		self.onNextClick = self.handleNextClick.bind( self );
	}

	componentWillMount() {
		this.props.reset();
	}

	handlePrevClick(e) {
		const self = this;
		const { step } = self.state;

		e.preventDefault();
		e.stopPropagation();

		let prev = false;
		if (step === STEP_UPLOAD) {
			prev = STEP_CONVERT;
		} else if (step === STEP_CONVERT) {
			prev = STEP_SELECT;
		}

		if (prev) {
			self.setState({ step: prev });
		}
	}

	handleNextClick(e) {
		const self = this;
		const { step } = self.state;
		const { files } = self.props;

		e.preventDefault();
		e.stopPropagation();

		let next = false;
		if (step === STEP_SELECT) {
			if (!files || !files.length) {
				alert('Please, select at least one file to upload.');
			} else {
				next = STEP_CONVERT;
			}
		} else if (step === STEP_CONVERT) {
			next = STEP_UPLOAD;
		}

		if (next) {
			self.setState({ step: next });
		}
	}

	getScreen() {
		const self = this;
		const { step } = self.state;
		const { files, user, uploaded, failed } = self.props;

		switch (step) {
			case STEP_SELECT:
				return <SelectFiles files={files} />;
			case STEP_CONVERT:
				return <ConversionOptions />;
			case STEP_UPLOAD:
				return <Uploading user={user} files={files} uploaded={uploaded} failed={failed}/>;
		}

		return false;
	}

	getNextStepLink() {
		const self = this;
		const { step } = self.state;

		if (step !== STEP_UPLOAD) {
			return (
				<li className="page-item page-next">
					<a href="#" onClick={self.onNextClick}>
						<div className="page-item-subtitle">Next</div>
						<div className="page-item-title h5">
							{step === STEP_CONVERT ? 'Upload Files' : 'Conversion Options'}
						</div>
					</a>
				</li>
			);
		}

		return false;
	}

	getPrevStepLink() {
		const self = this;
		const { step } = self.state;

		if (step === STEP_CONVERT) {
			return (
				<li className="page-item page-prev">
					<a href="#" onClick={self.onPrevClick}>
						<div className="page-item-subtitle">Previous</div>
						<div className="page-item-title h5">Select Files</div>
					</a>
				</li>
			);
		}

		return false;
	}

	handleDashboardClick = () => {
		this.props.history.replace(`/hosting`)
    return false;
	}

	render() {
		const self = this;
		const { user } = self.props;
		const { step } = self.state;

		return (
			<div>
				{user === false &&
					<div className="alert alert-warning">
						You are not currently signed in.  You can still upload files, but you won&apos;t be able to view or manage them later.  Sign-in above to save and view your uploads.
					</div>
				}
				<div className="dashboard_header">
					<h1>Upload Files</h1>
					<button onClick={this.handleDashboardClick} className="btn btn-primary dashboard_upload" >Dashboard</button>
				</div>

				<ul className="step">
					<li className={`step-item${step === STEP_SELECT ? ' active' : ''}`}>
						<a>Select Files</a>
					</li>
					<li className={`step-item${step === STEP_CONVERT ? ' active' : ''}`}>
						<a>Convert</a>
					</li>
					<li className={`step-item${step === STEP_UPLOAD ? ' active' : ''}`}>
						<a>Upload Files</a>
					</li>
				</ul>

				{self.getScreen()}

				<ul className="paging">
					{self.getPrevStepLink()}
					{self.getNextStepLink()}
				</ul>
			</div>
		);
	}

}

Upload.propTypes = {
	user: PropTypes.string.isRequired,
	files: PropTypes.arrayOf(PropTypes.object).isRequired,
	uploaded: PropTypes.shape({}).isRequired,
	failed: PropTypes.shape({}).isRequired
};

const mapStateToProps = ({ auth, upload }) => ({
	user: auth.user,
	files: upload.files,
	uploaded: upload.uploaded,
	failed: upload.failed,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ reset: resetFiles }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
