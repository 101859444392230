import React, { Component } from 'react';
import utils from '../../helpers/utils.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Bluebird from 'bluebird';

import CopyToClipboard from './CopyToClipboard';

import { uploadFile } from '../redux/actions/upload';
import { formatDate } from '../library/time';
import { getFileKey } from '../library/file';
import { getCdnUrl } from '../library/cdn';


import FA from '@fortawesome/react-fontawesome';

class Uploading extends Component {

	constructor(props) {
		super(props);

		const self = this;
		self.getTile = self.getTile.bind(self);
	}

	componentDidMount() {
		const self = this;
		const { user, files, upload, imageOptions, audioOptions } = self.props;

		//console.log("imageOptions", imageOptions);
		// files.forEach((file) => {
		// 	upload(user, file, { image: imageOptions, audio: audioOptions });
		// });
		Bluebird.map(files, (file) => {
			return this.uploadFile(upload, user, file, { image: imageOptions, audio: audioOptions })
				.catch(err => {
					utils.log(err);
				});
		}, {concurrency: 5});
	}

	uploadFile(upload, user, file, options) {
		return new Promise(function (resolve, reject) {
			return upload(user, file, options)
				.then(response => {
					utils.log("uploadFile Promise Resolved")
					resolve(response);
				})
				.catch(err => {
					utils.log("uploadFile Promise Rejected");
					reject(err);
				});
		});
	}

	getTile(file) {
		const self = this;
		const { uploaded, failed } = self.props;
		const { name, size, type, lastModified } = file;

		const kb = 1 << 10;
		const mb = 1 << 20;

		const key = getFileKey(file);
		const sizeLabel = size < mb ? `${Math.floor(size / kb)}KB` : `${(size / mb).toFixed(1)}MB`;
		const date = formatDate(lastModified);

		//utils.log('failed=', failed);
		//utils.log('key=', key);
		//utils.log('file=', file);

		const icon = uploaded[key] ? <i className="icon icon-check" /> : (failed[key] ? <FA className="upload_failure" icon="file-exclamation" /> : <FA className="upload_spinner" icon="spinner" />);

		const actions = [];
		if (uploaded[key]) {
			Object.keys(uploaded[key]).forEach((id) => {
				const url = `${getCdnUrl()}/${id}`;

				actions.push(
					<a key={id} href={url} className="btn btn-link" target="_blank" rel="noopener noreferrer">
						{uploaded[key][id] && uploaded[key][id].length ? uploaded[key][id] : 'original'}
					</a>
				);

				actions.push(
					<CopyToClipboard key={`${id}-copy`} text={url} />
				);
			});
		}

		return (
			<div key={key} className="tile tile-centered my-2">
				<div className="tile-icon mr-2">
					{icon}
				</div>

				<div className="tile-content">
					<div className="tile-title">{name}</div>
					<div className="tile-subtitle text-gray">
						{`${sizeLabel} · ${type} · ${date}`}
					</div>
				</div>

				<div className="tile-action">
					{actions}
				</div>
			</div>
		);
	}

	render() {
		const self = this;
		const { files } = self.props;
		const tiles = files.map(self.getTile);

		return (
			<div>
				{tiles}
				<div className="upload_instructions">
					Your file uploads are listed above.  Click the <span className="clipboardCopy2"title="Copy to Clipboard"><FA icon={["far", "link"]} /></span> icon to copy the URL of the file to your clipboard.
				</div>
			</div>
		);
	}

}

Uploading.propTypes = {
	user: PropTypes.string.isRequired,
	files: PropTypes.arrayOf(PropTypes.object).isRequired,
	uploaded: PropTypes.shape({}).isRequired,
	failed: PropTypes.shape({}).isRequired,
	upload: PropTypes.func.isRequired,
	imageOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
	audioOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ upload }) => ({
	imageOptions: upload.imageOptions,
	audioOptions: upload.audioOptions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ upload: uploadFile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Uploading);
