import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Header from '../Header';
// import Footer from '../Footer';
import FA from '@fortawesome/react-fontawesome';
import GoogleLogin from 'react-google-login';


import utils from '../helpers/utils.js';

import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../candlewick/redux/actions/auth';
import { logout } from '../candlewick/redux/actions/auth';

// import sampleProject from './sampleData.js';

var AWS = require("aws-sdk");
AWS.config.setPromisesDependency(require('bluebird'));

// import appConfig from "./config";
// import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRegisterForm: true,
      formFields: {
        firstName: {isValid: true, value: ''},
        lastName: {isValid: true, value: ''},
        email: {isValid: true, value: ''},
        password: {isValid: true, value: ''},
        passwordConfirm: {isValid: true, value: ''},
      },
      errorMessage: '',
      showForgotPassword: false,
      showForgotPasswordSent: false,
      nextPage: this.props.match.params.nextpage
    }

  }

  componentDidMount() {
    utils.log(this.props);
    utils.log("nextpage", this.props.match.params.nextpage);

    this.setState({
      nextPage: this.props.match.params.nextpage
    })

    window.Intercom("boot", {
      app_id: "rxv0p3l3",
    });
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login = (e) => {
    const { formFields, nextPage } = this.state;

    var that = this;
    utils.login(formFields.email.value, formFields.password.value).then(user => {
      utils.log('User=', user);
      that.props.login(user.user_id);
      window.Intercom('update', {
        "email": formFields.email.value,
        "user_id": user.user_id
      });
      utils.log('login success');
      if (nextPage) {
        that.goTo(nextPage);
      } else {
        that.goTo('projects');
      }
      return null;

    }).catch(err => {
      utils.log('login failure');
      that.setState({
        errorMessage: 'Invalid Email or Password'
      })
    });

    e.preventDefault();
    e.stopPropagation();
  }

  logout() {
    this.props.auth.logout();
    this.props.logout();
  }

  register = (e) => {
    const { formFields, nextPage } = this.state;

    if (this.validateRegisterForm()) {
      var that = this;
      utils.register(formFields.email.value, formFields.password.value).then(result => {
        utils.log('success', result);
        window.fbq('trackSingle', '211310806174815', 'CompleteRegistration');
        window.ga('send', 'event', 'Registration', 'Sign Up', 'New Customer');
        window.gtag_report_conversion(10);
        let user = {
          userName: formFields.email.value,
          firstName: formFields.firstName.value,
          lastName: formFields.lastName.value,
          profileImage: ''
        };
        utils.getGeolocationInfo().then(ipInfo => {
          utils.sendProofEvent('registration', {
            "type": "custom",
            "email": user.userName,
            "first_name": user.firstName,
            "city": ipInfo.city,
            "state": ipInfo.region,
            "country": ipInfo.country,

            "ip": ipInfo.query
          }).catch(err => {
            console.error(err);
          });
        }).catch(err => {
          console.error(err);
        });
        utils.createUser(user).then(result => {
          //utils.createProject(result, sampleProject
          utils.addToList(user).then(result => {
            if (nextPage) {
              that.goTo(nextPage);
            } else {
              that.goTo('projects');
            }
          }).catch(err => {
            utils.log(err);
            if (nextPage) {
              that.goTo(nextPage);
            } else {
              that.goTo('projects');
            }
          })

        }).catch(err => {
          utils.log(err);
        })
        return null;
      }).catch(err => {
        utils.log('failure', err);
        that.setState({
          errorMessage: err
        })
      });

    }

    e.preventDefault();
    e.stopPropagation();
  }

  validateRegisterForm() {
    const { formFields } = this.state;

    let errorMessage = '';

    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    formFields.email.isValid = emailRegex.test(formFields.email.value);

    // eslint-disable-next-line
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    formFields.password.isValid = strongRegex.test(formFields.password.value);

    formFields.passwordConfirm.isValid = formFields.password.value === formFields.passwordConfirm.value;

    formFields.firstName.isValid = formFields.firstName.value !== '';
    formFields.lastName.isValid = formFields.lastName.value !== '';

    this.setState({
      formFields
    });

    return formFields.email.isValid && formFields.password.isValid && formFields.passwordConfirm.isValid;
  }

  handleRegisterClick = () => {
    this.setState({
      showRegisterForm: !this.state.showRegisterForm,
      formFields: {
        firstName: {isValid: true, value: ''},
        lastName: {isValid: true, value: ''},
        email: {isValid: true, value: ''},
        password: {isValid: true, value: ''},
        passwordConfirm: {isValid: true, value: ''},
      },
      errorMessage: ''
    });
  }

  updateField = (field, value) => {
    let formFields = this.state.formFields;

    formFields[field].value = value;

    this.setState({
      formFields,
      errorMessage: ''
    });
  }

  responseGoogle = (authResult) => {
    const {nextPage} = this.state;

    utils.log('google authresult=', authResult);

    if (authResult.tokenId) {
      let user = {
        userName: authResult.profileObj.email,
        firstName: authResult.profileObj.givenName,
        lastName: authResult.profileObj.familyName,
        profileImage: authResult.profileObj.imageUrl
      };
      let that = this;
      utils.googleLogin(authResult.tokenObj.id_token, user).then(response => {
        that.props.login(response.user_id);
        utils.log(response);

        if (nextPage) {
          this.goTo(nextPage);
        } else {
          this.goTo('projects');
        }

      });


    } else if (authResult.code) {
      let hostname = window.location.hostname;
      utils.log(hostname);
      let isTest = hostname === 'localhost';
      utils.getGoogleToken(authResult.code, isTest).then(response => {
        utils.log(response);
        let user = response.user;
        let that = this;
        utils.googleLogin(response.token, user).then(response => {
          that.props.login(response.user_id);
          utils.log(response);

          //window.setInterval(utils.getCurrentUser, 10000);
          if (nextPage) {
            this.goTo(nextPage);
          } else {
            this.goTo('projects');
          }
          return;
        }).catch(err => {

        });
      });
    }
  }

  handleForgotPassword = () => {
    this.setState({
      showForgotPassword: true
    });
  }

  handleResetPassword = () => {
    const { formFields } = this.state;

    utils.setCookie('email', formFields['email'].value, 1);
    utils.forgotPassword(formFields['email'].value);

    this.setState({
      showForgotPasswordSent: true
    })

  }

  render() {
    const { showRegisterForm, formFields, errorMessage, showForgotPassword, showForgotPasswordSent, nextPage } = this.state;

    const toggleText = showRegisterForm ? 'Login' : 'Register';

    utils.log("next page=", nextPage);
    return (
      <div className="login_container">
        <Header auth={this.props.auth} history={this.props.history}/>
        {nextPage === "business" && <div className="business_header">Signup below to begin building your Business Skill</div>}
        {nextPage === "business" && <div className="business_subHeader">(It's FREE!  No credit card required!)</div>}
        {nextPage == null && <div className="business_header">Sign-in or Register Below</div>}
        <div className="login_body">
        {showForgotPassword ?
          (showForgotPasswordSent ?
          <div className="login_forgotPasswordSent">
            <div className="login_forgotPasswordSentTitle">You&apos;ve got mail!</div>
            <div className="login_forgotPasswordSentText">Check your inbox for instructions on how to reset your Voice Apps password.</div>
          </div>
          :
          <div className="login_standardLogin">
            <div className="module form-module">

              <div className="fixed-form">
                <h2>Forgot Password?</h2>
                <div className="login_text">Enter your email below and we&apos;ll send you a link to reset your password. Or, <span>return to login</span>.</div>
                <br/>
                <form>
                  <input type="text" placeholder="Username" value={formFields.email.value} onChange={evt => this.updateField('email', evt.target.value)}/>

                  <button onClick={this.handleResetPassword}>Reset Password</button>
                </form>
              </div>
            </div>
          </div>
        )
          :
          <div>
            <div className="login_googleLogin">
              <GoogleLogin
                clientId="805887651809-1hiqb5s8o9v417919bolb87gijah0gg8.apps.googleusercontent.com"
                className="login_googleLoginButton"
                buttonText="REGISTER/LOGIN WITH GOOGLE"
                responseType="code"
                accessType="offline"
                prompt="consent"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
              />
              <img className="login_googleImg" src="/images/google.jpg" alt="google"/>
            </div>
            <div className="login_orContainer">
              <div className="or"> OR </div>
            </div>

            {!showRegisterForm ?
              <div className="login_registerCallout">
                <div className="login_registerCalloutArrow"><FA icon={["far", "arrow-left"]} /></div>
                <div className="login_registerCalloutText">Don&apos;t have an account?<br/>
                Click here. It&apos;s FREE!</div>
              </div>
              :
              <div className="login_registerCallout">
                <div className="login_registerCalloutArrow"><FA icon={["far", "arrow-left"]} /></div>
                <div className="login_registerCalloutText">Already Registered?<br/>
                Click Here to Login.</div>
              </div>
            }

            <div className="login_standardLogin">
              <div className="module form-module">

              <div className="toggle login_registerButton" onClick={this.handleRegisterClick}>{toggleText}</div>

                {!showRegisterForm ?
                  <div className="form">
                  <h2>Login to your account</h2>
                  <form>
                    <input type="text" placeholder="Username" value={formFields.email.value} onChange={evt => this.updateField('email', evt.target.value)}/>
                    <input type="password" placeholder="Password" value={formFields.password.value} onChange={evt => this.updateField('password', evt.target.value)}/>
                    <div className="rememberMeContainer"><input className="cb_rememberMe" type="checkbox" name="rememberMe"/> <span className="rememberMe">Remember Me</span>
                      <span className="forgotPasswordContainer"><div onClick={this.handleForgotPassword}>Forgot your password?</div></span>
                    </div>
                    <button onClick={this.login}>Login</button>
                    <div className="login_errorMessage">{errorMessage}</div>
                  </form>
                </div>
                :
                <div className="form">
                  <h2>Create an account</h2>
                  <form>
                    <div>
                      <div className="login_name">
                        <input type="text" className={"form-control" + (formFields.firstName.isValid ? '' : ' is-invalid')} placeholder="First Name" value={formFields.firstName.value} onChange={evt => this.updateField('firstName', evt.target.value)} />
                        <div className="invalid-feedback">
                          First Name is required.
                        </div>
                      </div>
                      <div className="login_name">
                        <input type="text" className={"form-control" + (formFields.lastName.isValid ? '' : ' is-invalid')} placeholder="Last Name" value={formFields.lastName.value} onChange={evt => this.updateField('lastName', evt.target.value)} />
                        <div className="invalid-feedback">
                          Last Name is required.
                        </div>
                      </div>
                    </div>
                    <div>
                      <input type="email" className={'form-control' + (formFields.email.isValid ? '' : ' is-invalid')} placeholder="Email Address" value={formFields.email.value} onChange={evt => this.updateField('email', evt.target.value)}/>
                      <div className="invalid-feedback">
                        Please enter a valid e-mail address.
                      </div>
                    </div>
                    <div>
                      <input type="password" className={'form-control' + (formFields.password.isValid ? '' : ' is-invalid')} placeholder="Password" value={formFields.password.value} onChange={evt => this.updateField('password', evt.target.value)}/>
                      <div className="invalid-feedback">
                        Please enter a valid password. Passwords must be at least 8 characters, contain at least one uppercase, lowercase, number, and special character (!$#).
                      </div>
                    </div>
                    <div>
                      <input type="password" className={'form-control' + (formFields.passwordConfirm.isValid ? '' : ' is-invalid')} placeholder="Confirm Password" value={formFields.passwordConfirm.value} onChange={evt => this.updateField('passwordConfirm', evt.target.value)}/>
                      <div className="invalid-feedback">
                        Please make sure your passwords match.
                      </div>
                    </div>
                    <button onClick={this.register}>Register</button>
                    <div className="login_errorMessage">{errorMessage}</div>
                  </form>
                </div>
              }
              </div>
            </div>
          </div>
        }
        </div>
      </div>
    );
  }
}

Login.propTypes = {
	login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ login, logout }, dispatch);

export default connect(null, mapDispatchToProps)(Login);
//export default Login;
