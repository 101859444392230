import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import Multiselect from 'react-bootstrap-multiselect';
import Select from 'react-select';

import $ from 'jquery';

import Spinner from '../Spinner';
import utils from '../helpers/utils.js';
import templates from '../data/templates.js';
import categories from '../data/categories.js';
import templateIntents from '../data/templateIntents.js';
import words from '../data/words.js';

const CLOUDINARY_UPLOAD_PRESET = 'bophohq0';
const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/voiceapps/upload';
// const CLOUDINARY_FETCH_URL = 'https://res.cloudinary.com/voiceapps/image/fetch/';


class EditProject extends Component {
  constructor(props) {
    super(props);

    let selectedProject = props.project ? JSON.parse(JSON.stringify(props.project)) : { templateId: 0, name: '', description: '' };
    let locales = [];
    if (selectedProject.locales) {
        selectedProject.locales.forEach((locale) => {
        locales.push(locale.locale);
      });
    }

    if (locales.length === 0) {
      if (selectedProject.IsFlashBriefing) {
        locales.push('en-US');
      } else {
        locales.push('en-US');
        locales.push('en-GB');
        locales.push('en-CA');
        locales.push('en-IN');
        locales.push('en-AU');
      }
    }

    // if (selectedProject.locales) {
    //   locales = selectedProject;
    // }
    this.state = {
      selectedProject: selectedProject,
      projectTemplate: templates[selectedProject.templateId],
      projectCategory: selectedProject.categoryId,
      formFields: {
        projectName: {
          isValid: true,
          value: selectedProject.name == null ? selectedProject.name : ''
        },
        invocationName: {
          isValid: true,
          value: selectedProject.invocationName == null ? selectedProject.invocationName : ''
        },
        projectCategory: {
          isValid: true,
        },
        description: {
          isValid: true,
          value: selectedProject.description == null ? selectedProject.description : ''
        },
        icon: {
          isValid: true,
          value: selectedProject.icon == null ? selectedProject.icon : ''
        },
        locales: {
          isValid: true,
          value: locales
        },
        synchEnglishLocales: {
          isValid: true,
          value: selectedProject.synchEnglishLocales == null ? true : selectedProject.synchEnglishLocales
        }
      },
      showIcons: false,
      icons: [],
      iconSearchTerm: 'skills',
      isLoading: false,
      isEditing: false
    }

    this.searchTimer = setTimeout(this.performSearch, 500);
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentWillReceiveProps(props) {
    utils.log('---------------Props Received---------------');
    this.setState({
      showIcons: false,
    });

    if (this.state.isEditing) return;
    let selectedProject = props.project ? JSON.parse(JSON.stringify(props.project)) : { templateId: 0, name: '', description: '' };
    utils.log('Selected Project = ', selectedProject);

    let currentProjectTemplate = this.state.projectTemplate;
    let currentFields = this.state.formFields;

    let template = currentProjectTemplate == null ? templates.find(t => t.id === selectedProject.templateId) : currentProjectTemplate;
    utils.log('template=', template);

    let locales = [];
    if (selectedProject.locales) {
      selectedProject.locales.forEach((localeInfo) => {
        locales.push(localeInfo.locale);
      });
    }
    if (locales.length === 0) {
      if (selectedProject.IsFlashBriefing) {
        locales.push('en-US');
      } else {
        locales.push('en-US');
        locales.push('en-GB');
        locales.push('en-CA');
        locales.push('en-IN');
        locales.push('en-AU');
      }
    }
    if (selectedProject.locale) {
      locales = [ selectedProject.locale ];
    }
    utils.log('locales=', locales);

    //utils.log('currentFields=', currentFields);
    this.setState({
      selectedProject: selectedProject,
      projectTemplate: template,
      projectCategory: selectedProject.categoryId,
      formFields: {
        projectName: {
          isValid: true,
          value: selectedProject.name,
        },
        invocationName: {
          isValid: true,
          value: selectedProject.invocationName,
        },
        projectCategory: {
          isValid: true,
        },
        description: {
          isValid: true,
          value: selectedProject.description
        },
        icon: {
          isValid: true,
          value: selectedProject.icon
        },
        locales: {
          isValid: true,
          value: locales
        },
        synchEnglishLocales: {
          isValid: true,
          value: selectedProject.synchEnglishLocales == null ? true : selectedProject.synchEnglishLocales
        }
      },
      iconSearchTerm: template ? template.iconSearchTerm : 'skills'
    });

    this.searchTimer = setTimeout(this.performSearch, 500);
  }

  changeProjectTemplate = (id) => { // eslint-disable-line
    utils.log('------change project template------');
    utils.log('templateId', id);

    let template = templates.find(t => t.id === id);
    utils.log('template=', template);

    this.setState({
      projectTemplate: template,
      isEditing: true,
      iconSearchTerm: template.iconSearchTerm,
    });

    this.searchTimer = setTimeout(this.performSearch, 500);
  }

  changeProjectCategory = (id) => { // eslint-disable-line
    utils.log('------change project category------');

    utils.log("categoryId=", id);

    this.setState({
      projectCategory: id,
      isEditing: true,
    });
  }

  updateField = (field, value) => { // eslint-disable-line
    let formFields = this.state.formFields;

    formFields[field].value = value;

    if (field === 'projectName') {
      formFields['invocationName'].value = value.toLowerCase();
    }

    this.setState({
      formFields,
      isEditing: true,
    });
  }

  validateFormFields = () => {
    utils.log('================== VALIDATING FORM FIELDS ===================');
    let { formFields, projectCategory } = this.state;
    const { project } = this.props;

    let isValid = true;

    // Project name
    if (formFields.projectName.value === '') {
      formFields.projectName.isValid = false;
      formFields.projectName.error = 'You must enter a project name.';
    } else {
      formFields.projectName.isValid = true;
      formFields.projectName.error = '';
    }
    // // Invocation Name
    // let inRegEx = /^[a-z][a-z \.\u00C0-\u017Fa']*$/g;
    // if (!inRegEx.test(formFields.invocationName.value)) {
    //   formFields.invocationName.isValid = false;
    //   formFields.invocationName.error = 'Invocation names must only contain lowercase numbers, spaces, periods (when used in apostrophes), and possessive apostrophes';
    //   isValid = false;
    // } else {
    //   formFields.invocationName.isValid = true;
    //   formFields.invocationName.error = '';
    // }

    // utils.log('icon=', formFields.icon.value);
    if (formFields.icon.value == null || formFields.icon.value === '' || formFields.icon.value === '/images/choose_image.png') {
      formFields.icon.isValid = false;
      formFields.icon.error = "You must select an icon for your project.";
      isValid = false;
    } else {
      formFields.icon.isValid = true;
      formFields.icon.error = "";
    }

    if (projectCategory == null && project.IsFlashBriefing !== true) {
      formFields.projectCategory.isValid = false;
      formFields.projectCategory.error = 'You must choose a category for your skill.';
      isValid = false;
    } else {
      formFields.projectCategory.isValid = true;
      formFields.projectCategory.error = '';
    }

 // utils.log('locales=', formFields.locales.value, );
    if (formFields.locales.value == null || (formFields.locales.value && formFields.locales.value.length === 0)) {
      formFields.locales.isValid = false;
      formFields.locales.error = 'You must choose a language/locale for your skill.';
      isValid = false;
    } else {
      formFields.locales.isValid = true;
      formFields.locales.error = '';
    }
    // projectName: {
    //   isValid: true,
    //   value: selectedProject.name,
    // },
    // invocationName: {
    //   isValid: true,
    //   value: selectedProject.invocationName,
    // },
    // description: {
    //   isValid: true,
    //   value: selectedProject.description
    // },
    // icon: {
    //   isValid: true,
    //   value: selectedProject.icon
    // },
    // locales: {
    //   isValid: true,
    //   value: locales
    // },
    // synchEnglishLocales: {

    this.setState({
      formFields
    })

    return isValid;
  }

  handleSaveChanges = (e) => { // eslint-disable-line

    if (!this.validateFormFields()) return;

    utils.log('------------Handle Save Changes-------');
    let { formFields, projectTemplate, projectCategory } = this.state;

    if (projectTemplate == null) projectTemplate = {};
    projectTemplate.id = 3;

    if (projectCategory == null) projectCategory = 'Alarms & Clocks';

    const { project, isNew, isCopy } = this.props;

    project.name = formFields.projectName.value;

    if (project.IsFlashBriefing == null || project.IsFlashBriefing === false) {
      project.invocationName = formFields.invocationName.value;
      if (project.invocationNameSuffix == null) {
        project.invocationNameSuffix = this.getSuffix();
      }
      project.description = formFields.description.value;
      utils.log('before=', formFields.icon.value);
      if (!(formFields.icon.value.indexOf('upload/c_pad') > -1)) {
        project.icon = formFields.icon.value.replace('upload', 'upload/c_pad,w_512,h_512,b_white');
        project.iconSmall = formFields.icon.value.replace('upload', 'upload/c_pad,w_108,h_108,b_white');
        // project.icon = formFields.icon.value.replace('upload', 'upload/c_fit,h_512,w_512');
        // project.iconSmall = formFields.icon.value.replace('upload', 'upload/c_fit,w_108,h_108');
      }
      utils.log(project.icon);
      project.templateId = projectTemplate.id;
      project.categoryId = projectCategory;
      project.synchEnglishLocales = formFields.synchEnglishLocales.value;

      if (isNew) {
        project.intents = templateIntents[project.templateId.toString()];
        project.transform = {
          scale: 0.6658679953044612,
          x: 641.6699485045039,
          y: 458.28799362776516
        };
      }


      if (!project.locales) {
        project.locales = [];
      }
      // Remove locales not selected
      project.locales = project.locales.filter(l => {
        return formFields.locales.value.includes(l.locale);
      });
      utils.log('project locales=', project.locales);

      // Add new locales

      let newIntents = [];
      project.intents.forEach((currentIntent) => {
        let defaultIntent = {
          triggeredBy: 'user',
          activatedBy: [],
          sampleUtterances: [],
          responseGroups: [],
          nextActions: [
            {
              action: 'waitForUser'
            }
          ],
          reprompts: [],
          invalidResponses: [],
          contextualHelp: []
        }

        let newIntent = Object.assign(defaultIntent, currentIntent);
        if (newIntent.id === 'welcome') {
          newIntent.triggeredBy = 'launch';
          newIntent.responseGroups = [{
            type: 'responseGroup',
            functions: [
              {
                type: 'response',
                responses: ['Welcome to ' + project.name]
              }
            ],
            visitStart: 1,
            visitEnd: 0
          }];
        } else if (newIntent.id === 'goodbye') {
          newIntent.triggeredBy = 'user';
          newIntent.sampleUtterances = [
            'goodbye',
            'quit',
            'cancel',
            'stop'
          ]
          newIntent.responseGroups = [{
            type: 'responseGroup',
            functions: [
              {
                type: 'response',
                responses: ['Goodbye for now']
              }
            ],
            visitStart: 1,
            visitEnd: 0
          }];
          newIntent.nextActions = [
            {
              action: 'endSession'
            }
          ];
        } else if (newIntent.id === 'help') {
          newIntent.triggeredBy = 'help';
          newIntent.sampleUtterances = [
            'help',
            'help me',
            'how does this work',
          ]
          newIntent.responseGroups = [{
            type: 'responseGroup',
            functions: [
              {
                type: 'response',
                responses: [project.name + ' was created at Voice Apps dot com.']
              }
            ],
            visitStart: 1,
            visitEnd: 0
          }];
        }
        newIntents.push(newIntent);
      })

      // If we already have a locale, copy existing settings to new locales
      if (project.locales.length > 0) {
        newIntents = [];
        project.locales[0].intentSettings.forEach((intent) => {
          newIntents.push(intent);
        });
      }


      formFields.locales.value.forEach((locale) => {
        let foundLocale = project.locales.find(l => l.locale === locale);

        if (!foundLocale) {
          project.locales.push({
            locale: locale,
            name: project.name,
            invocationName: project.invocationName,
            description: project.description,
            icon: project.icon,
            iconSmall: project.icon,
            intentSettings: newIntents,
          });
        }
      });

      if (isNew || isCopy) {
        project.locales.forEach((locale) => {
          let welcomeIntent = locale.intentSettings.find(t => { return t.id === 'welcome' });

          welcomeIntent.publishingInfo.publicName = project.name;
          welcomeIntent.publishingInfo.invocationName = project.invocationName;
          welcomeIntent.publishingInfo.samplePhrase1 = 'Alexa, open ' + project.invocationName;
          welcomeIntent.publishingInfo.samplePhrase2 = 'Alexa, start ' + project.invocationName;
          welcomeIntent.publishingInfo.samplePhrase3 = 'Alexa, launch ' + project.invocationName;
          welcomeIntent.publishingInfo.shortDescription = 'We hope you enjoy using ' + project.name + '.';
          welcomeIntent.publishingInfo.description = project.description;
          welcomeIntent.publishingInfo.keywords = project.name.split(' ').join(',');
        });

      }
    } else { // This is a flash briefing skill
      project.locales = [];
      project.locales.push( { locale: formFields.locales.value[0] } );
      if (!(formFields.icon.value.indexOf('upload/c_pad') > -1)) {
        project.icon = formFields.icon.value.replace('upload', 'upload/c_pad,w_512,h_512,b_white');
        project.iconSmall = formFields.icon.value.replace('upload', 'upload/c_pad,w_108,h_108,b_white');
      }
      project.description = formFields.description.value;
    }
    utils.log(project);
    //return;

    utils.updateProject(project).then(result => {
      utils.log('DB Response=', result);
      let updatedProject = result;
      if (project.IsFlashBriefing) {
        this.goTo(`posts/${updatedProject.id}`);
      }
      if (this.props.onProjectUpdated) {
        this.props.onProjectUpdated();
      }
      //utils.publishSkill(project);
    });

    this.setState({
      changed: true,
      isEditing: false
    });



    $('#EditProjectModal').modal('toggle');

  }

  getSuffix = () => { // eslint-disable-line
    let word1Index = Math.floor(Math.random() * words.length);
    let word2Index = Math.floor(Math.random() * words.length);

    return words[word1Index] + ' ' + words[word2Index];
  }

  handleChangeIcon = (e) => { // eslint-disable-line
    const { formFields } = this.state;
    this.setState({
      showIcons: true,
      isEditing: true,
      iconSearchTerm: formFields.projectName.value,
    });

    this.searchTimer = setTimeout(this.performSearch, 200);
  }

  setIconUrl = (url) => { // eslint-disable-line
    utils.log(url);

    this.handleImageUploadFromUrl(url);
    return;

    // var formFields = this.state.formFields;
    // formFields.icon.value = CLOUDINARY_FETCH_URL + url;
    //
    // this.setState({
    //   formFields,
    //   showIcons: false,
    //   isEditing: true,
    // });
  }

  handleSearchChange = (text) => { // eslint-disable-line
    if (text !== '') {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(this.performSearch, 500);
    }

    this.setState({
      iconSearchTerm: text,
    })
  }

  performSearch = () => { // eslint-disable-line
    const { isPremium } = this.props;
    let text = this.state.iconSearchTerm;

    if (text !== '') {
      this.setState({
        isLoading: true
      });

      utils.searchIcons(text, isPremium).then(icons => {
        //console.log(icons);
        this.setState({
          icons: icons,
          isLoading: false
        })
      });
    }
  }

  onImageDrop = (files) => { // eslint-disable-line
    //console.log("Dropped", files);

    this.setState({
      uploadedFile: files[0]
    });

    this.handleImageUpload(files[0]);
  }

  handleImageUpload(file) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', file);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formFields = this.state.formFields;
        formFields.icon.value = response.body.secure_url;
        this.setState({
          formFields: formFields,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleImageUploadFromUrl(url) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', url);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formFields = this.state.formFields;
        formFields.icon.value = response.body.secure_url;
        this.setState({
          formFields: formFields,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleCloseIcons = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
    });
  }

  handleClose = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
      isEditing: false,
    });
  }

  handleLocaleChange = (element, checked) => { // eslint-disable-line
    utils.log("element:", element[0]);
    utils.log('value=', checked);

    let { formFields } = this.state;

    utils.log( formFields );

    // If it was checked, add it to the list
    if (checked.action === 'select-option') {
      if (!formFields.locales.value.includes(checked.option.value)) {
        formFields.locales.value.push(checked.option.value);
      }
    } else if (checked.action === 'remove-value') {
      let locales = formFields.locales.value.filter(l => l !== checked.removedValue.value);

      formFields.locales.value = locales;
    } else if (checked.action === 'clear') {
      formFields.locales.value = [];
    }

    // // If it was checked, add it to the list
    // if (checked) {
    //   if (!formFields.locales.value.includes(element[0].value)) {
    //     formFields.locales.value.push(element[0].value);
    //   }
    // } else {
    //   let locales = formFields.locales.value.filter(l => l !== element[0].value);
    //
    //   formFields.locales.value = locales;
    // }

    this.setState({
      formFields
    });

  }

  handleLocaleChangeSingle = (element, checked) => { // eslint-disable-line
    utils.log("element:", element);
    utils.log("value:", checked);

    let { formFields } = this.state;

    utils.log( formFields );

    formFields.locales.value = [];
    if (element.value) {
      formFields.locales.value.push(element.value);
    }


    this.setState({
      formFields
    });

  }

  handleUpgrade = () => {
    this.props.onShowPremiumMember();
  }

  render() {
    const { formFields, projectTemplate, projectCategory, showIcons, icons, iconSearchTerm, isLoading } = this.state;
    const { isNew, isCopy, isPremium, project } = this.props;
    utils.log("project--", project);

    //utils.log('projectTemplate=', projectTemplate);

    //const projectTemplateText = projectTemplate ? projectTemplate.name : 'Select Project Template';
    const category = categories.find(c => { return c.value === projectCategory });
    const projectCategoryText = category ? category.name : 'Choose One';

    if (formFields.icon.value == null) {
      formFields.icon.value = '/images/choose_image.png';
    }

    let availableLocales = [
      {
        label: 'English (US)',
        value: 'en-US',
      },
      {
        label: 'English (UK)',
        value: "en-GB"
      },
      {
        label: 'English (IN)',
        value: "en-IN"
      },
      {
        label: 'English (CA)',
        value: "en-CA"
      },
      {
        label: 'English (AU)',
        value: "en-AU"
      },
      {
        label: 'Spanish (US)',
        value: "es-US"
      },
      {
        label: 'Spanish (ES)',
        value: "es-ES"
      },
      {
        label: 'Spanish (MX)',
        value: "es-MX"
      },
      {
        label: 'French (FR)',
        value: "fr-FR"
      },
      {
        label: 'French (CA)',
        value: "fr-CA"
      },
      {
        label: 'German',
        value: "de-DE"
      },
      {
        label: 'Italian',
        value: "it-IT"
      },
      {
        label: 'Japanese',
        value: "ja-JP"
      },
      {
        label: 'Portuguese (BR)',
        value: "pt-BR"
      }
    ];


    let options = [];

    if (formFields.locales && formFields.locales.value) {
      formFields.locales.value.forEach(function (locale, index) {
        //utils.log('locale=', locale);
        let availableLocale = availableLocales.find(l => { return l.value === locale; });
        //utils.log(availableLocale);
        if (availableLocale) {
          availableLocale.selected = true;
          options.push(availableLocale);
        }
      });
    }
    //utils.log(icons);
    //utils.log(formFields.synchEnglishLocales);





    return (
      <div className="modal fade slide" id="EditProjectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-primary">
              {
                (project.IsFlashBriefing == true) ?
                  <h5 className="modal-title" id="exampleModalLabel">{isNew || isCopy ? 'Create Flash Briefing Project' : 'Edit Flash Briefing Project'}</h5>
                  :
                  <h5 className="modal-title" id="exampleModalLabel">{isNew || isCopy ? 'Create Project' : 'Edit Project'}</h5>
              }
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="projectName">Project Name</label>
                  <input type="text" className={"form-control pi_input" + (formFields.projectName.isValid ? '' : ' is-invalid')} id="projectName" placeholder="Project Name" value={formFields.projectName.value} onChange={evt => this.updateField('projectName', evt.target.value)} />
                  <div className="invalid-feedback">
                    {formFields.projectName.error}
                  </div>
                </div>
                {
                  !project.IsFlashBriefing &&
                  <div>
                    {/*<div className="form-group">
                      <label htmlFor="invocationName">Invocation Name</label>
                      <input type="text" className="form-control" id="invocationName" placeholder="Invocation Name" value={formFields.invocationName.value} onChange={evt => this.updateField('invocationName', evt.target.value)} />
                      <small id="invocationNameHelp" className="form-text text-muted">Users will call your voice app by this name.  For example, if your invocation name is "My Cool Sklll", users might say "Alexa, open My Cool Skill".</small>
                    </div>*/}

                    <div className="form-group">
                      <label htmlFor="projectName">Project Category</label>
                      <div className="dropdown edit_templateDropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {projectCategoryText}
                        </button>
                        <div className="dropdown-menu edit_scrollable" aria-labelledby="dropdownMenuButton">
                          {categories.map((category, index) => {
                            return (
                              <a key={index} className="dropdown-item" href="#" onClick={() => this.changeProjectCategory(category.value)}>{category.name}</a>
                            )
                          }, this)}
                        </div>
                      </div>
                      <input type="text" hidden className={"form-control" + (formFields.projectCategory.isValid ? '' : ' is-invalid')} />
                      <div className="invalid-feedback">
                        {formFields.projectCategory.error}
                      </div>
                    </div>
                  </div>
                }
                {
                  //(project.IsFlashBriefing  || !isPremium) &&
                  project.IsFlashBriefing &&
                    <div className="form-group">
                      <label htmlFor="icon">Target Languages/Locales</label>

                      <Select
                        placeholder="Choose Locale..."
                        name="locales"
                        value={options}
                        options={availableLocales}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleLocaleChangeSingle}
                      />
                      {/*<Multiselect onChange={this.handleLocaleChangeSingle} ref="locale" data={availableLocales} />*/}
                      {!isPremium &&
                        <div className="localePremium" onClick={this.handleUpgrade}>Upgrade to target multiple locales</div>
                      }
                      <div className="invalid-feedback">
                        {formFields.locales.error}
                      </div>
                    </div>
                }
                {
                  //(!project.IsFlashBriefing && isPremium) &&
                  !project.IsFlashBriefing &&
                  <div className="form-group">
                  <label htmlFor="icon">Target Languages/Locales</label>
                  <Select
                    placeholder="Choose Locale(s).."
                    isMulti
                    name="locales"
                    value={options}
                    options={availableLocales}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={this.handleLocaleChange}
                  />

                  {/*<Multiselect onChange={this.handleLocaleChange} ref="locale" data={availableLocales} multiple />*/}
                  <input type="text" hidden className={"form-control" + (formFields.locales.isValid ? '' : ' is-invalid')} />
                  <div className="invalid-feedback">
                    {formFields.locales.error}
                  </div>
                  <input
                    type="checkbox"
                    value={formFields.synchEnglishLocales.value}
                    checked={formFields.synchEnglishLocales.value}
                    name="synchEnglishLocales"
                    onChange={evt => this.updateField('synchEnglishLocales', evt.target.checked)} /> <span className="edit_synchEnglishLocales">Copy US data to other English locales (UK, IN, CA, AU)?</span>
                </div>
              }
                  <div>
                    <div className="form-group">
                      <label htmlFor="icon">Project Icon</label>
                      <div className="edit_iconThunbnail" onClick={this.handleChangeIcon}>
                        <img className="iconThumbnail" src={formFields.icon.value} alt="icon" />
                      </div>
                      <input type="text" hidden className={"form-control" + (formFields.icon.isValid ? '' : ' is-invalid')} />
                      <div className="invalid-feedback">
                        {formFields.icon.error}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="projectDescription">Description</label>
                      <textarea className="form-control" id="featureText" rows="5" value={formFields.description.value} onChange={evt => this.updateField('description', evt.target.value)}></textarea>
                      <small id="descriptionHelp" className="form-text text-muted">This will be visible to users on the skill detail page.</small>
                    </div>
                  </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={this.handleSaveChanges}>{isNew || isCopy ? 'Create' : 'Update'}</button>
            </div>
          </div>
        </div>
        {showIcons ?
          <div className="edit_icons">
            <div className="edit_iconHeader">
              <div className="edit_iconHeaderTitle">Choose an Icon</div>
              <div className="edit_close" onClick={this.handleCloseIcons}><FA icon="times" /></div>
            </div>
            {isPremium && true ? null : <div className="edit_premium" onClick={this.handleUpgrade}>Upgrade your account to get premium icons!</div>}
            <div className="edit_searchIconContainer">
              <div className="edit_search">
                <input className="edit_searchBox" type="text" placeholder="Enter search terms" value={iconSearchTerm} onChange={evt => this.handleSearchChange(evt.target.value)} />
                <div className="edit_searchIcon"><FA icon="search" /></div>
              </div>
              <div className="edit_iconsScrollable">
                {icons.length > 0 ?
                  icons.map(function (icon, index) {
                    if (icon.raster_sizes.length < 9) {
                      return null;
                    }
                    let iconUrl = icon.raster_sizes[8].formats[0].preview_url;
                    return (
                      <div key={index} className="edit_iconContainer" onClick={() => this.setIconUrl(iconUrl)} title="Click to select this icon.">
                        <img className="edit_icon" src={iconUrl} alt="icon" />
                      </div>
                    )
                  }, this)
                  : (isLoading ?
                    <div className="edit_spinner">
                      <div className="edit_spinnerBox"><Spinner size={30} /></div>
                      <div className="edit_spinnerText">Loading Icons...</div>
                    </div> :
                    <div className="edit_iconMessage">Enter a search term above to search for icons.</div>
                  )
                }
              </div>



              <Dropzone
                onDrop={this.onImageDrop}
                accept="image/*"
                multiple={false}
                minSize={0}
                maxSize={5242880}
              >
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div className="edit_dropzone"{...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drop an image or click to select a file to upload.</p>

                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div> : null
        }
      </div>

    );
  }
}

export default EditProject;
