import React, { Component } from 'react';
import './styles.css';
import $ from 'jquery';
import queryString from 'query-string';
import utils from '../helpers/utils.js';
import withdrawalReasons from '../data/withdrawalReasons.js';

import Spinner from '../Spinner';

class PublishProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 11,
      withdrawalReason: '',
    };
  }

  componentWillMount() {
    utils.log("==================================");
    utils.log("Publish Project Will Mount");
    utils.log(this.props.code);

    if (this.props.isAccountLinked) {
      utils.log("Account is linked");
      utils.log()
      this.setState({
        step: 13
      })
    } else if (this.props.code) {
      utils.log("Processing Code");
      utils.processAccessCode(this.props.code).then(response => {
        utils.log('process Access code response', response);
        this.setState({
          step: 12
        });
      }).catch(err => {
        //console.log("Error processing code");
      });
    }
  }
  componentDidMount() {
    $('#PublishProjectModal').modal('show');

    (function(d) {
      var a = d.createElement('script'); a.type = 'text/javascript';
      a.async = true; a.id = 'amazon-login-sdk';
      a.src = 'https://api-cdn.amazon.com/sdk/login1.js';
      d.getElementById('amazon-root').appendChild(a);
    })(document);

    // if (this.state.step === 12) {
    //   $('#PublishProjectModal').modal('show');
    // }


    window.onAmazonLoginReady = function() {
      window.amazon.Login.setClientId('amzn1.application-oa2-client.b36926cdc808430f9b87878789315e6f'); //Jeff
        //window.amazon.Login.setClientId('amzn1.application-oa2-client.f740aa01506e4e8ba556d38d229db299');//pankaj
      //window.amazon.Login.setClientId('amzn1.application-oa2-client.8d0ce18af8544d5486c3115ddb3845ff');
      //window.amazon.Login.setClientId('amzn1.application-oa2-client.cd39818a82394e6ab0d630b75ce7b35b'); //Fact-Skill
    };

  }

  handlePublish = () => {
    const { project } = this.props;

    this.setState({
      step: 7
    });

    utils.getProject(project.id).then(proj => {
      if (proj.productionVersion === 1) {
        proj.productionVersion = 2;
      } else {
        proj.productionVersion = 1;
      }

      return utils.updateProject(proj).then(response => {
        return utils.getProject(proj.id, 1).then(prod1proj => {
          proj.skillId = null;
          if (prod1proj) {
            proj.skillId = prod1proj.skillId;
          }
          return utils.updateProject(proj, proj.productionVersion).then(response => {
            return utils.publishSkill(proj, true).then(response => {
              if (response.result === 'success') {
                this.setState({
                  project: proj,
                  step: 8
                });
              } else {
                if (response.code === 3) {
                  this.setState({
                    project: proj,
                    step: 9
                  })
                }
                utils.log(response);
              }

            }).catch(err => {
              utils.log("Publish Error: ", err);
              // Rollback the productionVersion Change
              // if (proj.productionVersion === 1) {
              //   proj.productionVersion = 2;
              // } else {
              //   proj.productionVersion = 1;
              // }
              // return utils.updateProject(proj).then(response => {
              //   return;
              // }).catch(err => {
              //   utils.log(err);
              // })

            });
          })
        })
      }).catch(err => {
        utils.log(err);
      })
    }).catch(err => {
      utils.log(err);
    });


  }

  isValid = (project) => {
    let valid = false;

    if (project) {
      project.locales.forEach(localeInfo => {
        let welcomeIntent = localeInfo.intentSettings.find(f => {return f.id === 'welcome' || f.isLaunch == true});

        utils.log(localeInfo.intentSettings);
        //utils.log('Publishing Info=', welcomeIntent);
        if (welcomeIntent.publishingInfo) {
          valid = welcomeIntent.publishingInfo.invocationName !== '' &&
            welcomeIntent.publishingInfo.samplePhrase1 !== '' &&
            welcomeIntent.publishingInfo.samplePhrase2 !== '' &&
            welcomeIntent.publishingInfo.samplePhrase3 !== '' &&
            welcomeIntent.publishingInfo.shortDescription !== '' &&
            welcomeIntent.publishingInfo.description !== '' &&
            welcomeIntent.publishingInfo.keywords !== '';
        }
      })
    }

    return valid;
  }

  handleTest = () => {
    this.setState({
      step: 2
    })
  }

  handleEmailChange = (value) => {
    let { project } = this.props;

    project.testEmail = value;

    this.setState({
      project
    });

  }

  handleStartTest = () => {
    const { project } = this.props;

    this.setState({
      step: 3
    });

    let that = this;
    utils.updateProject(project).then(response => {
      utils.log('Project saved', project);
      utils.publishSkill(project, false).then(response => {
        utils.getProject(project.id).then(proj => {
          utils.log('Got Project: skill Id=', proj.skillId);
          utils.enableSkill(proj.skillId).then(response => {
            that.setState({
              step: 4
            });

            return null;
          })
          return null;
        })
        return null;
      }).catch(err => {
        utils.log(err);
      });
      return null;
    })

  }

  handleCertify = () => {
    let { project } = this.props;

    let isValid = this.isValid(project);

    this.setState({
      step: isValid ? 6 : 5
    })
  }

  handleClose = () => {
    $('#PublishProjectModal').modal('hide');

    if (this.props.isAccountLinked) {
      this.setState({
        step: 13
      })
    } else {
      this.setState({
        step: 11
      });
    }
  }

  changeWithdrawalReason= (id) => { // eslint-disable-line
    utils.log('------change withdrawal reason------');

    utils.log("reasonId=", id);

    this.setState({
      withdrawalReason: id,
    });
  }

  handleWithdraw = () => {
    let { project, withdrawalReason } = this.state;

    let reason = withdrawalReasons.find(r => { return r.value === withdrawalReason});
    if (reason) {
      let data = {
        "reason" : reason.value,
        "message" : reason.value === "OTHER" ? "TEST SKILL" : ''
      };

      utils.withdrawSkill(project.skillId, data).then(response => {
        this.setState({
          step: 10
        })
      }).catch(err => {
        utils.log(err);
      })
    }
  }

  handleWithdrawAndPublish = () => {
    let { project, withdrawalReason } = this.state;

    let reason = withdrawalReasons.find(r => { return r.value === withdrawalReason});
    if (reason) {
      let data = {
        "reason" : reason.value,
        "message" : reason.value === "OTHER" ? "TEST SKILL" : ''
      };

      utils.withdrawSkill(project.skillId, data).then(response => {
        this.handlePublish();
      }).catch(err => {
        utils.log(err);
      })
    }
  }

  handleLinkAccount = () => {
    let options = { scope : 'alexa::ask:skills:readwrite alexa::ask:models:readwrite alexa::ask:skills:test', response_type: 'code' };
    window.amazon.Login.authorize(options,window.location.href);
  }

  handleDeploySkill = () => {
    const { project, user } = this.props;

    let isValid = this.isValid(project);

    this.setState({
      step: isValid ? 7 : 5
    })

    if (isValid) {

      return utils.publishSkill(project, true).then(response => {
        if (response.result === 'success') {
          this.setState({
            project,
            step: 14
          });
          if (this.props.onUpdateProject) {
            this.props.onUpdateProject();
          }
          window.fbq('trackSingle', '211310806174815',  'PublishedSkill');
          window.ga('send', 'event', 'Skill Making', 'Publish', 'Success');
          utils.addIntercomTag('PublishedSkill');
          utils.getGeolocationInfo().then(ipInfo => {
            utils.sendProofEvent('publishedskill', {
              "type": "custom",
              "email": user.userName,
              "first_name": user.firstName,
              "city": ipInfo.city,
              "state": ipInfo.region,
              "country": ipInfo.country,

              "ip": ipInfo.query
            });
          });
        } else {
          if (response.code === 3) {
            window.ga('send', 'event', 'Skill Making', 'Publish', 'In Certification');
            this.setState({
              project,
              step: 9
            })
          } else {
            window.ga('send', 'event', 'Skill Making', 'Publish', 'Failure');
            utils.log(response);
            this.setState({
              errorText: response.message,
              step: 15
            });
          }
          utils.log(response);
        }

      }).catch(err => {
        utils.log("Publish Error: ", err);
        this.setState({
          errorText: err.message,
          step: 15
        });
      });
    }
  }

  render() {
    let { step, withdrawalReason, errorText} = this.state;
    const { project } = this.props;

    let withdrawalText = '[Please Select]';
    let reason = withdrawalReasons.find(r => { return r.value === withdrawalReason});
    if (reason) {
      withdrawalText = reason.name;
    }

    return (
      <div className="modal fade publish-modal-dialog-centered" id="PublishProjectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog publish-card" role="document">
          <div className="modal-content publish-modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">Publish Project</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {step === 1 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">You can publish your project and test it on your own device or you can publish your project to Amazon for certification.<br/><br/>  What would you like to do?</div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-info" onClick={this.handleTest}>Test on My Device</button>
                  <button type="button" className="btn btn-success" onClick={this.handleCertify}>Submit for Certification</button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                </div>
              </div> : null
            }

            {step === 2 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Enter the e-mail address associated with your Amazon Alexa device below and click "Start Test".</div>
                  <br />
                  <input className="form-control" type="text" placeholder="Alexa email address" value={project.testEmail} onChange={(event) => this.handleEmailChange(event.target.value)} />
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-info" onClick={this.handleStartTest}>Start Test</button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                </div>
              </div>
              :
              null
            }

            {step === 3 ?
              <div className="modal-body">

                  <div className="publish_spinnerContainer">
                    <div className="publish_spinnerTitle">Publishing...</div>
                    <Spinner size={30} />
                  </div>
              </div> : null
            }

            {step === 4 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Your skill has been published for testing.  If this is the first time you have published this skill, you will receive an e-mail shortly with instructions on enabling your device for testing.</div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                </div>
              </div>
              :
              null
            }

            {step === 5 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Your project is not quite ready.  Please fill out the Publishing Info for the Welcome intent in your project.  You need to do this for each locale.</div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                </div>
              </div> : null
            }

            {step === 6 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">By clicking the "Publish" button below, your project will be submitted for certification on the following platforms:</div>
                  <br />
                  <ul>
                    <li className="publish_platform">Amazon Alexa</li>
                  </ul>
                  <br />
                  <div className="alert alert-info" role="alert">The certification process varies by platform and can take anywhere from a few hours to several days.  We will notify you via e-mail when your project is live.</div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={this.handlePublish}>Publish</button>
                  </div>
                </div>
              </div> : null
            }

            {step === 7 ?
              <div className="modal-body">

                  <div className="publish_spinnerContainer">
                    <div className="publish_spinnerTitle">Publishing...</div>
                    <Spinner size={30} />
                  </div>
              </div> : null
            }

            {step === 8 ?
              <div className="publish_completeContainer">
                <div className="publish_completeText">Your project has been submitted for certifcation.</div>
              </div> : null
            }

            {step === 9 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="alert alert-danger" role="alert">This project is currently in the Certification process.</div>

                  <div className="publish_messageText">Would you like to withdraw the skill from certification and publish a new version?</div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="projectName">Reason for withdrawal</label>
                    <div className="dropdown edit_templateDropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {withdrawalText}
                      </button>
                      <div className="dropdown-menu edit_scrollable" aria-labelledby="dropdownMenuButton">
                        {withdrawalReasons.map((reason, index) => {
                          return (
                            <a key={index} className="dropdown-item" href="#" onClick={() => this.changeWithdrawalReason(reason.value)}>{reason.name}</a>
                          )
                        }, this)}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={this.handleWithdraw}>Withdraw from Certification</button>
                    <button type="button" className="btn btn-primary" onClick={this.handleWithdrawAndPublish}>Withdraw and Re-Publish</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                  </div>
                </div>
              </div> : null
            }

            {step === 10 ?
              <div className="publish_completeContainer">
                <div className="publish_completeText">Your project has been withdrawn from certifcation.</div>
              </div> : null
            }

            {step === 11 ?
              <div className="modal-body">
                <div id="amazon-root"></div>
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Voice Apps can automatically deploy your skill to your Amazon developer account so you can test on your own Alexa device.</div>
                  <br />
                  <div className="publish_messageText">If you don&apos;t already have a developer account, creating one only takes a few seconds.</div>
                  <br />
                  <div className="publish_messageText">Click the Link Account button below to link your developer account to Voice Apps.  You&apos;ll only need to do this once.</div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={this.handleLinkAccount}>Link Account</button>
                  </div>
                </div>
              </div> : null
            }

            {step === 12 ?
              <div className="modal-body">
                <div id="amazon-root"></div>
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Your Amazon developer account is now linked.</div>
                  <br />
                  <div className="publish_messageText">Click the Deploy Skill button below to deploy your skill to your Alexa device.</div>
                  <br />
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={this.handleDeploySkill}>Deploy Skill</button>
                  </div>
                </div>
              </div> : null
            }

            {step === 13 ?
              <div className="modal-body">
                <div id="amazon-root"></div>
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Click the Deploy Skill button below to deploy your skill to your Alexa device.</div>
                  <br />
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={this.handleDeploySkill}>Deploy Skill</button>
                  </div>
                </div>
              </div> : null
            }

            {step === 14 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">Your skill has been deployed to your Amazon account.</div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                </div>
              </div>
              :
              null
            }

            {step === 15 ?
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="publish_projectName">{project && project.name}</div>
                  <div className="publish_messageText">There was a problem deploying your skill.  Please correct the error below and try again.</div>
                  <br />
                  <div className="alert alert-danger" role="alert">{errorText}</div>
                  <br />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                </div>
              </div>
              :
              null
            }

            </div>
          </div>
        </div>
    );
  }
}

export default PublishProject;
