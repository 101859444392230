import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ResponseContainer from '../ResponseContainer';
import ResponseGroup from '../ResponseGroup';
import DataContainer from '../DataContainer';

import Trigger from '../Trigger';
import Slots from '../Slots';
import PublishingInfo from '../PublishingInfo';
import InvalidResponse from '../InvalidResponse';
import GetRandom from '../GetRandom';
import Lookup from '../Lookup';
import Integration from '../Integration';
import CallAPI from '../CallAPI';
import GetRSS from '../GetRSS';
import ISP from '../ISP';
import NextAction from '../NextAction';
import ContextualHelp from '../ContextualHelp';
import builtInVariables from '../data/builtInVariables.js';

class IntentEditor extends Component {
  constructor(props) {
    super(props);

    //console.log("intentEditor:Variables", props.variables);
    let variables = props.variables ? props.variables : [];
    let tags = [];
    builtInVariables.forEach(v => {
      tags.push(v.name);
    })
    tags = variables.concat(tags);

    let intent = props.data;
    if (!intent.actions) intent.actions = [];


    this.state = {
      tabId: 1,
      intent: intent,
      intents: props.intents,
      variables: tags
    };
  }

  componentWillReceiveProps(props) {

    //console.log("intentEditor:Variables", props.variables);
    let variables = props.variables ? props.variables : [];
    let tags = [];
    builtInVariables.forEach(v => {
      tags.push(v.name);
    })
    tags = variables.concat(tags);

    let intent = props.data;
    utils.log('IntentEditor Will Receive', JSON.parse(JSON.stringify(intent)));
    if (!intent.actions) intent.actions = [];

    this.setState({
      intent: intent,
      intents: props.intents,
      variables: tags
    })
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleTabChange = (id) => { // eslint-disable-line
    this.setState({
      tabId: id
    });

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);

  }

  handleTriggerChange = (data) => { // eslint-disable-line
    let { intent } = this.state;

    intent.isDuplicateIntent = data.isDuplicateIntent;
    intent.duplicateIntentName = data.duplicateIntentName;
    intent.sampleUtterances = data.sampleUtterances;
    if (intent.id !== 'welcome' && intent.isLaunch != true) {
      intent.triggeredBy = data.triggeredBy;
    }
    intent.activatedBy = data.activatedBy;
    intent.scope = data.scope;
    if (data.utterancesChanged) {
      intent.updateInteractionModel = true;
    }

    this.setState({
      intent,
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleActivationChange = (index, data) => { // eslint-disable-line
    let { intent } = this.state;

    let activation = intent.activations[index];
    activation.isDuplicateIntent = data.isDuplicateIntent;
    activation.duplicateIntentName = data.duplicateIntentName;
    activation.sampleUtterances = data.sampleUtterances;
    activation.activatedBy = data.activatedBy;
    activation.scope = data.scope;

    if (activation.sampleUtterances.length > 0) {
      activation.name = activation.sampleUtterances[0].toLowerCase();
    } else {
      activation.name = '';
    }

    this.setState({
      intent,
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleSlotChange = (data) => { // eslint-disable-line
    let { intent } = this.state;

    intent.slots = data.slots;
    if (data.isChanged) {
      intent.updateInteractionModel = true;
    }

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handlePublishingInfoChange = (data) => {
    let { intent } = this.state;

    intent.publishingInfo = data;
    utils.log('------------------PUBLISHING INFO _____________________');
    utils.log(data);

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleStateChange = (index, data) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions[index].values = null;
    intent.actions[index].code = data.code;

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleGetRandomChange = (index, action) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions[index].variable = action.variable;
    intent.actions[index].data = action.data;

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleLookupChange = (index, action) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions[index].sourceVariable = action.sourceVariable;
    intent.actions[index].targetVariable = action.targetVariable;
    intent.actions[index].keyValues = action.keyValues;

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleCallAPIChange = (index, action) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions[index].url = action.url;
    intent.actions[index].method = action.method;
    intent.actions[index].headers = action.headers;
    intent.actions[index].variables = action.variables;

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleGetRSSChange = (index, action) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions[index].url = action.url;
    intent.actions[index].variables = action.variables;

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleISPChange = (index, action) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions[index].productId = action.productId;
    intent.actions[index].targetVariable = action.targetVariable;

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleNextActionChange = (index, action) => { // eslint-disable-line
    let { intent } = this.state;

    utils.log('Next Action Change');
    intent.nextActions[index].action = action.action;
    intent.nextActions[index].intent = action.intent;

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleActionDelete = (index) => { // eslint-disable-line
    let { intent } = this.state;

    intent.actions.splice(index, 1);

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  addAction = (actionType) => { // eslint-disable-line
    let { intent } = this.state;
    let { isPremium } = this.props;


    if (!intent.actions) intent.actions = [];

    if (actionType === 'setState') {
      intent.actions.push({
        type: 'setState',
        values: []
      })
    } else if (actionType === 'setState2') {
      intent.actions.push({
        type: 'setState2',
        values: []
      })
    } else if (actionType === 'getRandom') {
      if (isPremium) {
        intent.actions.push({
          type: 'getRandom',
          source: 'fixed',
          variable: '',
          data: []
        });
      } else {
        this.props.onShowPremiumMember();
      }
    } else if (actionType === 'lookup') {
      if (isPremium) {
        intent.actions.push({
          type: 'lookup',
          sourceVariable: '',
          targetVariable: '',
          keyValues: []
        });
      } else {
        this.props.onShowPremiumMember();
      }
    } else if (actionType === 'integration') {
      intent.actions.push({
        type: 'integration'
      });
    } else if (actionType === 'callAPI') {
      intent.actions.push({
        type: 'callAPI',
        url: '',
        method: 'GET',
        headers: [],
        variables: []
      });
    } else if (actionType === 'getRSS') {
      intent.actions.push({
        type: 'getRSS',
        url: '',
        variables: []
      });
    } else if (actionType === 'isp') {
      if (isPremium) {
        intent.actions.push({
          type: 'isp',
          productId: '',
          targetVariable: ''
        });
      } else {
        this.props.onShowPremiumMember();
      }
    }

    this.setState({
      intent
    });

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  addResponseGroup = () => { // eslint-disable-line
    utils.log("Adding New Response Group");
    let { intent } = this.state;

    if (intent.responseGroups == null) intent.responseGroups = [];

    intent.responseGroups.push({
      type: 'responseGroup',
      functions: [
        {
          type: 'response',
          responses: [],
          voice: 'Alexa'
        }
      ],
      visitStart: 1,
      visitEnd: 0
    })

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  addIntentActivation = (activation) => { // eslint-disable-line
    utils.log("Adding New Activation");
    let { intent } = this.state;

    if (intent.activations == null) intent.activations = [];

    intent.activations.push({
      isDuplicateIntent: false,
      duplicateIntentName: '',
      sampleUtterances: [],
      triggeredBy: 'user',
      activatedBy: null,
    })

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleActivationDelete = (index) => { // eslint-disable-line
    let { intent } = this.state;

    intent.activations.splice(index, 1);

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  addNextAction = () => { // eslint-disable-line
    let { intent } = this.state;

    if (intent.nextActions == null) intent.nextActions = [];

    intent.nextActions.push({
      action: 'waitForUser'
    });

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleNextActionDelete = (index) => { // eslint-disable-line
    let { intent } = this.state;

    intent.nextActions.splice(index, 1);

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleResponseGroupDelete = (index) => { // eslint-disable-line
    utils.log("responseGroupIndex=", index);

    let { intent } = this.state;

    intent.responseGroups.splice(index, 1);

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleDataContainerChange = (data) => { // eslint-disable-line
    let { intent } = this.state;

    //console.log("ResponseContainer Data", data);
    intent.actions = data;

    utils.log("ResponseContainer Changed", intent);
    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleResponseContainerChange = (data) => { // eslint-disable-line
    let { intent } = this.state;

    //console.log("ResponseContainer Data", data);
    intent.responseGroups = data;

    utils.log("ResponseContainer Changed", intent);
    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleResponseGroupChange = (index, data) => { // eslint-disable-line
    let { intent } = this.state;

    intent.responseGroups[index] = data;

    utils.log("ResponseGroup Changed", intent);
    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleContextualHelpChange = (data) => { // eslint-disable-line
    let { intent } = this.state;

    utils.log("contextual Help=", data);
    intent.contextualHelp = data.responses;
    intent.contextualHelpVoice = data.voice;

    utils.log('Contextual Help Data', data);

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleInvalidResponseChange = (data) => { // eslint-disable-line
    let { intent } = this.state;

    utils.log('noResponseVoice=', data.noResponseVoice);
    intent.invalidResponses = data.responses;
    intent.invalidResponseVoice = data.invalidResponseVoice;
    intent.reprompts = data.reprompts;
    intent.noResponseVoice = data.noResponseVoice;

    this.setState({
      intent
    })

    if (this.props.onChange) {
      this.props.onChange(intent);
    }
  }

  handleAddIntent = () => {
    if (this.props.onAddIntent) {
      this.props.onAddIntent();
    }
  }

  render() {
    const { tabId, intent, intents, variables } = this.state;
    const { isPremium, onShowPremiumMember, products } = this.props;

    utils.log("Intent=", intent);

    return (
      <div className="ie_container">
        <ul className="nav nav-tabs">
          <li className="nav-item" onClick={() => this.handleTabChange(1)}>
            <a id="activation_tab" className={"nav-link feature_tab" + (tabId === 1 ? ' active' : '')}
            href="#">Activation</a>
          </li>
          <li className="nav-item" onClick={() => this.handleTabChange(2)}>
            <a id="data_tab" className={"nav-link feature_tab" + (tabId === 2 ? ' active' : '')}
            href="#">Data</a>
          </li>
          <li className="nav-item" onClick={() => this.handleTabChange(3)}>
            <a id="responses_tab" className={"nav-link feature_tab" + (tabId === 3 ? ' active' : '')}
            href="#">Responses</a>
          </li>
          <li className="nav-item" onClick={() => this.handleTabChange(4)}>
            <a id="nextactions_tab" className={"nav-link feature_tab" + (tabId === 4 ? ' active' : '')}
            href="#">Next Actions</a>
          </li>
        </ul>

        <div className={"ie_content" + (tabId === 1 ? ' visible' : '')} >
          {intent && intents &&
            <div>
              <Trigger data={intent} variables={variables} intents={intents} onChange={(data) => this.handleTriggerChange(data)}/>
              {intent.activations && intent.activations.map((activation, index) => {
                return (
                  <Trigger
                    data={activation}
                    variables={variables}
                    isSecondary={true}
                    intents={intents}
                    onChange={(data) => this.handleActivationChange(index, data)}
                    onDelete={() => this.handleActivationDelete(index)}
                  />
                )
              })}
              <Slots data={intent} variables={variables} onChange={(data) => this.handleSlotChange(data)}/>
              {(intent.id === 'welcome' || intent.isLaunch) ?
                <PublishingInfo data={intent.publishingInfo} onChange={(data) => this.handlePublishingInfoChange(data)}/> : null
              }
            </div>
          }
        </div>
        <div className={"ie_content" + (tabId === 2 ? ' visible' : '')} >
          {/*intent.actions && intent.actions.map((action, index) => {
            if (action.type === 'setState') {
              return (
                <SetState key={index} data={action} onChange={(data) => this.handleStateChange(index, data)}/>
              )
            } else if (action.type === 'getRandom') {
              return (
                <GetRandom key={index} data={action} onDelete={() => this.handleActionDelete(index)} onChange={(data) => this.handleGetRandomChange(index, data)}/>
              )
            } else if (action.type === 'lookup') {
              return (
                <Lookup key={index} data={action} onDelete={() => this.handleActionDelete(index)} onChange={(data) => this.handleLookupChange(index, data)}/>
              )
            } else if (action.type === 'integration') {
              return (
                <Integration key={index} data={action} onDelete={() => this.handleActionDelete(index)} onChange={(data) => this.handleIntegrationChange(index, data)}/>
              )
            } else if (action.type === 'callAPI') {
              return (
                <CallAPI key={index} data={action} onDelete={() => this.handleActionDelete(index)} onChange={(data) => this.handleCallAPIChange(index, data)}/>
              )
            } else if (action.type === 'getRSS') {
              return (
                <GetRSS key={index} data={action} onDelete={() => this.handleActionDelete(index)} onChange={(data) => this.handleGetRSSChange(index, data)}/>
              )
            } else if (action.type === 'isp') {
              return (
                <ISP key={index} data={action} products={products} onDelete={() => this.handleActionDelete(index)} onChange={(data) => this.handleISPChange(index, data)}/>
              )
            }

            return null;
          })*/}
          <DataContainer data={intent.actions} products={products} variables={variables} onChange={(data) => this.handleDataContainerChange(data)}/>

        </div>
        <div className={"ie_content" + (tabId === 3 ? ' visible' : '')} >
          {/*intent && intent.responseGroups && intent.responseGroups.map((group, index) => {
            return (
              <ResponseGroup key={index} isPremium={isPremium} onShowPremiumMember={onShowPremiumMember} data={group} onDelete={() => this.handleResponseGroupDelete(index)} onChange={(data) => this.handleResponseGroupChange(index, data)} />
            )
          })*/}
          {intent && intent.responseGroups &&
            <ResponseContainer isPremium={isPremium} onShowPremiumMember={onShowPremiumMember} data={intent.responseGroups} variables={variables} onChange={(data) => this.handleResponseContainerChange(data)} />
          }
        </div>
        <div className={"ie_content" + (tabId === 4 ? ' visible' : '')} >
          {intent && intent.nextActions && intent.nextActions.map((nextAction, index) => {
            return (
              <NextAction
                key={index}
                data={nextAction}
                intents={intents}
                canDelete={intent.nextActions.length>1}
                onDelete={() => this.handleNextActionDelete(index)}
                onChange={(data) => this.handleNextActionChange(index, data)}
                onAddIntent={this.handleAddIntent}
                products={products}
                variables={variables}
              />
            )
          })}
          {isPremium ?
            (intent && intent.contextualHelp && intent.contextualHelp ? <ContextualHelp responses={intent.contextualHelp} variables={variables} voice={intent.contextualHelpVoice} onChange={(data) => this.handleContextualHelpChange(data)}/> : null) :
            (
              <div className="intent_premium" onClick={this.props.onShowPremiumMember}>Upgrade your account to add Contextual Help</div>
            )
          }

          <InvalidResponse
            isPremium={isPremium}
            onShowPremiumMember={onShowPremiumMember}
            responses={intent.invalidResponses}
             variables={variables}
            invalidResponseVoice={intent.invalidResponseVoice}
            reprompts={intent.reprompts}
            noResponseVoice={intent.noResponseVoice}
            onChange={(data) => this.handleInvalidResponseChange(data)}/>
        </div>

        {tabId === 1 ?
          <div className="intent_toolbar">
            <div className="intent_tool" data-toggle="tooltip" data-placement="top" title="Add a new Activation" onClick={() => this.addIntentActivation()}><FA icon={["fal", "object-group"]} /></div>
          </div> : null
        }

        {tabId === 2 ?
          <div className="intent_toolbar" data-intro="You can access data in 3rd party services, get random values from a list of values, and use one value to lookup another.">
          <div className="intent_tool"data-toggle="tooltip" data-placement="top" title="SetState" onClick={() => this.addAction('setState2')}><div className="setstate_icon" /></div>
            <div className="intent_tool"data-toggle="tooltip" data-placement="top" title="JS Code" onClick={() => this.addAction('setState')}><FA icon={["fab", "js-square"]} /></div>
            <div className="intent_tool"data-toggle="tooltip" data-placement="top" title="Integrations" onClick={() => this.addAction('integration')}><FA icon={["far", "plug"]} /></div>
            <div className="intent_tool" data-toggle="tooltip" data-placement="top" title="Get Random Value" onClick={() => this.addAction('getRandom')}><FA icon={["far", "random"]} /></div>
            <div className="intent_tool" data-toggle="tooltip" data-placement="top" title="Lookup a Value" onClick={() => this.addAction('lookup')}><FA icon={["far", "map-signs"]} /></div>
            <div className="intent_tool" data-toggle="tooltip" data-placement="top" title="Verify In-skill Product" onClick={() => this.addAction('isp')}><FA icon={["far", "box-usd"]} /></div>
          </div> : null
        }

        {tabId === 3 ?
          <div className="intent_toolbar">
            <div className="intent_tool" data-toggle="tooltip" data-placement="top" title="Add a new Response Group" onClick={() => this.addResponseGroup()}><FA icon={["fal", "object-group"]} /></div>
          </div> : null
        }

        {tabId === 4 ?
          <div className="intent_toolbar">
            <div className="intent_tool" data-toggle="tooltip" data-placement="top" title="Add a new Next Action block." onClick={() => this.addNextAction()}><FA icon={["fal", "external-link"]} /></div>
          </div> : null
        }
      </div>
    );
  }
}

export default IntentEditor;
