import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import { render } from 'react-dom';
import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/json';
import 'brace/theme/tomorrow';

function onChange(newValue) {
  utils.log('change',newValue);
}
// import ace from 'brace';
// import 'brace/mode/json';
// import 'brace/theme/github';

class APLTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      template: (props.data && props.data.template) ? props.data.template : '',
      isEditing: false
    };
  }

  componentWillReceiveProps(props) {
    let newTemplate = props.data.template;

    this.setState({
      template: newTemplate,
      isEditing: false
    });

  }


  changeTemplate = (value) => {
    let { template } = this.state;

    template = value;

    this.setState({
      template,
      isEditing: true
    });

    if (this.props.onChange) {
      this.props.onChange(template);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !this.state.isEditing;
  }

  render() {
    utils.log('rendering APL');
    const { template } = this.state;

    return (
      <div className="intent_card">
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_apl" icon={["far", "newspaper"]} /></span>APL Template</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <AceEditor
          mode="json"
          theme="tomorrow"
          width="100%"
          value={template}
          onChange={this.changeTemplate}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{$blockScrolling: true}}
        />
      </div>
    );
  }
}

export default APLTemplate;
