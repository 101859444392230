import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import MultiText from '../MultiText';
import slotTypes from './slotTypes.json';

class Slots extends Component {
  constructor(props) {
    super(props);

    //console.log("Slots", props);
    let slots = this.getSlots(props.data.sampleUtterances, props.data.slots);

    this.state = {
      slots: slots ? slots : [],
      isChanged: false
    };

  }

  componentWillReceiveProps(newProps) {
    utils.log("Got new Slots", newProps);
    let slots = this.getSlots(newProps.data.sampleUtterances, newProps.data.slots);

    this.setState({
      slots: slots ? slots : [],
      isChanged: false
    });
  }

  getSlots = (utterances, slots) => { // eslint-disable-line

    slots = slots ? slots : [];

    let foundSlots = [];

    let slotsChanged = false;
    utterances && utterances.forEach(utterance => {
      let newSlots = utterance.match(/{[^}]*}/g);
      utils.log('newSlots=', newSlots);
      if (newSlots) {
        newSlots.forEach(slot => {
          foundSlots.push(slot);

          // If we don't already have this slot...
          if (!slots.find(s => { return s.name === slot})) {
            // add it
            slots.push({
              "name": slot,
              "type": "Custom",
              "values": []
            })

            slotsChanged = true;
          }
        })
      }

    })

    // Delete any slots that have been removed
    if (slots) {
      let slotCount = slots.length;
      slots = slots.filter(s => { return foundSlots.includes(s.name) });
      if (slotCount !== slots.length) {
        slotsChanged = true;
      }
    }

    // if (slotsChanged) {
    //   if (this.props.onChange) {
    //     this.props.onChange(slots);
    //   }
    // }

    utils.log("slots=", slots);
    return slots;
  }

  updateSlotValues = (index, data) => { // eslint-disable-line
    utils.log("index=", index, data);

    let { slots } = this.state;

    slots[index].values = data;

    this.setState({
      slots,
      isChanged: true
    });

    if (this.props.onChange) {
      this.props.onChange({
        slots,
        isChanged: true
      });
    }
  }

  changeSlotType = (index, slotType) => { // eslint-disable-line
    let { slots } = this.state;

    slots[index].type = slotType;

    this.setState({
      slots,
      isChanged: true
    });


    if (this.props.onChange) {
      this.props.onChange({
        slots,
        isChanged: true
      });
    }
  }

  changeConvertToNumeric = (index, convertToNumeric) => { // eslint-disable-line
    let { slots } = this.state;

    slots[index].convertToNumeric = convertToNumeric;

    this.setState({
      slots,
      isChanged: true
    });


    if (this.props.onChange) {
      this.props.onChange({
        slots,
        isChanged: true
      });
    }
  }

  render() {
    const { slots } = this.state;
    //utils.log(slotTypes);


    return (
      slots.length>0 ?
      <div className="intent_card">
        <div className="intent_cardHeader">Slots</div>
        <div className="trigger_utteranceHeader">I found the following slots in the sample utterances above.  Please configure the slots below...</div>
        {slots.map((slot, index) => {
          return (
            <div key={index} className="slots_slot">
              <div>
                <div className="slots_slotName">{slot.name}</div>
                <div className="dropdown slots_inline">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {slot.type}
                  </button>
                  <div className="dropdown-menu slots_menu" aria-labelledby="dropdownMenuButton">
                    {slotTypes.map((slotType, slotIndex) => {
                      return (
                        <a key={slotIndex} className="dropdown-item" href="#" onClick={() => this.changeSlotType(index, slotType)}>{slotType}</a>
                      )
                    })}
                  </div>
                </div>
              </div>
              {(slot.type === 'Custom' || slot.type === 'AMAZON.NUMBER' || slot.type === 'AMAZON.FOUR_DIGIT_NUMBER') &&
                <div className="slots_convertToNumeric">
                  <input
                    type="checkbox"
                    value={slot.convertToNumeric}
                    checked={slot.convertToNumeric}
                    name="convertToNumeric"
                    onChange={evt => this.changeConvertToNumeric(index, evt.target.checked)} /> <span className="edit_synchEnglishLocales">Convert value to numeric?</span>
                </div>
              }
              <br/>
              <div className="alert alert-info" role="alert">NOTE: The Voice Apps test console does NOT support all slot types.  Enter sample values below to allow for testing.</div>
              <MultiText
                header="Custom slot values..."
                inputPlaceHolder="Enter a slot value."
                inputPlaceHolder2="Enter another slot value."
                inputPlaceHolder3="Enter another slot value."
                data={slot.values}
                variables={this.props.variables}
                allowVariables={false}
                onChange={(data) => this.updateSlotValues(index, data)}
              />
            </div>
          )
        })}


      </div> : null
    );
  }
}

export default Slots;
