import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import ReactAudioPlayer from "react-h5-audio-player";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlaying: false
    };
  }

  componentWillReceiveProps(props) {
    if (props.canPlay === false) {
      if (this.player) {
        this.player.audio.pause();
        this.player.audio.currentTime = 0;
      }
    }
  }

  handlePlay = () => {
    this.setState({
      isPlaying: true
    })

    if (this.props.onPlay) {
      this.props.onPlay();
    }
  }

  handlePause = () => {
    this.setState({
      isPlaying: false
    })
  }

  handleError = (e) => {
    //console.log(e);
    if (this.player) {
      this.player.audio.pause();
    }
    //this.player.audio.src = this.props.src;

    this.setState({
      isPlaying: false
    })
  }

  render() {
    const { title, src } = this.props;
    const { isPlaying } = this.state;

    return (
      <div className="ap_container">
        <ReactAudioPlayer
          controls
          src={src}
          onPlay={this.handlePlay}
          onPause={this.handlePause}
          onError={this.handleError}
          ref={c => (this.player = c)}
          preload="none"
          listenInterval={500}
        />
        <div className={"ap_title" + (!isPlaying ? ' visible' : '')}>{title}</div>
      </div>
    );
  }
}

export default AudioPlayer;
