import React, { Component } from 'react';
import './index.css';
import $ from 'jquery';
import queryString from 'query-string';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
// import Footer from '../Footer';
import SkillTester from '../SkillTester';
import PublishProject from '../PublishProject';
import PremiumMember from '../PremiumMember';
import ShareProject from '../ShareProject';
import IntentEditor from '../IntentEditor';
import Product from './product';
import AddProduct from './addProduct';
import Confirm from '../Confirm';
import Toolbar from '../Toolbar';
import Intent from '../Intent';
import ZoomControl from '../ZoomControl';

import templateIntents from './intents.js';
//import project from './sampleData.js';
import utils from '../helpers/utils.js';
import builtInVariablesMaster from '../data/builtInVariables.js'

import panzoom from './panzoom.js';
import interact from 'interactjs';

import ResizeSensor from  'css-element-queries/src/ResizeSensor';

var panzoomControl = null;

class ProjectDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: null,
      currentLocale: 0,
      selectedIntent: null,//Object.assign({}, project.intents[0]),
      isselectedIntentChanged: false,
      changesSaved: false,
      selectedIntents: [],
      showTestPanel: false,
      showPublishPanel: false,
      isSpeaking: false,
      speakIndex: null,
      flash: false,
      tabId: 1,
      isAccountLinked: false,
      isPremium: true,
      showDetailsWindow: false,
      selectedProduct: undefined,//Object.assign({}, project.products[0]),
      postAction : 'Create',
      products: [],
      showConfirm: false,
      confirmTitle: `Delete Intent`,
      confirmBody: ``,
      confirmButtonText: `Delete`,
      confirmTarget: -1,
      showPublishProject: false,
      builtInVariables : builtInVariablesMaster
    }

    this.mouseposition = {};
    this.mouseposition.x = 0;
    this.mouseposition.y = 0;
    this.sourceId = null;
    this.targetId = null;

    this.lineDashOffset = 0;
    window.setInterval(() => {
      this.lineDashOffset += 2;
      if (this.lineDashOffset > 18) {
        this.lineDashOffset = 0;
      }
    }, 50);

    this.lineType = 1;
  }

  // getAvailableIntents() {
  //   const { project } = this.state;
  //
  //   let intents = templateIntents[project.templateId];
  //   var availableIntents = intents.filter( function( f ) {
  //     let result = false;
  //     for(let i=0;i<project.intents.length;i++) {
  //       if (project.intents[i].id === f.id) {
  //         result = true;
  //         break;
  //       }
  //     }
  //     return !result;
  //   });
  //
  //   return availableIntents;
  // }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentWillMount() {
    // utils.getCurrentUser().then(response => {
    //   return null;
    // });
    const parsed = queryString.parse(location.search); // eslint-disable-line
    utils.log('querystring parameters=', parsed);

    utils.log(parsed.code);
    if (parsed.code) {
      this.setState({
        AmazonCode: parsed.code,
        showPublishProject: true
      })
    }

  }

  componentDidMount() {

    const { currentLocale, builtInVariables } = this.state;
    utils.log("currentLocale=", currentLocale);
    let projectId = this.props.match.params.id;
    let isDemo = this.props.match.params.demo;

    utils.log('isDemo=', isDemo);

    utils.log("projectID", projectId);
    let that = this;
    utils.getCurrentUser().then(user => {
      utils.log('===========user========', user);
      if (user.refreshToken) {
        this.setState({
          isAccountLinked: true,
          user: user
        })
      }

      this.setState({
        isPremium: user.subscription ? user.subscription : false
      });

      window.Intercom("boot", {
        app_id: "rxv0p3l3",
        name: user.firstName + ' ' + user.lastName, // Full name
        email: user.userName, // Email address
        user_id: user.user_id,
        created_at: user.signUpDate // Signup date
      });

      //utils.getProject(projectId).then(proj => {
      utils.getProjectById(projectId).then(proj => {
        //proj.products[0].id = 'amzn1.adg.product.330f3382-76c3-40ca-afe2-75d261c85a8c';
        console.log("Project (component did mount)=", JSON.parse(JSON.stringify(proj)));

        // Add variables from permissions
        if (proj.permissions) {
          if (proj.permissions.firstName) builtInVariables.push({
            name: "{va_userFirstName}",
            description: "First Name of user from their Amazon account.<br/>  Permission is required to access this information."
          })

          if (proj.permissions.fullName) builtInVariables.push({
            name: "{va_userFullName}",
            description: "Full Name of user from their Amazon account.<br/>  Permission is required to access this information."
          })

          if (proj.permissions.email) builtInVariables.push({
            name: "{va_userEmail}",
            description: "Email address of user from their Amazon account.<br/>  Permission is required to access this information."
          })

          if (proj.permissions.phoneNumber) builtInVariables.push({
            name: "{va_userPhoneNumber}",
            description: "Phone Number of user from their Amazon account.<br/>  Permission is required to access this information."
          })
        }
        if (proj.variables == null) {
          proj.variables = [];

          let intentText = "";
          let extractJSON = function(obj) {
            for (const i in obj) {
              if (Array.isArray(obj[i]) || typeof obj[i] === 'object') {

                extractJSON(obj[i]);
              } else {
                intentText += obj[i];
              }
            }
          };
          extractJSON(proj);

          let found = intentText.match(/(?:{{1,2}).*?(?:}{1,2})/g);
          let matches = [];
          if (found) {
            //console.log("found=", found);
            found.forEach((variable) => {
              let res = variable.replace(/{/g, '').replace(/}/g, '').trim();
              if (res.length > 0) {
                matches.push('{' + res + '}')
              }
            })
          }
          let uniq = [...new Set(matches)];
          let res = uniq.filter(f => !builtInVariables.includes(f));

          proj.variables = res;
        }

        that.setState({
          project: proj,
          currentLocale: 0,
          selectedIntent: JSON.parse(JSON.stringify(proj.locales[currentLocale].intentSettings[0])), //Object.assign({}, proj.intents[0]),
          isDemo: isDemo,
          builtInVariables
        });

        setTimeout(() => {
          this.initialize(user.showDetailsIntro == null || user.showDetailsIntro === true);

        }, 200);


        return null;
      }).catch(err => {
        utils.log(err);
      });

      return null;
    }).catch(err => {
      utils.log(err);
      //that.goTo('login');
    });

    // just grab any DOM element
    var area = document.querySelector('.zoomable')

    // And pass it to panzoom
    panzoomControl = panzoom(area, {
      zoomSpeed: 0.02 // 6.5% per mouse wheel event
    });

    utils.log('scale=', panzoomControl.getTransform());
    //$('.prettySocial').prettySocial();

    interact('.connectable')
      .draggable({
        autoScroll: true,
        onstart: (e) => {
          that.connectionDragging = true;
          panzoomControl.dispose();
          utils.log("connection drag start");

          let { project, isDemo } = that.state;

          let id = e.target.id.replace('connector', '');
          this.connectionSource = id;
          this.prevConnectionSource = id;

          let target = document.getElementById('connection_target');

          let transform = panzoomControl.getTransform();
          utils.log(transform);

          let x = e.clientX;// * (1/transform.scale);
          let y = e.clientY;// * (1/transform.scale);

          target.style.top = y + 'px';
          target.style.left = x + 'px';
        },
        onmove: (e) => {
          that.connectionDragging = true;
          utils.log("connection moving");

          let target = document.getElementById('connection_target');

          let transform = panzoomControl.getTransform();

          let x = (e.clientX - transform.x) * (1/transform.scale);// * (1/transform.scale);
          let y = (e.clientY - transform.y) * (1/transform.scale) - 160;// * (1/transform.scale);

          utils.log(e);

          target.style.top = y + 'px';
          target.style.left = x + 'px';
          // target.style.webkitTransform =
          // target.style.transform =
          //   'translate(' + x + 'px, ' + y + 'px)';
        },
        onend: function() {
          that.connectionDragging = false;
          utils.log("connection drag end");

          that.connectionSource = null;

          // And pass it to panzoom
          panzoomControl.recreate();
        }
      })
    // target elements with the "draggable" class

  interact('.draggable')
    .draggable({
      // snap: {
      //   targets: [
      //     interact.createSnapGrid({ x: 5, y: 5 })
      //   ],
      //   range: Infinity,
      //   relativePoints: [ { x: 0, y: 0 } ]
      // },
      // enable inertial throwing
      //inertia: true,
      // keep the element within the area of it's parent
      // restrict: {
      //   restriction: "parent",
      //   endOnly: true,
      //   elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
      // },
      // enable autoScroll
      autoScroll: true,
      onstart: (event) => {
        if (that.connectionDragging) {
          event.interaction.stop();
        }
        panzoomControl.dispose();
      },
      // call this function on every dragmove event
      onmove: this.dragMoveListener,
      // call this function on every dragend event
      onend: (event) => {
        if (that.connectionDragging) return;
        let { project, isDemo } = that.state;

        let id = event.target.id.replace('intent', '');

        let intent = project.intents.find(t => { return t.id === id});

        // let transform = panzoomControl.getTransform();
        // project.transform = transform;

        if (intent) {
          intent.x = (parseFloat(event.target.getAttribute('data-x')) || 0);
          intent.y = (parseFloat(event.target.getAttribute('data-y')) || 0);

          this.updateProject(project);
          // !isDemo && utils.updateProject(project).then(result => {
          //   utils.log('Updating project (onend)', JSON.parse(JSON.stringify(project)));
          //   this.setState({
          //     project
          //   });
          //
          // }).catch(err => {
          //
          // });
        }

        // And pass it to panzoom
        panzoomControl.recreate();
      }
    })
    .on('tap', function (event) {
      //alert(JSON.stringify(event));
      utils.log('tapped');

      let id = event.target.id.replace('intent', '');

      that.handleIntentClick(id);
      event.preventDefault();
    })
    .dropzone({
      // only accept elements matching this CSS selector
      //accept: '#yes-drop',
      // Require a 75% element overlap for a drop to be possible
      //overlap: 0.75,

      // listen for drop related events:

      ondropactivate: function (event) {
        // add active dropzone feedback
        event.target.classList.add('drop-active')
      },
      ondragenter: function (event) {
        var draggableElement = event.relatedTarget
        var dropzoneElement = event.target

        // feedback the possibility of a drop
        dropzoneElement.classList.add('drop-target')
        draggableElement.classList.add('can-drop')
      },
      ondragleave: function (event) {
        // remove the drop feedback style
        event.target.classList.remove('drop-target')
        event.relatedTarget.classList.remove('can-drop')
      },
      ondrop: function (event) {
        utils.log("dropped");
        let { project, currentLocale } = that.state;

        let id = event.target.id.replace('intent', '');
        let targetIntent = project.locales[currentLocale].intentSettings.find(t => { return t.id === id});

        let sourceIntent = project.locales[currentLocale].intentSettings.find(t => { return t.id === that.prevConnectionSource});

        utils.log(targetIntent);
        if (targetIntent) {
          if (!targetIntent.activatedBy.includes(that.prevConnectionSource)) {
            targetIntent.activatedBy.push(that.prevConnectionSource);
          }
        }

        that.setState({
          project
        });

        that.updateProject(project);
      },
      ondropdeactivate: function (event) {
        // remove active dropzone feedback
        event.target.classList.remove('drop-active')
        event.target.classList.remove('drop-target')
      }
    });



    // this is used later in the resizing and gesture demos
    window.dragMoveListener = this.dragMoveListener;

    window.addEventListener("resize", this.initialize);

    var element = document.getElementById('zoomableContainer');
    new ResizeSensor(element, function() {
        //utils.log('Changed to ' + element.clientWidth);
        that.initialize();
    });

    // document.body.addEventListener('pan', function(e) {
    //   that.drawLines();
    // }, true);
    //
    // document.body.addEventListener('zoom', function(e) {
    //   that.drawLines();
    // }, true);
    //
    // document.body.addEventListener('panend', function(e) {
    //   //that.saveTransform();
    //   that.drawLines();
    // }, true);

    document.body.addEventListener('zoomend', function(e) {
      that.saveTransform();
      //that.drawLines();
    }, true);

    let c = document.getElementById("zoomCanvas");

    c.onmousemove = (e) => {
      var rect = c.getBoundingClientRect(),
      x = e.clientX - rect.left,
      y = e.clientY - rect.top

      this.mouseposition.x = x;
      this.mouseposition.y = y;

      //console.log(x, y);
    }

    this.drawLines();
  }

  componentDidUpdate() {
    utils.log(" ---------------------- Component Did Update --------------------")

    // let update = () => {
    //   if (ReactDOM.findDOMNode(this)) {
    //     utils.log("Drawing >ines");
    //     this.drawLines();
    //   } else {
    //     window.requestAnimationFrame(update);
    //   }
    // }
    //
    // update();
    // window.setTimeout(() => {
    //   this.drawLines();
    // }, 100)
    //
    // window.setTimeout(() => {
    //   this.drawLines();
    // }, 200)
    //
    // window.setTimeout(() => {
    //   this.drawLines();
    // }, 250)
    //
    // window.setTimeout(() => {
    //   this.drawLines();
    // }, 300)
    //
    // window.setTimeout(() => {
    //   this.drawLines();
    // }, 400)

    // window.setTimeout(() => {
    //   window.requestAnimationFrame(() => {
    //     this.drawLines();
    //   });
    // },500);


  }

  initialize = (showIntro) => { // eslint-disable-line
    var c = document.getElementById("zoomCanvas");

    if (c == null) return;

    // Make it visually fill the positioned parent
    c.style.width ='100%';
    c.style.height='100%';
    // ...then set the internal size to match
    c.width  = c.offsetWidth;
    c.height = c.offsetHeight;

    this.setInitialBoxPositions();
  }

  saveTransform () {
    let { project, isDemo } = this.state;

    let lineType = project.lineType;
    if (lineType == null) {
      lineType = this.lineType;
    }

    let transform = panzoomControl.getTransform();
    project.transform = transform;
    project.transform.lineType = lineType;

    utils.log("TRANSFORM==", transform);

    !isDemo && utils.updateProject(project).then(result => {
      utils.log('UpdatingProject (savetransform)', JSON.parse(JSON.stringify(project)));
      this.setState({
        project
      });

    }).catch(err => {
      utils.log('saveTransform error', err);
    });

  }

  setInitialBoxPositions = () => { // eslint-disable-line
    const { project } = this.state;


    if (!project.transform) {
      project.transform = {
        scale: 0.4768397195245725,
        x: 402.12073319841045,
        y: 289.1684855636426,
        lineType: this.lineType
      }
    }

    utils.log("project.lineType=", project.transform.lineType);

    if (project.transform) {
      // utils.log('initial zoom', project.transform);
      panzoomControl.moveTo(project.transform.x, project.transform.y);
      panzoomControl.zoomAbs(project.transform.x, project.transform.y, project.transform.scale);
      this.lineType = project.transform.lineType;
    }
  }

  dragMoveListener = (event) => { // eslint-disable-line

    utils.log("dragMoveListener", this.connectionDragging);
    if (this.connectionDragging) return;
    let scale = panzoomControl.getTransform().scale;

    var target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x = (parseFloat(target.getAttribute('data-x')) || 0) + (event.dx * (1/scale)),
        y = (parseFloat(target.getAttribute('data-y')) || 0) + (event.dy * (1/scale));

    // translate the element
    target.style.webkitTransform =
    target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)';

    // update the posiion attributes
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);

    //this.drawLines();
  }

  linesDrawn = null;

  drawLines = () => { // eslint-disable-line
    let c = document.getElementById("zoomCanvas");

    if (c == null) return;

    var ctx = c.getContext("2d");
    ctx.clearRect(0, 0, c.width, c.height);
    let transform = panzoomControl.getTransform();
    let scale = transform.scale;

    let { project, currentLocale } = this.state;

    if (false && this.linesDrawn) {
      this.linesDrawn.forEach(line => {
        line.position();
      })
    } else {
      if (project) {
        let intents = project.locales[currentLocale].intentSettings;

        let lines = [];
        let connections = {};
        let connectionId = 0;
        let links = {};
        let linkId = 0;
        var canvasRect = c.getBoundingClientRect();
        intents.forEach((targetIntent, index) => {
          if (targetIntent.activatedBy) {
            targetIntent.activatedBy.forEach((sourceIntentId) => {
              let sourceNode = document.getElementById('intent' + sourceIntentId);
              let targetNode = document.getElementById('intent' + targetIntent.id);

              if (!sourceNode || !targetNode) {
                return;
              }
              let sourceRect = sourceNode.getBoundingClientRect(),
              targetRect = targetNode.getBoundingClientRect(),
              x1 = sourceRect.left - canvasRect.left,
              y1 = sourceRect.top - canvasRect.top,
              x2 = targetRect.left - canvasRect.left,
              y2 = targetRect.top - canvasRect.top;

              let sourceWidth = sourceNode.offsetWidth;
              let sourceHeight = sourceNode.offsetHeight;
              let targetWidth = targetNode.offsetWidth;
              let targetHeight = targetNode.offsetHeight;

              x1 += (sourceWidth * scale)/2;
              y1 += (sourceHeight * scale)/2;
              x2 += (targetWidth * scale)/2;
              y2 += (targetHeight * scale)/2;

              let angle = Math.atan2(y2-y1, x2-x1) * 180 / Math.PI + 180;

              if (!connections[sourceIntentId]) {
                connections[sourceIntentId] = {
                  left: [],
                  right: [],
                  top: [],
                  bottom: []
                }
              }

              if (!connections[targetIntent.id]) {
                connections[targetIntent.id] = {
                  left: [],
                  right: [],
                  top: [],
                  bottom: []
                }
              }

              let link = {
                id: linkId,
                source: {
                  id: sourceIntentId,
                  x: x1,
                  y: y1,
                  width: sourceWidth,
                  height: sourceHeight
                },
                target: {
                  id: targetIntent.id,
                  x: x2,
                  y: y2,
                  width: targetWidth,
                  height: targetHeight
                }
              };

              links[linkId] = link;

              let source = {
                linkId,
                x: x1,
                y: y1
              };

              let target = {
                linkId,
                x: x2,
                y: y2
              }

              linkId++;

              if (angle > 125 && angle < 145) {
                // Box 2 is diagonally right/up from Box 1
                connections[sourceIntentId].right.push(target);
                connections[targetIntent.id].bottom.push(source);
              } else if (angle > 215 && angle < 235) {
                // Box 2 is diagonally right/down from Box 1
                connections[sourceIntentId].right.push(target);
                connections[targetIntent.id].top.push(source);
              } else if (angle > 35 && angle < 55) {
                // Box 2 is diagonally left/up from Box 1
                connections[sourceIntentId].left.push(target);
                connections[targetIntent.id].bottom.push(source);
              } else if (angle > 305 && angle < 325) {
                // Box 2 is diagonally left/down from Box 2
                connections[sourceIntentId].left.push(target);
                connections[targetIntent.id].top.push(source);
              } else if ((angle > 315 && angle < 360) || (angle > 0 && angle <= 45)) {
                // Box 2 is left of Box 1
                connections[sourceIntentId].left.push(target);
                connections[targetIntent.id].right.push(source);
              } else if (angle > 135 && angle <= 225) {
                // Box 2 is to the right of Box 1
                connections[sourceIntentId].right.push(target);
                connections[targetIntent.id].left.push(source);
              } else if (angle > 55 && angle < 125) {
                // Box 2 is above Box 1
                connections[sourceIntentId].top.push(target);
                connections[targetIntent.id].bottom.push(source);

              } else if (angle > 215 && angle < 305) {
                // Box 2 is below Box 1
                connections[sourceIntentId].bottom.push(target);
                connections[targetIntent.id].top.push(source);
                //console.log(target);
              }

              //lines.push({sourceNode, targetNode, sourceId: sourceIntentId, targetId: targetIntent.id});
              // this.drawLine(sourceNode, targetNode);
            })
          }
        });

        //console.log(connections);
        for (let boxId in connections) {
          let box = connections[boxId];

          box.left.sort((a,b) => {
            if (a.y > b.y) return 1;
            if (b.y > a.y) return -1;
            return 0;
          })

          box.right.sort((a,b) => {
            if (a.y > b.y) return 1;
            if (b.y > a.y) return -1;
            return 0;
          })

          box.top.sort((a,b) => {
            if (a.x > b.x) return 1;
            if (b.x > a.x) return -1;
            return 0;
          })

          box.bottom.sort((a,b) => {
            if (a.x > b.x) return 1;
            if (b.x > a.x) return -1;
            return 0;
          })

          let index = 0;
          let start = 0;
          let offset = 15 * scale;

          index = -Math.floor(box.left.length/2);
          start = box.left.length % 2 === 0 ? offset/2 : 0;
          for (let i=0; i < box.left.length;i++) {
            let conn = box.left[i];
            let link = links[conn.linkId];

            let newOffset = start + index * offset;
            //console.log("newOffset=", newOffset);
            if (boxId === link.source.id) {
              link.source.y += newOffset;
            } else {
              link.target.y += newOffset;
            }

            index++;
          }

          index = -Math.floor(box.right.length/2);
          start = box.right.length % 2 === 0 ? offset/2 : 0;
          for (let i=0; i < box.right.length;i++) {
            let conn = box.right[i];
            let link = links[conn.linkId];

            let newOffset = start + index * offset;
            //console.log("newOffset=", newOffset);
            if (boxId === link.source.id) {
              link.source.y += newOffset;
            } else {
              link.target.y += newOffset;
            }

            index++;
          }

          index = -Math.floor(box.top.length/2);
          start = box.top.length % 2 === 0 ? offset/2 : 0;
          for (let i=0; i < box.top.length;i++) {
            let conn = box.top[i];
            let link = links[conn.linkId];

            let newOffset = start + index * offset;
            if (boxId === link.source.id) {
              link.source.x += newOffset;
            } else {
              link.target.x += newOffset;
            }

            index++;
          }

          index = -Math.floor(box.bottom.length/2);
          start = box.bottom.length % 2 === 0 ? offset/2 : 0;
          for (let i=0; i < box.bottom.length;i++) {
            let conn = box.bottom[i];
            let link = links[conn.linkId];

            let newOffset = start + index * offset;
            if (boxId === link.source.id) {
              link.source.x += newOffset;
            } else {
              link.target.x += newOffset;
            }

            index++;
          }

        }

        for(var id in links) {
          let link = links[id];
          lines.push(link);
        }

        if (this.connectionSource) {
          let sourceNode = document.getElementById('intent' + this.connectionSource);
          let targetNode = document.getElementById("connection_target");
          let sourceRect = sourceNode.getBoundingClientRect(),
          targetRect = targetNode.getBoundingClientRect(),
          x1 = sourceRect.left - canvasRect.left,
          y1 = sourceRect.top - canvasRect.top,
          x2 = targetRect.left - canvasRect.left,
          y2 = targetRect.top - canvasRect.top;

          let sourceWidth = sourceNode.offsetWidth;
          let sourceHeight = sourceNode.offsetHeight;
          let targetWidth = targetNode.offsetWidth;
          let targetHeight = targetNode.offsetHeight;

          x1 += (sourceWidth * scale)/2;
          y1 += (sourceHeight * scale)/2;
          x2 += (targetWidth * scale)/2;
          y2 += (targetHeight * scale)/2;

          lines.push({
            source: {
              id: this.connectionSource,
              x: x1,
              y: y1,
              width: sourceWidth,
              height: sourceHeight
            },
            target: {
              id: null,
              x: x2,
              y: y2,
              width: targetWidth,
              height: targetHeight
            },
            isConnection: true
          });
        }

        this.linesDrawn = [];

        let lineDelete = document.getElementById('connection_delete');
        lineDelete.style.display = "none";
        this.sourceId = null;
        this.targetId = null;
        lines.forEach(line => {
          this.drawLine(c, line.source, line.target, line.isConnection, transform);
        })
      }
    }

    window.requestAnimationFrame(this.drawLines);
  }

  drawLine = (c, source, target, isConnection, transform) => { // eslint-disable-line
    //utils.log("drawing Line from ", source, target);
    //let c = document.getElementById("zoomCanvas");
    let scale = transform.scale;

    //if (c == null || source == null || target == null)  return;

    //var canvasRect = c.getBoundingClientRect();
    // sourceRect = source.getBoundingClientRect(),
    // targetRect = target.getBoundingClientRect(),
    // x1 = sourceRect.left - canvasRect.left,
    // y1 = sourceRect.top - canvasRect.top,
    // x2 = targetRect.left - canvasRect.left,
    // y2 = targetRect.top - canvasRect.top;
    //
    let sourceWidth = source.width;
    let sourceHeight = source.height;
    let targetWidth = target.width;
    let targetHeight = target.height;
    //
    //
    // x1 += (sourceWidth * scale)/2;
    // y1 += (sourceHeight * scale)/2;
    // x2 += (targetWidth * scale)/2;
    // y2 += (targetHeight * scale)/2;


    let x1 = source.x,
    y1 = source.y,
    x2 = target.x,
    y2 = target.y;



    //utils.log('x1,y1 - x2,y2 = ', x1, y1, x2, y2);
    //console.log(sourceWidth);


    var ctx = c.getContext("2d");

    let paths = null;
    if (this.lineType === 0) {
      paths = this.generateLine(x1, x2, y1, y2, sourceWidth, sourceHeight, targetWidth, targetHeight, scale);
    } else {
      paths = this.generateLineBezier(x1, x2, y1, y2, sourceWidth, sourceHeight, targetWidth, targetHeight, scale);
    }

    let path = paths.path;
    let path2 = paths.path2;
    let intersect = paths.intersect;

    ctx.lineWidth = 20;
    if (!isConnection &&
      ctx.isPointInStroke(path, this.mouseposition.x, this.mouseposition.y) //||
      // ctx.isPointInPath(this.mouseposition.x + 2, this.mouseposition.y) ||
      // ctx.isPointInPath(this.mouseposition.x - 2, this.mouseposition.y) ||
      // ctx.isPointInPath(this.mouseposition.x, this.mouseposition.y + 2) ||
      // ctx.isPointInPath(this.mouseposition.x, this.mouseposition.y - 2)
      ) {
      //console.log(true);
      ctx.strokeStyle = 'rgba(0,123,255,1)';
      ctx.lineWidth = 2;
      ctx.setLineDash([15,5]);
      ctx.lineDashOffset = -this.lineDashOffset;
      ctx.stroke(path);
      ctx.setLineDash([]);
      ctx.lineWidth = 16;
      ctx.strokeStyle = 'rgba(0,123,255,.1)';

      // Move line delete shape over line
      let lineDelete = document.getElementById('connection_delete');
      lineDelete.style.display = "block";
      lineDelete.style.top = (intersect.y - transform.y)/scale - lineDelete.offsetHeight/2 + 'px';;
      lineDelete.style.left = (intersect.x - transform.x)/scale - lineDelete.offsetWidth/2 + 'px';;
      // lineDelete.style.top = (y1 + (y2 - y1)/2 - transform.y)/scale - lineDelete.offsetHeight/2 + 'px';;
      // lineDelete.style.left = (x1 + (x2 - x1)/2 - transform.x)/scale - lineDelete.offsetWidth + 'px';;
      this.sourceId = source.id;
      this.targetId = target.id;
    } else if (isConnection) {
      ctx.strokeStyle = 'rgba(0,123,255,1)';
      //ctx.strokeStyle = paths.color;
      ctx.lineWidth = 2;
      ctx.setLineDash([15,5]);
      ctx.lineDashOffset = -this.lineDashOffset;
      ctx.stroke(path);
      ctx.setLineDash([]);
      ctx.lineWidth = 16;
      ctx.strokeStyle = 'rgba(0,123,255,.05)';
    } else {
      ctx.lineWidth = 1;
    }

    ctx.stroke(path);

    ctx.setLineDash([]);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';
    ctx.stroke(path2);
    ctx.fill(path2);

  }

  generateLine = (x1, x2, y1, y2, sourceWidth, sourceHeight, targetWidth, targetHeight, scale) => {
    var cornerRadius = 8;
    if (Math.abs(y2-y1)/2 < cornerRadius) cornerRadius = Math.abs(y2-y1)/2;
    if (Math.abs(x2-x1)/2 < cornerRadius) cornerRadius = Math.abs(x2-x1)/2;

    let path = new Path2D();
    let path2 = new Path2D();

    let color = 'black';

    //ctx.beginPath();
    path.moveTo(x1, y1);

    let intersect = {};

    let angle = Math.atan2(y2-y1, x2-x1) * 180 / Math.PI + 180;

    let arrowSize = 15 * scale;

    if ((angle > 125 && angle < 145) || (angle > 215 && angle < 235)) {
      // Box 2 is diagonally right/down or right/up from Box 1
      color = "red";
      x1 += (sourceWidth * scale)/2;
      path.moveTo(x1, y1);
      //path.moveTo(x1 + (targetWidth * scale)/2, y1);
      y2 += (y2>y1 ? (-targetHeight * scale)/2 : (targetHeight * scale)/2);
      path.lineTo(x2 + (x2>x1 ? -cornerRadius : cornerRadius), y1 );
      path.arcTo(x2, y1, x2, y1 + (y2>y1 ? cornerRadius : -cornerRadius), cornerRadius);
      path.lineTo(x2, y2);

      intersect = {x: x2, y: y1 + (y2>y1 ? cornerRadius : -cornerRadius)};

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 - (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2 + (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2, y2);


    } else if ((angle > 35 && angle < 55) || (angle > 305 && angle < 325)) {
      // Box 2 is diagonally left/up or left/down from Box 1
      color = "blue";
      x1 -= (sourceWidth * scale)/2;
      path.moveTo(x1, y1);
      //path.moveTo(x1 + (targetWidth * scale)/2, y1);
      y2 += (y2>y1 ? (-targetHeight * scale)/2 : (targetHeight * scale)/2);
      path.lineTo(x2 + (x2>x1 ? -cornerRadius : cornerRadius), y1 );
      path.arcTo(x2, y1, x2, y1 + (y2>y1 ? cornerRadius : -cornerRadius), cornerRadius);
      path.lineTo(x2, y2);

      intersect = {x: x2, y: y1 + (y2>y1 ? cornerRadius : -cornerRadius)};

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 - (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2 + (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2, y2);


    } else if ((angle > 315 && angle < 360) || (angle > 0 && angle <= 45)) {
      // Box 2 is left of Box 1
      if (x1 < x2) {
        x1 += (sourceWidth * scale)/2;
      } else {
        x1 -= (sourceWidth * scale)/2;
      }
      color = "green";
      x2 += (x2>x1 ? (-targetWidth * scale)/2 : (targetWidth * scale)/2);
      path.lineTo(x1 + (x2-x1)/2 + (x2-x1 >0 ? -cornerRadius : cornerRadius), y1);
      path.arcTo(x1 + (x2-x1)/2, y1, (x1 + (x2-x1)/2), y1 + (y2-y1 > 0 ? cornerRadius : -cornerRadius), cornerRadius );
      path.lineTo(x1 + (x2-x1)/2, y2 - (y2-y1 > 0 ? cornerRadius : -cornerRadius));
      path.arcTo(x1 + (x2-x1)/2, y2, x2 - (x2-x1 > 0 ? cornerRadius : -cornerRadius), y2, cornerRadius );
      path.lineTo(x2, y2);

      intersect = {x: (x2+x1)/2, y: (y2+y1)/2};
      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 - (arrowSize));
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 + (arrowSize));
      path2.lineTo(x2, y2);


    } else if (angle > 135 && angle <= 225) {
      // Box 2 is to the right of Box 1
      if (x1 < x2) {
        x1 += (sourceWidth * scale)/2;
      } else {
        x1 -= (sourceWidth * scale)/2;
      }
      color = "purple";
      x2 += (x2>x1 ? (-targetWidth * scale)/2 : (targetWidth * scale)/2);
      path.lineTo(x1 + (x2-x1)/2 + (x2-x1 >0 ? -cornerRadius : cornerRadius), y1);
      path.arcTo(x1 + (x2-x1)/2, y1, (x1 + (x2-x1)/2), y1 + (y2-y1 > 0 ? cornerRadius : -cornerRadius), cornerRadius );
      path.lineTo(x1 + (x2-x1)/2, y2 - (y2-y1 > 0 ? cornerRadius : -cornerRadius));
      path.arcTo(x1 + (x2-x1)/2, y2, x2 - (x2-x1 > 0 ? cornerRadius : -cornerRadius), y2, cornerRadius );
      path.lineTo(x2, y2);

      intersect = {x: (x2+x1)/2, y: (y2+y1)/2};
      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 - (arrowSize));
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 + (arrowSize));
      path2.lineTo(x2, y2);

    } else {
      // Box 2 is above or below Box 1
      if (y1 < y2) {
        y1 += (sourceHeight * scale)/2;
      } else {
        y1 -= (sourceHeight * scale)/2;
      }
      color = "black";
      y2 += (y2>y1 ? (-targetHeight * scale)/2 : (targetHeight * scale)/2);
      path.lineTo(x1, y1 + (y2-y1)/2 + (y2-y1 >0 ? -cornerRadius : cornerRadius));
      path.arcTo(x1, y1 + (y2-y1)/2, x1 + (x2-x1 > 0 ? cornerRadius : -cornerRadius), y1 + (y2-y1)/2, cornerRadius );
      path.lineTo(x2 - (x2-x1 > 0 ? cornerRadius : -cornerRadius), y1 + (y2-y1)/2);
      path.arcTo(x2, y1 + (y2-y1)/2, x2, y2 + (x2-x1 > 0 ? cornerRadius : -cornerRadius), cornerRadius );
      path.lineTo(x2, y2);

      intersect = {x: (x2+x1)/2, y: (y2+y1)/2};
      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 - (arrowSize), y2 + 1.5 * (y2<y1 ? arrowSize : -arrowSize));
      path2.lineTo(x2 + (arrowSize), y2 + 1.5 * (y2<y1 ? arrowSize : -arrowSize));
      path2.lineTo(x2, y2);
    }

    return {path, path2, color, intersect};
  }

  generateLineBezier = (x1, x2, y1, y2, sourceWidth, sourceHeight, targetWidth, targetHeight, scale) => {
    var cornerRadius = 8;
    if (Math.abs(y2-y1)/2 < cornerRadius) cornerRadius = Math.abs(y2-y1)/2;
    if (Math.abs(x2-x1)/2 < cornerRadius) cornerRadius = Math.abs(x2-x1)/2;

    let path = new Path2D();
    let path2 = new Path2D();
    let intersect = {};
    let color = 'black';

    //ctx.beginPath();
    path.moveTo(x1, y1);

    let bezDist = 500*scale;
    let bezDistX = bezDist;
    let bezDistY = bezDist;
    bezDistX= (Math.abs(x2-x1)/2 - 250*scale);
    bezDistY = (Math.abs(y2-y1)/2 - 100*scale);

    bezDistX = Math.max(bezDistX, 25);
    bezDistY = Math.max(bezDistY, 25);

    let arrowSize = 15 * scale;

    let angle = Math.atan2(y2-y1, x2-x1) * 180 / Math.PI + 180;
    // right = 135 to 225
    // bottom = 225 to 315
    // top = 45 to 135
    // left = 315 to 360 && 0 to 45
    if ((angle > 125 && angle < 145) || (angle > 215 && angle < 235)) {
      // Box 2 is diagonally right/down or right/up from Box 1

      color = "red";
      x1 += (sourceWidth * scale)/2;

      //path.moveTo(x1 + (targetWidth * scale)/2, y1);
      y2 += (y2>y1 ? (-targetHeight * scale)/2 : (targetHeight * scale)/2);
      path.moveTo(x1, y1);

      if (y1 < y2) {
        path.bezierCurveTo(x1 + bezDist/2, y1, x2, y2 - bezDist/2, x2, y2);
        intersect = this.bezierMidpoint(x1, y1, x1 + bezDist/2, y1, x2, y2 - bezDist/2, x2, y2);
      } else {
        path.bezierCurveTo(x1 + bezDist/2, y1, x2, y2 + bezDist/2, x2, y2);
        intersect = this.bezierMidpoint(x1, y1, x1 + bezDist/2, y1, x2, y2 + bezDist/2, x2, y2);
      }

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 - (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2 + (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2, y2);


    } else if ((angle > 35 && angle < 55) || (angle > 305 && angle < 325)) {
      // Box 2 is diagonally left/up or left/down from Box 1
      color = "blue";
      x1 -= (sourceWidth * scale)/2;
      path.moveTo(x1,y1);
      //path.moveTo(x1 + (targetWidth * scale)/2, y1);
      y2 += (y2>y1 ? (-targetHeight * scale)/2 : (targetHeight * scale)/2);

      if (y1 < y2) {
        path.bezierCurveTo(x1 - bezDist/2, y1, x2, y2 - bezDist/2, x2, y2);
        intersect = this.bezierMidpoint(x1, y1, x1 - bezDist/2, y1, x2, y2 - bezDist/2, x2, y2);
      } else {
        path.bezierCurveTo(x1 - bezDist/2, y1, x2, y2 + bezDist/2, x2, y2);
        intersect = this.bezierMidpoint(x1, y1, x1 - bezDist/2, y1, x2, y2 + bezDist/2, x2, y2);
      }

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 - (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2 + (arrowSize), y2 + (1.5 * (y2<y1 ? arrowSize : -arrowSize)));
      path2.lineTo(x2, y2);


    } else if ((angle > 315 && angle < 360) || (angle > 0 && angle <= 45)) {
      // Box 2 is left of Box 1
      if (x1 < x2) {
        x1 += (sourceWidth * scale)/2;
      } else {
        x1 -= (sourceWidth * scale)/2;
      }
      path.moveTo(x1,y1);
      color = "green";
      x2 += (x2>x1 ? (-targetWidth * scale)/2 : (targetWidth * scale)/2);
      path.bezierCurveTo(x1 - bezDistX, y1, x2 + bezDistX, y2, x2, y2);
      intersect = this.bezierMidpoint(x1, y1, x1 - bezDistX, y1, x2 + bezDistX, y2, x2, y2);

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 - (arrowSize));
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 + (arrowSize));
      path2.lineTo(x2, y2);


    } else if (angle > 135 && angle <= 225) {
      // Box 2 is to the right of Box 1
      if (x1 < x2) {
        x1 += (sourceWidth * scale)/2;
      } else {
        x1 -= (sourceWidth * scale)/2;
      }
      path.moveTo(x1,y1);
      color = "purple";
      x2 += (x2>x1 ? (-targetWidth * scale)/2 : (targetWidth * scale)/2);

      path.bezierCurveTo(x1 + bezDistX, y1, x2 - bezDistX, y2, x2, y2);
      intersect = this.bezierMidpoint(x1, y1, x1 + bezDistX, y1, x2 - bezDistX, y2, x2, y2);

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 - (arrowSize));
      path2.lineTo(x2 + 1.5 * (x2<x1 ? arrowSize : -arrowSize), y2 + (arrowSize));
      path2.lineTo(x2, y2);

    } else {
      // Box 2 is above or below Box 1
      if (y1 < y2) {
        y1 += (sourceHeight * scale)/2;
      } else {
        bezDistY = -bezDistY;
        y1 -= (sourceHeight * scale)/2;
      }
      path.moveTo(x1,y1);
      color = "black";
      y2 += (y2>y1 ? (-targetHeight * scale)/2 : (targetHeight * scale)/2);

      path.bezierCurveTo(x1, y1 + bezDistY, x2, y2 - bezDistY, x2, y2);
      intersect = this.bezierMidpoint(x1, y1, x1, y1 + bezDistY, x2, y2 - bezDistY, x2, y2);

      //path.beginPath();
      path2.moveTo(x2, y2);
      path2.lineTo(x2 - (arrowSize), y2 + 1.5 * (y2<y1 ? arrowSize : -arrowSize));
      path2.lineTo(x2 + (arrowSize), y2 + 1.5 * (y2<y1 ? arrowSize : -arrowSize));
      path2.lineTo(x2, y2);
    }


    return {path, path2, color, intersect};
  }

  bezierMidpoint = (x1, y1, x2, y2, x3, y3, x4, y4) => {
    let p1x = (x1 + x2)/2;
    let p1y = (y1 + y2)/2;
    let p2x = (x2 + x3)/2;
    let p2y = (y2 + y3)/2;
    let p3x = (x3 + x4)/2;
    let p3y = (y3 + y4)/2;

    let p4x = (p1x + p2x)/2;
    let p4y = (p1y + p2y)/2;
    let p5x = (p2x + p3x)/2;
    let p5y = (p2y + p3y)/2;

    let p6x = (p4x + p5x)/2;
    let p6y = (p4y + p5y)/2;

    return {x:p6x, y:p6y};
  }


  handleIntentClick = (id) => { // eslint-disable-line
    utils.log("Intent Clicked");
    const { project, currentLocale } = this.state;

    utils.log('Project before Click =', JSON.parse(JSON.stringify(project)));
    const intents = project.locales[currentLocale].intentSettings.filter(function(s) { return s.id === id });
    utils.log("intents=", intents);
    if (intents.length > 0) {
      this.setState({
        selectedIntent: JSON.parse(JSON.stringify(intents[0])) //Object.assign({}, intents[0]),
      });
    }
  }

  handleDeleteConfirm = (e, id) => {
    utils.log("Delete Confirm");
    //throw({error: "this sucks!"});

    const { project } = this.state;

    let targetIntent = project.intents.find(f => { return f.id === id});

    utils.log("TARGET INTENT", targetIntent);
    if (targetIntent) {
      this.setState({
        showConfirm: true,
        confirmTitle: `Delete Block`,
        confirmBody: `Are you sure you wish to delete the "${targetIntent.name}" block?`,
        confirmButtonText: `Delete`,
        confirmTarget: id
      });

    }

    $('#deleteModal').modal('show');

    e.preventDefault();
    e.stopPropagation();
  }

  handleDelete = () => { // eslint-disable-line

    const { confirmTarget } = this.state;

    $('#deleteModal').modal('hide');
    this.setState({
      showConfirm: false
    })

    if (!confirmTarget) {
      return;
    }

    let id = confirmTarget;

    utils.log("Delete Clicked");
    const { project, isDemo, currentLocale } = this.state;

    utils.log(JSON.stringify(project, null, 2));

    // Remove the intent from the intents
    utils.log("Before Delete:", project.intents);
    project.intents = project.intents.filter(f => { return f.id !== id});
    utils.log("After Delete:", project.intents);

    // Remove the intent from the locales
    project.locales.forEach((localeInfo) => {

      // Remove the target intent
      localeInfo.intentSettings = localeInfo.intentSettings.filter(f => { return f.id !== id});


      localeInfo.intentSettings.forEach(intent => {
        // Remove the intent from all ActivatedBy lists
        if (intent.activatedBy) {
          intent.activatedBy = intent.activatedBy.filter(f => { return f !== id });
        }

          // Remove the intent from all NextIntents
        if (intent.nextActions) {
          intent.nextActions.forEach(action => {
            if (action.intent === id) {
              action.intent = null;
            }
          })
        }
      });



    });


    var that = this;
    !isDemo && utils.updateProject(project).then(result => {
      that.setState({
        project,
        selectedIntent: JSON.parse(JSON.stringify(project.locales[currentLocale].intentSettings[0])) //Object.assign({}, intents[0]),
      });

      // window.setTimeout(() => {
      //   //that.setInitialBoxPositions();
      //   //that.drawLines();
      // }, 200);

      return null;
    });

    // e.preventDefault();
    // e.stopPropagation();
  }



  /** Call update API  here  */
  addUpdateProduct = (postData, productId) => {
    // let newProduct = {
    //   id: utils.guid(),
    //   name: 'Bluetooth Connector',
    //   days: 30,
    //   paymentFrequency: 'Yearly',
    //   summary: 'Hardware product',
    //   description: 'Modern IT architecture is rapidly evolving, with the cloud and devices becoming the new anchors for enterprise data.',
    //   keywords: 'PDF,PSD',
    //   productUrl: 'http://www.psdgraphics.com/file/download-software-box.jpg',
    //   examplePhases: [
    //     'Box 1', 'Box 2', 'Box 3'
    //   ],
    //   cardDescription: 'Advanced threat protection on a platform that supports an open ecosystem. Our goal is to make the cloud the most secure environment for your business.',
    //   price: 120
    // };
    const { project} = this.state;
    project.products = project.products || [];
    if(productId){
      project.products[productId] = postData;
    }else{
      project.products.push(postData);
    }
    this.setState({
      project
    });
    this.updateProject(project);
    $('#AddProductModal').modal('hide');
  }


  clearAddField = () => {

    this.setState({
      selectedProduct: "",
      productKey: "",
      postAction : 'Create',
    })

    utils.log('call me called clearAddField', this.state);
    setTimeout(() => {
      $('#AddProductModal').modal('show');
    }, 1800);
  }

  handleDeleteProduct = (key) => { // eslint-disable-line
    utils.log('Deleted key parent:', key);
    const { project} = this.state;
    // Remove the intent from the intents
    utils.log("Before Delete:", project.products);
    project.products = project.products.filter(f => {return f.key !== key});
    var that = this;
    utils.updateProject(project).then(result => {
      that.setState({
        project
      });
      return null;
    });
  }


  handleEditProduct = (key) => { // eslint-disable-line
    const { project} = this.state;
    utils.log('Edit key parent:', key);
    const product = project.products.filter(function(s) {
      return s.key  == key
    });
    utils.log("product detail=", product);
    utils.log("product detail=", product.length > 0);

      if (product.length > 0) {
        this.setState({
          selectedProduct: product, //Object.assign({}, intents[0]),
          productKey: key,
          postAction: 'Update',
        });
      }
        setTimeout(() => {
        $('#AddProductModal').modal('show');
        }, 800)

  }


  handleTestModalOpen = () => { // eslint-disable-line
    this.setState({
      showTestPanel: !this.state.showTestPanel
    });
  }

  handlePublishModalOpen = () => { // eslint-disable-line
    window.ga('send', 'event', 'Skill Making', 'Publish', 'Button');
    this.setState({
      showPublishProject: true
    })
    $('#PublishProjectModal').modal('show');
  }

  handlePremiumModalOpen = () => { // eslint-disable-line
    $('#PremiumModal').modal('show');
  }

  handlePremiumModalClose = () => { // eslint-disable-line
    $('#PremiumModal').modal('hide');
  }

  handleShareModalOpen = () => { // eslint-disable-line
    $('#ShareProjectModal').modal('show');
  }

  handleManageProductModal = () => { // Show product list
    $('#ManageProductModal').modal('show');
  }

  onClickAddProduct = () => { // Show product list
    $('#ManageAddModal').modal('show');
  }

  handleVariablesChange = (data) => {
    let { project, isDemo } = this.state;

    //console.log('------------Updating Variables ---------------');

    project.variables = data;

    let currentProject = JSON.parse(JSON.stringify(project));

    this.updateProject(currentProject);
    // let that = this;
    // if (this.updateTimeout) {
    //   window.clearTimeout(this.updateTimeout);
    // }
    //
    // this.updateTimeout = setTimeout(() => {
    //   !isDemo && utils.updateProject(currentProject).then(result => {
    //     utils.log('project', JSON.parse(JSON.stringify(currentProject)));
    //     utils.log("------------Project Updated------------");
    //
    //     that.setState({
    //       project: currentProject,
    //       isselectedIntentChanged: false,
    //       changesSaved: true
    //     });
    //   }).catch(err => {
    //     utils.log(err);
    //   });
    //
    //   //that.drawLines();
    // },2000);
  }

  handleSettingsChange = (data) => {
    let { project, builtInVariables } = this.state;

    project.name = data.projectName;
    project.defaultVoice = data.defaultVoice;
    project.permissions = data.permissions;
    project.dynamicEntities = data.dynamicEntities;

    let variables = builtInVariables;
    // Add variables from permissions
    if (project.permissions) {

      if (project.permissions.firstName) {
        if (!variables.find(v => v.name === '{va_userFirstName}')) {
          variables.push({
            name: "{va_userFirstName}",
            description: "First Name of user from their Amazon account.<br/>  Permission is required to access this information."
          });
        }
      } else {
        variables = variables.filter(v => v.name !== '{va_userFirstName}');
      }

      if (project.permissions.fullName) {
        if (!variables.find(v => v.name === '{va_userFullName}')) {
          variables.push({
            name: "{va_userFullName}",
            description: "Full Name of user from their Amazon account.<br/>  Permission is required to access this information."
          })
        }
      } else {
        variables = variables.filter(v => v.name !== '{va_userFullName}');
      }


      if (project.permissions.email) {
        if (!variables.find(v => v.name === '{va_userEmail}')) {
          variables.push({
            name: "{va_userEmail}",
            description: "Email address of user from their Amazon account.<br/>  Permission is required to access this information."
          })
        }
      } else {
        variables = variables.filter(v => v.name !== '{va_userEmail}');
      }


      if (project.permissions.phoneNumber) {
        if (!variables.find(v => v.name === '{va_userPhoneNumber}')) {
          variables.push({
            name: "{va_userPhoneNumber}",
            description: "Phone Number of user from their Amazon account.<br/>  Permission is required to access this information."
          })
        }
      } else {
        variables = variables.filter(v => v.name !== '{va_userPhoneNumber}');
      }
    }

    this.setState({
      builtInVariables: variables
    })

    this.updateProject(project);
  }

  handleIntentChange = (intent) => { // eslint-disable-line
    let { project, currentLocale, isDemo } = this.state;

    utils.log('Intent Change=', intent);
    let index = project.locales[currentLocale].intentSettings.map(f => { return f.id; }).indexOf(intent.id);
    utils.log('currentIndex = ', index);
    utils.log('currentLocale=', currentLocale);
    utils.log('before 1st splice=', JSON.parse(JSON.stringify(project.locales[currentLocale])));
    project.locales[currentLocale].intentSettings.splice(index, 1);
    utils.log('after 1st splice=', JSON.parse(JSON.stringify(project.locales[currentLocale])));
    project.locales[currentLocale].intentSettings.splice(index, 0, JSON.parse(JSON.stringify(intent)));

    if (project.locales[currentLocale].locale === 'en-US' && project.synchEnglishLocales) {
      project.locales.forEach(localeInfo => {
        if (localeInfo.locale.startsWith('en-')) {
          localeInfo.intentSettings.splice(index, 1);
          localeInfo.intentSettings.splice(index, 0, JSON.parse(JSON.stringify(intent)));
        }
      });
    }

    utils.log('Updated Project = ', JSON.parse(JSON.stringify(project)));

    let currentProject = JSON.parse(JSON.stringify(project));
    this.updateProject(currentProject);
  }

  updateProject = (project) => { // eslint-disable-line
    let { isDemo } = this.state;

    utils.log('SAVING PROJECT', JSON.parse(JSON.stringify(project)));
    let that = this;
    if (this.updateTimeout) {
      window.clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      !isDemo && utils.updateProject(project).then(result => {
        utils.log('project', JSON.parse(JSON.stringify(project)));
        utils.log("------------Project Updated------------");

        that.setState({
          project: project,
          isselectedIntentChanged: false,
          changesSaved: true
        });
      }).catch(err => {
        utils.log(err);
      });

      //that.drawLines();
    },500);

    // !isDemo && utils.updateProject(project).then(result => {
    //   utils.log('Updating Project (updateproject)', JSON.parse(JSON.stringify(project)));
    //   this.setState({
    //     project,
    //     isselectedIntentChanged: false,
    //     changesSaved: true
    //   });
    //
    //   this.setInitialBoxPositions();
    //   //this.drawLines();
    //
    //   window.setTimeout(() => {
    //     this.setState({
    //       changesSaved: false
    //     });
    //   }, 3000);
    // }).catch(err => {
    //   utils.log(err);
    // });


  }

  handleRefreshProject = () => {
    utils.log("Refreshing Project");
    let { project } = this.state;

    let that = this;
    utils.getProjectById(project.id).then(proj => {

      utils.log("Project (refresh project)=", proj);


      that.setState({
        project: proj,
      });
    });

      // setTimeout(() => {
      //   this.initialize(user.showDetailsIntro == null || user.showDetailsIntro === true);
      //
      // }, 200);
  }


  handleIntentTextChange = (e) => { // eslint-disable-line
    utils.log('Text Change')
    let { selectedIntent } = this.state;

    selectedIntent.text = e.target.value;

    this.setState({
      selectedIntent,
      isselectedIntentChanged: true,
      changesSaved: false
    });



  }

  changeLocale = (locale) => { // eslint-disable-line
    const {project, selectedIntent, isDemo} = this.state;

    let currentLocale = project.locales.findIndex(l => {return l.locale === locale});

    this.setState({
      currentLocale,
    });

    project.testLocale = locale;
    var that = this;
    !isDemo && utils.updateProject(project).then(response => {
      that.handleIntentClick(selectedIntent.id);
    });
    // setTimeout(() => {
    //     that.handleIntentClick(selectedIntent.id);
    // }, 500);


  }

  isIntentNameValid = (intentName) => {
    let inRegEx = /^[a-zA-Z][a-zA-Z ]*$|^$/g;
    return inRegEx.test(intentName);
  }

  changeIntentName = (value) => { // eslint-disable-line
    let { project, selectedIntent, currentLocale } = this.state;

    if (this.isIntentNameValid(value)) {
      let pf = project.intents.find(f => {return f.id === selectedIntent.id});
      pf.name = value;

      let lf = project.locales[currentLocale].intentSettings.find(f => { return f.id === selectedIntent.id});
      lf.name = value;

      this.setState({
        project
      });

      this.updateProject(project);
    }
  }

  changeIntentDescription = (value) => { // eslint-disable-line
    let { project, selectedIntent } = this.state;

    let pf = project.intents.find(f => {return f.id === selectedIntent.id});
    pf.description = value;

    //project.products = [];
    // let newProduct = {
    //   id: utils.guid(),
    //   name: 'Bluetooth Connector',
    //   days: 30,
    //   frequency: 'Yearly',
    //   summary: 'Hardware product',
    //   description: 'Modern IT architecture is rapidly evolving, with the cloud and devices becoming the new anchors for enterprise data.',
    //   keywords: 'PDF,PSD',
    //   productUrl: 'http://www.psdgraphics.com/file/download-software-box.jpg',
    //   examplePhases: [
    //     'Box 1', 'Box 2', 'Box 3'
    //   ],
    //   cardDescription: 'Advanced threat protection on a platform that supports an open ecosystem. Our goal is to make the cloud the most secure environment for your business.',
    //   price: 120
    // };
    // project.products.push(newProduct)

    this.setState({
      project
    });

    this.updateProject(project);
  }

  handleNewIntent = (id) => {
    this.handleAddIntent(false, id);
  }

  handleAddIntent = (connectToCurrentIntent, connectToIntentId) => { // eslint-disable-line
    let { project, selectedIntent } = this.state;

    let currentProject = JSON.parse(JSON.stringify(project));
    let transform = panzoomControl.getTransform();

    utils.log("TRANSFORM=", transform);

    let zc = document.getElementById('zoomableContainer');

    let newIntents = currentProject.intents.find(x => x.name.startsWith('New Block'));
    let intentSuffix = "";
    if (newIntents && newIntents.length > 0) {
      intentSuffix = "_" + newIntents.length;
    }

    let newIntent = {
      id: utils.guid(),
      name: 'New Block' + intentSuffix,
      description: 'New block description.',
      x: (-transform.x + zc.offsetWidth/2 ) * (1/transform.scale) - 250,
      y: (-transform.y + zc.offsetHeight/2) * (1/transform.scale)  - 100
    };

    currentProject.intents.push(newIntent);

    let activatedBy = [];
    if (selectedIntent && connectToCurrentIntent === true) {
      activatedBy.push(selectedIntent.id);
    }

    if (connectToIntentId) {
      let sourceIntent = currentProject.intents.find(x => x.id == connectToIntentId);
      activatedBy.push(connectToIntentId);
      newIntent.x = sourceIntent.x + 650;
      newIntent.y = sourceIntent.y;
    }

    let defaultIntent = {
      triggeredBy: 'user',
      activatedBy: activatedBy,
      sampleUtterances: [],
      responseGroups: [{
        type: 'responseGroup',
        functions: [
          {
            type: 'response',
            responses: []
          }
        ],
        visitStart: 1,
        visitEnd: 0
      }],
      nextActions: [
        {
          action: 'waitForUser'
        }
      ],
      reprompts: [],
      invalidResponses: [],
      contextualHelp: []
    }

    let newIntentSetting = Object.assign(defaultIntent, newIntent);


    currentProject.locales.forEach((locale) => {
      locale.intentSettings.push(newIntentSetting);
    });

    this.setState({
      project: currentProject
    });

    this.updateProject(currentProject);

    window.setTimeout(() => {
      this.setInitialBoxPositions();
      this.handleIntentClick(newIntent.id);

    }, 100);

    window.setTimeout(() => {
      this.setInitialBoxPositions();
      this.handleIntentClick(newIntent.id);

    }, 300);

    window.ga('send', 'event', 'Skill Making', 'New Project', 'New Intent');




  }

  handleCopyIntent = (connectToCurrentIntent) => { // eslint-disable-line
    let { project, selectedIntent, currentLocale } = this.state;

    const newProject = JSON.parse(JSON.stringify(project));

    let transform = panzoomControl.getTransform();

    utils.log("TRANSFORM=", transform);

    let zc = document.getElementById('zoomableContainer');

    // Make a copy of the current intent
    let currentIntent = newProject.intents.find(x => x.id === selectedIntent.id);
    let newIntent = JSON.parse(JSON.stringify(currentIntent));
    newIntent.id = utils.guid();
    newIntent.name += " Copy";
    newIntent.x = (-transform.x + zc.offsetWidth/2 ) * (1/transform.scale) - 250;
    newIntent.y = (-transform.y + zc.offsetHeight/2) * (1/transform.scale)  - 100;

    newProject.intents.push(newIntent);

    let currentIntentSetting = newProject.locales[currentLocale].intentSettings.find(x => x.id === selectedIntent.id);
    let newIntentSetting = JSON.parse(JSON.stringify(currentIntentSetting));
    newIntentSetting.id = newIntent.id;

    newProject.locales.forEach((locale) => {
      locale.intentSettings.push(newIntentSetting);
    });

    this.updateProject(newProject);

    this.setState({
      newProject
    });

    window.ga('send', 'event', 'Skill Making', 'New Project', 'New Intent');

    setTimeout(() => {
      this.setInitialBoxPositions();
      this.handleIntentClick(newIntent.id);

    }, 500);

  }

  changeShareStatus = (status) => { // eslint-disable-line
    utils.log("Share Status Updated=", status);
    let { project } = this.state;

    project.isSharingEnabled = status;

    this.setState({
      project
    });

    this.updateProject(project);
  }

  handleZoomClick = () => {
    utils.log('zoom click');
  }

  copyProject = () => {
    const { project } = this.state;

    const newProject = JSON.parse(JSON.stringify(project));

    newProject.id = utils.guid();
    newProject.isSharingEnabled = false;
    newProject.skillId = null;
    newProject.testEmail = null;
    newProject.isDemo = null;

    let that = this;
    utils.updateProject(newProject).then(response => {
      window.ga('send', 'event', 'Skill Making', 'Template', 'Duplicate-' + project.id);
      that.goTo(`project/${newProject.id}`);
    }).catch(err => {
      utils.log(err);
    })

  }

  setPremium = () => {
    this.handlePremiumModalClose();
    this.setState({
      isPremium: true
    })
  }

  toggleDetailsWindow = () => {
    this.setState({
      showDetailsWindow: !this.state.showDetailsWindow
    })
  }

  handleMenuClick = (e) => { // eslint-disable-line
    utils.log('menu clicked');

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  handleUpgradeModalOpen = () => { // eslint-disable-line
    $('#EditProjectModal').modal('hide');
    $('#PremiumModal').modal('hide');
    $('#UpgradeModal').modal('show');
  }

  handleChangeActiveIntent = (id) => {
    utils.log('Active Intent Changed: ', id);
    this.setState({
      activeIntent: id
    });
  }

  handleConnectionDelete = () => {
    const { project, currentLocale } = this.state;
    let intent = project.locales[currentLocale].intentSettings.find(x => x.id === this.targetId);

    if (intent) {
      intent.activatedBy = intent.activatedBy.filter(f => { return f !== this.sourceId });
    }

    this.setState({
      project
    });

    this.updateProject(project);
  }

  handleZoomIn = () => {
    let c = document.getElementById("zoomCanvas");
    let canvasRect = c.getBoundingClientRect();
    let x = canvasRect.left;
    let y = canvasRect.top;
    let width = canvasRect.width;
    let height = canvasRect.height;

    utils.log(x, y, width, height);

    let transform = panzoomControl.getTransform();

    let scale = transform.scale * 1.2;
    utils.log("new scale", scale);

    panzoomControl.zoomAbs(x + width/2, y + height/2, scale);

    this.saveTransform();
  }

  handleZoomOut = () => {
    let c = document.getElementById("zoomCanvas");
    let canvasRect = c.getBoundingClientRect();
    let x = canvasRect.left;
    let y = canvasRect.top;
    let width = canvasRect.width;
    let height = canvasRect.height;

    utils.log(x, y, width, height);

    let transform = panzoomControl.getTransform();

    let scale = transform.scale / 1.2;
    utils.log("new scale", scale);

    panzoomControl.zoomAbs(x + width/2, y + height/2, scale);

    this.saveTransform();
  }

  handleLineTypeChange = (lineType) => {
    this.lineType = lineType;

    this.saveTransform();
  }

  handleFitAll = () => {
    this.fitAll();
    this.fitAll();
    this.saveTransform();
  }

  fitAll = () => {
    const { project, currentLocale } = this.state;

    let transform = panzoomControl.getTransform();
    let scale = transform.scale;
    utils.log(scale);

    let zc = document.getElementById("zoomableContainer");
    let rect = zc.getBoundingClientRect();

    utils.log(rect);
    let c = document.getElementById("zoomCanvas");
    let canvasRect = c.getBoundingClientRect();
    let x = canvasRect.left;
    let y = canvasRect.top;
    let canvasWidth = canvasRect.width;
    let canvasHeight = canvasRect.height;
    //
    // utils.log(x, y, canvasWidth, canvasHeight);
    // if (isNaN(scale) || scale == null || scale === 0) {
    //   scale = .3;
    //   utils.log(x + canvasWidth/2, y + canvasHeight/2, scale);
    //   panzoomControl.zoomAbs(x + canvasWidth/2, y + canvasHeight/2, scale);
    //   this.saveTransform();
    //   return;
    //   transform = panzoomControl.getTransform();
    //   //scale = transform.scale;
    //   utils.log("scale2=", scale);
    // }

    let minX = 999999999;
    let maxX = -999999999;
    let minY = 999999999;
    let maxY = -999999999;

    project.intents.forEach(intent => {
      if (intent.x > maxX) maxX = intent.x;
      if (intent.x < minX) minX = intent.x;
      if (intent.y > maxY) maxY = intent.y;
      if (intent.y < minY) minY = intent.y;
    });

    utils.log(minX, maxX, minY, maxY);

    let centerX = (maxX + minX)/2;
    let centerY = (maxY + minY)/2;

    utils.log("scale=", scale);
    let newX = (-centerX - 250) * scale + zc.offsetWidth/2;
    let newY = (-centerY - 100) * scale + zc.offsetHeight/2;

    let projectWidth = Math.abs(maxX - minX);
    let projectHeight = Math.abs(maxY - minY);


    let scale1 = zc.offsetWidth / (projectWidth * (1 + scale));
    let scale2 = zc.offsetHeight / (projectHeight * (1 + scale));

    let newScale = Math.min(scale1, scale2);

    utils.log(newX, newY, newScale);
    //return;

    panzoomControl.moveTo(newX, newY);
    utils.log(x + canvasWidth/2, y + canvasHeight/2, newScale);
    panzoomControl.zoomAbs(x + canvasWidth/2, y + canvasHeight/2, newScale);
  }


  render() {
    const { selectedIntent, selectedIntents, currentLocale,
      showTestPanel, isDemo, isAccountLinked, isPremium,
      showDetailsWindow, user, activeIntent,
      showConfirm, confirmTitle, confirmBody, confirmButtonText,
      showPublishProject, AmazonCode, builtInVariables} = this.state;


    //console.log("CONFIRM DATA: ", showConfirm, confirmTitle, confirmBody, confirmButtonText);
    let project = JSON.parse(JSON.stringify(this.state.project));
    let currentProject = JSON.parse(JSON.stringify(this.state.project));
    //utils.log("Project=", project)
    let activeIntents = [];
    let allIntents = [];
    let availableLocales = [];
    let localeName = ''; // eslint-disable-line
    let currentIntent = {
      name: '',
      description: ''
    };

    utils.log('SelectedIntent Before Render', selectedIntent);
    if (project) {
      utils.log('Project Before Render=', JSON.parse(JSON.stringify(project)));
      //utils.log('selectedIntents', selectedIntents);
      utils.log(project.templateId);
      let intents = templateIntents[project.templateId];
      utils.log('FEATURES=', intents);
      allIntents = project.intents.map(x => Object.assign(x, intents.find(y => y.id === x.id)));
      utils.log('Intent Information ----------->')
      utils.log(JSON.parse(JSON.stringify(allIntents)));
      utils.log(JSON.parse(JSON.stringify(project.locales[currentLocale].intentSettings)));
      activeIntents = project.locales[currentLocale].intentSettings.map(x => Object.assign(x, allIntents.find(y => y.id === x.id)));
      utils.log(JSON.parse(JSON.stringify(activeIntents)));
      //availableIntents = this.getAvailableIntents().map(x => Object.assign(x, {selected: selectedIntents && selectedIntents.includes(x.id)}));

      let pf = project.intents.find(f => {return f.id === selectedIntent.id});
      if (pf) {
        currentIntent = Object.assign(selectedIntent, {
          name: pf.name,
          description: pf.description
        });
      }
      let locales = [
        {
          value: 'en-US',
          label: 'English (US)'
        },
        {
          label: 'English (UK)',
          value: "en-GB"
        },
        {
          label:'English (IN)',
          value: "en-IN"
        },
        {
          label:'English (CA)',
          value: "en-CA"
        },
        {
          label:'English (AU)',
          value: "en-AU"
        },
        {
          label: 'Spanish (US)',
          value: "es-US"
        },
        {
          label: 'Spanish (ES)',
          value: "es-ES"
        },
        {
          label: 'Spanish (MX)',
          value: "es-MX"
        },
        {
          label:'French (FR)',
          value: "fr-FR"
        },
        {
          label: 'French (CA)',
          value: "fr-CA"
        },
        {
          label:'German',
          value: "de-DE"
        },
        {
          label:'Italian',
          value: "it-IT"
        },
        {
          label:'Japanese',
          value: "ja-JP"
        },
        {
          label: 'Portuguese (BR)',
          value: "pt-BR"
        }
      ];

      availableLocales = locales.filter(l => {
        let locale = project.locales.find(loc => {
          // utils.log('locale=', loc.locale);
          // utils.log('l.value=', l.value);
          // utils.log(loc.locale == l.value);
          return loc.locale === l.value
        });
        return locale != null;
      });

      //utils.log('availableLocales=', availableLocales);
      localeName = availableLocales.find(l => {return l.value === project.locales[currentLocale].locale}).label;
    }

    // utils.log('availableIntents', availableIntents);
    var intentGroups = [];
    var i,j,chunk = 4;
    if (activeIntents) {
      for (i=0,j=activeIntents.length; i<j; i+=chunk) {
          intentGroups.push(activeIntents.slice(i,i+chunk));
      }
    }

    // var availableIntentGroups = [];
    // chunk = 2;
    // for (i=0,j=availableIntents.length; i<j; i+=chunk) {
    //     availableIntentGroups.push(availableIntents.slice(i,i+chunk));
    // }

    let scopeCount = 0;
    let scopeColors = {};

    // utils.log('intentGroups', intentGroups);
    // utils.log('availableIntentGroups', availableIntentGroups);

    utils.log('Current Intent', currentIntent);
    return (
      <div className="details_full">
        <div id="intercom_placeholder"></div>
        <Toolbar
          variables={project ? project.variables : []}
          builtInVariables={builtInVariables}
          project={project}
          onVariablesChange={(data) => this.handleVariablesChange(data)}
          onSettingsChange={(data) => this.handleSettingsChange(data)}
          />
      {(project && !project.isDemo) ?
        <div>
          {showPublishProject && <PublishProject code={AmazonCode} isAccountLinked={isAccountLinked} project={currentProject} onUpdateProject={this.handleRefreshProject} user={user}/>}
          <ShareProject project={project} onChange={this.changeShareStatus}/>
        </div> : null
      }
      <PremiumMember onSuccess={this.setPremium} history={this.props.history}/>
      {showConfirm && <Confirm title={confirmTitle} body={confirmBody} confirmText={confirmButtonText} onConfirm={this.handleDelete} />}

        <Product
        clearAddField={this.clearAddField}
        handleDeleteProduct={this.handleDeleteProduct}
        handleEditProduct={this.handleEditProduct}
        products={project ? project.products : []}/>

      <AddProduct
        isPremium={this.isPremium}
        productArr={this.state.project === null ? [] : this.state.project.products}
        productKey={this.state.productKey}
        productDetail={this.state.selectedProduct}
        postAction={this.state.postAction}
        addUpdateProduct={this.addUpdateProduct}
        isPremium={isPremium}
      />

      <div className={"details_testWindow" + (showTestPanel ? " open" : "")}>
        <SkillTester
          visible={true}
          project={project}
          locale={project ? project.locales[currentLocale].locale : 'en-US'}
          onChangeActiveIntent={this.handleChangeActiveIntent}
        />
      </div>

        <HeaderSecure
          project={project}
          currentLocale={currentLocale}
          auth={this.props.auth}
          isProfessional={isPremium}
          history={this.props.history}
          onChangeLocale={this.changeLocale}
          onTestClick={this.handleTestModalOpen}
          onShareClick={this.handleShareModalOpen}
          onPublishClick={this.handlePublishModalOpen}
        />
        <div id="intentContainer" className={"intentContainer" + (showTestPanel ? ' test' : '')}>


            <div className={"details_intentTools" + (showDetailsWindow ? ' active' : '')}>
              <div className="col text-right">
                <button id="addIntent" type="button" className="btn btn-primary" onClick={this.handleAddIntent}>New Block</button>
                {selectedIntent && (selectedIntent.id === 'welcome' || selectedIntent.isLaunch == true)?
                  <button id="addIntent" type="button" className="btn btn-primary disabled details_toolBtn">Duplicate Block</button>
                  :
                  <button id="addIntent" type="button" className="btn btn-primary details_toolBtn" onClick={this.handleCopyIntent}>Duplicate Block</button>
                }
              </div>
            </div>
            <ZoomControl
              lineType={project ? project.transform.lineType : 0}
              onZoomIn={this.handleZoomIn}
              onZoomOut={this.handleZoomOut}
              onLineTypeChange={this.handleLineTypeChange}
              onFitAll={this.handleFitAll}
              />
            <div id="zoomableContainer" className="zoomableContainer">
              <canvas id="zoomCanvas" width="100%" height="100%" />
                <div className="zoomable" onClick={this.handleZoomClick}>
                <div className="connection_target" id="connection_target"/>
                <div className="connection_delete" id="connection_delete" title="Delete" onClick={this.handleConnectionDelete} data-tip="Delete Line" data-place="top" data-delay-show="1000">
                  <FA icon={["far", "trash-alt"]} />
                </div>
                {activeIntents.map((intent, f) => {

                  let color = 0;
                  if (intent.scope) {
                    utils.log('Has scope:', intent.scope);
                    if (scopeColors[intent.scope.toLowerCase()]) {
                      color = scopeColors[intent.scope.toLowerCase()];
                    } else {
                      utils.log('New Scope');
                      scopeCount++;
                      scopeColors[intent.scope.toLowerCase()] = scopeCount;
                      color = scopeCount;
                    }
                  }

                  if (selectedIntent.id === intent.id) {
                    color = 0;
                  }

                  return (
                    <Intent
                      key={f}
                      intent={intent}
                      isActive={intent.id === activeIntent}
                      isSelected={selectedIntent.id === intent.id}
                      scopeColor={color}
                      onClick={(id) => this.handleIntentClick(id)}
                      onDelete={(e,id) => this.handleDeleteConfirm(e, id)}
                      onNewIntent={(id) => this.handleNewIntent(id)}
                    />
                  );

                            // let isValid = true;
                            // if (intent.id === 'welcome' || intent.isLaunch == true) {
                            //   if (intent.messages && intent.messages[0].message !== '') {
                            //     isValid = true;
                            //   }
                            // } else if (intent.text !== '') {
                            //   isValid = true;
                            // }
                            // //utils.log('selectedIntent', selectedIntent);
                            // const intentClass = 'intent-card draggable ' + (selectedIntent.id === intent.id ? ' selected' : '');
                            // const showClose = !(intent.id === 'welcome' || intent.isLaunch == true);
                            // let color = 0;
                            // if (intent.scope) {
                            //   utils.log('Has scope:', intent.scope);
                            //   if (scopeColors[intent.scope.toLowerCase()]) {
                            //     color = scopeColors[intent.scope.toLowerCase()];
                            //   } else {
                            //     utils.log('New Scope');
                            //     scopeCount++;
                            //     scopeColors[intent.scope.toLowerCase()] = scopeCount;
                            //     color = scopeCount;
                            //   }
                            // }
                            //
                            // if (selectedIntent.id === intent.id) {
                            //   color = 0;
                            // }
                            //
                            // let headerColor = "";
                            // if (intent.id === 'welcome' || intent.isLaunch === true) {
                            //   headerColor = " intent-start";
                            // }
                            //
                            // if (intent.nextActions && intent.nextActions.length > 0) {
                            //   intent.nextActions.forEach(na => {
                            //     if (na.action === "endSession") {
                            //       headerColor = " intent-end";
                            //     }
                            //   })
                            // }
                            //
                            // if (intent.activatedBy === "help" || (intent.sampleUtterances && intent.sampleUtterances.length>0 && intent.sampleUtterances[0] === "help")) {
                            //   headerColor = " intent-help";
                            // }
                            //
                            // let intentActive = '';
                            // if (intent.id === activeIntent) {
                            //   intentActive = 'pulse';
                            // }
                            // return (
                            //     <div key={intent.id} id={'intent' + intent.id} className={ `${intentClass} ${intentActive}`} onClick={() => this.handleIntentClick(intent.id)}>
                            //       <div className={`intent_color intent_color${color}`}>
                            //       {showClose ? <div className="card-close" onClick={(e) => this.handleDeleteConfirm(e, intent.id)}><FA icon="times"/></div> : null }
                            //       <div className="card-body intent-card-body">
                            //         <div className={"intent-card-title" + headerColor}>
                            //           {intent.name}
                            //           <p className="intent-card-description">{intent.description}</p>
                            //         </div>
                            //         {this.getFunctions(intent, )}
                            //       </div>
                            //
                            //       {!isValid ? <div className="card-not-complete" data-toggle="tooltip" data-placement="right" title="This intent is not complete."><FA icon="exclamation-circle"/></div> : null }
                            //       </div>
                            //     </div>
                            // );

            })}
              </div>
              {isDemo ?
                (project.membershipLevel && !isPremium ?
                <div className="details_demoTextTop alert alert-primary">This is a premium template.  To use this template as a starting point for your own project, you will need to<button id="upgrade_button" type="button" className="btn btn-warning btn-sm hs_button2 hs_button_upgrade" onClick={this.handleUpgradeModalOpen}>Upgrade</button>  your account</div>
                :
                <div className="details_demoTextTop alert alert-danger">This is a demo project.  No changes will be saved.<br />To create an editable copy of this project, <span className="details_createCopyLink" onClick={this.copyProject}>click here</span>.</div>
              ) : null
              }
              {isDemo ?
                (project.membershipLevel && !isPremium ?
                <div className="details_demoTextBottom alert alert-primary">This is a premium template.  To use this template as a starting point for your own project, you will need to<button id="upgrade_button" type="button" className="btn btn-warning btn-sm hs_button2 hs_button_upgrade" onClick={this.handleUpgradeModalOpen}>Upgrade</button>  your account</div>
                :
                <div className="details_demoTextBottom alert alert-danger">This is a demo project.  No changes will be saved.<br />To create an editable copy of this project, <span className="details_createCopyLink" onClick={this.copyProject}>click here</span>.</div>
              ) : null
              }
            </div>

        </div>
        <div className={"details_intentTab" + (showDetailsWindow ? ' active' : '')} onClick={this.toggleDetailsWindow}>DETAILS</div>
        <div id="intent_window" className={"intentDetails" + (showTestPanel ? ' test' : '') + (showDetailsWindow ? ' active' : '')}>
          <div className="details_intentClose" onClick={this.toggleDetailsWindow}><FA icon={["far", "times"]} /></div>
          <input type="text" className="intentDetailsTitle" value={currentIntent.name} onChange={(event) => this.changeIntentName(event.target.value)} />
          <input type="text" className="intentDetailsDescription" value={currentIntent.description} onChange={(event) => this.changeIntentDescription(event.target.value)} />
          <IntentEditor
            isPremium={isPremium}
            data={currentIntent}
            intents={project && project.intents}
            variables={project && project.variables}
            onChange={(data) => this.handleIntentChange(data)}
            onShowPremiumMember={this.handlePremiumModalOpen}
            onAddIntent={() => this.handleAddIntent(true)}
            products={project && project.products}
          />
        </div>
        <audio id="player"></audio>
      </div>
    );
  }

  say = (text, index) => { // eslint-disable-line
    const player = document.getElementById('player');

    if (player.paused) {
      this.setState({
        isSpeaking: true,
        speakIndex: index
      });
      utils.sayText('<speak>' + text + '</speak>', player);

      //utils.log('Player started');
      this.speakTimer = setTimeout(this.flashPlay, 800);
    } else {
      player.pause();
      this.speakTimer = null;
      this.setState({
        isSpeaking: false,
        speakIndex: null,
        flash: false
      })
    }
  }

  flashPlay = () => { // eslint-disable-line
    const player = document.getElementById('player');

    if (!player.paused) {
      //utils.log('Player is still playing');
      this.speakTimer = setTimeout(this.flashPlay, 500);
      this.setState({
        flash: !this.state.flash
      });
    } else {
      //utils.log('Player is stopped');
      this.setState({
        isSpeaking: false,
        speakIndex: null,
        flash: false
      })
    }
  }
}


export default ProjectDetails;
