import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
//import Footer from '../Footer';
import EditProject from '../EditProject';
import Spinner from '../Spinner';
import PremiumMember from '../PremiumMember';
import Questions from './basic_questions.js';
import Questions_Free from './free_questions.js';
import Industries from '../data/industries.js';
import Countries from '../data/countries.js';
import InvocationWords from '../data/invocationWords.js'
import {Typeahead} from 'react-bootstrap-typeahead';
import Stepper from 'react-stepper-horizontal';
import Fuse from 'fuse.js';
import MultiText from '../MultiText';
import SkillTester from '../SkillTester';

import utils from '../helpers/utils.js';
import projectCategories from '../data/categories.js';

const CLOUDINARY_UPLOAD_PRESET = 'bophohq0';
const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/voiceapps/upload';


class Business extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPremium: true,
      tab: 1,
      upgradeOfferShown: false,
      selectedPlan: 'free',
      planLevel: 0,
      questions: [],
      countries: [],
      categories: [],
      filteredCategories: [],
      formData: {
        country: {
          isValid: true,
          value: 'en-US'
        },
        category: {
          isValid: true,
          value: ''
        },
        companyName: {
          isValid: true,
          value: ''
        },
        address: {
          isValid: true,
          value: ''
        },
        address2: {
          isValid: true,
          value: ''
        },
        city: {
          isValid: true,
          value: ''
        },
        state: {
          isValid: true,
          value: ''
        },
        zipCode: {
          isValid: true,
          value: ''
        },
        phoneNumber: {
          isValid: true,
          value: ''
        },
        email: {
          isValid: true,
          value: ''
        },
        icon: {
          isValid: true,
          value: ''
        },
        projectCategory: {
          isValid: true,
          value: ''
        },
        publicName: {
          isValid: true,
          value: ''
        },
        invocationName: {
          isValid: true,
          value: ''
        },
        description: {
          isValid: true,
          value: ''
        },
        keywords: {
          isValid: true,
          value: ''
        }
      },
      showIcons: false,
      icons: [],
      iconSearchTerm: 'business',
      isLoading: false,
      isEditing: false
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  componentWillMount() {
    utils.getCurrentUser().then(user => {
      this.setState({
        isPremium: user.subscription ? user.subscription : false
      });

      //utils.addIntercomTag("startedBusinessSkill");
      window.Intercom('trackEvent', 'Started Business Skill');

    });
  }

  detectmob = () => {
   if( navigator.userAgent.match(/Android/i)
   || navigator.userAgent.match(/webOS/i)
   || navigator.userAgent.match(/iPhone/i)
   || navigator.userAgent.match(/iPad/i)
   || navigator.userAgent.match(/iPod/i)
   || navigator.userAgent.match(/BlackBerry/i)
   || navigator.userAgent.match(/Windows Phone/i)
   ){
      return true;
    }
   else {
      return false;
    }
  }


  componentDidMount() {
    let countries = Countries.sort((a,b) => {
      if (a.name === "United States") return -1;
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    //let  is_mobile = this.detectmob();
    window.Intercom('update', {
      "hide_default_launcher": true
    });

    this.updateCategories.call(this);

    this.setState({
      countries
    })

    let projectId = this.props.match.params.id;

    if (projectId) {
      utils.getProjectById(projectId).then(proj => {
        utils.log(proj);

        let questions = JSON.parse(JSON.stringify(Questions_Free));
        if (proj.planLevel === 1) {
          questions = JSON.parse(JSON.stringify(Questions));
        }
        questions.forEach(question => {
          if (question.sample) {
            question.responses.push(question.sample);
          }
          if (question.samples) {
            question.responses = question.samples;
          }
        });

        this.state.questions = questions;

        this.mapProjectToConfiguration(proj);

        this.setState({
          project: proj,
          //questions
        });
      })
    } else {
      // This is a new skill
      let questions = JSON.parse(JSON.stringify(Questions_Free));

      questions.forEach(question => {
        if (question.sample) {
          question.responses.push(question.sample);
        }
        if (question.samples) {
          question.responses = question.samples;
        }
      })

      this.setState({
        questions
      })


    }


  }

  updateCategories = () => {
    let categories = [];

    Industries.forEach(industry => {
      if (industry.country_blacklist && industry.country_blacklist.includes("US")) {
        return;
      }
      if (industry.country_whitelist && !industry.country_whitelist.includes("US")) {
        return;
      }
      let parent = null;
      let newCategory = {};
      if (industry.parents.length > 0) {
        let parentCategory = Industries.find(t => t.alias === industry.parents[0]);
        if (parentCategory) {
          parent = parentCategory.title;
          if (parentCategory.parents.length > 0) {
            let grandParentCategory = Industries.find(t => t.alias === parentCategory.parents[0]);
            if (grandParentCategory) {
              parent = grandParentCategory.title + " > " + parent;
            }
          }
        }
      }
      newCategory.title = industry.title;
      newCategory.id = industry.alias;
      if (parent) {
        newCategory.title = parent + " > " + industry.title;
      }

      categories.push(newCategory);
    });

    this.setState({
      categories
    })

    this.initializeCheckout.call(this);
  }


  initializeCheckout = () => {
    var that = this;
    utils.getCurrentUser().then(user => {
      if (user == null || user.userName == null) {
        that.goTo('login/checkout');
        utils.log('User == null');
      } else {
        let cbInstance = window.Chargebee.init({
            site: "voice-apps"
        });

        //var cbInstance = window.Chargebee.getInstance();

        //var cart = cbInstance.getCart();
        utils.log("user=", user);

        cbInstance.setCheckoutCallbacks(function(cart) {
          if (user.userName) {
            var customer = {first_name: user.firstName, last_name: user.lastName, email: user.userName, billing_address:{first_name: user.firstName, last_name: user.lastName, company: "", phone: "", address_line1: "", address_line2: ""}};
            cart.setCustomer(customer);
          }


            // you can define a custom callbacks based on cart object
            return {
                loaded: function() {
                    utils.log("checkout opened");
                },
                close: function() {
                    utils.log("checkout closed");
                    // that.upgradeProject('1234');
                    //
                    // that.setState({
                    //   upgradeOfferShown: true,
                    //   tab: 3,
                    //   planLevel: 1
                    // })
                },
                success: function(hostedPageId) {
                    that.upgradeProject(hostedPageId);

                    that.setState({
                      upgradeOfferShown: true,
                      tab: 3,
                      planLevel: 1
                    })

                    utils.log("checkout success!!", hostedPageId);
                    window.fbq('trackSingle', '2044350275643772', 'Purchase', {value: '14.95', currency: 'USD'});
                    window.ga('send', 'event', 'Purchase', 'Sign Up', 'Paid', '14.95');
                    window.gtag_report_conversion(19.95);

                },
                step: function(value) {
                    // value -> which step in checkout
                    utils.log(value);
                }
            }


        });



      }

      return null;
    }).catch(err => {
      utils.log(err);
      that.goTo('login/checkout');
    })
  }

  mapProjectToConfiguration(project) {
    let {formData, questions} = this.state;

    utils.log("Plan Level=", project.planLevel);
    if (project.planLevel && project.planLevel > 0) {
      this.setState({
        planLevel: project.planLevel,
        upgradeOfferShown: true
      })
    }
    utils.log("project=", project);
    if (project.business) {
      formData.country.value = project.business.country;
      formData.category.value = project.business.category;
      formData.companyName.value = project.business.companyName;
      formData.address.value = project.business.address;
      formData.address2.value = project.business.address2;
      formData.city.value = project.business.city;
      formData.state.value = project.business.state;
      formData.zipCode.value = project.business.zipCode;
      formData.phoneNumber.value = project.business.phoneNumber;
      formData.email.value = project.business.email;
    }

    let welcomeIntent = project.locales[0].intentSettings.find(i => i.name === 'Welcome');

    let pi = welcomeIntent.publishingInfo;

    formData.publicName.value = pi.publicName;
    formData.invocationName.value = pi.invocationName;
    formData.description.value = pi.description;
    formData.keywords.value = pi.keywords;
    formData.icon.value = project.icon;
    formData.projectCategory.value = project.categoryId;

    this.setState({
      formData,
      planLevel: project.planLevel
    });

    questions.forEach(question => {
      let intent = project.locales[0].intentSettings.find(i => i.name === question.targetIntent);

      utils.log("intent=", intent);
      if (intent) {
        question.responses = intent.responseGroups[0].functions[0].responses;
        if (question.sample) {
          question.sample = question.responses[0];
        }
        if (question.samples) {
          question.samples = question.responses;
        }
      }
    })

  }

  mapConfigurationToProject() {
    let {project, formData, questions, planLevel} = this.state;

    if (project == null) {
      // This is a new project

      let templateId = 'f67b754e-71db-4d46-9043-45ca12242edc'; // free business
      if (planLevel === 1) {
        templateId = '66f16bd5-dd0e-4921-b161-383083ca8d6b'; // basic business
      }
      //Get the template
      utils.getProjectById(templateId).then(proj => {
        proj.skillId = null;
        proj.isBusiness = true;
        proj.planLevel = planLevel;
        proj.id = utils.guid();
        if (proj.business == null) {
          proj.business = {};
        }
        proj.locales[0].locale = formData.country.value;
        utils.log("new Project =", proj);
        utils.createProject(proj).then(response => {
          this.state.project = proj;
          this.mapConfigurationToProject();
          this.mapProjectToConfiguration(proj);
        });
      });

      return;
    }

    if (!project.business) {
      project.business = {};
    }

    project.business.country = formData.country.value;
    project.business.category = formData.category.value;
    project.business.companyName = formData.companyName.value;
    project.business.address = formData.address.value;
    project.business.address2 = formData.address2.value;
    project.business.city = formData.city.value;
    project.business.state = formData.state.value;
    project.business.zipCode = formData.zipCode.value;
    project.business.phoneNumber = formData.phoneNumber.value;
    project.business.email = formData.email.value;

    project.locales[0].locale = formData.country.value;

    let welcomeIntent = project.locales[0].intentSettings.find(i => i.name === 'Welcome');

    let pi = welcomeIntent.publishingInfo;

    pi.publicName = formData.publicName.value;
    pi.invocationName = formData.invocationName.value;
    pi.shortDescription = "We hope you enjoy " + formData.publicName.value;
    pi.description = formData.description.value;
    pi.samplePhrase1 = "Alexa, open " + formData.invocationName.value;
    pi.samplePhrase2 = "Alexa, start " + formData.invocationName.value;
    pi.samplePhrase3 = "Alexa, launch " + formData.invocationName.value;
    pi.keywords = formData.keywords.value;

    project.name = formData.publicName.value;
    project.invocationName = formData.invocationName.value;
    if (planLevel > 0) {
      if (formData.icon.value !== "" && formData.icon.value !== '/images/choose_image.png') {
        if (!(formData.icon.value.indexOf('upload/c_fill') > -1)) {
          project.icon = formData.icon.value.replace('upload', 'upload/c_fill,g_xy_center,h_437,w_437/c_lpad,h_512,w_512');
          project.iconSmall = formData.icon.value.replace('upload', 'upload/c_scale,w_92,h_92/c_lpad,w_108,h_108');
        }
      }
      if (formData.projectCategory.value !== "") {
        project.categoryId = formData.projectCategory.value;
      }
    }

    questions.forEach(question => {
      let intent = project.locales[0].intentSettings.find(i => i.name === question.targetIntent);

      utils.log("intent=", intent);
      if (intent) {
        intent.responseGroups[0].functions[0].responses = question.responses;
      }
    });

    utils.updateProject(project).then(response => {
      this.setState({
        project
      })
    })
  }

  upgradeProject = (subscriptionId) => {
    let {project} = this.state;

    let templateId = '66f16bd5-dd0e-4921-b161-383083ca8d6b'; // basic business

    //Get the template
    utils.getProjectById(templateId).then(proj => {
      proj.skillId = project.skillId;
      proj.isBusiness = true;
      proj.planLevel = 1;
      proj.subscription = subscriptionId;
      proj.id = project.id;
      proj.business = project.business;

      let questions = JSON.parse(JSON.stringify(Questions));

      questions.forEach(question => {
        if (question.sample) {
          question.responses.push(question.sample);
        }
        if (question.samples) {
          question.responses = question.samples;
        }
      });

      this.state.questions = questions;

      project.planLevel = 1;
      this.mapProjectToConfiguration(project);

      //utils.log(proj.business);
      utils.updateProject(proj).then(response => {
        this.state.project = proj;
        this.mapConfigurationToProject();
      });
    });

    return;
  }

  handlePrevClick = () => {
    let {tab} = this.state;

    tab--;

    if (tab < 1) {
      tab = 1;
    }

    this.setState({
      tab
    })
  }

  handleNextClick = () => {
    let {tab, selectedPlan, upgradeOfferShown} = this.state;

    if (!this.validateForm(tab)) return;

    if (tab > 1) {
      this.mapConfigurationToProject();
    }

    if (tab === 3 && !upgradeOfferShown) {
      if (selectedPlan === 'monthly' || selectedPlan === 'yearly') {

        var checkout = document.getElementById('business_signup_' + selectedPlan);
        checkout.click();

        return;
      } else {
        this.setState({
          upgradeOfferShown: true
        })

        return;
      }
    }

    tab++;

    if (tab > 6) {
      tab = 6;
    }

    this.setState({
      tab
    })
  }

  validateForm = (tab) => {
    const { upgradeOfferShown } = this.state;

    utils.log("validate form", tab);

    let result = true;

    if (tab === 1) {
      let result1 = this.validateCountry();
      let result2 = this.validateCategory();

      result = result1 && result2;
    }
    if (tab === 2) {
      let result1 = this.validateCompanyName();
      let result2 = this.validateAddress();
      let result3 = this.validateCity();
      let result4 = this.validateState();
      let result5 = this.validateZipCode();
      let result6 = this.validatePhoneNumber();
      let result7 = this.validateEmail();

      result = result1 && result2 && result3 && result4 && result5 && result6 && result7;
    } else if (tab === 3 && upgradeOfferShown) {
      let result1 = this.validateSkillName();
      let result2 = this.validateInvocationName();
      let result3 = this.validateDescription();
      let result4 = this.validateKeywords();
      let result5 = this.validateIcon();
      let result6 = this.validateProjectCategory();

      result = result1 && result2 && result3 && result4 && result5 && result6;
    }

    utils.log(result);
    return result;
  }

  validateCompanyName = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.companyName.value === "") {
      result = false;
    }

    formData.companyName.isValid = result;

    this.setState({
      formData
    })

    return result;
  }

  validateAddress = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.address.value === "") {
      result = false;
    }

    formData.address.isValid = result;

    this.setState({
      formData
    })

    return result;
  }

  validateCity = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.city.value === "") {
      result = false;
    }

    formData.city.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateState = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.state.value === "") {
      result = false;
    }

    formData.state.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateZipCode = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.zipCode.value === "") {
      result = false;
    }

    formData.zipCode.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validatePhoneNumber = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.phoneNumber.value === "") {
      result = false;
    }

    formData.phoneNumber.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateEmail = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.email.value === "") {
      result = false;
    }

    formData.email.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateSkillName = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.publicName.value === "") {
      result = false;
    }

    formData.publicName.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateInvocationName = (value) => {
    let {formData} = this.state;

    formData.invocationName.isValid = true;
    formData.invocationName.isWarning = false;
    formData.invocationName.message = "";
    formData.invocationName.warningMessage = "";
    let result = true;
    let resultMsg = "Invocation names must only contain lowercase letters, spaces, periods (when used in apostrophes), and possessive apostrophes";

    // Invocation Name
    let inRegEx = /^[a-z][a-z \.\u00C0-\u017Fa']*$/g;
    if (!inRegEx.test(formData.invocationName.value)) {
      result = false;
    } else {
      result = true;
    }

    // Check invocation words
    InvocationWords.forEach(word => {
      let wordRegEx = new RegExp("(?:^|\\s)" + word + "(?:$|\\s)", "g");
      if (wordRegEx.test(formData.invocationName.value)) {
        result = false;
        resultMsg = "Invocation name must not contain invocation words like \"start\", \"open\", \"launch\", etc.  See requirements link above for details.";
      }
    })

    // Missing
    if (formData.invocationName.value === "") {
      result = false;
      resultMsg = "Invocation name is required.";
    }

    // Single word
    if (formData.invocationName.value !== "" && formData.invocationName.value.split(" ").length === 1) {
      formData.invocationName.isWarning = true;
      formData.invocationName.warningMessage = "Warning: Single word invocation names are not allowed unless that single word is your brand."
    }

    // Abbreviations
    let abbrevRegEx = /(?:^| )[abcdfghjklmnpqrstvwxz]{2,}(?: |$)/g;
    if (abbrevRegEx.test(formData.invocationName.value)) {
      formData.invocationName.isWarning = true;
      formData.invocationName.warningMessage = "Warning: Abbreviations should be written as \"u. s. a.\" and not \"usa\".";
    }

    // Periods
    let periodRegEx = /\.[^ $]/g;
    if (periodRegEx.test(formData.invocationName.value)) {
      result = false;
      resultMsg = "Periods in abbreviations must be followed by a space. \"u. s. a.\" and not \"u.s.a.\"";
    }

    // Connecting words
    let inRegEx2 = /(?:^|\s)(?:to|from|by|if|and|whether|in|using|with|about|for|that)(?:\s|$)/g;
    if (inRegEx2.test(formData.invocationName.value)) {
      result = false;
      resultMsg = 'Invocation name must not contain connecting words like "and", "from", "in", "about", etc.  See requirements link above for details.';
    }

    formData.invocationName.isValid = result;
    formData.invocationName.message = resultMsg;

    this.setState({
      formData
    })

    return result;
  }

  validateDescription = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.description.value === "") {
      result = false;
    }

    formData.description.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateKeywords = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.keywords.value === "") {
      result = false;
    } else {

      utils.log(formData.keywords.value.replace(/,/g, ' ').replace(/ {2}/g, ' ').trim().split(' ').length);
      if (formData.keywords.value.replace(/,/g, ' ').replace(/ {2}/g, ' ').trim().split(' ').length > 30) {
        result = false;
      } else {
        result = true;
      }
    }

    formData.keywords.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateIcon = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.icon.value === "" || formData.icon.value === '/images/choose_image.png') {
      result = false;
    }

    formData.icon.isValid = result;

    this.setState({
      formData
    })


    return result;
  }

  validateProjectCategory = (value) => {
    let {formData} = this.state;

    let result = true;

    if (formData.projectCategory.value === "") {
      result = false;
    }

    formData.projectCategory.isValid = result;

    this.setState({
      formData
    })

    return result;
  }

  handleChange = (name, value) => {
    utils.log(name, value);
    let {formData} = this.state;

    if (name === 'invocationName') {
      value = value.toLowerCase();
    }
    formData[name].value = value;
    formData[name].isValid = true;

    this.setState({
      formData
    })
  }

  handleQuestionChange = (index, data) => {
    let {questions} = this.state;

    if (Array.isArray(data)) {
      questions[index].responses = data;
    } else {
      questions[index].responses = [];
      questions[index].responses.push(data);
    }

    this.setState({
      questions
    })

    //utils.log(questions);
  }

  handlePublish = () => {
    let {project} = this.state;

    this.setState({
      tab: 7
    })

    let that = this;
    utils.publishSkill(project, true).then(response => {
      utils.log(response);
      if (response.result === 'success') {
        this.setState({
          project,
          tab: 8
        });
        //utils.addIntercomTag("publishedBusinessSkill");
        var metadata = {
          skillName: project.name,
          invocationName: project.invocationName
        };

        window.Intercom('trackEvent', 'Published Business Skill', metadata);
      } else if (response.message != null) {
        utils.log('got here');
        that.setState({
          errorText: response.message,
          tab: 10
        });
      } else {
        if (response.code === 3) {
          window.ga('send', 'event', 'Skill Making', 'Publish', 'In Certification');
          this.setState({
            project,
            tab: 9
          })
        } else {
          window.ga('send', 'event', 'Skill Making', 'Publish', 'Failure');
          this.setState({
            errorText: 'Unknown Error',
            tab: 10
          });
        }
        utils.log(response);
      }

    }).catch(err => {
      this.setState({
        errorText: err.message,
        tab: 10
      });
    });
  }

  handleSearch = (query) => {
    const {categories} = this.state;
    let {formData} = this.state;

    var options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        "title",
        "author.firstName"
      ]
    };
    var fuse = new Fuse(categories, options); // "list" is the item array
    var result = fuse.search(query);

    formData.category.isValid = true;
    formData.category.value = '';
    this.setState({
      filteredCategories: result,
      formData,
    })
  }

  handleCategoryChange = (categories) => {
    let {formData} = this.state;
    utils.log('category selected');
    if (categories.length > 0) {
      formData.category.isValid = true;
      formData.category.value = categories[0].id;
      this.setState({
        formData
      });
    } else {
      formData.category.isValid = false;
      formData.category.value = '';
      this.setState({
        formData
      })
    }

  }

  handleCountryClick = (locale) => {
    let {formData} = this.state;

    formData.country.value = locale;
    this.setState({
      formData
    })
  }

  handlePlanSelect = (plan) => {
    utils.log(plan);

    this.setState({
      selectedPlan: plan
    })

    let that = this;
    window.setTimeout(this.initializeCheckout, 200);
  }

  validateCountry = () => {
    let {formData} = this.state;

    if (formData.country.value === '') {
      formData.country.isValid = false;
      this.setState({
        formData
      });

      return false;
    }

    return true;
  }

  validateCategory = () => {
    utils.log('validating category');
    let {formData} = this.state;

    if (formData.category.value === '') {
      formData.category.isValid = false;
      this.setState({
        formData
      })

      return false;
    }

    return true;
  }

  handleChangeIcon = (e) => { // eslint-disable-line
    const { formData } = this.state;
    this.setState({
      showIcons: true,
      isEditing: true,
      iconSearchTerm: 'store',
    });

    this.searchTimer = setTimeout(this.performSearch, 200);
  }

  setIconUrl = (url) => { // eslint-disable-line
    utils.log(url);

    this.handleImageUploadFromUrl(url);
    return;

    // var formData = this.state.formData;
    // formData.icon.value = CLOUDINARY_FETCH_URL + url;
    //
    // this.setState({
    //   formData,
    //   showIcons: false,
    //   isEditing: true,
    // });
  }

  handleSearchChange = (text) => { // eslint-disable-line
    if (text !== '') {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(this.performSearch, 500);
    }

    this.setState({
      iconSearchTerm: text,
    })
  }

  performSearch = () => { // eslint-disable-line
    let text = this.state.iconSearchTerm;

    if (text !== '') {
      this.setState({
        isLoading: true
      });

      utils.searchIcons(text, true).then(icons => {
        // utils.log(icons);
        this.setState({
          icons: icons,
          isLoading: false
        })
      });
    }
  }

  onImageDrop = (files) => { // eslint-disable-line
    this.setState({
      uploadedFile: files[0]
    });

    this.handleImageUpload(files[0]);
  }

  handleImageUpload(file) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', file);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formData = this.state.formData;
        formData.icon.value = response.body.secure_url;
        this.setState({
          formData: formData,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleImageUploadFromUrl(url) {
    let upload = request.post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
      .field('file', url);

    upload.end((err, response) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        utils.log('fileUrl=', response.body.secure_url);
        let formData = this.state.formData;
        formData.icon.value = response.body.secure_url;
        this.setState({
          formData: formData,
          showIcons: false,
          isEditing: true,
        });
      }
    });
  }

  handleCloseIcons = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
    });
  }

  changeProjectCategory = (id) => { // eslint-disable-line
    let { formData } = this.state;

    utils.log('------change project category------');

    utils.log("categoryId=", id);

    formData.projectCategory.value = id;

    this.setState({
      formData,
      isEditing: true,
    });
  }



  render() {
    const {isPremium, tab, formData, questions,
      project, errorText, countries, categories,
      filteredCategories, upgradeOfferShown, selectedPlan, planLevel,
      showIcons, icons, iconSearchTerm, isLoading
    } = this.state;

    if (project) {
      //console.log(project.locales[0].locale);
    }

    utils.log("planLevel=", planLevel);
    const filterByCallback = (option, props) => {
      return true;
    };

    let selectedCountryItem = countries.find(c => c.locale === formData.country.value);
    let selectedCountryName = "Choose your Country";
    if (selectedCountryItem) {
      selectedCountryName = selectedCountryItem.name + " (" + selectedCountryItem.language + ")";
    }

    let selectedCategoryItem = categories.find(cat => {return cat.id === formData.category.value});
    //utils.log("selected Category=", formData.category.value);
    //utils.log("categories", categories);
    //utils.log("selectedCategory=", selectedCategoryItem);
    let selectedCategories = [];
    if (selectedCategoryItem) {
      selectedCategories.push(selectedCategoryItem);
    }

    if (formData.icon.value === '') {
      formData.icon.value = '/images/choose_image.png';
    }

    const selectedProjectCategory = projectCategories.find(c => { return c.value === formData.projectCategory.value });
    const projectCategoryText = selectedProjectCategory ? selectedProjectCategory.name : 'Choose One';

    return (
      <div className="business_full">

        <HeaderSecure menuId={2} auth={this.props.auth} history={this.props.history} isProfessional={true} />
        <div className="row align-items-top">
          <div className="col">
            <Stepper steps={ [{title: 'Business Type'}, {title: 'Business Info'}, {title: 'Skill Responses'}, {title: 'Skill Info'}, {title: 'Test'}, {title: 'Publish'}] } activeStep={ tab-1 } />
          </div>
        </div>
        {tab===1 &&
          <div className="container business_container">
            <div className="row align-items-center">
              <div className="col">
                <div className="business_header">You're only a few steps away from creating an Alexa Skill for your Business!</div>
                <div className="business_subHeader">Select your country and type of business.</div>
                <div className="form-group">

                  <div className="dropdown">
                    <div className="label-inline">Country/Language:</div>
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="countryDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {selectedCountryName}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="countryDropdown">
                      {countries.map((country, index) => {
                        return (
                          <a key={index} onClick={() => this.handleCountryClick(country.locale)} className="dropdown-item" href="#">{country.name + " (" + country.language + ")"}</a>
                        );
                      })}

                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="label-inline">Business Type:</div>
                  <span id="categoryHelpBlock" class="form-text text-muted">
                    Start typing and then choose one of the business types from the drop-down menu that appears.  Choose the best category for your business.
                  </span>
                  <Typeahead
                    filterBy={filterByCallback}
                    labelKey="title"
                    options={filteredCategories}
                    placeholder="examples:  &quot;plumbing&quot;, &quot;italian restaurant&quot;, &quot;hair salon&quot;"
                    onInputChange={this.handleSearch}
                    selected={selectedCategories}
                    onChange={this.handleCategoryChange}
                    minLength={1}
                    isInvalid={!formData.category.isValid}
                    onBlur={this.validateCategory}
                  />

                  {formData.category.isValid !== true && <div className="va-invalid-feedback">
                    You have not selected a business type.  Click the type from the drop down.
                  </div> }
                </div>
              </div>
            </div>
          </div>
        }
        {tab===2 &&
          <div className="container business_container">
            <div className="row align-items-center">
              <div className="col">
                <div className="business_header">Enter your business details below</div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Company Name</label>
                    <input type="text" className={"form-control" + (formData.companyName.isValid ? '' : ' is-invalid')} id="inputEmail4" placeholder="Company Name" onChange={(e) => this.handleChange('companyName', e.target.value)} value={formData.companyName.value}/>
                    {formData.companyName.isValid !== true && <div className="va-invalid-feedback">
                      Please enter your Company's name.
                    </div> }
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputAddress">Address</label>
                  <input type="text" className={"form-control" + (formData.address.isValid ? '' : ' is-invalid')} id="inputAddress" placeholder="1234 Main St" onChange={(e) => this.handleChange('address', e.target.value)}  value={formData.address.value}/>
                  {formData.address.isValid !== true && <div className="va-invalid-feedback">
                    Please enter your address.
                  </div> }
                </div>
                <div className="form-group">
                  <label htmlFor="inputAddress2">Address 2</label>
                  <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" onChange={(e) => this.handleChange('address2', e.target.value)} value={formData.address2.value}/>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputCity">City</label>
                    <input type="text" className={"form-control" + (formData.city.isValid ? '' : ' is-invalid')} id="inputCity" onChange={(e) => this.handleChange('city', e.target.value)} value={formData.city.value}/>
                    {formData.city.isValid !== true && <div className="va-invalid-feedback">
                      Please enter your city.
                    </div> }
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">State/Province/Region</label>
                    <input type="text" className={"form-control" + (formData.state.isValid ? '' : ' is-invalid')} id="inputState" placeholder="" onChange={(e) => this.handleChange('state', e.target.value)} value={formData.state.value}/>
                    {formData.state.isValid !== true && <div className="va-invalid-feedback">
                      Please enter your Company's name.
                    </div> }
                  </div>
                  <div className="form-group col-md-2">
                    <label htmlFor="inputZip">Zip/Postal Code</label>
                    <input type="text" className={"form-control" + (formData.zipCode.isValid ? '' : ' is-invalid')} id="inputZip" onChange={(e) => this.handleChange('zipCode', e.target.value)} value={formData.zipCode.value}/>
                    {formData.zipCode.isValid !== true && <div className="va-invalid-feedback">
                      Please enter your Company zip/postal code.
                    </div> }
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPhoneNumber">Phone Number</label>
                    <input type="text" className={"form-control" + (formData.phoneNumber.isValid ? '' : ' is-invalid')} id="inputPhoneNumber" placeholder="(555) 555-1212" onChange={(e) => this.handleChange('phoneNumber', e.target.value)} value={formData.phoneNumber.value}/>
                    {formData.phoneNumber.isValid !== true && <div className="va-invalid-feedback">
                      Please enter your Company Phone Number.
                    </div> }
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail">Email Address</label>
                    <input type="email" className={"form-control" + (formData.email.isValid ? '' : ' is-invalid')} id="inputEmail" placeholder="Email Address" onChange={(e) => this.handleChange('email', e.target.value)}  value={formData.email.value}/>
                    {formData.email.isValid !== true && <div className="va-invalid-feedback">
                      Please provide your Company's email address.
                    </div> }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {tab === 3 && !upgradeOfferShown &&
          <div className="container business_container">
            <div className="business_header">Choose Your Plan</div>
            <div className="row">
              <div className="col-12 col-md-4 text-center">
                <div className={"business_paymentPlan" + (selectedPlan === 'free' ? ' selected' : '')} onClick={() => this.handlePlanSelect('free')}>
                  <div className="business_paymentPlan_header">FREE</div>
                  <div className="business_paymentPlan_price">$0.00</div>
                  <div className="business_paymentPlan_per">per Month</div>
                  <div className="business_paymentPlan_summary">Paid Monthly</div>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center">
                <div className={"business_paymentPlan" + (selectedPlan === 'monthly' ? ' selected' : '')} onClick={() => this.handlePlanSelect('monthly')}>
                  <div className="business_paymentPlan_header">Monthly</div>
                  <div className="business_paymentPlan_price">$14.95</div>
                  <div className="business_paymentPlan_per">per Month</div>
                  <div className="business_paymentPlan_summary">Paid Monthly</div>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center">
                <div className={"business_paymentPlan" + (selectedPlan === 'yearly' ? ' selected' : '')} onClick={() => this.handlePlanSelect('yearly')}>
                  <div className="business_paymentPlan_header">Annually</div>
                  <div className="business_paymentPlan_price">$12.71</div>
                  <div className="business_paymentPlan_per">per Month</div>
                  <div className="business_paymentPlan_summary">$152.52 Billed annually</div>
                  <div className="business_paymentPlan_savings">
                    <div className="title">Save</div>
                    <div className="amount">15%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="business_paymentPlan_features">
                  <div className="business_paymentPlan_featuresHeader">FREE Plan Includes:</div>
                  <div className="row">
                    <div className="col">
                      <ul>
                        <li>Welcome Message</li>
                        <li>About Us</li>
                        <li>Location</li>
                        <li>Directions</li>
                        <li>Hours of Operation</li>
                        <li>Phone Number</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="business_paymentPlan_features">
                  <div className="business_paymentPlan_featuresHeader">Paid Plans Include All FREE Plan Features Plus:</div>
                  <div className="row">
                    <div className="col col-6">
                      <ul>
                        <li>Custom Icon</li>
                        <li>Analytics</li>
                        <li>Category Targeting</li>
                        <li>Social Media</li>
                        <li>Products & Services</li>
                        <li>Top Clients</li>
                        <li>Owner Bio</li>
                        <li>Support Information</li>
                        <li>Company History</li>


                      </ul>
                    </div>
                    <div className="col col-6">
                      <ul>
                        <li>Service Guarantee</li>
                        <li>Mission Statement</li>
                        <li>Employment Opportunities</li>
                        <li>Specials/Discounts/Coupons</li>
                        <li>Testimonials</li>
                        <li>Frequently Asked Questions</li>
                        <li>Upcoming Events</li>
                        <li>Fun Facts</li>
                        <li>Jokes</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {tab===4  &&
          <div className="container business_container">
            <div className="row align-items-center">
              <div className="col">
                <div className="business_header">Provide the skill responses below</div>
                <div className="business_subHeader">When the user asks about each topic below, Alexa will give the corresponding response.</div>
                <div className="alert alert-danger" role="alert">NOTE: We've provided some sample data below so you can get an idea of what to put in each field.  Please enter your own answers to each section.</div>
                {questions.map((q, index) => {
                  if (q.type === 'multiple') {
                    return (
                      <div key={index} className="form-group">
                        <label className="business-label" htmlFor={"question" + index}>{q.title}</label>
                        <hr className="business-hr"></hr>
                        <MultiText
                          inputPlaceHolder={q.placeHolder}
                          inputPlaceHolder2="Enter another and Alexa will choose randomly."
                          data={q.samples}
                          variables={this.props.variables}
                          isPremium={isPremium}
                          onChange={(data) => this.handleQuestionChange(index, data)}
                        />
                        <small id={"questionHelpBlock" + index} className="form-text text-muted business-multiple-help">
                          {q.for}
                        </small>

                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="form-group">
                        <label className="business-label" htmlFor={"question" + index}>{q.title}</label>
                        <hr className="business-hr"></hr>
                        <textarea type="text" className="form-control" id={"question" + index} placeholder="" aria-describedby={"questionHelpBlock" + index} onChange={(e) => this.handleQuestionChange(index, e.target.value)} value={q.responses.length>0 ? q.responses[0] : q.sample}></textarea>
                        <small id={"questionHelpBlock" + index} className="form-text text-muted">
                          {q.for}
                        </small>

                      </div>
                    );
                  }

                })}

                <div className="form-group">

                </div>

              </div>
            </div>
          </div>
        }

        {tab===3 && upgradeOfferShown &&
          <div className="container business_container">


            <div className="row align-items-center">
              <div className="col">
                <div className="business_header">Skill Information</div>
                {planLevel > 0 && <div className="form-group">
                  <label htmlFor="icon">Project Icon</label>
                  <div className="edit_iconThunbnail" onClick={this.handleChangeIcon}>
                    <img className="iconThumbnail" src={formData.icon.value} alt="icon" />
                  </div>
                  <small id="iconHelpBlock" className="form-text text-muted">
                    Click the image above to choose/upload your icon.
                  </small>
                  {formData.icon.isValid !== true && <div className="va-invalid-feedback">
                    Please choose an icon for your skill.
                  </div> }
                </div>}
                {planLevel > 0 && <div className="form-group">
                  <label htmlFor="projectName">Project Category</label>
                  <div className="dropdown edit_templateDropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {projectCategoryText}
                    </button>
                    <div className="dropdown-menu edit_scrollable" aria-labelledby="dropdownMenuButton">
                      {projectCategories.map((category, index) => {
                        return (
                          <a key={index} className="dropdown-item" href="#" onClick={() => this.changeProjectCategory(category.value)}>{category.name}</a>
                        )
                      }, this)}
                    </div>
                  </div>
                  <small id="projectCategoryHelpBlock" className="form-text text-muted">
                    This is the category where your skill will be published in the Amazon Skill store.
                  </small>
                  {formData.projectCategory.isValid !== true && <div className="va-invalid-feedback">
                    Please select a category for your skill.
                  </div> }
                </div>}
                <div className="form-group">
                  <label htmlFor="skillName">Skill Name</label>
                  <span className="business-counter">{formData.publicName == null ? 0 : formData.publicName.value.length }/50</span>
                  <input type="text" className={"form-control" + (formData.publicName.isValid ? '' : ' is-invalid')} id="skillName" placeholder="" aria-describedby="skillNameHelpBlock" maxLength={50} onChange={(e) => this.handleChange('publicName', e.target.value)} value={formData.publicName.value}/>
                  <small id="skillNameHelpBlock" className="form-text text-muted">
                    The name of your skill.  This is usually just your company name.
                  </small>
                  {formData.publicName.isValid !== true && <div className="va-invalid-feedback">
                    Please provide a Skill Name
                  </div> }
                </div>

                <div className="form-group">
                  <label htmlFor="invocationName">Invocation Name (<a href="https://developer.amazon.com/docs/custom-skills/choose-the-invocation-name-for-a-custom-skill.html#cert-invocation-name-req" target="_new">Requirements</a>)</label>
                  <span className="business-counter">{formData.invocationName == null ? 0 : formData.invocationName.value.length}/50</span>
                  <input type="text" className={"form-control" + (formData.invocationName.isValid ? '' : ' is-invalid')} id="invocationName" placeholder="" aria-describedby="invocationNameHelpBlock" maxLength={50} onChange={(e) => this.handleChange('invocationName', e.target.value)} value={formData.invocationName.value} onBlur={this.validateInvocationName}/>
                  <small id="invocationNameHelpBlock" className="form-text text-muted">
                    Users will say "Alexa, open " and then say your invocation name.  "Alexa, open ACME Plumbers"
                  </small>
                  {formData.invocationName.isValid !== true && <div className="va-invalid-feedback">
                    {formData.invocationName.message}
                  </div> }
                  {formData.invocationName.isWarning === true && <div className="va-warning-feedback">
                    {formData.invocationName.warningMessage}
                  </div> }

                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea type="text" className={"form-control" + (formData.description.isValid ? '' : ' is-invalid')} id="description" placeholder="" aria-describedby="descriptionHelpBlock" onChange={(e) => this.handleChange('description', e.target.value)} value={formData.description.value}></textarea>
                  <small id="descriptionHelpBlock" className="form-text text-muted">
                    Describe your skill and what it does for your customers.  Users will see this on the skill details page in the Alexa App and on Amazon.
                  </small>
                  {formData.description.isValid !== true && <div className="va-invalid-feedback">
                    Please provide a description for your skill.
                  </div> }
                </div>

                <div className="form-group">
                  <label htmlFor="keywords">Keywords</label>
                  <span className="business-counter">{formData.keywords.value === '' ? 0 : formData.keywords.value.replace(/,/g, ' ').replace(/ {2}/g, ' ').trim().split(' ').length}/30</span>
                  <input type="text" className={"form-control" + (formData.keywords.isValid ? '' : ' is-invalid')} id="keywords" placeholder="" aria-describedby="keywordsHelpBlock" onChange={(e) => this.handleChange('keywords', e.target.value)} value={formData.keywords.value}/>
                  <small id="keywordsHelpBlock" className="form-text text-muted">
                    A comma-separated list of keywords that will help users find your skill.
                  </small>
                  {formData.keywords.isValid !== true && <div className="va-invalid-feedback">
                    You must have at least 1 and no more than 30 keywords.
                  </div> }
                </div>
              </div>
            </div>
          </div>
        }

        {tab===5 &&
          <div className="container business_container">


            <div className="row align-items-top row-full">
              <div className="col-12 col-md-7">
                <div className="business_header">Test your skill</div>
                <div className="business_subHeader">Use the test console to see how your skill will work once it's live.  If you need to make changes, just hit the Previous button below.</div>
                <div>To start the skill, click the "Start Skill" button at the bottom.  Then, type your responses in the box at the top.  You can also click the microphone button to speak your responses.</div>
                <br/>
                <div>Here are some things you can try:</div>
                <br/>
                <ul>
                  <li>"What are your hours?""</li>
                  <li>"What services do you offer?""</li>
                  <li>"Where are you located?""</li>
                  <li>"What is your history?""</li>
                  <li>"Do you have any job openings?""</li>
                  <li>"Tell me a joke.""</li>
                </ul>
              </div>
              <div className="col-12 col-md-5">
                <div className="business_testContainer">
                  <SkillTester
                    visible={true}
                    project={project}
                    locale={project != null ? project.locales[0].locale : 'en-US'}
                    onChangeActiveIntent={this.handleChangeActiveIntent}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {tab===6 &&
          <div className="container business_container">


            <div className="row align-items-top">
              <div className="col">
                <div className="business_header">Publish your skill</div>
                <div className="business_subHeader">AWESOME!  You are now ready to publish your new business skill!</div>
                <br/>
                <div className="center-block">
                  <div className="center-block">Click the Publish button below to publish your skill to Amazon.</div>
                  <br/>
                  <button className="btn btn-success btn-lg" onClick={this.handlePublish} >Publish</button>
                </div>
              </div>
            </div>
          </div>
        }

        {tab===7 &&
          <div className="container business_container">


            <div className="row align-items-top">
              <div className="col">
                <div className="business_header">Publish your skill</div>
                <br/>
                <br/>
                <div className="business_subHeader">Please wait while we publish your skill to Amazon.</div>
                <br/>
                <div><Spinner size={30}/></div>
                <br/>
              </div>
            </div>
          </div>
        }

        {tab===8 &&
          <div className="container business_container">


            <div className="row align-items-top">
              <div className="col">
                <div className="business_header">CONGRATULATIONS!</div>
                <br/>
                <br/>
                <div className="business_subHeader">Your skill is now published to Amazon.</div>
                <br/>
                <div className="center-block">We will send you an e-mail once your skill is approved and is live.  This can take anywhere from a few hours to a day or more.</div>
                <br/>
              </div>
            </div>
          </div>
        }

        {tab===9 &&
          <div className="container business_container">


            <div className="row align-items-top">
              <div className="col">
                <div className="business_header">Publish your skill</div>
                <br/>
                <br/>
                <div className="business_subHeader">OH NO!  Your skill is already awaiting certification.</div>
                <br/>
                <div className="center-block">You will need to wait until the certification process completes to update your skill.</div>
                <br/>
              </div>
            </div>
          </div>
        }

        {tab===10 &&
          <div className="container business_container">


            <div className="row align-items-top">
              <div className="col">
                <div className="business_header">Publish your skill</div>
                <br/>
                <br/>
                <div className="business_subHeader">OH NO!  Something went wrong while publishing your skill.</div>
                <br/>
                <div>{errorText}</div>
              </div>
            </div>
          </div>
        }

        <div className="business_next_prev">
          {tab !== 1 && tab < 7 && <button className="btn btn-primary previous" onClick={this.handlePrevClick}>Previous</button>}
          {tab !== 6 && tab < 7 && <button className="btn btn-primary next" onClick={this.handleNextClick}>Next</button>}
        </div>
        <a id="business_signup_monthly" className="checkout_selectPlan" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="business-basic-monthly" >Select this plan</a>
        <a id="business_signup_yearly" className="checkout_selectPlan" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="business-basic-annual" >Select this plan</a>

        {showIcons ?
          <div className="edit_icons">
            <div className="edit_iconHeader">
              <div className="edit_iconHeaderTitle">Choose an Icon</div>
              <div className="edit_close" onClick={this.handleCloseIcons}><FA icon="times" /></div>
            </div>
            {isPremium && true ? null : <div className="edit_premium" onClick={this.handleUpgrade}>Upgrade your account to get premium icons!</div>}
            <div className="edit_searchIconContainer">
              <div className="edit_search">
                <input className="edit_searchBox" type="text" placeholder="Enter search terms" value={iconSearchTerm} onChange={evt => this.handleSearchChange(evt.target.value)} />
                <div className="edit_searchIcon"><FA icon="search" /></div>
              </div>
              <div className="edit_iconsScrollable">
                {icons.length > 0 ?
                  icons.map(function (icon, index) {
                    if (icon.raster_sizes.length < 9) {
                      return null;
                    }
                    let iconUrl = icon.raster_sizes[8].formats[0].preview_url;
                    return (
                      <div key={index} className="edit_iconContainer" onClick={() => this.setIconUrl(iconUrl)} title="Click to select this icon.">
                        <img className="edit_icon" src={iconUrl} alt="icon" />
                      </div>
                    )
                  }, this)
                  : (isLoading ?
                    <div className="edit_spinner">
                      <div className="edit_spinnerBox"><Spinner size={30} /></div>
                      <div className="edit_spinnerText">Loading Icons...</div>
                    </div> :
                    <div className="edit_iconMessage">Enter a search term above to search for icons.</div>
                  )
                }
              </div>

              <Dropzone
                className="edit_dropzone"
                multiple={false}
                accept="image/*"
                onDrop={this.onImageDrop}>
                <p>Drop an image or click to select a file to upload.</p>
              </Dropzone>
            </div>
          </div> : null
        }
      </div>
    );
  }
}




export default Business;
