import Bluebird from 'bluebird';
import utils from '../../../helpers/utils.js';

export const ACTION_SELECT_FILES = 'ACTION_SELECT_FILES';
export const ACTION_DESELECT_FILE = 'ACTION_DESELECT_FILE';
export const ACTION_UPLOADING_FILE = 'ACTION_UPLOADING_FILE';
export const ACTION_UPLOADED_FILE = 'ACTION_UPLOADED_FILE';
export const ACTION_FAILED_FILE = 'ACTION_FAILED_FILE';
export const ACTION_RESET_FILES = 'ACTION_RESET_FILES';
export const ACTION_SELECT_IMAGE_OPTION = 'ACTION_SELECT_IMAGE_OPTION';
export const ACTION_DESELECT_IMAGE_OPTION = 'ACTION_DESELECT_IMAGE_OPTION';
export const ACTION_SELECT_AUDIO_OPTION = 'ACTION_SELECT_AUDIO_OPTION';
export const ACTION_DESELECT_AUDIO_OPTION = 'ACTION_DESELECT_AUDIO_OPTION';

export const selectFiles = (files) => ({ type: ACTION_SELECT_FILES, files });
export const deselectFile = (file) => ({ type: ACTION_DESELECT_FILE, file });
export const resetFiles = () => ({ type: ACTION_RESET_FILES });

export const selectImageOption = (option) => ({ type: ACTION_SELECT_IMAGE_OPTION, option });
export const deselectImageOption = (option) => ({ type: ACTION_DESELECT_IMAGE_OPTION, option });

export const selectAudioOption = (option) => ({ type: ACTION_SELECT_AUDIO_OPTION, option });
export const deselectAudioOption = (option) => ({ type: ACTION_DESELECT_AUDIO_OPTION, option });

export const uploadFile = (user, file, options) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		dispatch({
			type: ACTION_UPLOADING_FILE,
			file,
		});

		reader.onload = (e) => {
			const { target } = e;

			const body = JSON.stringify({
				name: file.name,
				type: file.type,
				data: btoa(target.result),
				userId: user,
				options,
			});

			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const params = {
				method: 'POST',
				mode: 'cors',
				headers,
				body,
			};

			const dispatchUploaded = (data) => {
				dispatch({
					type: ACTION_UPLOADED_FILE,
					uploadedFiles: data.files,
					originalFile: file,
				});
			};

			const dispatchFailed = () => {
				dispatch({
					type: ACTION_FAILED_FILE,
					originalFile: file,
				});
			};

			return fetch(`https://253mhygcg5.execute-api.us-east-1.amazonaws.com/production/file`, params)
				.then(response => response.json())
				.then(dispatchUploaded)
				.then(response => {
					utils.log("upload resolved");
					resolve(response);
				})
				.catch(error => {
					dispatchFailed();
					utils.log('upload rejected');
					utils.log(error);
					reject(error);
				});
		};

		reader.readAsBinaryString(file);
	});
};

export default {
	selectFiles,
	deselectFile,
	resetFiles,
	uploadFile,
	selectImageOption,
	deselectImageOption,
};
