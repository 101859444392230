import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import AddProduct from './addProduct';
import moment from 'moment';

import Spinner from '../Spinner';
import utils from '../helpers/utils.js';


const CLOUDINARY_UPLOAD_PRESET = 'bophohq0';
const CLOUDINARY_UPLOAD_URL = 'https://api.cloudinary.com/v1_1/voiceapps/upload';


class Product extends Component {

  constructor(props) {
    super(props);

    this.state = {

      formFields: {
        id: '',
        name: '',
        url: '',
        imageUri: '',
      },
      showIcons: false,
      icons: [],
      iconSearchTerm: 'skills',
      isLoading: false,
      isEditing: false,
      isSubmitPost: false,
      showLoader: false,
      selectedProductKey: null

    }
  }

  handleSaveChanges = (e) => {
    let {formFields} = this.state;

    this.setState({ isSubmitPost: true });
    utils.log('------------Handle Save Changes-------');

    utils.log("formFields", formFields);
    utils.log("props", this.props);

    /** Check required validation  */
    let count = 0;
    Object.keys(formFields).map((item) => {
      if (item != 'id' && formFields[item] == "") {
        count++;
      }
    });
    if (count > 0) {
      utils.log("Some fields are requried ");
      return false;
    }

    /* Check valid URL  */
    if (this.validURL(formFields.url) == false) {
      utils.log("Invalid URL");
      return false;
    }

    /**Check for valid Preamble name */
    if(this.valildPreambleName(formFields.vuiPreamble) ==false){
      return false;
    }

     /**Check for Preamble max length */
    if(this.maxPreambleNameLength(formFields.vuiPreamble)== false){
      return false;
    }

    let postData = Object.assign({}, formFields);
    if (this.props.postAction == "Create") {
      /** Adding a post */
      postData.postDate = moment(this.props.postDate).format("YYYY-MM-DD");
      /** add a unique id  */
      postData.id = btoa(new Date());
      utils.log(this.props.projectId, "success", postData);
      this.setState({ showLoader: true });

      this.props.posts.push(postData);
      /** Save details in db */
      this.updatePostAPI(this.props.projectId + "-" + postData.postDate, this.props.posts);

    } else {
      /** Updating a post  */
      utils.log("Updateing", );
      let selectedIndex = this.props.posts.findIndex((item) => item.id == formFields.id);


      postData.postDate = moment(this.props.postDate).format("YYYY-MM-DD");
      utils.log("selectedIndex", selectedIndex, postData);
      this.props.posts[selectedIndex] = postData;
      /** Save details in db */
      this.updatePostAPI(this.props.projectId + "-" + postData.postDate, this.props.posts);
    }
  }


  /** update form values in state  */
  updateField = (field, value) => { // eslint-disable-line
    utils.log(field, value, this.state.formFields);
    let formFields = this.state.formFields;
    formFields[field] = value;
    this.setState({
      formFields: formFields,
      isEditing: true,
    });
  }



  handleCloseIcons = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
    });
  }

  /** Check valid URL  */
  /*validURL(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    //let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    let regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let res = regexp.test(str);
    return res;
  }*/

   validURL(str) {
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    let id = str.indexOf("http");
    if(id == 0){
      let reg = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/
      return reg.test(str)
    }else{
      return false;
    }

  }

/**Check Preamble is started with In or From   */
  valildPreambleName(str){
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    let regexp = /^(In|From).*$/;

    let res = regexp.test(str);
    return res;
  }

  /** check length of character for preamble  */
  maxPreambleNameLength(str){
    if (typeof str == 'undefined' || str == '') {
      return true;
    }
    if(str.length <= 70 ){
      return true;
    }else{
      return false;
    }

  }

  handleClose = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
      isEditing: false,
    });
  }

  /* show required validatin  */
  renderRequiredValidation(input) {
    let state = this.state;
    if (state.isSubmitPost == true && (typeof state.formFields[input] == 'undefined' || state.formFields[input] == '')) {
      return <p className="text-danger">This is required</p>
    }
  }


  renderButton() {
    if (this.state.showLoader == true) {
      return <a href="javascript:void(0)" className="btn btn-primary">{this.props.postAction}ing....</a>
    } else {
      return <button type="button" className="btn btn-primary" onClick={this.handleSaveChanges}>{this.props.postAction}</button>
    }
  }

  handleDeleteProduct = (key) => {
    this.setState({
      selectedProductKey: key
    })
    setTimeout(() => {
      $('#deleteModal').modal('show');
      }, 800)
    // utils.log('Deleted id', id);
    // this.props.handleDeleteProduct(id);
  }


  handleDeleteConfirm = () => {
    utils.log('Deleted id', this.state.selectedProductKey);
    setTimeout(() => {
      $('#deleteModal').modal('hide');
      }, 800)
    this.props.handleDeleteProduct(this.state.selectedProductKey);
  }



  handleEditProduct = (key) => {
    utils.log('Edit key', key);
    this.props.handleEditProduct(key);
  }


  renderProducts = () => {
    utils.log("Product like>", typeof this.props.products);
    if(typeof this.props.products == "undefined" || this.props.products.length == 0){
      return (
        <div className="alert alert-info product-alert">
  <center className="">You have not created any products.  Click "Create Product" to create one.</center>
</div>
      )
    }else{
      return this.props.products.map((product) => {
        if(product){
          /* Set default Icon if value is empty */
            let productUrl = product.productUrl  != '' ? product.productUrl :'https://res.cloudinary.com/voiceapps/image/upload/v1531481073/ni90vyxlbqgmf7njf69v.png' ;
            return (
            <div key={product.key} className="col-xs-12 col-md-12 bootstrap snippets">
            <div className="product-content product-wrap clearfix">
              <div className="row">

                  <div className="col-md-2 col-sm-12 col-xs-12">

                    <div onClick={(e) => this.handleEditProduct(product.key)} className="product-image">
                      <img src={productUrl} className="img-responsive"/>
                    </div>
                  </div>
                  <div className="col-md-10 col-sm-12 col-xs-12">
                  <div className="card-close" onClick={(e) => this.handleDeleteProduct(product.key)}><FA icon={["far", "trash"]} /></div>
                  <div onClick={(e) => this.handleEditProduct(product.key)} className="product-deatil">
                      <h5 className="price-container">
                        Name: <span>{product.name}</span>
                      </h5>
                  </div>

                  <div onClick={(e) => this.handleEditProduct(product.key)} className="description">
                    <div className="price-container">
                      Price: <span>${product.price}</span>
                      </div>
                      <div className="price-container">
                        Payment Frequency: <span>{product.frequency}</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            )
          }
      });
    }
  }

  handleClose = () => { // eslint-disable-line
    this.setState({
      showIcons: false,
      isEditing: false,
    });
  }

  handleAddProduct = () => { // eslint-disable-line

    this.props.clearAddField();


    // let { project } = this.state;
    // let zc = document.getElementById('zoomableContainer');
    // let newProduct = {
    //   id: utils.guid(),
    //   name: 'New Intent',
    //   description: 'New intent description.'
    // };
    // this.props..push({ products: newProduct});
    // this.setState({
    //   project
    // });
    // this.updateProject(project);
  }


  render() {
    const { showIcons, isPremium, icons, isLoading, iconSearchTerm } = this.state;
    utils.log('priduct List', this.props.products);
    return (
      <div className="modal fade slide" id="ManageProductModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg-custom" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-primary">
              <h5 className="modal-title" id="exampleModalLabel">Product List {this.props.postAction}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col col-12">
                    <button id="manage_product_button" type="button" className="pull-right btn btn-success hs_button btn-add-product" onClick={this.handleAddProduct}> Create Product</button>
                    <div className="clearfix"></div>
                    <div className="va-main-card">
                      <div className="container">
                        { this.renderProducts() }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn m-b0 btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.selectedProductKey ?
          <div className="modal fade slide" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header modal-header-primary">
                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete Project</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete product?</p>
                <p>This action cannot be undone.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                <button type="button" className="btn btn-danger" onClick={this.handleDeleteConfirm}>Delete</button>
              </div>
            </div>
          </div>
        </div>
          : ''
        }
      </div>
    );
  }
}

export default Product;
