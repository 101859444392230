import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';

class MultiState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variableArray: props.data,
      selectedTextIndex: null,
      inputOperator: '=',
      inputTarget: '',
      inputValue: '',
      inputValue2: '',
      inputWhenOperator: '==',
      inputWhenTarget: '',
      inputWhenValue: '',
      editWhen: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      variableArray: props.data,
    });
  }


  handleTextKeyPress = (e) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      utils.log('got here');
        this.addText(e.target.value);
        e.target.value = '';
    }
  }

  handleTextEditBlur = () => { // eslint-disable-line
    this.setState({
      selectedTextIndex: null
    })
  }

  selectText = (index) => { // eslint-disable-line
    return;
    // this.setState({
    //   selectedTextIndex: index
    // });
    //
    // setTimeout(() => {
    //   let element = document.getElementById('tu_' + index);
    //   element.select();
    //   var val = element.value; //store the value of the element
    //   element.value = ''; //clear the value of the element
    //   element.value = val; //set that value back.
    // }, 200);
  }

  changeOperator = (operator) => { // eslint-disable-line
    this.setState({
      inputOperator: operator
    });
  }

  handleChangeTarget = (value) => { // eslint-disable-line
    this.setState({
      inputTarget: value
    });
  }

  handleChangeValue = (value) => { // eslint-disable-line
    this.setState({
      inputValue: value
    });
  }

  handleChangeValue2 = (value) => { // eslint-disable-line
    this.setState({
      inputValue2: value
    });
  }

  changeWhenOperator = (operator) => { // eslint-disable-line
    this.setState({
      inputWhenOperator: operator
    });
  }

  handleChangeWhenTarget = (value) => { // eslint-disable-line
    this.setState({
      inputWhenTarget: value
    });
  }

  handleChangeWhenValue = (value) => { // eslint-disable-line
    this.setState({
      inputWhenValue: value
    });
  }

  addVariable = () => { // eslint-disable-line
    utils.log('Add Variable');
    let { variableArray, inputTarget, inputOperator, inputValue, inputValue2, inputWhenTarget, inputWhenOperator, inputWhenValue } = this.state;

    let when = null;
    if (inputWhenTarget && inputWhenOperator && inputWhenValue) {
      when = {
        sourceVariable: inputWhenTarget,
        operator: inputWhenOperator,
        targetVariable: inputWhenValue
      }
    }
    variableArray.push({
      name: inputTarget,
      method: inputOperator,
      value: inputValue,
      value2: inputValue2,
      when
    });

    this.setState({
      variableArray,
      inputTarget: '',
      inputOperator: '=',
      inputValue: '',
      inputValue2: '',
      inputWhenTarget: '',
      inputWhenOperator: '==',
      inputWhenValue: '',
      editWhen: false
    })

    if (this.props.onChange) {
      this.props.onChange(variableArray);
    }
  }

  handleVariableDelete = (e, index) => { // eslint-disable-line
    utils.log(index);
    let { variableArray } = this.state;

    variableArray.splice(index, 1);

    this.setState({
      variableArray,
    });

    if (this.props.onChange) {
      this.props.onChange(variableArray);
    }

    e.preventDefault();
    e.stopPropagation();
  }

  toggleWhen = () => { // eslint-disable-line
    this.setState({
      editWhen: true
    })
  }
  render() {
    const { variableArray, selectedTextIndex, inputOperator, inputTarget, inputValue, inputValue2, editWhen, inputWhenOperator, inputWhenTarget, inputWhenValue } = this.state;

    const { header } = this.props;


    return (
      <div className="form-group">
        <div className="multiText_TextHeader" dangerouslySetInnerHTML={{__html: header}}></div>
        <div className="multiState_TextGroup">
          {variableArray.map((variable, index) => {
            return (
              (selectedTextIndex === index ?
                <input key={index} id={"tu_" + index} className="form-control multiText_TextEdit" defaultValue={Text} type="text" onBlur={this.handleTextEditBlur} onKeyPress={(event) => this.handleTextEditKeyPress(event, index)}/>
                :
                <div key={index} className="multiState_variableContainer">
                  <div className="multiState_variable">
                    <div className="multiState_label">Set:</div>
                    <input onClick={() => this.selectText(index)} className="multiState_target" value={variable.name} />
                    <div onClick={() => this.selectText(index)} className="multiState_operator">{variable.method}</div>
                    <input onClick={() => this.selectText(index)} className="multiState_value" value={variable.value} />
                  </div>
                  {variable.value2 ?
                    <div className="multiState_variable">
                       <input onClick={() => this.selectText(index)} className="multiState_value2" text={variable.value2} value={variable.value2} />
                    </div>
                       : null
                   }

                    {variable.when ?
                      <div className="multiState_variable">
                        <div className="multiState_label">When:</div>
                        <input onClick={() => this.selectText(index)} className="multiState_target" value={variable.when.sourceVariable} />
                        <div onClick={() => this.selectText(index)} className="multiState_operator">{variable.when.operator}</div>
                        <input onClick={() => this.selectText(index)} className="multiState_value" value={variable.when.targetVariable} />
                      </div>
                      :
                      null
                    }
                  <div className="multiText_delete" onClick={(event) => this.handleVariableDelete(event, index)}><FA icon="minus-circle" /></div>
                </div>
              )
            )
          })}

        </div>

        <div className="multiText_InputGroup">
          <div className="ss_row">
            <div className="multiState_label">Set:</div>
            <input type="text" className="ss_target" placeholder="variable_name" value={inputTarget} onChange={(event) => this.handleChangeTarget(event.target.value)}/>
            <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {inputOperator}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('=')}>= (set)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('*=')}>*= (multiply)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('/=')}>/= (divide)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('sum')}>sum</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('diff')}>diff</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('+=')}>+= (increase)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeOperator('-=')}>-= (decrease)</a>
            </div>
            <input type="text" className="ss_value" placeholder="value" value={inputValue} onChange={(event) => this.handleChangeValue(event.target.value)} />
          </div>
          {inputOperator === 'sum' || inputOperator === 'diff' ?
            <div className="ss_row">
               <input type="text" className="ss_value2" placeholder="value2" value={inputValue2} onChange={(event) => this.handleChangeValue2(event.target.value)} />
            </div>
               : null
           }

          {editWhen ?
          <div className="ss_row">
            <div className="multiState_label">When:</div>
            <input type="text" className="ss_target" value={inputWhenTarget} onChange={(event) => this.handleChangeWhenTarget(event.target.value)}/>
            <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {inputWhenOperator}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('==')}>== (equal to)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('!=')}>!= (not equal to)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('>')}>&gt; (greater than)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('<')}>&lt; (less than)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('>=')}>&gt;= (greater than or equal to)</a>
              <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('<=')}>&lt;= (less than or equal to)</a>
            </div>
            <input type="text" className="ss_value" value={inputWhenValue} onChange={(event) => this.handleChangeWhenValue(event.target.value)} />
          </div>
          :
          <div className="multiState_variable">
            <div className="multiState_label">When:</div>
            <div className="multiState_always" onClick={this.toggleWhen}>Always</div>
          </div>
        }
        <div className="multiText_bulkAdd" onClick={() => this.addVariable()}>Add</div>
      </div>
    </div>
    );
  }
}

export default MultiState;
