import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';

class HomeCard extends Component {
  constructor(props) {
    super(props);

    let cardData = props.data;

    utils.log(cardData);
    //utils.log("Publishing INFO (constructor)=", JSON.parse(JSON.stringify(publishingInfo)));
    if (cardData.title == null) {
      cardData = {
        isLinkedAccount: false,
        title: '',
        description: '',
        smallImageUrl: '',
        largeImageUrl: ''
      }
    }
    this.state = {
      cardData: cardData,
      formFields: {
        cardType: {
          isValid: true
        },
        title: {
          isValid: true
        },
        description: {
          isValid: true
        },
        smallImageUrl: {
          isValid: true
        },
        largeImageUrl: {
          isValid: true
        }
      }
    };
  }

  componentWillReceiveProps(props) {
    utils.log('Home Card ComponentWillReceivePropss')
    let cardData = props.data;

    utils.log(cardData);
    //utils.log("Publishing INFO (constructor)=", JSON.parse(JSON.stringify(publishingInfo)));
    if (cardData.title == null) {
      cardData = {
        isLinkedAccount: false,
        title: '',
        description: '',
        smallImageUrl: '',
        largeImageUrl: ''
      }
    }
    this.setState({
      cardData: cardData,
      formFields: {
        cardType: {
          isValid: true
        },
        title: {
          isValid: true
        },
        description: {
          isValid: true
        },
        smallImageUrl: {
          isValid: true
        },
        largeImageUrl: {
          isValid: true
        }
      }
    });
  }

  validateFormFields = () => {
    utils.log('================== VALIDATING FORM FIELDS ===================');
    let { cardData, formFields } = this.state;

    // Title
    if (cardData.title === '') {
      formFields.title.isValid = false;
      formFields.title.error = "Title is required.";
    } else {
      formFields.title.isValid = true;
      formFields.title.error = '';
    }

    // Description
    if (cardData.description === '') {
      formFields.description.isValid = false;
      formFields.description.error = "Title is required.";
    } else {
      formFields.description.isValid = true;
      formFields.description.error = '';
    }

    // Small Image Url
    if (cardData.smallImageUrl === '') {
      formFields.smallImageUrl.isValid = false;
      formFields.smallImageUrl.error = "Title is required.";
    } else {
      formFields.smallImageUrl.isValid = true;
      formFields.smallImageUrl.error = '';
    }

    // Short Image Url
    if (cardData.largeImageUrl === '') {
      formFields.largeImageUrl.isValid = false;
      formFields.largeImageUrl.error = "Title is required.";
    } else {
      formFields.largeImageUrl.isValid = true;
      formFields.largeImageUrl.error = '';
    }

    this.setState({
      formFields
    })
  }

  handleIsLinkedAccountChange = (value) => {
    let { cardData } = this.state;

    cardData.isLinkedAccount = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleTitleChange = (value) => {
    let { cardData } = this.state;

    cardData.title = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleDescriptionChange = (value) => {
    let {cardData} = this.state;

    cardData.description = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleSmallImageUrlChange = (value) => {
    let {cardData} = this.state;

    cardData.smallImageUrl = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleLargeImageUrlChange = (value) => {
    let {cardData} = this.state;

    cardData.largeImageUrl = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { cardData, formFields } = this.state;

    return (
      <div className="intent_card">
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_cardresponse" icon={["far", "id-card"]} /></span>Card Response</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div className="form-group">
          <input type="checkbox" value={cardData.isLinkedAccount} checked={cardData.isLinkedAccount} name="isLinkedAccount" onChange={evt => this.handleIsLinkedAccountChange(evt.target.checked)}/> <span className="edit_synchEnglishLocales">Send Linked Account Card</span>
        </div>

        {!cardData.isLinkedAccount &&
          <div className="form-group">
            <div className="trigger_utteranceHeader">Title</div>
            <span className="pi_counter">{cardData.title == null ? 0 : cardData.title.length }/50</span>
            <input className={"form-control" + (formFields.title.isValid ? '' : ' is-invalid')} maxLength="50" placeholder="Title to display on the card." type="text" value={cardData.title} onChange={(event) => this.handleTitleChange(event.target.value)}/>
            <div className="invalid-feedback">
              {formFields.title.error}
            </div>

            <div className="trigger_utteranceHeader">Description</div>
            <span className="pi_counter">{cardData.description == null ? 0 : cardData.description.length}/4000</span>
            <textarea className="form-control" id="featureText" rows="5" maxLength="4000" value={cardData.description} placeholder="The text that displays on the card." onChange={evt => this.handleDescriptionChange(evt.target.value)}></textarea>
            <div className="invalid-feedback">
              {formFields.description.error}
            </div>

            <div className="trigger_utteranceHeader">Small Image URL</div>
            <span className="pi_counter">{cardData.smallImageUrl == null ? 0 : cardData.smallImageUrl.length }/2000</span>
            <input className={"form-control" + (formFields.smallImageUrl.isValid ? '' : ' is-invalid')} maxLength="2000" placeholder="HTTPS URL (720w X 480h recommended)" type="text" value={cardData.smallImageUrl} onChange={(event) => this.handleSmallImageUrlChange(event.target.value)}/>
            <div className="invalid-feedback">
              {formFields.smallImageUrl.error}
            </div>

            <div className="trigger_utteranceHeader">Large Image URL</div>
            <span className="pi_counter">{cardData.largeImageUrl == null ? 0 : cardData.largeImageUrl.length }/2000</span>
            <input className={"form-control" + (formFields.largeImageUrl.isValid ? '' : ' is-invalid')} maxLength="2000" placeholder="HTTPS URL (1200w X 800h recommended)" type="text" value={cardData.largeImageUrl} onChange={(event) => this.handleLargeImageUrlChange(event.target.value)}/>
            <div className="invalid-feedback">
              {formFields.largeImageUrl.error}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default HomeCard;
