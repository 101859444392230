import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import MultiKeyValue from '../MultiKeyValue';

class ISP extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      action: props.data ? props.data : {keyValues:[]}
    };
  }

  componentWillReceiveProps(props) {

    utils.log('action=', props.data);
    this.setState({
      action: props.data ? props.data : {keyValues:[]}
    });
  }

  componentDidMount() {
    if (this.boxRef.current) {

      this.boxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  handleProductKeyChange = (value) => {
    let {action} = this.state;

    action.productKey = value;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleTargetVariableChange = (value) => {
    let {action} = this.state;

    action.targetVariable = value;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleDataChange = (data) => {
    let { action } = this.state;

    action.keyValues = data;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  handleManageProduct = () => { // eslint-disable-line
    $('#ManageProductModal').modal('show');
  }

  render() {
    const { action } = this.state;
    const { products } = this.props;

    let selectedProduct = products && products.find(p => p.key === action.productKey);

    let productName = 'Select a Product';
    if (selectedProduct) {
      productName = selectedProduct.name;
    }

    return (
      <div className="intent_card" ref={this.boxRef}>
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_isp" icon={["far", "box-usd"]} /></span>Verify In-skill Product</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>

        <div className="form-group">
            <div className="na_label">Check this Product ID:</div>
            <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {productName}
            </button>
            <div className="dropdown-menu trigger_dropdown" aria-labelledby="dropdownMenuButton">
              {products && products.map((product, index) => {
                return (
                  <a key={index} className="dropdown-item" href="#" onClick={() => this.handleProductKeyChange(product.key)}>{product.name}</a>
                )
              })}
            </div>
          <button id="manage_product_button" type="button" className="btn btn-success add-product" onClick={this.handleManageProduct}><FA className="hs_icon" icon={["far", "box-open"]} /> Manage Products</button>

        </div>
        {selectedProduct && selectedProduct.frequency !== 'CONSUMABLE' &&
          <div className="form-group">
            <div className="trigger_utteranceHeader">And set this variable to 1 (true) or 0 (false) if the user is entitled to it or not. </div>
            <input className="form-control random_inline" placeholder="variable_name" type="text" value={action.targetVariable} onChange={(event) => this.handleTargetVariableChange(event.target.value)} />

          </div>
        }

        {selectedProduct && selectedProduct.frequency === 'CONSUMABLE' &&
          <div className="form-group">
            <div className="trigger_utteranceHeader">And set this variable to the total purchased units.</div>
            <input className="form-control random_inline" placeholder="variable_name" type="text" value={action.targetVariable} onChange={(event) => this.handleTargetVariableChange(event.target.value)} />
          </div>
        }
      </div>
    );
  }
}

export default ISP;
