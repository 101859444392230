var reasons = [
  {
    name: 'This was a test skill.',
    value: 'TEST_SKILL'
  },
  {
    name: 'I need to add more features.',
    value: 'MORE_FEATURES'
  },
  {
    name: 'I discovered an issue with the skill.',
    value: 'DISCOVERED_ISSUE'
  },
  {
    name: 'I have not yet received certification feedback.',
    value: 'NOT_RECEIVED_CERTIFICATION_FEEDBACK'
  },
  {
    name: 'I did not intend to publish this skill.',
    value: 'NOT_INTEND_TO_PUBLISH'
  },
  {
    name: 'Other',
    value: 'OTHER'
  },

];

module.exports = reasons;
