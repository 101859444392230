import React, { Component } from 'react';
import './styles.css';
import utils from '../helpers/utils.js';

class Header extends Component {

  goTo = (e, route) => {
    this.props.history.replace(`/${route}`);

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  login = () => {
    this.props.auth.login();
  }

  logout = () => {
    utils.logout().then(response => {
      this.goTo('login');
    });

  }

  render() {

    const { menuId, loginTarget } = this.props;

    let loginPath = '/login' + (loginTarget ? '/' + loginTarget : '');
    return (
      <div>
        <nav className="navbar navbar-static-top navbar-expand-sm navbar-dark bg-dark">
          <a className="navbar-brand" href="#" onClick={(event) => this.goTo(event, '')}>
            <div className="header_logo_container">
              <img className="header_logo" alt="logo" src="/images/logo_small.png" />
              <div className="header_logo_text" href="">Voice Apps</div>
            </div>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse header_navbar_links" id="navbarNavDropdown">
            <ul className="navbar-nav navbar-right">
              <li className={"header_nav-item nav-item" + (menuId === 1 ? ' active' : '')}>
                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
              </li>
              <li className={"header_nav-item nav-item" + (menuId === 3 ? ' active' : 'null')}>
                <a className="nav-link" href="/skills">Built with Voice Apps</a>
              </li>
              <li className={"header_nav-item nav-item" + (menuId === 4 ? ' active' : 'null')}>
                <a className="nav-link" href="/plans">Pricing</a>
              </li>
              <li className={"header_nav-item nav-item" + (menuId === 6 ? ' active' : 'null')}>
                <a className="nav-link" href="/hosting">Hosting</a>
              </li>
              <li className={"header_nav-item nav-item" + (menuId === 5 ? ' active' : 'null')}>
                <a className="nav-link" href={loginPath}>Sign In</a>
              </li>
            </ul>
          </div>
        </nav>

      </div>

    );
  }
}

export default Header;
