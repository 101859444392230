import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

class Variables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentVariable: '',
      variables: props.data,
      builtInVariables: props.builtInVariables
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      variables: props.data,
      builtInVariables: props.builtInVariables
    })
  }

  handleInputKeyPress = (e) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      utils.log('got here');
        this.addValue();
        e.target.value = '';
        $(e.target).focus();
    }
  }

  addValue = () => {
    let { currentVariable, variables } = this.state;

    variables.push("{" + currentVariable + "}");

    this.setState({
      variables,
      currentVariable: ''
    })

    if (this.props.onChange) {
      this.props.onChange(variables)
    }

  }

  changeVariable = (value) => {
    this.setState({
      currentVariable: value
    })
  }

  deleteVariable = (index) => {
    let { variables } = this.state;

    variables.splice(index, 1);

    this.setState({
      variables
    });

    if (this.props.onChange) {
      this.props.onChange(variables)
    }
  }

  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }


  render() {
    const { currentVariable, variables, builtInVariables } = this.state;

    return (
      <div className="vb_container">
        <ReactTooltip effect="solid" multiline={true}/>
        <div className="tb_header">Variables</div>
        <div className="tb_close" onClick={this.close}><FA icon={["far", "times"]} /></div>
        <hr className="tb_hr"/>
        <div className="tb_title">Custom Variables</div>
        <div className="tb_subTitle"> These are the variables you've created specifically for use in this project.</div>
        <div className="vb_variableBox">
          {variables.map((key, index) => {
            return (
              <div key={index} className="vb_variable">{`${key}`}<span className="vb_variable_delete" onClick={() => this.deleteVariable(index)}><FA icon={["far", "times"]} /></span></div>
            )
          })

          }
        </div>
        <div className="vb_variableInput">
          <input type="text" placeholder="Enter a new variable..." value={currentVariable} onKeyPress={this.handleInputKeyPress} onChange={(event) => this.changeVariable(event.target.value)}/>
          <div className="vb_inputIcon" />
        </div>
        <div className="tb_help"> Press <b>"Enter"</b> to add variable.</div>
        <hr className="tb_hr"/>
        <div className="tb_title">Built-In Variables</div>
        <div className="tb_subTitle"> These variables are created automatically by Voice Apps and are available for you to use in your project. Hover/tap over each variable to see a description.</div>
        <div className="vb_variableBox">
          {builtInVariables.map((key, index) => {
            return (
              <div key={index} className="vb_variable_builtin" data-tip={key.description}>{`${key.name}`}</div>
            )
          })

          }
        </div>
      </div>
    );
  }
}

export default Variables;
