import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiKeyValue from '../MultiKeyValue';
import VariablePicker from '../VariablePicker';

class Lookup extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      action: props.data ? props.data : {keyValues:[]}
    };
  }

  componentWillReceiveProps(props) {

    utils.log('action=', props.data);
    this.setState({
      action: props.data ? props.data : {keyValues:[]}
    });
  }

  componentDidMount() {
    if (this.boxRef.current) {

      this.boxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  handleSourceVariableChange = (value) => {
    let {action} = this.state;

    action.sourceVariable = value;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleTargetVariableChange = (value) => {
    let {action} = this.state;

    action.targetVariable = value;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  handleDataChange = (data) => {
    let { action } = this.state;

    action.keyValues = data;

    this.setState({
      action,
    });

    if (this.props.onChange) {
      this.props.onChange(action);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }
  render() {
    const { action } = this.state;

    return (
      <div className="intent_card" ref={this.boxRef}>
        <div className="intent_cardHeader"><span className="intent_header_icon"><FA className="intent_lookup" icon={["far", "map-signs"]} /></span>Lookup Value</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div className="form-group">
          <div className="trigger_utteranceHeader">Use the value of the following key variable...</div>
          {/*<input className="form-control random_inline" placeholder="variable_name" type="text" value={action.sourceVariable} onChange={(event) => this.handleSourceVariableChange(event.target.value)}/>*/}
          <VariablePicker initialValue={action.sourceVariable} variables={this.props.variables} onChange={(data) => this.handleSourceVariableChange(data)}/>

        </div>
        <MultiKeyValue
          header="To find the associated value from this list..."
          keyPlaceHolder="key"
          valuePlaceHolder="value"
          variables={this.props.variables}
          addButtonText="Add Key/Value"
          allowJSON={true}
          allowBulk={true}
          data={action.keyValues}
          onChange={this.handleDataChange}
        />
        <div className="form-group">
          <div className="trigger_utteranceHeader">And assign it to a variable named:</div>
          {/*<input className="form-control random_inline" placeholder="variable_name" type="text" value={action.targetVariable} onChange={(event) => this.handleTargetVariableChange(event.target.value)} />*/}
          <VariablePicker initialValue={action.targetVariable} variables={this.props.variables} onChange={(data) => this.handleTargetVariableChange(data)}/>
        </div>
      </div>
    );
  }
}

export default Lookup;
