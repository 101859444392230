import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import Settings from '../Settings';
import Variables from '../Variables';

class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1,
      showBar: false,
      showOverflow: false
    };
  }

  componentWillReceiveProps(props) {
  }

  toggleBar = () => {
    const { showBar, tab } = this.state;

    this.setState({
      showBar: !this.state.showBar,
      tab: this.state.showBar ? 1 : tab
    })

    if (showBar) {
      this.setState({
        showOverflow: false
      })
    } else {
      window.setTimeout(() => {
        this.setState({
          showOverflow: true
        })
      }, 200);
    }
  }

  componentDidMount() {
    window.va_showVariables = () => {
      this.setState({
        tab: 2,
        showBar: true,
        showOverflow: true
      })
    }

    window.addEventListener('mouseup', (event) => {
      let box = document.getElementById('va_toolbar');
      //console.log(box);
      //console.log("EVENT TARGET", event.target, event.target.parentNode);
      // if (event && event.target !== box &&
      //   event.target.parentNode !== box &&
      //   event.target.parentNode.parentNode !== box &&
      //   event.target.parentNode.parentNode.parentNode !== box &&
      //   event.target.parentNode.parentNode.parentNode.parentNode !== box &&
      //   event.target.parentNode.parentNode.parentNode.parentNode.parentNode !== box &&
      //   event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode !== box
      // ) {
      if($(event.target).closest('#va_toolbar').length == 0) {
           /* Hide dropdown here */
        this.setState({
          showBar: false,
          showOverflow: false
        });
      }
    })
  }



  selectTab = (id) => {
    const { tab } = this.state;

    if (tab === id) {
      id = 0;
    }

    this.setState({
      tab: id
    })
  }

  handleMouseOver = () => {
    this.setState({
      isHovering: true
    })
  }

  handleMouseOut = () => {
    this.setState({
      isHovering: false
    })
  }

  handleVariablesChange = (data) => {
    if (this.props.onVariablesChange) {
      this.props.onVariablesChange(data);
    }
  }

  handleSettingsChange = (data) => {
    if (this.props.onSettingsChange) {
      this.props.onSettingsChange(data);
    }
  }

  onClose = () => {
    this.toggleBar()
  }

  render() {
    const { project, builtInVariables, variables } = this.props;
    const { showBar, showOverflow, tab, isHovering } = this.state;

    return (
      <div className="tb_container" id="va_toolbar">
        <ReactTooltip effect="solid" multiline={true}/>
        <div className={"tb_button" + (showBar ? " open" : "") + (isHovering ? " hover" : "")} onClick={this.toggleBar}><FA icon={["far", "sliders-v-square"]} /></div>
        <div className={"tb_buttonClose" + (showBar ? " open" : "") + (isHovering ? " hover" : "")} onClick={this.toggleBar} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}><FA icon={["far", "times"]} /></div>
        <div className={"tb_bar" + (showBar ? " open": "") + (showOverflow ? " overflow" : "")}>
          <div className={"tb_bar_icon settings" + (tab === 1 ? " selected" : "")} onClick={() => this.selectTab(1)} data-tip="Project Settings"><FA icon={["far", "cog"]}/></div>
          <div className={"tb_bar_icon variables" + (tab === 2 ? " selected" : "")} onClick={() => this.selectTab(2)} data-tip="Variables"></div>
        </div>
        {showOverflow && tab > 0 &&
          <div className="tb_content">
            {tab === 1 &&
              <Settings data={variables} project={project} onChange={(data) => this.handleSettingsChange(data)} onClose={this.onClose}/>
            }
            {tab === 2 &&
              <Variables data={variables} builtInVariables={builtInVariables} onChange={(data) => this.handleVariablesChange(data)} onClose={this.onClose}/>
            }
          </div>
        }
      </div>
    );
  }
}

export default Toolbar;
