import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../redux/actions/auth';
import utils from '../../helpers/utils.js';


import Header from '../../Header';
import HeaderSecure from '../../HeaderSecure';


class Terms extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoggedIn: false
		};
	}

	componentWillMount() {
		var that = this;
		utils.getCurrentUser().then(user => {
			that.setState({
				isLoggedIn: true
			});
		}).catch(err => {
			this.props.logout();
			that.setState({
				isLoggedIn: false
			});
		})
	}


	render() {
		let { isLoggedIn } = this.state;
		let user = this.props.user;
		utils.log("User=", user);
		// if (user === false) {
		// 	return (<Authenticate />);
		// }

		return (
			<div>
				{isLoggedIn ?
					<HeaderSecure auth={this.props.auth} history={this.props.history} />
					: <Header loginTarget="hosting"/>
				}
				<div className="hosting_header">Voice Apps Hosting</div>
				<div className="hosting_subheader">Terms Of Use</div>
				<div className="cw_container2">

				<p>Voice Apps, LLC. (“Voice Apps,” “we,” or “us”) provides an online file sharing platform and community. By registering as a member or by using Voice Apps in any way, you accept these Terms of Service (“Agreement”), which forms a binding agreement between you and Voice Apps in relation to your use of the Website, Apps and Services (together, the “Platform”).</p>
				<p><strong>IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE PLATFORM.</strong></p>
				<h2>Service</h2>
				<p>Registered and unregistered users of the Platform may submit, upload, record and post audio, images, text, comments and other content, data or information (“Content”), which will be stored by Voice Apps, and may be shared and distributed by such registered and unregistered users of the Platform using the tools and features provided as part of the Platform and accessible via the Website, Apps and elsewhere. The Service includes all aspect of Voice Apps, including but not limited to all products, software and services offered via the Voice Apps website.</p>
				<p>Unless otherwise stated, any new features or tools which are added to the Website and/or the Service are subject to these Terms.</p>
				<h2>Your Content &amp; Ownership</h2>
				<p>Any and all audio, images, text, comments, and other content, data or information that you upload, store, transmit, submit, exchange or make available to or via the Platform (“Your Content”) is generated, owned and controlled solely by you, and not by Voice Apps. For clarity, you retain all of your ownership rights in Your Content. However, by uploading, creating or submitting Your Content to the Platform, you initiate an automated process to transcode any Content and direct Voice Apps to store Your Content on our servers, from where your content will be made publicly available. To the extent it is necessary in order for Voice Apps to provide you with any of the aforementioned services, you grant Voice Apps a limited, worldwide, non-exclusive, royalty-free, fully paid-up, perpetual, irrevocable license to use, reproduce, distribute, modify, adapt, prepare derivative works of, display, perform Your Content in connection with the Platform, including, without limitation, for promoting and redistributing part or all of the Platform through any media channels.</p>
				<h2>Your Account</h2>
				<p>You are not required to register to use the Platform. However, certain services are only available to registered users. When creating your account, you must provide accurate and complete information. You will provide us with your email address and will choose a password for your account. Your email address and any other information you chose to provide about yourself will be treated in accordance with our Privacy Policy. You must ensure that the email address that you provide is, and remains, valid.</p>
				<p>You are solely responsible for the activity that occurs on your account, and you must keep your account password secure. You must notify Voice Apps immediately of any breach of security or unauthorized use of your account.</p>
				<p>You may terminate your account at any time.</p>
				<h2>Content Restrictions</h2>
				<p>You may not upload, post, or transmit (“Submit”) any audio recording, image, text or other work (“Content”) that:</p>
				<p>1) Infringes any third party copyrights or other rights (e.g., trademark, privacy rights, etc.)</p>
				<p>2) Promotes fraudulent or dubious business schemes</p>
				<p>3) Violates any law</p>
				<h2>Usage Restrictions</h2>
				<p>Content that is provided to Voice Apps under these terms is only to be used in and served from Voice Applications such as Alexa Skills, Google Actions, Bixby Skills and other similar services.  Voice Apps reserves the sole right to determine the appropriate uses for the Content and, if it determines that the content is being used inappropriately, may remove the content without notice and/or terminate the user&#39;s account.</p>
				<h2>Copyrights and Copyright Agents</h2>
				<p>Voice Apps, LLC. has a zero tolerance policy for those who post copyrighted material: we fully comply with the Digital Millennium Copyright Act of 1998. By agreeing to these Terms of Service (“Agreement”), you are acknowledging that you have read and agree to our <u>Digital Millennium Copyright Act (“DMCA”) Policy.</u></p>
				<h2>DISCLAIMER</h2>
				<p>THE PLATFORM, INCLUDING, WITHOUT LIMITATION, THE WEBSITE, THE APPS AND ALL CONTENT AND SERVICES ACCESSED THROUGH OR VIA THE WEBSITE, THE APPS OR OTHERWISE, ARE PROVIDED “AS IS”, “AS AVAILABLE”, AND “WITH ALL FAULTS”.</p>
				<p>WHILE VOICE APPS, INC USES REASONABLE ENDEAVORS TO CORRECT ANY ERRORS OR OMISSIONS IN THE PLATFORM AS SOON AS POSSIBLE ONCE THEY HAVE BEEN BROUGHT TO OUR ATTENTION, VOICE APPS MAKES NO PROMISES, GUARANTEES, REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER REGARDING THE WEBSITE, THE APPS, THE SERVICES OR ANY PART OR PARTS THEREOF, ANY CONTENT, OR ANY LINKED SERVICES OR OTHER EXTERNAL SERVICES. VOICE APPS DOES NOT WARRANT THAT YOUR USE OF THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM OR ANY PARTS THEREOF, THE CONTENT, OR THE SERVERS ON WHICH THE PLATFORM OPERATES ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. VOICE APPS DOES NOT WARRANT THAT ANY TRANSMISSION OF CONTENT UPLOADED TO THE PLATFORM WILL BE SECURE OR THAT ANY ELEMENTS OF THE PLATFORM DESIGNED TO PREVENT UNAUTHORIZED ACCESS, SHARING OR DOWNLOAD OF CONTENT WILL BE EFFECTIVE IN ANY AND ALL CASES, AND DOES NOT WARRANT THAT YOUR USE OF THE PLATFORM IS LAWFUL IN ANY PARTICULAR JURISDICTION.</p>
				<p>VOICE APPS, SPECIFICALLY DISCLAIMS ALL OF THE FOREGOING WARRANTIES AND ANY OTHER WARRANTIES NOT EXPRESSLY SET OUT HEREIN TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING WITHOUT LIMITATION ANY EXPRESS OR IMPLIED WARRANTIES REGARDING NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>
				<p>WHERE THE LAW OF ANY JURISDICTION LIMITS OR PROHIBITS THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES AS SET OUT ABOVE, THE ABOVE DISCLAIMERS SHALL NOT APPLY TO THE EXTENT THAT THE LAW OF SUCH JURISDICTION APPLIES TO THIS AGREEMENT.</p>
				<h2>Indemnity</h2>
				<p>You agree to indemnify, hold harmless, and keep Voice Apps, its subsidiaries and affiliates, and their respective owners, officers, managers, members, agents and employees, fully and effectively indemnified with respect to any and all costs, claims, demands, investigations, liabilities, losses, damages, judgments, settlements, costs and expenses, including attorneys&#39; fees arising out of or in connection with these Terms of Service, including, without limitation: (a) your use of the Platform; (b) your violation of these Terms or any law, rule or regulation; (c) your use of the Content; or (d) your placement or transmission of any of your Content or any other content or materials on or through the Platform. You will cooperate as fully and reasonably as required by Voice Apps in the defense of any claim. Notwithstanding the foregoing, Voice Apps retains the exclusive right to settle, compromise and pay any and all claims, demands, proceedings, suits, actions or causes of actions which are brought against Voice Apps herein under the terms and provisions of these Terms and in no event shall you settle any such claim without Voice Apps&#39; prior written approval.</p>
				<h2>Changes to the Platform</h2>
				<p>We reserve the right to add, delete or amend parts of these Terms of Service at our sole discretion and at any time without prior notice to you. We will post any changes to the Terms of Service on this page and will indicate the effective date of the revised Terms of Service at the top of the page. It is important for you to refer to these Terms of Service from time to time to make sure that you are aware of any changes that we may have made. By continuing to use the Platform you are agreeing to be bound by the revised Term.</p>
				<h2>Termination</h2>
				<p>You understand and agree that Voice Apps will determine your compliance with these Terms of Service in its sole discretion. Voice Apps reserves the right to restrict, suspend, deny or terminate access to all or part of the Website or any Service and to deny access to any person in its sole discretion without notice or liability of any kind. Voice Apps maintains a policy that provides for the termination in appropriate circumstances of the Website use privileges of users who are repeat infringers of intellectual property rights and/or any other breaches of any of these Terms. Any violation of these Terms of Service may be referred to law enforcement authorities. Upon termination of your account or access to the Website, or upon demand by Voice Apps, you must destroy all materials obtained from the Platform and all related documentation.</p>
			</div>
			</div>
		);
	}

}

Terms.propTypes = {
	user: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	login: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth }) => ({ user: auth.user });
const mapDispatchToProps = (dispatch) => bindActionCreators({ login }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
