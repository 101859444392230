import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import Editor from '../Editor';
import VariablePicker from '../VariablePicker';

class MultiKeyValue extends Component {
  constructor(props) {
    super(props);

    this.lastInput = React.createRef();

    this.state = {
      keyValues: props.data,
      selectedTextIndex: null,
      inputOperator: '=',
      inputTarget: '',
      inputValue: '',
      inputWhenOperator: '=',
      editWhen: false,
      showBulk: false,
      bulkText: ''
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      keyValues: props.data,
      selectedTextIndex: null,
      inputOperator: '=',
      inputTarget: '',
      inputValue: '',
      inputWhenOperator: '=',
      editWhen: false,
      value: props.value ? props.value : this.state.value
    })
  }

  handleAddKeyValue = (e) => {
    this.addKeyValue();
    window.setTimeout(() => {
      $(this.lastInput.current).focus();
    },100);

  }

  handleInputValueKeyPress = (e) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      utils.log('got here');
        this.addKeyValue();
        e.target.value = '';
        $(e.target).prev().focus();
    }
  }

  handleChangeKey = (value, index) => {
    let { keyValues } = this.state;

    keyValues[index].key = value;

    this.setState({
      keyValues
    });

    if (this.props.onChange) {
      this.props.onChange(keyValues);
    }
  }

  handleChangeValue = (value, index) => {
    let { keyValues } = this.state;

    keyValues[index].value = value;

    this.setState({
      keyValues
    });

    if (this.props.onChange) {
      this.props.onChange(keyValues);
    }

  }

  handleKeyPress = (e) => { // eslint-disable-line

    var keycode = (e.keyCode ? e.keyCode : e.which);
    utils.log(keycode);

    if (keycode === 13) {
      this.deselectKeyValue();
    }
  }

  selectKeyValue = (index) => { // eslint-disable-line
    this.setState({
      selectedIndex: index
    });
  }

  deselectKeyValue = () => {
    this.setState({
      selectedIndex: null
    });
  }

  handleChangeInputKey = (value) => { // eslint-disable-line
    this.setState({
      key: value
    });
  }

  handleChangeInputValue = (value) => { // eslint-disable-line
    this.setState({
      value: value
    });
  }

  addKeyValue = () => { // eslint-disable-line
    utils.log('Add Variable');
    let { keyValues, key, value } = this.state;

    keyValues.push({
      key: '',
      value: ''
    });

    this.setState({
      keyValues,
      key: '',
      value: '',
    });

    if (this.props.onChange) {
      this.props.onChange(keyValues);
    }

    setTimeout(() => {
      var mydiv = $(".kv_Group");
      mydiv.scrollTop(mydiv.prop("scrollHeight"));
    }, 300);
  }

  handleKeyValueDelete = (e, index) => { // eslint-disable-line
    utils.log(index);
    let { keyValues } = this.state;

    keyValues.splice(index, 1);

    this.setState({
      keyValues,
    });

    if (this.props.onChange) {
      this.props.onChange(keyValues);
    }

    e.preventDefault();
    e.stopPropagation();
  }

  toggleBulkEdit = () => { // eslint-disable-line
    utils.log("toggle bulk");
    this.setState({
      showBulk: !this.state.showBulk
    });
  }

  handleBulkTextChange = (e) => { // eslint-disable-line
    this.setState({
      bulkText: e.target.value
    });
  }

  bulkAddText = () => { // eslint-disable-line
    let { keyValues, bulkText, isJSON } = this.state;
    // const { allowJSON } = this.props;
    //
    // let json = this.tryParseJSON(bulkText);
    // utils.log("isJSON?", json);
    // if (json && json.length > 0 && allowJSON === true) {
    //   keyValues = [];
    //   keyValues.push(JSON.stringify(json, null, 4));
    //
    //   this.setState({
    //     isJSON: true,
    //     textArray,
    //     bulkText: ''
    //   });
    //
    //   if (this.props.onChange) {
    //     this.props.onChange(textArray);
    //   }
    // } else {

      if (bulkText != null) {
        if (isJSON) {
          keyValues = [];
        }

        let bulkTextValues = bulkText.replace(/\n\n/g, '\n').split('\n');
        bulkTextValues = bulkTextValues.filter(f => {return f.trim() !== '';});

        bulkTextValues.forEach((keyvalue, index) => {
          let splits = keyvalue.split('::');

          if (splits.length===2) {
            keyValues.push({key:splits[0], value: splits[1]});
          } else {
            keyValues.push({key: index+1, value: keyvalue});
          }
        })

      }

      this.setState({
        keyValues,
        showBulk: false,
        isJSON: false,
        bulkText: ''
      });

      if (this.props.onChange) {
        this.props.onChange(keyValues);
      }
    // }
  }

  render() {
    const { keyValues, key, value, selectedIndex, showBulk, bulkText } = this.state;

    const { header, keyPlaceHolder, valuePlaceHolder, allowBulk, allowJSON, addButtonText, allowVariables, keyIsVariable } = this.props;

    let bulkPlaceholder = "Enter or paste a list of \"key::value\" (2 colons) entries.  Place each \"key::value\" pair on its own line. \n\nYou can also paste \"value\" only entries and the system will auto-assign a numeric key.";
    if (allowJSON) {
      bulkPlaceholder += "\n\nYou can also paste a valid JSON array.";
    }

    //console.log("Rendering MultiKeyalue");
    utils.log('selectedIndex=', selectedIndex);
    return (
      <div className="form-group">
        <div className="multiText_TextHeader" dangerouslySetInnerHTML={{__html: header}}></div>
        <div className="kv_container">
          <div className={"kv_Group" + (keyIsVariable ? ' noscroll' : '')}>
            {keyValues.map((keyvalue, index) => {
              return (
                <div key={index} className="multikv_variableContainer">
                  <div className="multikv_variable">
                    {/*<input ref={index === keyValues.length-1 ? this.lastInput : null} onFocus={() => this.selectKeyValue(index)} onBlur={this.deselectKeyValue} className={"kv_key" + (selectedIndex===index ? ' active' : '')} value={keyvalue.key} onChange={(event) => this.handleChangeKey(index, event.target.value)}/>
                    <input onFocus={() => this.selectKeyValue(index)} onBlur={this.deselectKeyValue} className={"kv_value" + (selectedIndex===index ? ' active' : '')} value={keyvalue.value} onChange={(event) => this.handleChangeValue(index, event.target.value)} />*/}
                    <div className="multikv_keyvalue">
                      <div className="multikv_key">
                        {keyIsVariable ?
                          <VariablePicker initialValue={keyvalue.key} variables={this.props.variables} onChange={(data) => this.handleChangeKey(data, index)}/>
                          :
                          <Editor
                            variables={this.props.variables}
                            placeHolder={keyPlaceHolder}
                            value={keyvalue.key}
                            onChange={(data) => this.handleChangeKey(data, index)}
                            allowVariables={allowVariables}
                            clearOnBlur={false}
                            singleLine={true}
                          />
                        }
                      </div>
                      <div className="multikv_value">
                        <Editor
                          variables={this.props.variables}
                          placeHolder={valuePlaceHolder}
                          value={keyvalue.value}
                          onChange={(data) => this.handleChangeValue(data, index)}
                          allowVariables={allowVariables}
                          clearOnBlur={false}
                          singleLine={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="multikv_delete" onClick={(event) => this.handleKeyValueDelete(event, index)}><FA icon="minus-circle" /></div>
                </div>
              )
            })}

          </div>
        </div>

        <div className="multiText_InputGroup">
        {showBulk ?
          <div>
            <textarea className="form-control multiText_textArea" placeholder={bulkPlaceholder} rows="4" value={bulkText} onChange={(event) => this.handleBulkTextChange(event)}></textarea>
            <div className="multiText_bulkAdd" onClick={this.bulkAddText}>Bulk Add</div>
          </div>
          :
          <div className="ss_row">
            {/*<input type="text" className="kv_inputKey" placeholder={keyPlaceHolder} value={key} onChange={(event) => this.handleChangeInputKey(event.target.value)}/>
            <input type="text" className="kv_inputValue" placeholder={valuePlaceHolder} value={value} onChange={(event) => this.handleChangeInputValue(event.target.value)} onKeyPress={this.handleInputValueKeyPress}/>
            */}
            <button type="button" className="btn btn-light api_testButton" onClick={this.handleAddKeyValue}><span className="api_testIcon"><FA icon={["far", "plus"]} /></span>{addButtonText}</button>
            {allowBulk ? <div className={"multiText_bulk"} onClick={this.toggleBulkEdit} title="Enter values in bulk"><FA icon={["fal", "truck"]} /></div> : null }
          </div>
        }
      </div>
    </div>
    );
  }
}

export default MultiKeyValue;
