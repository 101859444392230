import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import FunctionContainer from '../FunctionContainer';

import AlexaResponse from '../AlexaResponse';
import ShortAudio from '../ShortAudio';
import HomeCard from '../HomeCard';
import Permissions from '../Permissions';
import DisplayTemplate from '../DisplayTemplate';
import APLTemplate from '../APLTemplate';
import Expression from '../Expression';

class ResponseGroup extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      group: props.data,
      editWhen: props.data.when != null
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      group: props.data,
      editWhen: props.data.when != null
    })
  }

  componentDidMount() {
    if (this.boxRef.current) {

      this.boxRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  toggleWhen = () => { // eslint-disable-line
    let { group, editWhen, savedWhen } = this.state;

    if (group.when && editWhen ) {
      savedWhen = group.when;
      group.when = null;
    } else {
      group.when = savedWhen ? savedWhen : {}
    }


    this.setState({
      group,
      editWhen: !this.state.editWhen,
      savedWhen
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  changeWhenOperator = (operator) => { // eslint-disable-line
    let { group } = this.state;

    if (!group.when) group.when = {};

    group.when.operator = operator;

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleChangeWhenTarget = (value) => { // eslint-disable-line
    let { group } = this.state;

    if (!group.when) group.when = {};

    group.when.sourceVariable = value;

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleChangeWhenValue = (value) => { // eslint-disable-line
    let { group } = this.state;

    if (!group.when) group.when = {};

    group.when.targetVariable = value;

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleExpressionChange = (data) => {
    //console.log("----------Handle Expression Change----------");
    //console.log(data);

    let { group } = this.state;

    if (!group.when) group.when = {};

    group.when = data;

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  deleteIntent = () => { // eslint-disable-line
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  addResponse = () => { // eslint-disable-line
    let { group } = this.state;

    group.functions.push({
      type: "response",
      responses: []
    });

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  addAudio = () => { // eslint-disable-line
    let { group } = this.state;

    group.functions.push({
      type: "playAudio",
      urls: []
    });

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  addCard = () => { // eslint-disable-line
    let { group } = this.state;

    group.functions.push({
      type: "homeCard"
    });

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  addPermissions = () => { // eslint-disable-line
    let { group } = this.state;

    group.functions.push({
      type: "permissions"
    });

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  addDisplayTemplate = () => { // eslint-disable-line
    let { group } = this.state;
    let { isPremium, onShowPremiumMember } = this.props;

    if (isPremium) {
      group.functions.push({
        type: "displayTemplate"
      });

      this.setState({
        group
      });

      if (this.props.onChange) {
        this.props.onChange(group);
      }
    } else {
      if (onShowPremiumMember) {
        onShowPremiumMember();
      }
    }
  }

  addAPLTemplate = () => { // eslint-disable-line
    let { group } = this.state;
    let { isPremium, onShowPremiumMember } = this.props;

    //if (isPremium) {
      group.functions.push({
        type: "APLTemplate"
      });

      this.setState({
        group
      });

      if (this.props.onChange) {
        this.props.onChange(group);
      }
    // } else {
    //   if (onShowPremiumMember) {
    //     onShowPremiumMember();
    //   }
    // }
  }

  handleResponsesChange = (index, data) => { // eslint-disable-line
    let { group } = this.state;

    group.functions[index].responses = data.responses;
    group.functions[index].voice = data.voice;

    utils.log("Responses Changed, ", group.functions[index]);

    this.setState({
      group
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleShortAudioChange = (index, data) => { // eslint-disable-line
    let { group } = this.state;

    group.functions[index].urls = data;

    this.setState({
      group
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleHomeCardChange = (index, data) => { // eslint-disable-line
    let { group } = this.state;

    group.functions[index].isLinkedAccount = data.isLinkedAccount;
    group.functions[index].title = data.title;
    group.functions[index].description = data.description;
    group.functions[index].smallImageUrl = data.smallImageUrl;
    group.functions[index].largeImageUrl = data.largeImageUrl;

    this.setState({
      group
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handlePermissionsChange = (index, data) => { // eslint-disable-line
    let { group } = this.state;

    group.functions[index].permissions = data;

    this.setState({
      group
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleDisplayTemplateChange = (index, data) => { // eslint-disable-line
    let { group } = this.state;

    group.functions[index].title = data.title;
    group.functions[index].description = data.description;
    group.functions[index].smallImageUrl = data.smallImageUrl;
    group.functions[index].largeImageUrl = data.largeImageUrl;

    this.setState({
      group
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleAPLTemplateChange = (index, data) => { // eslint-disable-line
    let { group } = this.state;

    group.functions[index].template = data;

    this.setState({
      group
    })

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  getItems = () => { // eslint-disable-line
    const { group } = this.state;
    const { isPremium, onShowPremiumMember} = this.props;

    let data = [];
    group.functions.forEach((func, index) => {
      if (func.type === 'response') {
        data.push({
          id: index,
          content: (
            <AlexaResponse isPremium={isPremium} onShowPremiumMember={onShowPremiumMember} data={func} variables={this.props.variables} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleResponsesChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'playAudio') {
        data.push({
          id: index,
          content: (
            <ShortAudio data={func} variables={this.props.variables} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleShortAudioChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'homeCard') {
        data.push({
          id: index,
          content: (
            <HomeCard data={func} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleHomeCardChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'permissions') {
        data.push({
          id: index,
          content: (
            <Permissions permissions={func.permissions} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handlePermissionsChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'displayTemplate') {
        data.push({
          id: index,
          content: (
            <DisplayTemplate data={func} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleDisplayTemplateChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      } else if (func.type === 'APLTemplate') {
        data.push({
          id: index,
          content: (
            <APLTemplate data={func} onDelete={() => this.deleteFunction(index)} onChange={(data) => this.handleAPLTemplateChange(index, data)}/>
          ),
          isDragDisabled: false,
        })
      }
    })

    return data;
  }

  deleteFunction = (index) => { // eslint-disable-line
    let {group} = this.state;

    group.functions.splice(index, 1);

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  changeVisitStart = (visitStart) => { // eslint-disable-line
    let { group } = this.state;

    group.visitStart = visitStart;

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  changeVisitEnd = (visitEnd) => { // eslint-disable-line
    let { group } = this.state;

    group.visitEnd = visitEnd;

    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  handleFunctionsChange = (data) => { // eslint-disable-line
    utils.log("Functions Change");

    utils.log('data=', data);
    let { group } = this.state;

    let newFunctions = [];
    data.forEach((func, index) => {
      newFunctions.push(group.functions[func.id]);
    });

    group.functions = newFunctions;

    utils.log('Group After=', group);
    this.setState({
      group
    });

    if (this.props.onChange) {
      this.props.onChange(group);
    }
  }

  render() {
    const { group, editWhen } = this.state;

    let functions = this.getItems();

    return (
      <div className="intent_card" ref={this.boxRef}>
        <div className="intent_cardHeader">Response Group</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div>
          {functions.length > 0 ?
            <FunctionContainer data={functions} variables={this.props.variables} onChange={(data) => this.handleFunctionsChange(data)}/>
            :
            <div className="rg_noFunctions">Use the icons below to add responses.</div>
          }
        </div>
        <div>
          <div className="rg_toolbox">
            <div className="rg_tool" onClick={this.addResponse} data-toggle="tooltip" data-placement="top" title="Add Voice Response"><FA icon={["far", "comment-alt"]} /></div>
            <div className="rg_tool" onClick={this.addAudio} data-toggle="tooltip" data-placement="top" title="Add Short Audio"><FA icon={["fal", "music"]} /></div>
            <div className="rg_tool" onClick={this.addCard} data-toggle="tooltip" data-placement="top" title="Add Home Card"><FA icon={["fal", "id-card"]} /></div>
            <div className="rg_tool" onClick={this.addPermissions} data-toggle="tooltip" data-placement="top" title="Ask For Permissions"><FA icon={["fal", "key"]} /></div>
            <div className="rg_tool" onClick={this.addDisplayTemplate} data-toggle="tooltip" data-placement="top" title="Add DisplayTemplate"><FA icon={["fal", "image"]} /></div>
            <div className="rg_tool" onClick={this.addAPLTemplate} data-toggle="tooltip" data-placement="top" title="Add APL Template"><FA icon={["fal", "newspaper"]} /></div>
          </div>
        </div>
        {!(group.visitStart === 1 && group.visitEnd === 0) &&
          <div className="form-group rg_visitInfo">
            <div className="rg_visitNumberPrefix">Active from visit</div>
            <input className="form-control ar_visitInput" placeholder="0" maxLength='2' type="text" value={group.visitStart}
              onChange={(event) => this.changeVisitStart(event.target.value)}/>
            <div className="rg_visitNumberPrefix"> to </div>
            <input className="form-control ar_visitInput" placeholder="0" maxLength='2' type="text" value={group.visitEnd}
                onChange={(event) => this.changeVisitEnd(event.target.value)}/>
            <div className="rg_visitNumberPrefix"> (0 = infinite) </div>
          </div>
        }
        {editWhen ?
        <div className="na_row">
          <div className="na_when_header">
            <div className="na_label">When:</div>
            <div className="na_delete" onClick={() => this.toggleWhen()}><FA icon="minus-circle" /></div>
          </div>
          {/*<div className="multiState_label">When:</div>
          <input type="text" className="ss_target" placeholder="source_variable" value={group.when.sourceVariable} onChange={(event) => this.handleChangeWhenTarget(event.target.value)}/>
          <button className="btn btn-secondary dropdown-toggle" type="button" id="projectTemplateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {group.when.operator}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('==')}>== (equal to)</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('!=')}>!= (not equal to)</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('>')}>&gt; (greater than)</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('<')}>&lt; (less than)</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('>=')}>&gt;= (greater than or equal to)</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeWhenOperator('<=')}>&lt;= (less than or equal to)</a>
          </div>
          <input type="text" className="ss_value" placeholder="value_or_variable" value={group.when.targetVariable} onChange={(event) => this.handleChangeWhenValue(event.target.value)} />
          <div className="multiText_delete" onClick={() => this.toggleWhen()}><FA icon="minus-circle" /></div>*/}
          <Expression when={group.when} variables={this.props.variables} showEquation={false} onChange={this.handleExpressionChange}/>
        </div>
        :
        <div className="multiState_variable">
          <div className="multiState_label">When:</div>
          <div className="multiState_always" onClick={this.toggleWhen}>Always</div>
        </div>
      }
      </div>
    );
  }
}

export default ResponseGroup;
