import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';

class DisplayTemplate extends Component {
  constructor(props) {
    super(props);

    let cardData = props.data;

    //utils.log(cardData);
    //utils.log("Publishing INFO (constructor)=", JSON.parse(JSON.stringify(publishingInfo)));
    if (cardData.title == null) {
      cardData = {
        template: "BodyTemplate1",
        title: '',
        primaryText: '',
        secondaryText: '',
        tertiaryText: '',
        backgroundImageUrl: '',
        foregroundImageUrl: ''
      }
    }
    this.state = {
      cardData: cardData,
      formFields: {
        template: {
          isValid: true
        },
        title: {
          isValid: true
        },
        primaryText: {
          isValid: true
        },
        secondaryText: {
          isValid: true
        },
        tertiaryText: {
          isValid: true
        },
        backgroundImageUrl: {
          isValid: true
        },
        foregroundImageUrl: {
          isValid: true
        }
      }
    };
  }

  componentWillReceiveProps(props) {
    //utils.log('Home Card ComponentWillReceivePropss')
    let cardData = props.data;

    // utils.log(cardData);
    //utils.log("Publishing INFO (constructor)=", JSON.parse(JSON.stringify(publishingInfo)));
    if (cardData.title == null) {
      cardData = {
        template: "BodyTemplate1",
        title: '',
        primaryText: '',
        secondaryText: '',
        tertiaryText: '',
        backgroundImageUrl: '',
        foregroundImageUrl: ''
      }
    }
    this.setState({
      cardData: cardData,
      formFields: {
        template: {
          isValid: true
        },
        title: {
          isValid: true
        },
        primaryText: {
          isValid: true
        },
        secondaryText: {
          isValid: true
        },
        tertiaryText: {
          isValid: true
        },
        backgroundImageUrl: {
          isValid: true
        },
        foregroundImageUrl: {
          isValid: true
        }
      }
    });
  }

  validateFormFields = () => {
    utils.log('================== VALIDATING FORM FIELDS ===================');
    let { cardData, formFields } = this.state;

    // // Title
    // if (cardData.title === '') {
    //   formFields.title.isValid = false;
    //   formFields.title.error = "Title is required.";
    // } else {
    //   formFields.title.isValid = true;
    //   formFields.title.error = '';
    // }
    //
    // // Description
    // if (cardData.description === '') {
    //   formFields.description.isValid = false;
    //   formFields.description.error = "Title is required.";
    // } else {
    //   formFields.description.isValid = true;
    //   formFields.description.error = '';
    // }
    //
    // // Small Image Url
    // if (cardData.smallImageUrl === '') {
    //   formFields.smallImageUrl.isValid = false;
    //   formFields.smallImageUrl.error = "Title is required.";
    // } else {
    //   formFields.smallImageUrl.isValid = true;
    //   formFields.smallImageUrl.error = '';
    // }
    //
    // // Short Image Url
    // if (cardData.largeImageUrl === '') {
    //   formFields.largeImageUrl.isValid = false;
    //   formFields.largeImageUrl.error = "Title is required.";
    // } else {
    //   formFields.largeImageUrl.isValid = true;
    //   formFields.largeImageUrl.error = '';
    // }

    // this.setState({
    //   formFields
    // })
  }

  changeTemplate = (value) => {
    let { cardData } = this.state;

    cardData.template = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleTitleChange = (value) => {
    let { cardData } = this.state;

    cardData.title = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handlePrimaryTextChange = (value) => {
    let {cardData} = this.state;

    cardData.primaryText = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleSecondaryTextChange = (value) => {
    let {cardData} = this.state;

    cardData.secondaryText = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleTertiaryTextChange = (value) => {
    let {cardData} = this.state;

    cardData.tertiaryText = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleBackgroundImageUrlChange = (value) => {
    let {cardData} = this.state;

    cardData.backgroundImageUrl = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleForegroundImageUrlChange = (value) => {
    let {cardData} = this.state;

    cardData.foregroundImageUrl = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  handleShowBackButtonChange = (value) => {
    let {cardData} = this.state;

    cardData.showBackButton = value;

    this.setState({
      cardData,
    });

    this.validateFormFields();

    if (this.props.onChange) {
      this.props.onChange(cardData);
    }
  }

  deleteIntent = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    const { cardData, formFields } = this.state;

    let template = cardData.template;
    let templateText = 'Body Template 1';
    if (template === 'BodyTemplate1') templateText = 'Body Template 1';
    if (template === 'BodyTemplate2') templateText = 'Body Template 2';
    if (template === 'BodyTemplate3') templateText = 'Body Template 3';
    if (template === 'BodyTemplate6') templateText = 'Body Template 6';
    if (template === 'BodyTemplate7') templateText = 'Body Template 7';

    return (
      <div className="intent_card">
        <div className="intent_cardHeader">DisplayTemplate</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <div className="form-group">
          <button className="btn btn-secondary dropdown-toggle" type="button" id="templateDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {templateText}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#" onClick={() => this.changeTemplate('BodyTemplate1')}>Body Template 1</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeTemplate('BodyTemplate2')}>Body Template 2</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeTemplate('BodyTemplate3')}>Body Template 3</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeTemplate('BodyTemplate6')}>Body Template 6</a>
            <a className="dropdown-item" href="#" onClick={() => this.changeTemplate('BodyTemplate7')}>Body Template 7</a>
          </div>
        </div>

        <div className="form-group">
          <div className="trigger_utteranceHeader">Title</div>
          <span className="pi_counter">{cardData.title == null ? 0 : cardData.title.length }/50</span>
          <input className={"form-control" + (formFields.title.isValid ? '' : ' is-invalid')} maxLength="50" placeholder="Title text" type="text" value={cardData.title} onChange={(event) => this.handleTitleChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.title.error}
          </div>
        </div>

        <div className="form-group">
          <div className="trigger_utteranceHeader">Text Content</div>
          <span className="pi_counter">{cardData.primaryText == null ? 0 : cardData.primaryText.length }/8000</span>
          <textarea className={"form-control" + (formFields.primaryText.isValid ? '' : ' is-invalid')} maxLength="8000" placeholder="Primary display text" type="text" value={cardData.primaryText} onChange={(event) => this.handlePrimaryTextChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.primaryText.error}
          </div>
        </div>

        <div className="form-group">
          <span className="pi_counter">{cardData.secondaryText == null ? 0 : cardData.secondaryText.length }/8000</span>
          <textarea className={"form-control" + (formFields.secondaryText.isValid ? '' : ' is-invalid')} maxLength="8000" placeholder="Secondary display text" type="text" value={cardData.secondaryText} onChange={(event) => this.handleSecondaryTextChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.secondaryText.error}
          </div>
        </div>

        <div className="form-group">
          <span className="pi_counter">{cardData.tertiaryText == null ? 0 : cardData.tertiaryText.length }/8000</span>
          <textarea className={"form-control" + (formFields.tertiaryText.isValid ? '' : ' is-invalid')} maxLength="8000" placeholder="Tertiary display text" type="text" value={cardData.tertiaryText} onChange={(event) => this.handleTertiaryTextChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.tertiaryText.error}
          </div>
        </div>

        <div className="form-group">

          <div className="trigger_utteranceHeader">Background Image URL</div>
          <span className="pi_counter">{cardData.backgroundImageUrl == null ? 0 : cardData.backgroundImageUrl.length }/2000</span>
          <input className={"form-control" + (formFields.backgroundImageUrl.isValid ? '' : ' is-invalid')} maxLength="2000" placeholder="HTTPS URL" type="text" value={cardData.backgroundImageUrl} onChange={(event) => this.handleBackgroundImageUrlChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.backgroundImageUrl.error}
          </div>

          <div className="trigger_utteranceHeader">Foreground Image URL</div>
          <span className="pi_counter">{cardData.foregroundImageUrl == null ? 0 : cardData.foregroundImageUrl.length }/2000</span>
          <input className={"form-control" + (formFields.foregroundImageUrl.isValid ? '' : ' is-invalid')} maxLength="2000" placeholder="HTTPS URL" type="text" value={cardData.foregroundImageUrl} onChange={(event) => this.handleForegroundImageUrlChange(event.target.value)}/>
          <div className="invalid-feedback">
            {formFields.foregroundImageUrl.error}
          </div>
        </div>

        <div className="form-group">
          <input type="checkbox" value={cardData.showBackButton} checked={cardData.showBackButton} name="showBackButton" onChange={evt => this.handleShowBackButtonChange(evt.target.checked)}/> <span className="edit_synchEnglishLocales">Show Back Button</span>
        </div>


      </div>
    );
  }
}

export default DisplayTemplate;
