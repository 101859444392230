import React, { Component } from 'react';
import './Home.css';
import $ from 'jquery';
import Header from '../Header';
import Footer from '../Footer';


class Home extends Component {
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount() {
    new window.WOW().init();
		   // $(document).ready(function() {
       //        var owl = $('#slider');
       //        owl.owlCarousel({
				// loop:true,
       //          margin: 20,
				// autoplay:true,
				// autoplayTimeout:4000,
				// smartSpeed:450,
				// dots:false,
       //
       //          nav: true,
				//    navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
       //
       //          responsive: {
       //            0: {
       //              items: 1
       //            },
       //            600: {
       //              items: 2
       //            },
       //            1000: {
       //              items: 3
       //            }
       //          }
       //        })
       //      });

    // $(function() {
    //   $('#main-menu').smartmenus({
    //     mainMenuSubOffsetX: -1,
    //     subMenusSubOffsetX: 10,
    //     subMenusSubOffsetY: 0
    //   });
    // });

    // SmartMenus mobile menu toggle button
    $(function() {
      var $mainMenuState = $('#main-menu-state');
      if ($mainMenuState.length) {
        // animate mobile menu
        $mainMenuState.change(function(e) {
          var $menu = $('#main-menu');
          if (this.checked) {
            $menu.hide().slideDown(250, function() { $menu.css('display', ''); });
          } else {
            $menu.show().slideUp(250, function() { $menu.css('display', ''); });
          }
        });
        // hide mobile menu beforeunload
        $(window).bind('beforeunload unload', function() {
          if ($mainMenuState[0].checked) {
            $mainMenuState[0].click();
          }
        });
      }
    });
    //$('.prettySocial').prettySocial();
    window.Intercom("boot", {
      app_id: "rxv0p3l3",
    });
  }

  render() {
    return (
      <div className="home_container">
      <div className="header-top">
        <div className="container">
          <nav className="main-nav" role="navigation">

            {// Mobile menu toggle button (hamburger/x icon) -->
            }
            <input id="main-menu-state" type="checkbox" />
            <label className="main-menu-btn" htmlFor="main-menu-state"> <span className="main-menu-btn-icon"></span> Toggle main menu visibility </label>
            <h2 className="nav-brand"><a href="#"><img className="img-responsive" src="images/home_logo.png"/></a></h2>

            {//<!-- Sample menu definition -->
            }
            <ul id="main-menu" className="sm sm-simple">
              <li><a href="#" onClick={() => this.goTo('')}>Home</a></li>
              <li><a href="#" onClick={() => this.goTo('hosting')}>Hosting</a></li>
              <li><a href="#" onClick={() => this.goTo('skills')}>Built with VoiceApps</a></li>
              <li><a href="#" onClick={() => this.goTo('plans')}>Pricing</a></li>
              <li className="last_menu"><a href="#" onClick={() => this.goTo('login')}>SIGN IN</a></li>
            </ul>
          </nav>
        </div>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-md-6 wow fadeInUp animated">
                <div className="banner-text">
                  <h4>Build and Publish Alexa <br/>
                    Skills In Minutes</h4>
                  <p>Voice Apps makes it easy for anyone to build skills for Amazon Alexa devices (echo, dot, show, etc.)</p>
                  <a className="slider_btn" href="" onClick={() => this.goTo('login')}>Start Now - It&apos;s FREE!</a> </div>
              </div>
              <div className="col-md-6 dektop fadeInUp animated">
                <div className="banner-icon"> <img className="slider_img" src="images/banner-icon.png"/> </div>
              </div>
              <div className="col-md-6 phone wow fadeInUp animated">
                <div className="banner-icon"> <img className="img-responsive slider_img" src="images/banner-icon.png"/> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="echo">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-5 wow fadeInUp animated">
              <div className="echo-images"> <img className="img-responsive echo_img" src="images/swag.png"/> </div>
            </div>
            <div className="col-md-6 col-sm-7 wow fadeInUp animated">
              <div className="echo-text">
                <div className="echo-title">
                  <h4>Get FREE Stuff!</h4>
                </div>
                <ul>
                  <li>VoiceApps and Amazon are continuously running promotions and providing incentives for developing Alexa skills.</li>
                  <li>The next promotion will start later this month!</li>
                  <li>Building these skills with Voice Apps is quick and easy!
                    Just customize one of our sample skills and you&apos;re done!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="voice desktop_view">
        <div className="container">
          <div className="row">
            <div className="col-md-6 tab wow fadeInUp animated">
              <div className="img-voice"> <img className="img-responsive voice_img" src="images/voice-icon.png"/> </div>
            </div>
            <div className="col-md-6 col-sm-7 wow fadeInUp animated">
              <div className="voice-text">
                <div className="voice-title">
                  <h4>What can you create with <br/>
                    Voice Apps</h4>
                  <p>You can build almost any skill you can dream up!  Our comprehensive tools allow you to take full control of the Alexa platform!</p>
                  <div className="free_btn_box"> <a className="free_btn" href="" onClick={() => this.goTo('login')}>Get Your Free Account</a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-5 tab1 wow fadeInUp animated">
              <div className="voice-icon">
                <div className="images-voice"> <img className="img-responsive voice_img" src="images/voice-icon.png"/> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="voice phone_view">
        <div className="container">
          <div className="row wow fadeInUp animated">
            <div className="col-md-12">
              <div className="point_img"> <img className="img-responsive phone_voice" src="images/point_img.png"/> </div>
            </div>
          </div>
          <div className="row wow fadeInUp animated">
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/trivia.png"/>
                <h4>Trivia Games</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/business.png"/>
                <h4>Streaming Audio Skills</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/kids.png"/>
                <h4>Kids Games</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/adventure.png"/>
                <h4>Adventure Games</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/business.png"/>
                <h4>Business Skills</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/fact.png"/>
                <h4>Fact Skills</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/joke.png"/>
                <h4>Joke Skills</h4>
              </div>
            </div>
            <div className="col-xs-6 text-center">
              <div className="icon_box"> <img className="img-responsive logoimg" src="images/other.png"/>
                <h4>Other</h4>
              </div>
            </div>
          </div>
          <div className="col-md-12 wow fadeInUp animated">
            <div className="voice_detail">
              <h2>What can you create with Voice Apps</h2>
              <p>Voice Apps makes it easy for anyone to build skills for Amazon Alexa devices (echo, dot, show, etc.)</p>
              <div className="get-start-box-1"> <a className="get-start-1" href="" onClick={() => this.goTo('login')}>Start Now! It’s Free</a> </div>
            </div>
          </div>
        </div>
      </div>
      <div className="easy">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp animated">
              <div className="easy-text">
                <div className="easy-heding"><a data-toggle="modal" data-target="#myModal"><img className="img-responsive video_play" src="images/play.png"/></a>
                  <h4>Easy as Pie</h4>
                  <p>Building complex skills is easy with Voice Apps. Our visual, drag and drop editor <br/>
                    makes it easy to see your entire project.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp animated">
              <div className="testimonial-title">
                <div className="testimonial-heding">
                  <h4>What others are saying about us</h4>
                  <p>We&apos;ve had some really great feedback from our users.  Here&apos;s some of our favorites!</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 wow fadeInUp animated">
              <div className="owl-carousel owl-theme ss_carousel" id="slider">
                <div className="item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="testimonial-section">
                        <div className="testimonial-comment">
                          <p>“I love the Voice Apps platform!  It&apos;s so easy to use and the support team is there to help me every step of the way.”</p>
                        </div>
                        <div className="client-name-section">
                          <div className="client-name">
                            <h4>Jeremy Williams</h4>
                            <p>Reston, VA</p>
                          </div>
                        </div>
                      </div>
                      <img className="img-responsive down" src="images/down.png"/> </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="testimonial-section">
                        <div className="testimonial-comment">
                          <p>“I was able to build my first skill so fast.  With the pre-made templates, I didn&apos;t have to start from scratch.  Keep up the great work guys!”</p>
                        </div>
                        <div className="client-name-section">
                          <div className="client-name">
                            <h4>Joseph Beaudette</h4>
                            <p>Spokane, ID</p>
                          </div>
                        </div>
                      </div>
                      <img className="img-responsive down" src="images/down.png"/> </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="testimonial-section">
                        <div className="testimonial-comment">
                          <p>“I didn&apos;t know anything about building Alexa skills, but was able to create a skill I&apos;m really proud of.  Thanks Voice Apps! I can&apos;t wait to get my free echo dot.”</p>
                        </div>
                        <div className="client-name-section">
                          <div className="client-name">
                            <h4>Maureen Waldrop</h4>
                            <p>Smithville, OK</p>
                          </div>
                        </div>
                      </div>
                      <img className="img-responsive down" src="images/down.png"/> </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="testimonial-section">
                        <div className="testimonial-comment">
                          <p>“You had me at &quot;Get a free echo dot&quot;.  Ha ha!  Thanks so much for making this so easy.”</p>
                        </div>
                        <div className="client-name-section">
                          <div className="client-name">
                            <h4>George Summer</h4>
                            <p>Jackson, MS</p>
                          </div>
                        </div>
                      </div>
                      <img className="img-responsive down" src="images/down.png"/> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="publish">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 wow fadeInUp animated">
              <div className="publish-icon"> <img className="img-responsive publish_img" src="images/publish-icon.png"/> </div>
            </div>
            <div className="col-md-8 col-sm-8 wow fadeInUp animated">
              <div className="publish-title">
                <h4>Build and Publish Alexa Skills In Minutes</h4>
                <div className="get-start-box"> <a className="get-start" href="" onClick={() => this.goTo('login')}>Build Your First Skill Now!</a> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp animated">
              <ul id="footer">
                <li><a href="#" onClick={() => this.goTo('')}>Home</a></li>
                <li><a href="#" onClick={() => this.goTo('skills')}>Built with VoiceApps</a></li>
                <li><a href="#" onClick={() => this.goTo('privacy')}>Privacy</a></li>
                <li><a href="#" onClick={() => this.goTo('terms')}>Terms</a></li>
                <li><a href="#" onClick={() => this.goTo('plans')}>Pricing</a></li>
                <li><a href="#" onClick={() => this.goTo('login')}>Sign Up</a></li>
              </ul>
            </div>
            {/*<div className="col-md-12 wow fadeInUp animated">
              <ul id="footer1">
                <li><a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href=""><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                <li><a href=""><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
              </ul>
            </div>*/
          }
            <div className="col-md-12 wow fadeInUp animated">
              <div className="footer-icon"> <img alt="footer" src="images/footer-icon.png"/>
                <h4>© {new Date().getYear() + 1900} Voice Apps. All Rights Reserved. </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="myModal" role="dialog">
        <div className="modal-dialog">

          {//<!-- Modal content-->
          }
          <div className="modal-content">
            <div className="modal-header">
              How Voice Apps Works
              <button type="button" className="close home_close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <iframe id="iframeYoutube" width="100%" height="315"  src="https://www.youtube.com/embed/ytA90aJEzXc" frameBorder="0" allowFullScreen></iframe>
            </div>

          </div>

        </div>
      </div>
    </div>
    );
  }
}


export default Home;
