import React, { Component } from 'react';
import './index.css';
import Header from '../Header';
// import Footer from '../Footer';
import FA from '@fortawesome/react-fontawesome';
import GoogleLogin from 'react-google-login';


import utils from '../helpers/utils.js';

// import sampleProject from './sampleData.js';

var AWS = require("aws-sdk");
AWS.config.setPromisesDependency(require('bluebird'));

// import appConfig from "./config";
// import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formFields: {
        email: {isValid: true, value: ''},
        password: {isValid: true, value: ''},
        passwordConfirm: {isValid: true, value: ''},
      },
      errorMessage: '',
      showPasswordChanged: false,
      showCodeExpired: false
    }
  }
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login = (e) => {
    const { formFields } = this.state;

    var that = this;
    utils.login(formFields.email.value, formFields.password.value).then(result => {
      utils.log('login success');
      that.goTo('projects');
      return null;

    }).catch(err => {
      utils.log('login failure');
      that.setState({
        errorMessage: 'Invalid Email or Password'
      })
    });

    e.preventDefault();
    e.stopPropagation();
  }

  logout() {
    this.props.auth.logout();
  }

  register = (e) => {
    const { formFields } = this.state;

    if (this.validateRegisterForm()) {
      var that = this;
      utils.register(formFields.email.value, formFields.password.value).then(result => {
        utils.log('success', result);

        //utils.createProject(result, sampleProject
        that.goTo('projects');
        return null;
      }).catch(err => {
        utils.log('failure', err);
        that.setState({
          errorMessage: err
        })
      });

    }

    e.preventDefault();
    e.stopPropagation();
  }

  validateRegisterForm() {
    const { formFields } = this.state;

    let errorMessage = '';

    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    formFields.email.isValid = emailRegex.test(formFields.email.value);

    // eslint-disable-next-line
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    formFields.password.isValid = strongRegex.test(formFields.password.value);

    formFields.passwordConfirm.isValid = formFields.password.value === formFields.passwordConfirm.value;

    this.setState({
      formFields
    });

    return formFields.email.isValid && formFields.password.isValid && formFields.passwordConfirm.isValid;
  }

  componentDidMount() {
    let { formFields } = this.state;

    formFields.email = {};
    formFields.code = {};

    formFields.email.value = utils.getCookie('email');
    formFields.code.value = this.props.match.params.code;

    utils.log(formFields);

    this.setState({
      formFields
    })

  }

  handleRegisterClick = () => {
    this.setState({
      showRegisterForm: !this.state.showRegisterForm,
      formFields: {
        email: {isValid: true, value: ''},
        password: {isValid: true, value: ''},
        passwordConfirm: {isValid: true, value: ''},
      },
      errorMessage: ''
    });
  }

  updateField = (field, value) => {
    let formFields = this.state.formFields;

    formFields[field].value = value;

    this.setState({
      formFields,
      errorMessage: ''
    });
  }

  responseGoogle = (authResult) => {
    utils.log(authResult);

    if (authResult.tokenId) {
      utils.googleLogin(authResult.tokenObj.id_token).then(response => {
        utils.log(response);
        this.goTo('projects');
      });


    }
  }

  handleForgotPassword = () => {
    this.setState({
      showForgotPassword: true
    });
  }

  handleResetPassword = (e) => {
    const { formFields } = this.state;

    var that = this;
    if (this.validateForm()) {
      utils.resetPassword(formFields['email'].value, formFields.code.value, formFields.password.value).then(result => {
        utils.login(formFields['email'].value, formFields.password.value).then(result => {
          that.goTo('projects');
        }).catch(err => {
          utils.log(err);
        })
      }).catch(err => {
        this.setState({
          showPasswordChanged: false,
          showCodeExpired: true
        })
      });
    }

    e.preventDefault();
    e.stopPropagation();
  }

  validateForm() {
    const { formFields } = this.state;

    let errorMessage = '';

    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    formFields.email.isValid = emailRegex.test(formFields.email.value);

    // eslint-disable-next-line
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    formFields.password.isValid = strongRegex.test(formFields.password.value);

    formFields.passwordConfirm.isValid = formFields.password.value === formFields.passwordConfirm.value;

    this.setState({
      formFields
    });

    return formFields.email.isValid && formFields.password.isValid && formFields.passwordConfirm.isValid;
  }

  render() {
    const { showRegisterForm, formFields, errorMessage, showPasswordChanged, showCodeExpired } = this.state;

    const toggleText = showRegisterForm ? 'Login' : 'Register';

    return (
      <div>
        <Header auth={this.props.auth} history={this.props.history}/>
        {showPasswordChanged ?
          <div>
            Password Changed Successfully.
          </div> : null
        }

        {showCodeExpired ?
          <div className="reset_codeExpiredContainer">
            <div className="reset_codeExpiredHeader">Sorry.</div>
            <div className="reset_codeExpiredText">The password reset code has expired.</div>
            <div className="reset_codeExpiredReturnToLogin" onClick={() => this.goTo('login')}>Return to login.</div>
          </div> : null
        }

        {!showPasswordChanged && !showCodeExpired ?
          <div className="login_standardLogin">
            <div className="module form-module">

              <div className="fixed-form">
                <h2>Reset Password</h2>
                <div className="login_text">Enter your new password below.</div>
                <form>
                  <input type="text" placeholder="Username" value={formFields.email.value} readOnly/>
                  <div>
                    <input type="password" className={'form-control' + (formFields.password.isValid ? '' : ' is-invalid')} placeholder="Enter your new password." value={formFields.password.value} onChange={evt => this.updateField('password', evt.target.value)}/>
                    <div className="invalid-feedback">
                      Please enter a valid password. Passwords must be at least 8 characters, contain at least one uppercase, lowercase, number, and special character (!$#).
                    </div>
                  </div>
                  <div>
                    <input type="password" className={'form-control' + (formFields.passwordConfirm.isValid ? '' : ' is-invalid')} placeholder="Confirm Password" value={formFields.passwordConfirm.value} onChange={evt => this.updateField('passwordConfirm', evt.target.value)}/>
                    <div className="invalid-feedback">
                      Please make sure your passwords match.
                    </div>
                  </div>
                  <button onClick={this.handleResetPassword}>Reset Password</button>
                  <div className="login_errorMessage">{errorMessage}</div>
                </form>
              </div>

            </div>
          </div> : null
        }
      </div>
    );
  }
}


export default Login;
