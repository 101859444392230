import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, AmazonCognitoIdentity } from 'amazon-cognito-identity-js';

var constants = require('../constants/constants');
var Promise = require('bluebird');
var axios = require('axios');
var invocationWords = require('../data/invocationWords');
let Querystring = require('querystring');
var lzstring = require('./lz-string.js');

var AWS = require("aws-sdk");
AWS.config.setPromisesDependency(require('bluebird'));
// AWS.config.update({
//   region: "us-east-1",
//   endpoint: "https://dynamodb.us-east-1.amazonaws.com"
// });

// var docClient = new AWS.DynamoDB.DocumentClient();
var dynamodb = new AWS.DynamoDB();

// const Stream = require('stream');
// const Speaker = require('speaker');

// Create an Polly client
// const Polly = new AWS.Polly({
//     signatureVersion: 'v4',
//     region: 'us-east-1'
// });



var poolData = {
  UserPoolId: 'us-east-1_SzrVFKloP', // Your user pool id here
  ClientId: '3skb302egdi0jdsne7a2dn89na' // Your client id here
};
var userPool = new CognitoUserPool(poolData);

var sayQueue = [];
var curPlayer = null;

var googleTimer = window.setInterval(() => {
  utils.log('Google Timer Check');
  let authType = localStorage.getItem('type');
  if (authType === 'google') {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    utils.log('ExpiresAt=', expiresAt);
    utils.log('Now=', new Date().getTime());
    if (new Date().getTime() > (expiresAt - 300000)) {
      utils.log('Token Expires Soon');
      utils.getNewGoogleToken();
    }
  }
}, 60000);


Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

var utils = {
  log: function (data, data1) {
    try {
      if (constants.isDebug) {
        if (data1) {
          console.log(data, data1);
        } else {
          console.log(data);
        }
      }
    } catch (err) {

    }
  },

  guid: function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) // eslint-disable-line
    )
  },

  isNumeric: function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  getTimestamp: function(d, includeTime) {
    let year = d.getUTCFullYear();
    let month = (d.getUTCMonth() + 1).toString().padStart(2,'0');
    let day = d.getUTCDate().toString().padStart(2,'0');

    let timeString = '00:00:00';
    if (includeTime) {
      let hours = d.getUTCHours().toString().padStart(2, '0');
      let minutes = d.getUTCMinutes().toString().padStart(2, '0');
      let seconds = d.getUTCSeconds().toString().padStart(2, '0');

      timeString = hours + ':' + minutes + ':' + seconds;
    }
    let ts = year + '-' + month + '-' + day + 'T' + timeString + 'Z';

    return ts;

  },

  getTimestampMonth: function(d) {
    let year = d.getUTCFullYear();
    let month = (d.getUTCMonth() + 1).toString().padStart(2,'0');

    let ts = year + '-' + month;

    return ts;
  },

  // setSession: function(authResult) {
  //   // Set the time that the access token will expire at
  //   let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
  //   localStorage.setItem('access_token', authResult.accessToken);
  //   localStorage.setItem('expires_at', expiresAt);
  // },

  setSession: function (authResult) {
    utils.log('Setting Local Storage', authResult);
    if (authResult.expiresIn) {
      let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('expires_at', expiresAt);
      
    }
    localStorage.setItem('access_token', authResult.accessToken);
    authResult.googleRefreshToken && localStorage.setItem('googleRefreshToken', authResult.googleRefreshToken);
    authResult.type && localStorage.setItem('type', authResult.type);
    localStorage.setItem('accessKeyId', authResult.accessKeyId);
    localStorage.setItem('secretAccessKey', authResult.secretAccessKey);
  
    utils.log('Setting Session', authResult);
  },

  isAuthenticated: function () {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    if (new Date().getTime() < expiresAt) {
      return true;
    }
    AWS.config.credentials && AWS.config.credentials.clearCachedId();

    return false;
  },



  logout: function () {
    return new Promise(function (resolve, reject) {
      utils.getCurrentUser().then(user => {

        utils.log("Logging out user: ", user);
        if (user && user.signOut) {
          user.signOut();
        }
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('type');
        localStorage.removeItem('accessKeyId');
        localStorage.removeItem('secretAccessKey');
        localStorage.removeItem('expires_at');

        AWS.config.credentials && AWS.config.credentials.clearCachedId();

        return resolve(null);
      }).catch(err => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('type');
        localStorage.removeItem('accessKeyId');
        localStorage.removeItem('secretAccessKey');
        localStorage.removeItem('expires_at');

        AWS.config.credentials && AWS.config.credentials.clearCachedId();

        return resolve(null);

      })
    });


  },

  forgotPassword: function (username) {
    var userData = {
      Username: username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        utils.log('call result: ' + result);
      },
      onFailure: function (err) {
        alert(err);
      },
      inputVerificationCode() {
        // var verificationCode = prompt('Please input verification code ' ,'');
        // var newPassword = prompt('Enter new password ' ,'');
        // cognitoUser.confirmPassword(verificationCode, newPassword, this);
      }
    });
  },

  resetPassword: function (username, code, newPassword) {
    return new Promise(function (resolve, reject) {
      var userData = {
        Username: username,
        Pool: userPool
      };

      var cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: function (result) {
          return resolve(result);
        },
        onFailure: function (err) {
          utils.log(err);
          return reject(err);
        },
      });
    });
  },

  setCookie: function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  getCookie: function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  getNewGoogleToken: function () {
    utils.log('Getting a new Google Refresh Token.');
    return new Promise(function (resolve, reject) {
      let refreshToken = localStorage.getItem('googleRefreshToken');
      utils.log('Google Refresh Token=', refreshToken);
      return utils.refreshGoogleToken(refreshToken).then(tokenData => {
        utils.log('Got new Google token:');
        localStorage.setItem('expires_at', new Date().getTime() + 3599000);
        return utils.googleLogin(tokenData.token).then(result => {
          if (result.identityId) {
            utils.log('still active');
            return utils.getUser(result.identityId).then(user => {
              if (user) {
                user.user_id = result.identityId;
              }
              return resolve(user);
            });
          } else {
            return reject();
          }
        }).catch(err => {
          utils.log(err);
          return reject(err);
        });
      }).catch(err => {
        utils.log(err);
        // TODO:  Logout User
        utils.log("Couldn't get google refresh Token");
      });
    });
  },

  getCurrentUser: function () {
    return new Promise(function (resolve, reject) {
      //   let authenticationType = localStorage.getItem('type');
      //   let token = localStorage.getItem('access_token');
      //
      //   if (authenticationType === 'google') {
      //     utils.log("is google login");
      //     return utils.googleLogin(token).then(result => {
      //       if (result.identityId) {
      //         return utils.getUser(result.identityId).then(user => {
      //           return resolve(user);
      //         });
      //       } else {
      //         utils.log("google login result=", result);
      //         return resolve(result);
      //       }
      //     }).catch(err => {
      //       utils.log(err);
      //       return reject(err);
      //     });
      //   }

      let authenticationType = localStorage.getItem('type');
      let token = localStorage.getItem('access_token');

      utils.log("Got Token:", token);
      if (authenticationType == 'google') {
        return utils.googleLogin(token).then(result => {
          if (result.identityId) {
            utils.log('still active');
            return utils.getUser(result.identityId).then(user => {
              if (user) {
                user.user_id = result.identityId;
                user.refreshToken && localStorage.setItem('googleRefreshToken', user.googleRefreshToken);
              }
              return resolve(user);
            });
          } else {
            utils.log('Failed Login');
            utils.log("google login result=", result);
            // Let's refresh the google token
            let refreshToken = localStorage.getItem('googleRefreshToken');
            utils.log('Google Refresh Token=', refreshToken);
            return utils.refreshGoogleToken(refreshToken).then(tokenData => {
              utils.log('Got new Google token:');
              return utils.googleLogin(tokenData.token).then(result => {
                if (result.identityId) {
                  utils.log('still active');
                  return utils.getUser(result.identityId).then(user => {
                    if (user) {
                      user.user_id = result.identityId;
                    }
                    return resolve(user);
                  });
                } else {
                  return reject();
                }
              }).catch(err => {
                utils.log(err);
                return reject(err);
              });
            }).catch(err => {
              utils.log(err);
              // TODO:  Logout User
              utils.log("Couldn't get google refresh Token");
            });

          }
        }).catch(err => {
          utils.log(err);
          // TODO:  Logout User
          utils.log('Ooops, Google Login failed');
          // Let's refresh the google token
          let refreshToken = localStorage.getItem('googleRefreshToken');
          utils.log('Google Refresh Token=', refreshToken);
          return utils.refreshGoogleToken(refreshToken).then(tokenData => {
            utils.log('Got new Google token:', tokenData);
            return utils.googleLogin(tokenData.token).then(result => {
              if (result.identityId) {
                utils.log('still active');
                return utils.getUser(result.identityId).then(user => {
                  if (user) {
                    user.user_id = result.identityId;
                  }
                  return resolve(user);
                });
              } else {
                return reject();
              }
            }).catch(err => {
              utils.log(err);
              return reject(err);
            });
          }).catch(err => {
            utils.log(err);
            // TODO:  Logout User
            utils.log("Couldn't get google refresh Token");
            return reject(err);
          });

        });

      } else {

        var cognitoUser = userPool.getCurrentUser();
        utils.log('Got User=', cognitoUser);
        if (cognitoUser != null) {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              return reject(err);
            } else {
              utils.log('session validity: ' + session.isValid());
              AWS.config.region = 'us-east-1';
              AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:e4274699-6439-4599-92bf-31a497bfab78', // your identity pool id here
                Logins: {
                  // Change the key below according to the specific region your user pool is in.
                  'cognito-idp.us-east-1.amazonaws.com/us-east-1_SzrVFKloP': session.getIdToken().getJwtToken()
                }
              });
              AWS.config.credentials.refreshPromise().then(results => {
                // utils.log('AWS identity set');
                utils.getUser(AWS.config.credentials.identityId).then(user => {
                  if (user == null) {
                    return resolve({});
                  } else {
                    user.user_id = AWS.config.credentials.identityId;
                    return resolve(user);
                  }

                }).catch(err => {
                  utils.log(err);
                  return reject(err);
                });

                return null;
              }).catch(err => {
                utils.log(err);
              });

            }
          });
        } else {
          // Give unauthenticated access
          AWS.config.region = 'us-east-1';
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-east-1:e4274699-6439-4599-92bf-31a497bfab78'
          });
          AWS.config.region = 'us-east-1';
          AWS.config.credentials.refreshPromise().then(results => {
            // utils.log('AWS identity set');

            return reject('User is not authenticated.');
          }).catch(err => {
            utils.log(err);
            return reject('User is not authenticated.');
          });

          return null;
        }
      }
    });
  },

  // getUserAttribute: function(attributeName) {
  //   return new Promise(function(resolve,reject) {
  //     return utils.getCurrentUser().then(cognitoUser => {
  //       cognitoUser.getUserAttributes(function(err, result) {
  //           if (err) {
  //             return reject(err);
  //           }
  //           utils.log('user attributes=', result);
  //           var results = result.find(p => {
  //             utils.log(p.getName());
  //             return p.getName() === attributeName;
  //           });
  //
  //           utils.log('results=', results);
  //           if (results ) {
  //             return resolve(results.getValue());
  //           } else {
  //             return resolve(null);
  //           }
  //       });
  //     });
  //   });
  //
  // },
  //
  // setUserAttribute: function(attributeName, attributeValue) {
  //   return utils.getCurrentUser().then(cognitoUser => {
  //     var attributeList = [];
  //     var att = {
  //         Name : attributeName,
  //         Value : attributeValue
  //     };
  //     var attribute = new CognitoUserAttribute(att);
  //     attributeList.push(attribute);
  //
  //     return new Promise(function(resolve,reject) {
  //       cognitoUser.updateAttributes(attributeList, function(err, result) {
  //           if (err) {
  //               return reject(err);
  //           }
  //           return resolve();
  //       });
  //     });
  //   });
  //
  // },

  login: function (email, password) {
    var authenticationData = {
      Username: email,//'jeff@voiceapps.com',
      Password: password, //'x29!73bVA',
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);

    var userData = {
      Username: authenticationData.Username,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);

    return new Promise(function (resolve, reject) {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          utils.setSession({ expiresIn: 30 * 24 * 60 * 60, authToken: result.getIdToken().getJwtToken() })
          return resolve(result);
        },

        onFailure: function (err) {
          return reject(err);
        },

      });
    });
  },

  googleLogin: function (token, user) {
    utils.log('starting google login');
    return new Promise(function (resolve, reject) {
      // Add the Google access token to the Cognito credentials login map.
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:e4274699-6439-4599-92bf-31a497bfab78',
        Logins: {
          'accounts.google.com': token
        }
      });

      AWS.config.region = 'us-east-1';

      // Obtain AWS credentials
      AWS.config.credentials.get(function () {
        // Access AWS resources here.
      });

      //utils.log('token=', token);
      var cognitoidentity = new AWS.CognitoIdentity();

      var params = {
        IdentityPoolId: 'us-east-1:e4274699-6439-4599-92bf-31a497bfab78', /* required */
        AccountId: '384874157925',
        Logins: {
          'accounts.google.com': token,
          /* '<IdentityProviderName>': ... */
        }
      };

      AWS.config.region = 'us-east-1';
      cognitoidentity.getId(params, function (err, data) {
        if (err) {
          utils.log(err, err.stack); // an error occurred
          return reject(err);
        }
        else {
          utils.log(data);           // successful response

          params = {
            IdentityId: data.IdentityId, /* required */
            //  CustomRoleArn: 'STRING_VALUE',
            Logins: {
              'accounts.google.com': token,
              /* '<IdentityProviderName>': ... */
            }
          };

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-east-1:e4274699-6439-4599-92bf-31a497bfab78', // your identity pool id here
            Logins: {
              // Change the key below according to the specific region your user pool is in.
              'accounts.google.com': token,
            }
          });
          return AWS.config.credentials.refreshPromise().then(results => {
            //utils.log('AWS identity set', token);

            if (user) {
              utils.log('Session 1');
              utils.setSession({
                expiresIn: 60 * 60,
                accessToken: token,
                googleRefreshToken: user && user.googleRefreshToken,
                type: 'google',
                //accessKeyId: data.Credentials.AccessKeyId,
                //secretAccessKey: data.Credentials.SecretKey
              });
              utils.log('got here');
              return utils.getUser(data.IdentityId).then(userInfo => {
                utils.log('got here 2');
                utils.log('User=', userInfo);

                if (userInfo) {
                  utils.log('User Already Exists');
                  user.user_id = data.identityId || AWS.config.credentials.identityId;
                  user.refreshToken = userInfo.refreshToken;
                  user.subscription = userInfo.subscription;
                  user.showProjectsIntro = userInfo.showProjectsIntro;
                  user.showDetailsIntro = userInfo.showDetailsIntro;
                  //user.googleRefreshToken = userInfo.googleRefreshToken;
                  return utils.updateUser(user).then(response => {
                    utils.log('Session 2');
                    utils.setSession({
                      expiresIn: 60 * 60,
                      accessToken: token,
                      googleRefreshToken: user.googleRefreshToken,
                      type: 'google',
                      //accessKeyId: data.Credentials.AccessKeyId,
                      //secretAccessKey: data.Credentials.SecretKey
                    });
                    // utils.addToList(user).then(response => {
                    //   return resolve(user);
                    // }).catch(err => {
                    //   utils.log(err);
                    return resolve(user);
                    // })

                  });
                } else {
                  utils.log("User Doesn't Exist!!");
                  window.fbq('trackSingle', 'CompleteRegistration');
                  window.ga('send', 'event', 'Registration', 'Sign Up', 'New Customer');
                  window.gtag_report_conversion(10);
                  window.LeadDyno.recordLead(user.userName);
                  utils.getGeolocationInfo(ipInfo => {
                    utils.sendProofEvent('registration', {
                      "type": "custom",
                      "email": user.userName,
                      "first_name": user.firstName,
                      "city": ipInfo.city,
                      "state": ipInfo.region,
                      "country": ipInfo.country,

                      "ip": ipInfo.query
                    }).catch(err => {
                      console.error(err);
                    });
                  }).catch(err => {
                    console.error(err);
                  });

                  return utils.createUser(user).then(response => {
                    utils.log('Session 3');
                    utils.setSession({
                      expiresIn: 60 * 60,
                      accessToken: token,
                      googleRefreshToken: user.googleRefreshToken,
                      type: 'google',
                      //accessKeyId: data.Credentials.AccessKeyId,
                      //secretAccessKey: data.Credentials.SecretKey
                    });
                    utils.addToList(user).then(response => {
                      return resolve(user);
                    }).catch(err => {
                      utils.log(err);
                      return resolve(user);
                    })

                  });
                }
              }).catch(err => {
                utils.log('getUser Error: ', err);
                return reject(err);
              });
              //return user;
            } else {
              return resolve({ identityId: data.IdentityId });
            }
          }).catch(err => {
            utils.log(err);
            return reject(err);
          });
          // cognitoidentity.getCredentialsForIdentity(params, function(err, data) {
          //   if (err) {
          //     utils.log(err, err.stack); // an error occurred
          //     return reject(err);
          //   } else {
          //     utils.log(data);           // successful response
          //     utils.setSession({
          //       expiresIn: 30 * 24 * 60 * 60,
          //       authToken: null,
          //       accessKeyId: data.Credentials.AccessKeyId,
          //       secretAccessKey: data.Credentials.SecretKey});
          //     return resolve(data);
          //   }
          // });
        }
      });
    });



    //utils.setSession({expiresIn: 30 * 24 * 60 * 60, authToken: 'g:' + token})
  },

  // logout: function() {
  //   return utils.getCurrentUser().then(cognitoUser => {
  //     if (cognitoUser != null) {
  //       cognitoUser.signOut();
  //     }
  //   }).catch(err => {
  //     utils.log(err);
  //   });
  // },

  register: function (email, password) {
    var attributeList = [];

    var dataEmail = {
      Name: 'email',
      Value: email
    };

    var attributeEmail = new CognitoUserAttribute(dataEmail);

    attributeList.push(attributeEmail);
    attributeList.push({ Name: 'custom:isNewUser', Value: '1' });

    return new Promise(function (resolve, reject) {
      userPool.signUp(email, password, attributeList, null, function (err, result) {
        if (err) {
          utils.log(err);
          return reject(err);
        }
        var cognitoUser = result.user;
        utils.log('user name is ' + cognitoUser.getUsername());
        // logout any other users to prevent issues
        utils.logout().then(response => {
          // login this new user
          utils.login(email, password).then(result => {
            utils.log('Cognito Login Success');
            return resolve(cognitoUser);
          }).catch(err => {
            return reject(err);
          });
          return null;
        }).catch(err => {
          utils.log(err);
        })

      });
    });

  },

  getUser: function (id) {
    return new Promise(function (resolve, reject) {
      utils.log('identityId=', AWS.config.credentials.identityId);
      utils.log('projectID=', id);
      var params = {
        TableName: "VoiceAppsCustomers",
        Key: {
          "UserId": {
            S: AWS.config.credentials.identityId
          }
        }
      };

      dynamodb = new AWS.DynamoDB();
      var getItemPromise = dynamodb.getItem(params).promise();
      getItemPromise.then(response => {
        utils.log("User=", response);

        if (response.Item) {
          let user = JSON.parse(response.Item.User.S);
          return resolve(user);
        } else {
          utils.log('The user does not exist.');
          return resolve(null);
        }

      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
      utils.log('end of promise');
    });
  },

  createUser: function (user) {
    return new Promise(function (resolve, reject) {
      utils.getCurrentUser().then((response) => {
        utils.log('identityId=', AWS.config.credentials.identityId);
        var params = {
          TableName: "VoiceAppsCustomers",
          Item: {
            "UserId": {
              S: AWS.config.credentials.identityId
            },
            "User": {
              S: JSON.stringify(user)
            },
            "CreatedDate": {
              S: new Date().toUTCString()
            }
          }
        };

        dynamodb = new AWS.DynamoDB();
        var putItemPromise = dynamodb.putItem(params).promise();
        putItemPromise.then(response => {
          return resolve(response);
        }).catch(err => {
          utils.log(err);
          return reject(err);
        });
        utils.log('end of promise');
      });
    });
  },

  updateUser: function (user) {
    return new Promise(function (resolve, reject) {
      utils.getCurrentUser().then((response) => {
        utils.log('identityId=', AWS.config.credentials.identityId);
        var params = {
          TableName: "VoiceAppsCustomers",
          Item: {
            "UserId": {
              S: AWS.config.credentials.identityId
            },
            "User": {
              S: JSON.stringify(user)
            }
          }
        };

        dynamodb = new AWS.DynamoDB();
        var putItemPromise = dynamodb.putItem(params).promise();
        putItemPromise.then(response => {
          return resolve(response);
        }).catch(err => {
          utils.log(err);
          return reject(err);
        });
        utils.log('end of promise');
      });
    });
  },




  createProject: function (project) {
    return new Promise(function (resolve, reject) {
      utils.log('identityId=', AWS.config.credentials.identityId);

      let summary = {
        id: project.id,
        name: project.name,
        iconSmall: project.iconSmall,
        description: project.description,
        membershipLevel: project.membershipLevel,
        IsFlashBriefing: project.IsFlashBriefing,
        isBusiness: project.isBusiness
      };

      var params = {
        TableName: "VoiceAppsProjects",
        Item: {
          "UserId": {
            S: AWS.config.credentials.identityId
          },
          "ProjectId": {
            S: project.id
          },
          "DateCreated": {
            S: utils.getTimestamp(new Date(), true)
          },
          "Project": {
            S: "1:" + lzstring.compressToBase64(JSON.stringify(project))
          },
          "Summary": {
            S: JSON.stringify(summary)
          }
        }
      };

      dynamodb = new AWS.DynamoDB();
      var putItemPromise = dynamodb.putItem(params).promise();
      putItemPromise.then(response => {
        return resolve(response);
      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
      utils.log('end of promise');
    });
  },

  getProjects: function (customerId, lastEvaluatedKey, results) {
    if (results == null) {
      results = [];
    }
    return new Promise(function (resolve, reject) {
      //utils.getCurrentUser().then((response) => {
        utils.log('identityId=', AWS.config.credentials.identityId);
        const id = customerId ? customerId : AWS.config.credentials.identityId;

        var params = {
          TableName: "VoiceAppsProjects",
          KeyConditionExpression: "UserId = :a",
          ProjectionExpression: "UserId, ProjectId, Summary, DateCreated",
          ExpressionAttributeValues: {
            ":a": {
              S: id
            }
          }
        };

        if (lastEvaluatedKey) {
          params.ExclusiveStartKey = lastEvaluatedKey;
        }

        dynamodb = new AWS.DynamoDB();
        var getItemsPromise = dynamodb.query(params).promise();
        return getItemsPromise.then(data => {
          utils.log('getItem REsponse', data);
          if (data.Items) { // We found the existing code
            let projects = [];
            data.Items.forEach(project => {
              let proj = project.Summary.S;
              proj = JSON.parse(proj);
              //console.log(proj);

              if (project.UserId.S !== AWS.config.credentials.identityId) {
                proj.isDemo = true;
              }
              if (project.DateCreated != null) {
                proj.dateCreated = project.DateCreated.S;
              }
              projects.push(proj);
            });

            results = results.concat(projects);
            if (data.LastEvaluatedKey) {
              return utils.getProjects(customerId, data.LastEvaluatedKey, []).then(response => {
                results = results.concat(response);
                return resolve(results);
              });
            } else {
              return resolve(results);
            }
          } else {
            return resolve(null);
          }
        }).catch(err => {
          utils.log(err);
          return reject(err);
        });
      // }).catch(err => {
      //   utils.log(err);
      //   return reject(err);
      // });
    });
  },

  deleteProject: function (id) {
    return new Promise(function (resolve, reject) {
      utils.log('identityId=', AWS.config.credentials.identityId);
      utils.log('projectID=', id);
      var params = {
        TableName: "VoiceAppsProjects",
        Key: {
          "UserId": {
            S: AWS.config.credentials.identityId
          },
          "ProjectId": {
            S: id
          }
        }
      };

      dynamodb = new AWS.DynamoDB();
      var deleteItemPromise = dynamodb.deleteItem(params).promise();
      deleteItemPromise.then(response => {
        return resolve(response);
      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
      utils.log('end of promise');
    });
  },

  getProject: function (id, productionVersion) {
    return new Promise(function (resolve, reject) {
      //console.log('identityId=', AWS.config.credentials.identityId);
      utils.log('projectID=', id);

      let tableName = 'VoiceAppsProjects';
      if (productionVersion === 1) {
        tableName = 'VoiceAppsProjects-Prod1';
      } else if (productionVersion === 2) {
        tableName = 'VoiceAppsProjects-Prod2';
      }

      var params = {
        TableName: tableName,
        Key: {
          "UserId": {
            S: AWS.config.credentials.identityId
          },
          "ProjectId": {
            S: id
          }
        }
      };

      dynamodb = new AWS.DynamoDB();
      var getItemPromise = dynamodb.getItem(params).promise();
      getItemPromise.then(response => {
        //console.log("project=", response);

        if (response.Item) {
          let proj = response.Item.Project.S;
          if (proj.startsWith("1:")) {
            proj = JSON.parse(lzstring.decompressFromBase64(proj.substring(2)));
          } else {
            proj = JSON.parse(proj);
          }
          if (response.Item.SkillId) {
            proj.skillId = response.Item.SkillId.S;
          }
          return resolve(proj);
        } else {
          utils.log(`That project doesn't exist.`);
          utils.log(response);
          return resolve(null);
        }

      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
      utils.log('end of promise');
    });
  },

  /** Create post */
  createPost: function (projectId, posts) {
    return new Promise(function (resolve, reject) {
      utils.log('identityId=', AWS.config.credentials.identityId);
      var params = {
        TableName: "VoiceAppsPosts",
        Item: {
          "UserId": {
            S: AWS.config.credentials.identityId
          },
          "ProjectYearMonthDay": {
            S: projectId
          },
          "Post": {
            S: JSON.stringify(posts)
          }
        }
      };

      dynamodb = new AWS.DynamoDB();
      var putItemPromise = dynamodb.putItem(params).promise();
      putItemPromise.then(response => {
        return resolve(response);
      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
      utils.log('end of promise');
    });
  },

  /* Get feed posts  */
  getPosts: function (projectId) {
    return new Promise(function (resolve, reject) {
      utils.log('identityId=', AWS.config.credentials.identityId);
      utils.log('projectID=', projectId);

      let tableName = 'VoiceAppsPosts';

      var params = {
        TableName: tableName,
        Key: {
          "UserId": {
            S: AWS.config.credentials.identityId
          },
          "ProjectYearMonthDay": {
            S: projectId
          }
        }
      };

      dynamodb = new AWS.DynamoDB();
      var getItemPromise = dynamodb.getItem(params).promise();
      getItemPromise.then(response => {
        utils.log("posts =", response);
        if (response.Item) {
          return resolve(JSON.parse(response.Item.Post.S));
        } else {
          utils.log(`That project doesn't exist.`);
          utils.log(response);
          return resolve([]);
        }

      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
      utils.log('end of promise');
    });
  },

  /* Get feed posts  */
  getPosts2: function(projectId) {
    return new Promise(function(resolve,reject) {

      var params = {
        ExpressionAttributeValues: {
         ":p1": {
           S: projectId
         },
         ":u1": {
           S: AWS.config.credentials.identityId
         }
        },
        ScanIndexForward: false,
        KeyConditionExpression: "UserId = :u1 AND begins_with(ProjectYearMonthDay, :p1)",
        TableName: "VoiceAppsPosts"
       };
       var queryPromise = dynamodb.query(params).promise();
       queryPromise.then(response => {
         // utils.log("project=", JSON.stringify(response));

         if (response.Items) {
           let result = [];
           response.Items.forEach(post => {
             result = result.concat(JSON.parse(post.Post.S));
           })
           return resolve(result);
         } else {
           let result = [];
           return resolve(result);
         }

       }).catch(err => {
         utils.log(err);
         return reject(err);
       });
     });
  },

  getProjectById: function (projectId) {
    return new Promise(function (resolve, reject) {

      var params = {
        ExpressionAttributeValues: {
          ":v1": {
            S: projectId
          }
        },
        KeyConditionExpression: "ProjectId = :v1",
        TableName: "VoiceAppsProjects",
        IndexName: "ProjectId-index"
      };

      dynamodb = new AWS.DynamoDB();
      var queryPromise = dynamodb.query(params).promise();
      queryPromise.then(response => {
        utils.log("project=", JSON.stringify(response));

        if (response.Items && response.Items.length > 0) {
          let proj = response.Items[0].Project.S;
          if (proj.startsWith("1:")) {
            proj = JSON.parse(lzstring.decompressFromBase64(proj.substring(2)));
          } else {
            proj = JSON.parse(proj);
          }
          if (response.Items[0].UserId.S !== AWS.config.credentials.identityId) {
            if (AWS.config.credentials.identityId === 'us-east-1:7d7ed42a-ef7a-4f6e-a9f8-94115de535e9') {
              proj.id = utils.guid();
              proj.skillId = null;
            } else {
              proj.isDemo = true;
            }
          }
          return resolve(proj);
        } else {
          return reject(response);
        }

      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
    });
  },

  updateProject: function (project, productionVersion) {
    return new Promise(function (resolve, reject) {
      let tableName = 'VoiceAppsProjects';
      if (productionVersion === 1) {
        tableName = 'VoiceAppsProjects-Prod1';
      } else if (productionVersion === 2) {
        tableName = 'VoiceAppsProjects-Prod2';
      }

      let summary = {
        id: project.id,
        name: project.name,
        iconSmall: project.iconSmall,
        description: project.description,
        membershipLevel: project.membershipLevel,
        IsFlashBriefing: project.IsFlashBriefing,
        isBusiness: project.isBusiness,
        subscription: project.subscription
      };

      utils.getCurrentUser().then(user => {

        utils.log('identityId=', AWS.config.credentials.identityId);

        var params = {
          TableName: tableName,
          Key: {
            "UserId": {
              S: AWS.config.credentials.identityId
            },
            "ProjectId": {
              S: project.id
            }
          },
          UpdateExpression: "set #c = :p, #s = :s, #cd = if_not_exists(#cd, :ts)",
          ExpressionAttributeValues: {
            ":p": {
              S: "1:" + lzstring.compressToBase64(JSON.stringify(project))
            },
            ":s": {
              S: JSON.stringify(summary)
            },
            ":ts": {
              S: utils.getTimestamp(new Date(), true)
            }
          },
          ExpressionAttributeNames: {
            '#c': 'Project',
            '#s': 'Summary',
            '#cd': 'DateCreated'
          },
          ReturnValues: "UPDATED_NEW"
        };

        dynamodb = new AWS.DynamoDB();
        var updateItemPromise = dynamodb.updateItem(params).promise();
        return updateItemPromise.then(response => {
          let proj = response.Attributes.Project.S;
          if (proj.startsWith("1:")) {
            proj = JSON.parse(lzstring.decompressFromBase64(proj.substring(2)));
          } else {
            proj = JSON.parse(proj);
          }
          return resolve(proj);
        }).catch(err => {
          utils.log(err);
          return reject(err);
        });
        utils.log('end of promise');
      }).catch(err => {
        return reject(err);
      });
    });
  },

  getSpeechAudio: function (ssml, locale, forceVoice) {
    return new Promise(function (resolve, reject) {
      utils.log(ssml);
      utils.log('Get Speech Audio: ', locale);
      let voice = 'Joanna';
      if (locale === 'en-GB') {
        voice = 'Amy';
      } else if (locale === 'en-IN') {
        voice = 'Aditi';
      } else if (locale === 'en-CA') {
        voice = 'Kendra';
      } else if (locale === 'en-AU') {
        voice = 'Nicole';
      } else if (locale === 'ja-JP') {
        voice = 'Mizuki';
      } else if (locale === 'fr-FR') {
        voice = 'Celine';
      } else if (locale === 'de-DE') {
        voice = 'Marlene';
      } else if (locale === 'it-IT') {
        voice = 'Carla';
      } else if (locale === 'es-ES') {
        voice = 'Penelope';
      }

      if (forceVoice) voice = forceVoice;

      axios.post('https://hc7smfv3e5.execute-api.us-east-1.amazonaws.com/production', {
        //axios.post('https://53608lpiqb.execute-api.us-east-1.amazonaws.com/Production', {
        speakText: ssml,
        voice: voice
      })
        .then(function (response) {
          utils.log(response);
          return resolve(response.data.presignedUrl);
          //sayQueue.push({ src: response.data.presignedUrl, player });

        })
        .catch(function (error) {
          utils.log(error);
          return reject(error);
        });
    });
  },

  sayText: function (ssml, player) {
    utils.getSpeechAudio(ssml).then((url) => {
      player.pause();
      player.src = url;
      player.load();
      player.play();
    });
  },

  play: function () {
    //utils.log(sayQueue);
    if (sayQueue.length > 0) {
      if (curPlayer == null || (curPlayer && curPlayer.paused)) {

        let say = sayQueue[0];
        curPlayer = say.player;
        say.player.src = say.src;
        say.player.load();
        say.player.play();
        sayQueue.splice(0, 1);

      }
    }

    window.setTimeout(utils.play, 500);

  },

  processVoiceInput: function (project, text, locale, previousResponse) {
    let that = this;
    return new Promise(function (resolve, reject) {
      var url = `https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/skills/${project.id}/simulations`;
      utils.log('url=', url);
      let content = text.toLowerCase();
      if (!invocationWords.includes(text.split(' ')[0])) {
        if (content.endsWith(project.invocationName.toLowerCase())) {
          content = `ask ${project.invocationName} ${project.invocationNameSuffix}`;
        } else {
          // content = `ask ${project.invocationName} ${project.invocationNameSuffix} ${content} `;
        }
      } else {
        if (content.endsWith(project.invocationName.toLowerCase())) {
          content = `ask ${project.invocationName} ${project.invocationNameSuffix} `;
        } else {
          // content = `ask ${project.invocationName} ${project.invocationNameSuffix} ${content} `;
        }
      }
      if (that.userId == null) {
        that.userId = utils.guid();
      }
      utils.log('Content=', content);
      axios.post(url,
        {
          "input": {
            "content": content
          },
          "device": {
            "locale": locale
          },
          "userId": that.userId,
          "attributes": previousResponse && previousResponse.body.sessionAttributes,
          "activeBlock": previousResponse && previousResponse.body.activeBlock,
          "activeScope": previousResponse && previousResponse.body.activeScope,
        }
      ).then((response) => {
        utils.log(response.data);
        let result = response.data.result;
        if (result.skillExecutionInfo && result.skillExecutionInfo.invocationResponse) {
          return resolve(result.skillExecutionInfo.invocationResponse);
          // } else if (result.error.message.startsWith('Simulation did not result')) {
          //   return utils.processVoiceInput(project, `for help`);
        } else {
          return reject(result.error);
        }
      }).catch((err) => {
        utils.log(err);
        return reject(err);
      })
    });
  },

  processAccessCode: function (code) {
    return new Promise(function (resolve, reject) {
      let userId = AWS.config.credentials.identityId;
      var url = `https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/account/${userId}/token`;
      utils.log('url=', url);
      axios.post(url,
        {
          "code": code
        },
        { headers: {'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':'*',
       }}

      ).then((response) => {
        utils.log(response);
        if (response.data.result === 'error') {
          utils.log("There was an error!!");
          return resolve(response.data.error.error);
        } else {
          return resolve({ result: 'success' });
        }

      }).catch((err) => {
        utils.log(err);
        return reject(err);
      })
    });
  },

  publishSkill: function (project, toProduction) {
    return new Promise(function (resolve, reject) {
      var url = `https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/skills/`;
      utils.log('url=', url);
      return axios.post(url,
        {
          "customerId": AWS.config.credentials.identityId,
          "projectId": project.id,
          "toProduction": toProduction
        },
        //{ headers: { 'X-Amz-Invocation-Type': 'Event','Access-Control-Allow-Origin': '*',
        //'Access-Control-Allow-Headers':'*', } }
        { headers: { 'X-Amz-Invocation-Type': 'Event',
          'Access-Control-Allow-Headers':'*',
          'Access-Control-Allow-Origin': '*',
        } }
      ).then((response) => {
        utils.log(response);
        if (response.data && response.data.error) {
          utils.log("There was an error!!", response.data.error);
          return resolve(response.data.error);
        } else {
          return resolve({ result: 'success' });
        }

      }).catch((err) => {
        utils.log(err);
        return reject(err);
      })
    });
  },

  withdrawSkill: function (skillId, data) {
    return new Promise(function (resolve, reject) {
      var url = `https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/skills/${skillId}/withdraw`;
      utils.log('url=', url);
      axios.post(url,
        data,
        {}
      ).then((response) => {
        utils.log(response);
        if (response.data && response.data.result === 'error') {
          return reject(response.data.result.error);
        } else {
          return resolve(response);
        }

      }).catch((err) => {
        utils.log(err);
        return reject(err);
      })
    });
  },

  searchIcons: function (searchText, isPremium) {
    return new Promise(function (resolve, reject) {
      axios.get(`https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/icons/token`).then(response => {
        const token = response.data.token;
        //utils.log("token=", token);
        axios.get(`https://api.iconfinder.com/v3/icons/search?query=${searchText.replace(/ /g, '+')}&count=100&size_minimum=512&license=none&vector=all&premium=${isPremium ? 1 : 0}`,
          { headers: { 'Authorization': 'JWT ' + token } }
        ).then(response => {
          return resolve(response.data.icons);
        }).catch(err => {
          utils.log(err);
          return reject(err);
        });
      }).catch(err => {
        utils.log(err);
        return reject(err);
      });
    });
  },

  enableSkill: function (skillId) {
    return axios.get(`https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/skills/${skillId}/enable`
    ).then(response => {
      return response.data;
    }).catch(err => {
      return err;
    });
  },

  updateSubscription: function (hostedPageId) {
    let accountId = AWS.config.credentials.identityId;
    return axios.post(`https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/account/${accountId}/subscription/${hostedPageId}`
    ).then(response => {
      return response.data;
    }).catch(err => {
      return err;
    });
  },

  addToList: function (user) {
    let accountId = AWS.config.credentials.identityId;
    return axios.post(`https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/account/${accountId}/list`
    ).then(response => {
      return response.data;
    }).catch(err => {
      return err;
    });
  },

  getGoogleToken: function (code, test) {
    return axios.post(`https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/account/google/token`,
      { code, test }
    ).then(response => {
      return response.data;
    }).catch(err => {
      return err;
    });
  },

  refreshGoogleToken: function (refreshToken) {
    return axios.post(`https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/account/google/refresh`,
      { refreshToken }
    ).then(response => {
      return response.data;
    }).catch(err => {
      return err;
    });
  },

  sendAffiliateData: function (orderId, type, value) {
    return axios.get(`https://shareasale.com/sale.cfm?amount=${value}&tracking=${orderId}&transtype=${type}&merchantID=80286`
    ).then(response => {
      return response.data;
    }).catch(err => {
      return err;
    });
  },

  addIntercomTag: function (tagName) {
    return axios.post('https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/intercom/tags',
      {
        "name": tagName,
        "users": [
          {
            "user_id" : AWS.config.credentials.identityId
          }
        ]
      }

    ).then((response) => {
      utils.log(response);
      return response.data;
    }).catch((err) => {
      utils.log(err);
      return err;
    })
  },

  sendProofEvent: function (eventType, data) {
    let url = 'https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/proof';

    let payload = {
      "eventType": eventType,
      "data": data
    };
    return axios.post(url,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    ).then(response => {
      utils.log(response);
      return response.data;
    }).catch(err => {
      console.error(err);
      return err;
    });
  },

  getGeolocationInfo: function() {
    let url = 'http://ip-api.com/json';

    return axios.get(url,
      { headers: { 'Content-Type': 'application/json' } }
    ).then(response => {
      utils.log(response.data);
      return response.data;
    }).catch(err => {
      console.error(err);
      return err;
    });
  },

  getCustomerStats: function (skillId, startDate, endDate) {
    let url = `https://3u57h8mtw3.execute-api.us-east-1.amazonaws.com/production/${skillId}/customers`;

    let payload = {
      "start": startDate,
      "end": endDate
    };
    return axios.post(url,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    ).then(response => {
      utils.log(response);
      return response.data;
    }).catch(err => {
      console.error(err);
      return err;
    });
  },

  getSessionStats: function (skillId, startDate, endDate) {
    let url = `https://3u57h8mtw3.execute-api.us-east-1.amazonaws.com/production/${skillId}/sessions`;

    let payload = {
      "start": startDate,
      "end": endDate
    };
    return axios.post(url,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    ).then(response => {
      utils.log(response);
      return response.data;
    }).catch(err => {
      console.error(err);
      return err;
    });
  },

  getIntentStats: function (skillId, startDate, endDate) {
    let url = `https://3u57h8mtw3.execute-api.us-east-1.amazonaws.com/production/${skillId}/intents`;

    let payload = {
      "start": startDate,
      "end": endDate
    };
    return axios.post(url,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    ).then(response => {
      utils.log(response);
      return response.data;
    }).catch(err => {
      console.error(err);
      return err;
    });
  },

  callAPI: function(url, method, body, headers, isRSS) {
    utils.log('callAPI', url, method, body, headers);

    let options = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    //{ headers: {"Authorization" : `${token}`, "Content-Type": "application/json"}}
    return new Promise(function(resolve,reject) {
      return axios.post('https://4e0ns6odj1.execute-api.us-east-1.amazonaws.com/Production/apitest',
        {
          "url": url,
          "method": method,
          "body": body,
          "headers": headers,
          "isRSS": isRSS
        },
        options
      ).then((response) => {
        utils.log("API Response=", response);
        if (response) {
          return resolve(response.data);
        } else {
          return resolve(response);
        }

      }).catch((err) => {
        utils.log(err);
        return reject(err);
      });
    });
  },

  getFileUploadUrl: function(fileName, fileType) {

      return axios.post('https://u3xh3lmrsi.execute-api.us-east-1.amazonaws.com/production',
        {
          fileName,
          fileType
        }
      ).then((response) => {
        console.log("Get Upload URL Response=", response);
        if (response && response.data) {
          let data = {
            signedUrl: response.data.url,
            finalUrl: response.data.finalUrl
          }
          return (data);
        } else {
          return ({signedUrl: '', finalUrl: ''})
        }
      }).catch((err) => {
        utils.log(err);
        return (err);
      });
  },

  uploadFile: function(file, signedUrl) {

      var options = {
        headers: {
          'Content-Type': file.type
        }
      };

      return axios.put(signedUrl, file, options)
        .then(response => {
          return (response);
        })
        .catch(err => {
          console.log(err);
          return (err);
        });

  },

  getUrl: function(url) {

    return axios.get(url)
      .then(response => {
        return response;
      })
      .catch(err => {
        //console.log(err);
        return err;
      });
  },

  isJSON: function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
  },

  replaceVariables: function(text, variables) {
    variables.forEach(keyvalue => {
      let varRegEx = new RegExp("{{?" + keyvalue.key + "}}", "gi");
      text = text.replace(varRegEx, keyvalue.value);
    })

    return text;
  },

  getRegexMatches: function(str1, regex1) {
    // Reset the regex
    regex1.lastIndex = 0;

    var results = [];
    var array1;

    while ((array1 = regex1.exec(str1)) !== null) {
      results.push(array1[1]);
    }

    return results;
  }


};



window.setTimeout(utils.play, 500);

export default utils;
