import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from './redux/actions/auth';
import { Switch, Route, withRouter } from 'react-router-dom';

import Authenticate from './pages/Authenticate';
import Dashboard from './pages/Dashboard';
import Upload from './pages/Upload';

import utils from '../helpers/utils';

import './styles/main.css';


import Header from '../Header';
import HeaderSecure from '../HeaderSecure';
import Navbar from './components/Navbar';

class Application extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoggedIn: false
		};
	}

	componentWillMount() {
		var that = this;
		utils.getCurrentUser().then(user => {
			that.setState({
				isLoggedIn: true
			});
		}).catch(err => {
			this.props.logout();
			that.setState({
				isLoggedIn: false
			});
		})
	}

	render() {
		let { isLoggedIn } = this.state;
		let user = this.props.user;
		utils.log("User=", user);
		// if (user === false) {
		// 	return (<Authenticate />);
		// }

		return (
			<div>
				{isLoggedIn ?
					<HeaderSecure auth={this.props.auth} history={this.props.history} />
					: <Header loginTarget="hosting"/>
				}
				<div className="hosting_header">Voice Apps Hosting</div>
				<div className="hosting_subheader">Audio and Image Hosting for your Alexa Skills</div>
				<div className="cw_container">


					<div className="divider" style={{ margin: '10px 0 30px' }} />

					<div className="columns">
						<div className="column col-12">
							<Switch>
								<Route exact path="/hosting/" component={Dashboard} />
								<Route path="/hosting/upload" component={Upload} />
							</Switch>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

Application.propTypes = {
	user: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({ user: auth.user });
const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Application);
