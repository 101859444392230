import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';

class VariablePicker extends Component {
  constructor(props) {
    super(props);

    //console.log("INITIAL VALUE=", props.initialValue);
    this.state = {
      showDropdown: false,
      variables: props.variables,
      filterVars: props.variables,
      currentValue: (props.initialValue != null && props.initialValue !== "") ? props.initialValue : 'Choose Variable',
      value: '',
      id: utils.guid()
    }
  }

  componentWillReceiveProps(props) {
    //console.log("______________________Variable PICKER_________________");
    //console.log("UPDATED VALUE=", props.initialValue);
    this.setState({
      currentValue: (props.initialValue != null && props.initialValue !== "") ? props.initialValue : 'Choose Variable',
      variables: props.variables
    })
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.mouseupHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseupHandler);
  }

  mouseupHandler = (event) => {
    const { id } = this.state;
    //console.log(id);
    let box = document.getElementById('vpick_container_' + id);
    var e = event.target;
    //console.log(event);
    // check for all children levels (checking from bottom up)
    while(e && e.parentNode && e.parentNode != window) {
        if (e.parentNode == box ||  e == box) {
            // if(e.preventDefault) {
            //   e.preventDefault();
            // }
            return false;
        }
        e = e.parentNode;
    }

    //console.log("got here");
    //console.log(box);
    // utils.log("EVENT TARGET", event.target, event.target.parentNode);
    // if (event.target !== box && event.target.parentNode !== box) {
      this.setState({
        showDropdown: false
      })
    // }
  }
  // onMouseOut(target, event) {
  //   utils.log("event=", event);
  //   //target is the original element the event handler was assigned to
  //   var e = event.toElement || event.relatedTarget;
  //
  //   utils.log("e=", e);
  //
  //   //check for all children levels (checking from bottom up)
  //   while(e && e.parentNode && e.parentNode != window) {
  //       if (e.parentNode == target ||  e == target) {
  //           if(e.preventDefault) {
  //             e.preventDefault();
  //           }
  //           return false;
  //       }
  //       e = e.parentNode;
  //   }
  //
  //   //Do something u need here
  //   this.setState({
  //     showDropdown: false
  //   })
  // }

  toggleDropdown = () => {
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  handleKeyPress = (e) => {
    let { variables } = this.props;

    let text = e.target.value;
    //console.log(text);

    let filterVars = variables;

    if (text !== '') {
      filterVars = variables.filter(v => v.indexOf(text) > -1);
    }

    this.setState({
      filterVars: filterVars,
      showDropdown: true,
      value: text
    })
  }

  handleBlur = () => {
    //console.log("Handle Blur");
    let { variables, value } = this.state;

    if (value !== '') {
      this.blur = window.setTimeout(() => {
        //console.log(variables[0]);

        this.setState({
          variables: this.props.variables,
          filterVars: this.props.variables,
          currentValue: variables[0],
          value: '',
          showDropdown: false
        })

        if (this.props.onChange) {
          this.props.onChange(variables[0]);
        }
      })

    }
  }

  handleClick = (e, index) => {
    let { filterVars } = this.state;

    //console.log(filterVars);
    //console.log(filterVars[index]);
    let selectedValue = filterVars[index];

    this.setState({
      variables: this.props.variables,
      filterVars: this.props.variables,
      currentValue: selectedValue,
      value: '',
      showDropdown: false
    })

    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }

    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  manageVariables = (e) => {
    //console.log("got here");
    window.va_showVariables();

    e.stopPropagation();
    e.preventDefault();

    window.clearTimeout(this.blur);
  }


  render() {
    const { showDropdown, currentValue, value, filterVars, id } = this.state;

    return (
      <div className="vp_container" id={"vpick_container_" + id} onClick={this.toggleDropdown} >
        <div className="vp_icon"/>
        <div className="vp_value">
          {value === '' && <div className="vp_currentValue">{currentValue}</div>}
          <input type="text" autoComplete="off" value={value} onChange={this.handleKeyPress} onBlur={this.handleBlur}/>
        </div>
        <div className="vp_caret">{showDropdown ? <FA icon={["fas", "caret-up"]} /> : <FA icon={["fas", "caret-down"]} />}</div>
        {showDropdown &&
          <div className="vp_dropdown">
            <div className="vp_dropdown_content">
              {filterVars.map((variable, index) => {
                return (
                  <div key={index} className="vp_item" onClick={(event) => this.handleClick(event, index)}>
                    <div className="vp_item_icon"></div>
                    <div className="vp_item_text">{variable}</div>
                  </div>
                )
              })}
            </div>
            <div className="vp_dropdown_links" onClick={this.manageVariables}>
              <div className="vp_link" >Manage Variables</div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default VariablePicker;
