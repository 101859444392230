import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import MultiText from '../MultiText';
import VoicePicker from '../VoicePicker';

class ContextualHelp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responses: props.responses,
      voice: props.voice
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      responses: props.responses,
      voice: props.voice
    });
  }

  handleResponsesChange = (data) => {
    const { voice } = this.state;

    this.setState({
      responses: data
    });

    if (this.props.onChange) {
      this.props.onChange({
        responses: data,
        voice,
      });
    }
  }

  handleChangeVoice = (voice) => {
    const { responses } = this.state;

    if (this.props.onChange) {
      this.props.onChange({
        responses,
        voice,
      });
    }
  }

  render() {
    const { responses } = this.state;

    utils.log("RESPONSES", responses);

    let { voice } = this.state;

    if (voice == null) {
      voice = 'Default';
    }

    return (
      <div className="intent_card">
        <div className="intent_cardHeader">Contextual Help</div>
        <div>If the user asks for help,</div>
        <VoicePicker selectedVoice={voice} afterText=" will say..." onChange={(voice) => this.handleChangeVoice(voice)} />
        <MultiText
          header="If the user asks for help, Alexa will say..."
          inputPlaceHolder="What should Alexa say?"
          inputPlaceHolder2="Enter another and Alexa will choose randomly (optional)."
          inputPlaceHolder3="Enter another random response (optional)."
          data={responses}
          variables={this.props.variables}
          onChange={(data) => this.handleResponsesChange(data)}
        />

      </div>
    );
  }
}

export default ContextualHelp;
