import React, { Component } from 'react';
import utils from '../helpers/utils.js';
import './styles.css';
import $ from 'jquery';
import FA from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

class ZoomControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lineType: props.lineType ? props.lineType : 0
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      lineType: props.lineType ? props.lineType : this.state.lineType
    })
  }



  componentDidMount() {

  }

  handleZoomIn = () => {
    if (this.props.onZoomIn) {
      this.props.onZoomIn();
    }
  }

  handleZoomOut = () => {
    if (this.props.onZoomOut) {
      this.props.onZoomOut();
    }
  }

  handleChangeLine = () => {

    let newLineType = this.state.lineType === 0 ? 1 : 0;
    this.setState({
      lineType: newLineType
    })

    if (this.props.onLineTypeChange) {
      this.props.onLineTypeChange(newLineType);
    }
  }

  handleFitAll = () => {
    if (this.props.onFitAll) {
      this.props.onFitAll();
    }
  }


  render() {
    const { lineType } = this.state;

    return (
      <div className="zc_container">
        <div className="zc_zoom zc_button">
          <div className="zc_zoom_plus" onClick={this.handleZoomIn} data-tip="Zoom In" data-place="left" data-delay-show="1000">+</div>
          <div className="zc_zoom_minus" onClick={this.handleZoomOut} data-tip="Zoom Out" data-place="left" data-delay-show="1000">-</div>
        </div>
        <div className="zc_lineSelector zc_button" onClick={this.handleChangeLine} data-tip="Change line style" data-place="left" data-delay-show="1000">
          {lineType === 1 ? <FA icon={["far", "wave-square"]} /> : <FA icon={["far", "wave-sine"]} />}
        </div>
        <div className="zc_fitAll zc_button" onClick={this.handleFitAll} data-tip="Zoom to Fit" data-place="left" data-delay-show="1000">
          <FA icon={["far", "object-group"]} />
        </div>
      </div>
    );
  }
}

export default ZoomControl;
