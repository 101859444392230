import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from '../redux/actions/auth';

const Navbar = (props) => (
	<header className="navbar mt-2">
		<section className="navbar-section">
			<Link to="/" className="navbar-brand mr-2">
				CandleWick
			</Link>
			<NavLink to="/hosting" exact className="btn btn-link" activeClassName="text-bold">Dashboard</NavLink>
			<NavLink to="/hosting/upload" exact className="btn btn-link" activeClassName="text-bold">Upload</NavLink>
		</section>
		<section className="navbar-section">
			<span className="text-bold">{props.user === '0' ? 'guest' : props.user}</span>
			{', '}
			<button type="button" className="btn btn-link" onClick={props.logout}>
				{'Sign out'}
			</button>
		</section>
	</header>
);

Navbar.propTypes = {
	user: PropTypes.string.isRequired,
	logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Navbar));
