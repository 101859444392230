var categories = [
  {
    name: 'Alarms & Clocks',
    value: 'ALARMS_AND_CLOCKS'
  },
  {
    name: 'Astrology',
    value: 'ASTROLOGY'
  },
  {
    name: 'Business & Finance',
    value: 'BUSINESS_AND_FINANCE'
  },
  {
    name: 'Calculators',
    value: 'CALCULATORS'
  },
  {
    name: 'Calendars & Reminders',
    value: 'CALENDARS_AND_REMINDERS'
  },
  {
    name: 'Childrens Education & Reference',
    value: 'CHILDRENS_EDUCATION_AND_REFERENCE'
  },
  {
    name: 'Childrens Games',
    value: 'CHILDRENS_GAMES'
  },
  {
    name: 'Childrens Music & Audio',
    value: 'CHILDRENS_MUSIC_AND_AUDIO'
  },
  {
    name: 'Childrens Novelty & Humor',
    value: 'CHILDRENS_NOVELTY_AND_HUMOR'
  },
  {
    name: 'Communication',
    value: 'COMMUNICATION'
  },
  {
    name: 'Connected Car',
    value: 'CONNECTED_CAR'
  },
  {
    name: 'Cooking & Recipe',
    value: 'COOKING_AND_RECIPE'
  },
  {
    name: 'Currency Guides & Converters',
    value: 'CURRENCY_GUIDES_AND_CONVERTERS'
  },
  {
    name: 'Dating',
    value: 'DATING'
  },
  {
    name: 'Delivery & Takeout',
    value: 'DELIVERY_AND_TAKEOUT'
  },
  {
    name: 'Device Tracking',
    value: 'DEVICE_TRACKING'
  },
  {
    name: 'Education & Reference',
    value: 'EDUCATION_AND_REFERENCE'
  },
  {
    name: 'Event Finders',
    value: 'EVENT_FINDERS'
  },
  {
    name: 'Exercise & Workout',
    value: 'EXERCISE_AND_WORKOUT'
  },
  {
    name: 'Fashion & Style',
    value: 'FASHION_AND_STYLE'
  },
  {
    name: 'Flight Finders',
    value: 'FLIGHT_FINDERS'
  },
  {
    name: 'Friends & Family',
    value: 'FRIENDS_AND_FAMILY'
  },
  {
    name: 'Game Info & Accessory',
    value: 'GAME_INFO_AND_ACCESSORY'
  },
  {
    name: 'Games',
    value: 'GAMES'
  },
  {
    name: 'Health & Fitness',
    value: 'HEALTH_AND_FITNESS'
  },
  {
    name: 'Hotel Finders',
    value: 'HOTEL_FINDERS'
  },
  {
    name: 'Knowledge & Trivia',
    value: 'KNOWLEDGE_AND_TRIVIA'
  },
  {
    name: 'Movie & TV Knowledge & Trivia',
    value: 'MOVIE_AND_TV_KNOWLEDGE_AND_TRIVIA'
  },
  {
    name: 'Movie Info & Reviews',
    value: 'MOVIE_INFO_AND_REVIEWS'
  },
  {
    name: 'Movie Showtimes',
    value: 'MOVIE_SHOWTIMES'
  },
  {
    name: 'Music & Audio Accessories',
    value: 'MUSIC_AND_AUDIO_ACCESSORIES'
  },
  {
    name: 'Music & Audio Knowledge & Trivia',
    value: 'MUSIC_AND_AUDIO_KNOWLEDGE_AND_TRIVIA'
  },
  {
    name: 'Music Info, Reviews, & Recognition Services',
    value: 'MUSIC_INFO_REVIEWS_AND_RECOGNITION_SERVICE'
  },
  {
    name: 'Navigation & Trip Planners',
    value: 'NAVIGATION_AND_TRIP_PLANNER'
  },
  {
    name: 'News',
    value: 'NEWS'
  },
  {
    name: 'Novelty',
    value: 'NOVELTY'
  },
  {
    name: 'Organizers & Assistants',
    value: 'ORGANIZERS_AND_ASSISTANTS'
  },
  {
    name: 'Pets & Animals',
    value: 'PETS_AND_ANIMAL'
  },
  {
    name: '',
    value: 'PODCAST'
  },
  {
    name: 'Public Transportation',
    value: 'PUBLIC_TRANSPORTATION'
  },
  {
    name: 'Religion & Spirituality',
    value: 'RELIGION_AND_SPIRITUALITY'
  },
  {
    name: 'Restaurant Bookging, Info, and Reviews',
    value: 'RESTAURANT_BOOKING_INFO_AND_REVIEW'
  },
  {
    name: 'Schools',
    value: 'SCHOOLS'
  },
  {
    name: 'Score Keeping',
    value: 'SCORE_KEEPING'
  },
  {
    name: 'Shopping',
    value: 'SHOPPING'
  },
  {
    name: 'Smart Home',
    value: 'SMART_HOME'
  },
  {
    name: 'Social Networking',
    value: 'SOCIAL_NETWORKING'
  },
  {
    name: 'Sports Games',
    value: 'SPORTS_GAMES'
  },
  {
    name: 'Sports News',
    value: 'SPORTS_NEWS'
  },
  {
    name: 'Streaming Services',
    value: 'STREAMING_SERVICE'
  },
  {
    name: 'Taxi & Ridesharing',
    value: 'TAXI_AND_RIDESHARING'
  },
  {
    name: 'To Do Lists & Notes',
    value: 'TO_DO_LISTS_AND_NOTES'
  },
  {
    name: 'Translators',
    value: 'TRANSLATORS'
  },
  {
    name: 'TV Guides',
    value: 'TV_GUIDES'
  },
  {
    name: 'Unit Converters',
    value: 'UNIT_CONVERTERS'
  },
  {
    name: 'Weather',
    value: 'WEATHER'
  },
  {
    name: 'Wine & Beverage',
    value: 'WINE_AND_BEVERAGE'
  },
  {
    name: 'Zip Code Lookup',
    value: 'ZIP_CODE_LOOKUP'
  }

];

module.exports = categories;
