import React, { Component } from 'react';
import './styles.css';
import FA from '@fortawesome/react-fontawesome';
import MultiText from '../MultiText';
import VoicePicker from '../VoicePicker';

class AlexaResponse extends Component {
  constructor(props) {
    super(props);

    //console.log("AlexaResponse", props);
    this.state = {
      responses: props.data.responses,
      responseVoice: props.data.voice,
      visitNumber: ''
    };
  }

  componentWillReceiveProps(props) {
    //console.log("AlexaResponseWR:", props);
    this.setState({
      responses: props.data.responses,
      responseVoice: props.data.voice
    })
  }

  handleResponsesChange = (data) => { // eslint-disable-line
    const { responseVoice } = this.state;

    if (this.props.onChange) {
      this.props.onChange({
        responses: data,
        voice: responseVoice
      });
    }
  }

  handleChangeVoice = (voice) => {
    const { responses } = this.state;
    const { isPremium, onShowPremiumMember } = this.props;

    if (isPremium) {
      if (this.props.onChange) {
        this.props.onChange({
          responses: responses,
          voice: voice
        });
      }
    } else {
      onShowPremiumMember();
    }
  }

  deleteIntent = () => { // eslint-disable-line
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    let { responses, responseVoice } = this.state;
    const { isPremium, onShowPremiumMember } = this.props;

    if (responseVoice == null) {
      responseVoice = 'Default';
    }
    return (
      <div className="intent_card">
        <div className="intent_cardHeader"><span className="intent_icon_alexa intent_header_icon"/>Voice Response</div>
        <div className="intent_delete" onClick={this.deleteIntent}><FA icon={["far", "trash"]} /></div>
        <VoicePicker selectedVoice={responseVoice} afterText=" will say..." onChange={(voice) => this.handleChangeVoice(voice)} />

        <MultiText
          inputPlaceHolder="What do you want to say?"
          inputPlaceHolder2="Enter another and we'll choose randomly."
          inputPlaceHolder3="Enter another random response (optional)."
          data={responses}
          variables={this.props.variables}
          allowVariables={true}
          isPremium={isPremium}
          onShowPremiumMember={onShowPremiumMember}
          onChange={this.handleResponsesChange}
        />
      </div>
    );
  }
}

export default AlexaResponse;
