import React, { Component } from 'react';
import './index.css';
import FA from '@fortawesome/react-fontawesome';
import HeaderSecure from '../HeaderSecure';
//import Footer from '../Footer';
import EditProject from '../EditProject';
import Spinner from '../Spinner';
import PremiumMember from '../PremiumMember';

import $ from 'jquery';

import utils from '../helpers/utils.js';

import projectData from './sampleData.js';
var sampleProject = projectData[0];

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProject: {name:null},
      projectTemplate: null,
      formFields: {
        projectName: {
          isValid: false,
          value: ''
        },
        invocationName: {
          isValid: false,
          value: ''
        },
        description: {
          isValid: false,
          value: ''
        }
      },
      projects: [],
      demos: [],
      showDemos: true,
      isNewProject: false,
      isCopyProject: false,
      showLoading: true,
      isPremium: true
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  //componentWillMount() {
    // utils.getUserAttribute('custom:isNewUser').then(value => {
    //   utils.log('custom:isNewUser=', value);
    //   if (value === '1') {
    //     utils.createProject(sampleProject).then(result => {
    //       utils.log('DB Response=', result);
    //       utils.setUserAttribute('custom:isNewUser', '0').then(result => {
    //         utils.log('Set isNewUser = 0');
    //         return null;
    //       });
    //       return null;
    //     });
    //   }
    //   return null;
    // }).catch(err => {
    //   utils.log(err);
    //   //utils.logout();
    //   //this.goTo('login');
    // });
  //}

  componentWillMount() {
    var that = this;
    utils.getCurrentUser().then(user => {
      this.setState({
        isPremium: user.subscription ? user.subscription : false
      });

      utils.getProjects().then(response => {
        response.sort(function(a, b){
            if (a.dateCreated == null) {
              a.dateCreated = '2000-01-01';
            }
            if (b.dateCreated == null) {
              b.dateCreated = '2000-01-01';
            }
            return (new Date(b.dateCreated) - new Date(a.dateCreated));
        });
        utils.log("project list", response);
        that.setState({
          projects: response,
          showLoading: false
        });

        window.Intercom("boot", {
          app_id: "rxv0p3l3",
          name: user.firstName + ' ' + user.lastName, // Full name
          email: user.userName, // Email address
          user_id: user.user_id,
          created_at: user.signUpDate // Signup date
        });

        //window.setTimeout(that.startTour, 200);
      }).catch(err => {
        utils.log(err);
      });

      utils.getProjects('us-east-1:f96a3e5a-1fc3-4764-828b-2554bc6a436d').then(response => {
        that.setState({
          demos: response,
        });

        //window.setTimeout(that.startTour, 200);
      }).catch(err => {
        utils.log(err);
      });

      return null;
    }).catch(err => {
      utils.log(err);
      that.goTo('login');
    })
  }

  componentDidMount() {
    // utils.getCurrentUser().then(user => {
    //   if (user.showProjectsIntro == null || user.showProjectsIntro === true) {
        // var intro = window.introJs();
        // intro.setOptions({
        //   steps: [
        //     {
        //       intro: "Welcome to Voice Apps! <br/><br/>Since this is your first time here, I thought I would take a moment to show you around.<br /><br />This is the Project page where you will create and manage your projects.",
        //     },
        //     {
        //       element: document.querySelector('#your_projects'),
        //       intro: "This is where you will find all of your projects."
        //     },
        //     {
        //       element: document.querySelector('#demo_projects'),
        //       intro: "We've provided many sample projects for you to play with so you can see how things are done or give you new ideas. You can even use them as a starting point for your own skills!",
        //       position: 'top-right'
        //     },
        //     {
        //       element: document.querySelector('#newProject'),
        //       intro: "You can create a new project by clicking here."
        //     },
        //     {
        //       element: document.querySelector('#help_link'),
        //       intro: "To help you learn all the great features of Voice Apps, we've created many training articles and video tutorials.  You can find them here."
        //     },
        //     {
        //       element: document.querySelector('#intercom_placeholder'),
        //       intro: "You can always send us a message and we'll be happy to help you in real-time.  Let us know if you need assistance, have a suggestion, or just want to say hi!  We're here for YOU!",
        //     },
        //   ]
        // });
        //
        // intro.onexit(function() {
        //   utils.log('Exiting Projects Intro');
        //   utils.getCurrentUser().then(user => {
        //     user.showProjectsIntro = false;
        //     utils.updateUser(user);
        //   });
        // });
        //
        // window.setTimeout(() => {
        //   intro.start();
        // }, 2000);

    //   }
    //
    // });

    // Event snippet for Sign Ups conversion page
    window.gtag('event', 'conversion', {'send_to': 'AW-823047199/Tj2wCNCvhYABEJ_ouogD'});

  }

  handleProjectClick = (id, isDemo, isFlashBriefing, isBusiness) => { // eslint-disable-line

    if (isDemo) {
      window.ga('send', 'event', 'Skill Making', 'Template', id);
      this.goTo(`project/${id}/true`);
    } else if(isFlashBriefing){
      this.goTo(`posts/${id}`);
    } else if (isBusiness) {
      this.goTo(`business/${id}`);
    } else {
      this.goTo(`project/${id}`);
    }

  }

  handleProjectEdit = (e, id, isBusiness) => { // eslint-disable-line
    utils.log('e=', e);
    let that = this;

    if (isBusiness) {
      this.goTo(`business/${id}`);
    } else {
      utils.getProject(id).then(project => {
        utils.log("selected project=", project);
        that.setState({
          selectedProject: project,
          isNewProject: false,
          isCopyProject: false
        });
        $('#EditProjectModal').modal('show');
      })
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  handleProjectDelete = (e, id) => { // eslint-disable-line
    const project = this.state.projects.find(obj => obj.id === id);
    utils.log("selected project=", project);
    this.setState({
      selectedProject: this.state.projects.find(obj => obj.id === id)
    });
  }

  handleDeleteConfirm = () => { // eslint-disable-line
    let id = this.state.selectedProject.id;
    utils.deleteProject(id).then(response => {
      utils.log(response);
      let projects = this.state.projects;
      const newProjects = projects.filter(obj => !(obj.id === id));

      this.setState({
        projects: newProjects
      });
    }).catch(err => {
      utils.log(err);
    });

    $('#deleteModal').modal('toggle');
  }

  handleProjectCreate = () => { // eslint-disable-line
    const newProject = {
      "id": utils.guid(),
      "templateId": null,
      "name": '',
      "invocationName": '',
      locales: [
      ],
    };

    this.setState({
      selectedProject: newProject,
      isNewProject: true,
      isCopyProject: false
    });

    window.fbq('trackSingle', '211310806174815', 'StartNewProject');
    window.ga('send', 'event', 'Skill Making', 'New Project', 'Button');

    setTimeout(() => {
      $('#EditProjectModal').modal('show');
    }, 100);

  }


  handleFlashBriefingProjectCreate = () => { // eslint-disable-line
    const newProject = {
      "id": utils.guid(),
      "templateId": null,
      "name": '',
      "IsFlashBriefing": true,
      locales: [
      ],
    };

    this.setState({
      selectedProject: newProject,
      isNewProject: true,
      isCopyProject: false
    });

    window.fbq('trackSingle', '211310806174815',  'StartNewProject');
    window.ga('send', 'event', 'Skill Making', 'New Project', 'Button');

    setTimeout(() => {
      $('#EditProjectModal').modal('show');
    }, 100);

  }

  handleBusinessProjectCreate = () => {
    this.goTo('business');
  }

  handleMenuClick = (e) => { // eslint-disable-line
    utils.log('menu clicked');

    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  handleProjectUpdated = () => { // eslint-disable-line
    var that = this;
    utils.getProjects().then(response => {
      utils.log(response);
      response.sort(function(a, b){
          if (a.dateCreated == null) {
            a.dateCreated = '2000-01-01';
          }
          if (b.dateCreated == null) {
            b.dateCreated = '2000-01-01';
          }
          return (new Date(b.dateCreated) - new Date(a.dateCreated));
      });
      that.setState({
        projects: response,
      });
    }).catch(err => {
      utils.log(err);
    });
  }

  toggleDemoPanel = () => { // eslint-disable-line
    this.setState({
      showDemos: !this.state.showDemos,
    });
  }

  handleCopyProject = (e, id) => {
    const { isPremium } = this.state;

    //console.log(id);

    let that = this;
    utils.getProjectById(id).then(project => {
      if (project.membershipLevel>0 && !isPremium) {
        that.handlePremiumModalOpen();
      } else {
        project.id = utils.guid();
        project.isDemo = false;

        this.setState({
          selectedProject: project,
          isNewProject: false,
          isCopyProject: true
        });

        setTimeout(() => {
          $('#EditProjectModal').modal('show');
        }, 100);
      }
    })
  }

  handleDuplicateProject = (e, id) => {

    utils.getProject(id).then(project => {
      project.id = utils.guid();
      project.isSharingEnabled = false;
      project.skillId = null;
      project.testEmail = null;
      project.isDemo = null;

      this.setState({
        selectedProject: project,
        isNewProject: false,
        isCopyProject: true
      });

      setTimeout(() => {
        $('#EditProjectModal').modal('show');
      }, 100);
    })
  }

  setPremium = () => {
    this.handlePremiumModalClose();
    $('#EditProjectModal').modal('show');
    this.setState({
      isPremium: true
    })
  }

  handlePremiumModalOpen = () => { // eslint-disable-line
    //$('#EditProjectModal').modal('hide');
    $('#PremiumModal').modal('show');
  }

  handlePremiumModalClose = () => { // eslint-disable-line
    $('#PremiumModal').modal('hide');
  }

  render() {
    const { selectedProject, projects, isNewProject, isCopyProject, demos, showDemos, showLoading, isPremium } = this.state;

    let selectedProjectCopy = JSON.parse(JSON.stringify(selectedProject));
    var projectGroups = [];
    var i,j,chunk = 4;
    for (i=0,j=projects.length; i<j; i+=chunk) {
        projectGroups.push(projects.slice(i,i+chunk));
    }

    var demoGroups = [];
    for (i=0,j=demos.length; i<j; i+=chunk) {
        demoGroups.push(demos.slice(i,i+chunk));
    }

    utils.log("render: selected project=", selectedProject);
    return (
      <div className="projects_full">
        <div id="intercom_placeholder"></div>
        <EditProject history={this.props.history} isPremium={isPremium} project={selectedProjectCopy} isNew={isNewProject} isCopy={isCopyProject} onProjectUpdated={this.handleProjectUpdated} onShowPremiumMember={this.handlePremiumModalOpen}/>

        <div className="modal fade slide" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header modal-header-primary">
                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete Project</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete project {selectedProject.name}?</p>
                <p>This action cannot be undone.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-danger" onClick={this.handleDeleteConfirm}>Delete</button>
              </div>
            </div>
          </div>
        </div>



        <HeaderSecure menuId={2} auth={this.props.auth} history={this.props.history} isProfessional={isPremium} />
        <div id="your_projects" className="projectsContainer">
          <div className="container-fluid">
            <div id="projects" className="row proj_headerRow">
              <div className="col text-left proj_yourProjectsHeader">
                Your Projects
              </div>
              <div className="col text-right">
              <div className="dropdown">
                <a className="btn btn-primary dropdown-toggle" type="button" href="#" id="newProjectButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Create New Project
                </a>
                <div className="dropdown-menu" aria-labelledby="newProjectButton">
                  <a className="dropdown-item" onClick={this.handleProjectCreate} href="#" >Custom Skill</a>
                  <a className="dropdown-item" onClick={this.handleFlashBriefingProjectCreate} href="#">Flash Briefing</a>
                  <a className="dropdown-item" onClick={this.handleBusinessProjectCreate} href="#">Business Skill</a>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {showLoading ?
              <div className={"proj_loadingContainer"+ (showDemos ? ' active' : '')}>
                <div className="proj_spinnerBox"><Spinner size={30}/></div>
                <div className="proj_spinnerText">Loading Projects...</div>
              </div>
              :
              projectGroups.length > 0 ?

              projectGroups.map(function(group, g) {

                return (
                  <div key={g} className="row">
                    {
                      group.map(function(project, p) {
                        let id = '';
                        if (p===0) {
                          id = 'firstCard';
                        }

                        let defaultCartClass = "col col-12 col-sm-6 col-xl-3"
                        if(project.IsFlashBriefing){
                          defaultCartClass = "col col-12 col-sm-6 col-xl-3 flash-briefing-card";
                        }

                        //utils.log('selectedFeature', selectedFeature);
                        const featureClass = 'card' + ((selectedProject && (selectedProject.id === project.id)) ? '' : '');
                        return (
                          <div id={id} key={p} className={defaultCartClass} >
                            <div className={featureClass} onClick={() => this.handleProjectClick(project.id, false, project.IsFlashBriefing, project.isBusiness)}>
                              <div className="dropdown" onClick={this.handleMenuClick}>
                                <div className="cardMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <FA icon="ellipsis-v" />
                                </div>
                                <div className="dropdown-menu-right dropdown-menu" style={{minWidth: '120px', width: '120px', marginRight: '30px'}}>
                                  {project.isBusiness && project.subscription && <button className="dropdown-item" type="button" onClick={() => this.goTo("analytics/" + project.id)}>Analytics</button>}
                                  <button className="dropdown-item" type="button" onClick={(e) => this.handleProjectEdit(e, project.id, project.isBusiness)}>Edit</button>
                                  <button className="dropdown-item" type="button" onClick={(e) => this.handleDuplicateProject(e, project.id)}>Duplicate</button>
                                  <button className="dropdown-item" type="button" data-toggle="modal" data-target="#deleteModal" onClick={(e) => this.handleProjectDelete(e, project.id)}>Delete</button>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="card-title-box">
                                  <div className="card-icon">

                                  </div>
                                  <div className="card-icon-container">
                                    <img src={project.iconSmall} alt={project.name} />
                                  </div>
                                  <h4 className="card-title">{project.name}</h4>
                                </div>
                                <p className="card-text">{project.description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      }, this)
                    }
                  </div>
                );
              }, this)
              :
              <div className={"proj_noProjectsContainer" + (showDemos ? ' active' : '')}>
                <div className="proj_noProjectsHeader">You don&apos;t have any projects.</div>
                <div className="proj_noProjectsText">Click the "Create New Project" button above to create one.</div>
                <div className="proj_noProjectsText">You can also view the sample projects below or click on the ellipsis icon next to one to use it as a starting point for your new project.</div>
              </div>
            }




          </div>


        </div>
        <div id="demo_projects" className={"proj_demoContainer" + (showDemos ? ' active' : '')}>
          <div className="proj_demoContainerTab" onClick={this.toggleDemoPanel}>Sample Projects</div>
          <div className="proj_demoScrollContainer">
          {demoGroups.map(function(group, g) {

            return (
              <div key={g} className="row">
                {
                  group.map(function(project, p) {
                    let id = '';
                    if (p===0) {
                      id = 'firstCard';
                    }
                    //utils.log('selectedFeature', selectedFeature);
                    const featureClass = 'card' + ((selectedProject && (selectedProject.id === project.id)) ? '' : '');
                    return (
                      <div  id={id} key={p} className="col col-12 col-sm-6 col-xl-3" onClick={() => this.handleProjectClick(project.id, true)}>

                        <div className={featureClass}>
                          <div className="dropdown" onClick={this.handleMenuClick}>
                            <div className="cardMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <FA icon="ellipsis-v" />
                            </div>
                            <div className="dropdown-menu-right dropdown-menu" style={{minWidth: '120px', width: '120px', marginRight: '30px'}}>
                              <button className="dropdown-item" type="button" onClick={(e) => this.handleCopyProject(e, project.id)}>Create Copy</button>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="card-title-box">
                              <div className="card-icon">

                              </div>
                              <div className="card-icon-container">
                                <img src={project.iconSmall} alt={project.name} />
                              </div>
                              <h4 className="card-title">{project.name}</h4>
                            </div>
                            <p className="card-text">{project.description}</p>
                          </div>
                          {project.membershipLevel===1 && <div className="project_premium"><FA icon="usd-circle"/></div>}
                        </div>
                      </div>
                    );
                  }, this)
                }
              </div>
            );
          }, this)}
          </div>

        </div>
        <PremiumMember onSuccess={this.setPremium} history={this.props.history}/>


      </div>
    );
  }
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {  // eslint-disable-line
    value: function(predicate) {
     // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    }
  });
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", { // eslint-disable-line
    enumerable: false,
    value: function(obj) {
        var newArr = this.filter(function(el) {
          return el == obj;
        });
        return newArr.length > 0;
      }
  });
}

export default Projects;
